import React, { useState } from "react";
import PropTypes from "prop-types";
import { createClass, createConfirmAlert, createGroup, sanitizeWords, toTimeWithTimeZone } from "../../../common/utilities/helper";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import { useUpsertEmployeeDeductions } from "./hooks";
import { BASE_CLASS, DEDUCTION_PAYMENT_TYPE, FIELD } from "./const";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import Select from "../../../common/components/extra/select/Select";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import { NOTES_MAX_LEN } from "../../../common/utilities/const";
import EmployeeSelectLazy from "../employees/EmployeeSelectLazy";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";

function UpdateModal({ open, onClose, onBack, onFinish, id }) {
    const isCreate = !id;

    const [form, updateForm, { upsert, hasChanges, isGettingRecord, isUpserting, config }] = useUpsertEmployeeDeductions(id);
    const [error, setError] = useState({ all: null });

    const disableSave = isGettingRecord || (!isCreate && !hasChanges) || isUpserting;
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;
    const currency = setting.currency;
    const hideFields = config.hasConfirmedDate || config.isCancelled;

    const handleSave = async () => {
        const result = await upsert();
        if (!result.error) {
            if (error.all) {
                setError({ ...error, all: null });
            }
            typeof onFinish === "function" && onFinish(result);
        }
        if (result.error) {
            setError({ ...error, all: result.error.message });
        }
        return result.error;
    };

    const handleFormChange = ({ name, value } = {}) => {
        const temp = { [name]: value };
        switch (name) {
            case FIELD.START_DATE: {
                const start = toTimeWithTimeZone(value, timezone);
                const end = toTimeWithTimeZone(form[FIELD.END_DATE], timezone);
                if (end && start && start.isSameOrAfter(end)) {
                    temp[FIELD.END_DATE] = "";
                    setError({
                        ...error,
                        [FIELD.END_DATE]: "The end date has been reset. The start date cannot exceed the end date."
                    });
                }
                break;
            }
            case FIELD.END_DATE: {
                const start = toTimeWithTimeZone(form[FIELD.START_DATE], timezone);
                const end = toTimeWithTimeZone(value, timezone);
                if (start && end && end.isSame(start)) {
                    temp[FIELD.END_DATE] = ""; // just reset to show the placeholder
                }
                if (error[FIELD.END_DATE]) {
                    setError({ ...error, [FIELD.END_DATE]: null });
                }
                break;
            }
            default:
                if (error[FIELD.END_DATE]) {
                    setError({ ...error, [FIELD.END_DATE]: null });
                }
                break;
        }
        updateForm(temp);
    };

    const createTitle = () => {
        return (
            <div className="flex gap-05">
                <span className={!isCreate ? "fade" : ""}>{isCreate ? "Create" : "Update"} Deduction</span>
                {!isCreate && (
                    <>
                        <span className="fade">-</span>
                        <span>{sanitizeWords(config?.employee?.fullName)}</span>
                    </>
                )}
            </div>
        );
    };

    return (
        <BaseUpdateModal
            open={open}
            onClose={onClose}
            onBack={(!isCreate && onBack) || null}
            onSave={(e) =>
                createConfirmAlert({
                    title: !isCreate ? "Update Record" : "Create Record",
                    content: `Are you sure you want to ${isCreate ? "create" : "update"} this record? This cannot be undone.`,
                    onConfirm: async (close) => {
                        close();
                        const hasError = await handleSave(e);
                        if (!hasError) {
                            onBack();
                        }
                    }
                })
            }
            disableSave={disableSave}
            isLoading={isUpserting}
            isForm
        >
            {(!!error.all || hideFields) && (
                <div className="flex column gap-05" style={{ marginBottom: "1rem" }}>
                    <SectionCollapseError show={!!error.all}>{error.all}</SectionCollapseError>
                    <SectionCollapseInfo title="Reminder" show={hideFields}>
                        Only notes can be edited when the record is confirmed or canceled.
                    </SectionCollapseInfo>
                </div>
            )}
            {createGroup({
                base: createClass("__modal-content-update", BASE_CLASS),
                title: createTitle(),
                body: (
                    <div className="flex column gap-05">
                        {!hideFields && (
                            <div className="flex gap-1 wrap">
                                <div className="flex column gap-05" style={{ flex: 1 }}>
                                    {isCreate && (
                                        <EmployeeSelectLazy
                                            label="Employee"
                                            value={form[FIELD.EMPLOYEE]}
                                            onChange={(target) => handleFormChange({ name: FIELD.EMPLOYEE, value: target })}
                                            isDisabled={!isCreate}
                                            isLoading={isGettingRecord}
                                            required
                                            allowOnShift
                                        />
                                    )}
                                    <Select
                                        label="Type"
                                        value={config[FIELD.TYPE]}
                                        options={config.typeOpt}
                                        onChange={(target) => handleFormChange({ name: FIELD.TYPE, value: target.value })}
                                        isDisabled={hideFields}
                                        isLoading={isGettingRecord}
                                        required
                                        isOutlined
                                        disabledOutline
                                    />
                                    <Input
                                        type={INPUT_TYPE.NUMBER}
                                        name={FIELD.AMOUNT}
                                        value={form[FIELD.AMOUNT]}
                                        label="Amount"
                                        onChange={(e) => handleFormChange({ name: FIELD.AMOUNT, value: e.target.value })}
                                        afterExtra={<span>{currency}</span>}
                                        disabled={hideFields}
                                        isLoading={isGettingRecord}
                                        required
                                    />
                                </div>
                                <div className="flex column gap-05" style={{ flex: 1 }}>
                                    <Input
                                        type={INPUT_TYPE.CHECKBOX}
                                        name={FIELD.PAYMENT_TYPE}
                                        value={form[FIELD.PAYMENT_TYPE] == DEDUCTION_PAYMENT_TYPE.ONE_TIME}
                                        label="One-time Payment?"
                                        onChange={(e) =>
                                            handleFormChange({
                                                name: FIELD.PAYMENT_TYPE,
                                                value: e.target.checked ? DEDUCTION_PAYMENT_TYPE.ONE_TIME : DEDUCTION_PAYMENT_TYPE.INSTALLMENT
                                            })
                                        }
                                        disabled={hideFields}
                                    />
                                    <Input
                                        type={INPUT_TYPE.DATE}
                                        name={FIELD.START_DATE}
                                        label={<span style={{ whiteSpace: "nowrap" }}>Start Date</span>}
                                        onChange={(date) => handleFormChange({ name: FIELD.START_DATE, value: date })}
                                        selected={form[FIELD.START_DATE] && new Date(form[FIELD.START_DATE])}
                                        startDate={form[FIELD.START_DATE] && new Date(form[FIELD.START_DATE])}
                                        endDate={form[FIELD.END_DATE] && new Date(form[FIELD.END_DATE])}
                                        isLoading={isGettingRecord}
                                        noPast={isCreate}
                                        error={[error[FIELD.START_DATE]]}
                                        isMonthYear
                                        selectsStart
                                        required
                                        useSubTextStyle
                                    />
                                    {form[FIELD.PAYMENT_TYPE] == DEDUCTION_PAYMENT_TYPE.INSTALLMENT && (
                                        <Input
                                            type={INPUT_TYPE.DATE}
                                            name={FIELD.END_DATE}
                                            label={<span style={{ whiteSpace: "nowrap" }}>End Date</span>}
                                            onChange={(date) => handleFormChange({ name: FIELD.END_DATE, value: date })}
                                            selected={form[FIELD.END_DATE] && new Date(form[FIELD.END_DATE])}
                                            startDate={form[FIELD.START_DATE] && new Date(form[FIELD.START_DATE])}
                                            endDate={form[FIELD.END_DATE] && new Date(form[FIELD.END_DATE])}
                                            minDate={form[FIELD.START_DATE] && new Date(form[FIELD.START_DATE])}
                                            isLoading={isGettingRecord}
                                            error={[error[FIELD.END_DATE]]}
                                            isMonthYear
                                            required
                                            selectsEnd
                                            useSubTextStyle
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="flex column" style={{ marginTop: "1rem" }}>
                            <Input
                                name={FIELD.NOTES}
                                label="Notes/Description"
                                type={INPUT_TYPE.TEXTAREA}
                                value={form[FIELD.NOTES]}
                                parentStyle={{ height: "10rem", minHeight: "5rem" }}
                                onChange={(e) => handleFormChange({ name: FIELD.NOTES, value: e.target.value })}
                                maxLength={NOTES_MAX_LEN}
                                isLoading={isGettingRecord}
                            />
                        </div>
                    </div>
                )
            })}
        </BaseUpdateModal>
    );
}

UpdateModal.propTypes = {
    photo: PropTypes.string,
    open: PropTypes.bool,
    id: PropTypes.any,
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func
};

export default UpdateModal;
