import React from "react";
import RemoveIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Visibility";
import {
    createConfirmAlert,
    createFullName,
    getTotalDaysFromDateRange,
    renderNA,
    sanitizeTimestamp,
    sanitizeWords,
    toReadableFromDate,
    toTimeWithTimeZone
} from "../../../common/utilities/helper";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import LetteredAvatar from "../../../common/components/extra/avatar/LetteredAvatar";
import { selectUser } from "../../common/slice";
import { TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";
import Tag from "../../../common/components/extra/Tag";
import { FIELD } from "./const";
import { getSettlementStatus, getSupportedTypeByInfo } from "./helper";
import Tooltip, { PLACES } from "../../../common/components/extra/Tooltip";
import Button from "../../../common/components/extra/Button";

const HEADERS = {
    EMPLOYEE: "employee",
    COVERAGE_DATE: "coverage",
    PROCESSED_DATE: "processed_date",
    PAID_DATE: "paid_date",
    STATUS: "status",
    UPDATED_AT: "updatedAt",
    CREATED_AT: "createdAt",
    ACTIONS: "actions"
};

const HEADERS_TO_ARRAY = Object.values(HEADERS);

const CreateTableHeaders = ({ onAction }) => {
    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    const timezone = setting.timezone;

    return HEADERS_TO_ARRAY.map((head) => {
        const temp = { key: head, style: {} };
        switch (head) {
            case HEADERS.EMPLOYEE: {
                temp.sortKey = "index1";
                temp.label = "Employee";
                temp.style.margin = ".5rem 0";
                temp.style.width = "25rem";
                temp.fixed = true;
                temp.render = (_, row) => {
                    return (
                        <>
                            <div className="flex gap-1 w100" style={{ alignItems: "center", padding: ".5rem 0" }}>
                                <LetteredAvatar
                                    name={createFullName(row.Employee.first_name, row.Employee.last_name)}
                                    src={row.Employee.photo}
                                    size={30}
                                    small
                                />
                                <div className="flex column gap-05" style={{ flex: 1 }}>
                                    <div
                                        className="link-hover"
                                        style={{ fontWeight: "bold" }}
                                        onClick={() => onAction(row, TABLE_ACTION_TYPE_COMMON.PREVIEW)}
                                    >
                                        {createFullName(row.Employee.first_name, row.Employee.last_name)}
                                    </div>
                                    <div className="flex gap-05 wrap">
                                        <div className="flex gap-05 semi-bold fade">{sanitizeWords(getSupportedTypeByInfo(row))}</div>
                                    </div>
                                </div>
                                {row.finalized_date && (
                                    <Tooltip message={toReadableFromDate(row.finalized_date, timezone)} isIcon>
                                        <Tag className="green">FINALIZED</Tag>
                                    </Tooltip>
                                )}
                            </div>
                        </>
                    );
                };
                break;
            }
            case HEADERS.COVERAGE_DATE: {
                temp.sortKey = "index4";
                temp.style.width = "20rem";
                temp.label = "Date";
                temp.render = (_, row) => {
                    const startTime = row[FIELD.COVERAGE_START_DATE] && toTimeWithTimeZone(row[FIELD.COVERAGE_START_DATE], timezone);
                    const endTime = row[FIELD.COVERAGE_END_DATE] && toTimeWithTimeZone(row[FIELD.COVERAGE_END_DATE], timezone);

                    const startddd = startTime && startTime.format("ddd");
                    const startFormat = startTime && startTime.format("MMM DD, YYYY");

                    const endddd = endTime && endTime.format("ddd");
                    const endFormat = endTime && endTime.format("MMM DD, YYYY");

                    const totalDays = getTotalDaysFromDateRange(row[FIELD.COVERAGE_START_DATE], row[FIELD.COVERAGE_END_DATE], timezone, "days");
                    const coveredPeriod = getTotalDaysFromDateRange(row[FIELD.COVERAGE_START_DATE], row[FIELD.COVERAGE_END_DATE], timezone, "years");
                    const isSingleDay = totalDays === 1;

                    return (
                        <div className="flex column gap-05">
                            <span className="flex gap-05">
                                <span className="flex" style={{ whiteSpace: "nowrap" }}>
                                    <span className="fade semi-bold">{startddd}</span>&nbsp;
                                    <span className="semi-bold">{startFormat}</span>
                                    {isSingleDay && <span className="fade">&nbsp;(Whole Day)</span>}
                                </span>
                                {!isSingleDay && (
                                    <>
                                        {startddd && endddd && "-"}
                                        <span className="flex" style={{ whiteSpace: "nowrap" }}>
                                            <span className="fade semi-bold">{endddd}</span>&nbsp;
                                            <span className="semi-bold">{endFormat}</span>
                                        </span>
                                    </>
                                )}
                            </span>
                            {coveredPeriod !== 0 && (
                                <div className="flex gap-05 small-font">
                                    <span className="fade small-font flex align-center">Covered Period:</span>
                                    <div className="flex gap-03">
                                        <span className="semi-bold">{coveredPeriod}</span>
                                        <span className="fade">Year/s</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                };
                break;
            }
            case HEADERS.PROCESSED_DATE: {
                temp.sortKey = "index2";
                temp.label = "Processed Date";
                temp.render = (_, row) => {
                    const date = row[FIELD.PROCESSED_DATE] && toTimeWithTimeZone(row[FIELD.PROCESSED_DATE], timezone);
                    const startddd = date && date.format("ddd");
                    const startFormat = date && date.format("MMM DD, YYYY");
                    return (
                        <div className="flex column gap-05 overflow-hidden">
                            <span className="text-ellipsis flex gap-05">
                                {date ? (
                                    <span className="flex" style={{ whiteSpace: "nowrap" }}>
                                        <span className="fade semi-bold">{startddd}</span>&nbsp;
                                        <span className="semi-bold">{startFormat}</span>
                                    </span>
                                ) : (
                                    <span className="small-font">{renderNA("Not Yet Processed")}</span>
                                )}
                            </span>
                        </div>
                    );
                };
                break;
            }
            case HEADERS.PAID_DATE: {
                temp.sortKey = "index3";
                temp.label = "Paid Date";
                temp.render = (_, row) => {
                    const date = row[FIELD.PAID_DATE] && toTimeWithTimeZone(row[FIELD.PAID_DATE], timezone);
                    const startddd = date && date.format("ddd");
                    const startFormat = date && date.format("MMM DD, YYYY");
                    return (
                        <div className="flex column gap-05 overflow-hidden">
                            <span className="text-ellipsis flex gap-05">
                                {date ? (
                                    <span className="flex" style={{ whiteSpace: "nowrap" }}>
                                        <span className="fade semi-bold">{startddd}</span>&nbsp;
                                        <span className="semi-bold">{startFormat}</span>
                                    </span>
                                ) : (
                                    <span className="small-font">{renderNA("Not Yet Paid")}</span>
                                )}
                            </span>
                        </div>
                    );
                };
                break;
            }
            case HEADERS.STATUS: {
                temp.label = "Status";
                temp.render = (_, row) => {
                    return (
                        <div className="flex column gap-05 overflow-hidden">
                            <span className="text-ellipsis flex gap-05" style={{ letterSpacing: ".5px" }}>
                                <Tag className={getSettlementStatus(row).tagColor}>{getSettlementStatus(row).label}</Tag>
                            </span>
                        </div>
                    );
                };
                break;
            }
            case HEADERS.UPDATED_AT: {
                temp.sortKey = "updatedAt";
                temp.label = "Updated";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">
                            {sanitizeTimestamp(row.updatedAt, row.createdAt, setting.timezone) || renderNA()}
                        </span>
                    </div>
                );
                break;
            }
            case HEADERS.CREATED_AT: {
                temp.sortKey = "createdAt";
                temp.label = "Created";
                temp.render = (val) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">{toReadableFromDate(val, setting.timezone)}</span>
                    </div>
                );
                break;
            }
            case HEADERS.ACTIONS: {
                temp.childStyle = {
                    display: "flex",
                    gap: ".5rem",
                    justifyContent: "end",
                    paddingRight: "1rem",
                    alignItems: "center"
                };
                temp.render = (_, row) => (
                    <>
                        <PreviewIcon onClick={() => onAction(row, TABLE_ACTION_TYPE_COMMON.PREVIEW)} />
                        <Tooltip position={PLACES.LEFT} message={!!row.finalized_date && "Cannot delete settlement because it is already finalized."}>
                            <Button
                                onClick={() =>
                                    createConfirmAlert({
                                        title: "Remove Settlement",
                                        content: "Are you sure? This cannot be undone.",
                                        onConfirm: (onClose) => {
                                            onAction?.(row, TABLE_ACTION_TYPE_COMMON.REMOVE);
                                            onClose();
                                        }
                                    })
                                }
                                options={{ style: { padding: 0, boxShadow: "unset", marginLeft: "auto", maxWidth: "max-content" } }}
                                disabled={!!row.finalized_date}
                                transparent
                            >
                                <RemoveIcon className="hover-svg" style={{ width: "1.4rem", color: "red" }} />
                            </Button>
                        </Tooltip>
                    </>
                );
                break;
            }
            default:
                break;
        }
        return temp;
    });
};

export default CreateTableHeaders;
