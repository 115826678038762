import React from "react";
import PropTypes from "prop-types";
import { createClass } from "../../../common/utilities/helper";
import { BASE_CLASS_CONFIRM_MODAL, FIELD } from "./const";
import BaseViewModal from "../../../common/components/layout/modalViewUpdateLayout/BaseViewModal";
import UnpaidLeavesLazyTable from "./UnpaidLeavesLazyTable";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";
import { CommonPreview } from "./PreviewInfo";

function UnpaidLeavesLazyTableModal({ open, onClose, onBack, form, previewData }) {
    return (
        <>
            <BaseViewModal
                open={open}
                onClose={onClose}
                onBack={onBack}
                styles={{
                    content: {
                        height: "max-content",
                        maxHeight: "90vh",
                        width: "max-content",
                        minWidth: "40rem",
                        maxWidth: "45rem"
                    },
                    body: {
                        overflow: "auto"
                    },
                    inner: {
                        paddingTop: 0
                    }
                }}
                className={createClass(BASE_CLASS_CONFIRM_MODAL)}
                title="Unpaid Leaves"
                hasHeaderStyle
            >
                <div className="flex column gap-1">
                    <SectionCollapseInfo title="Summary" show alwaysOpen>
                        <div className="confirm-summary-info">
                            <div style={{ marginLeft: "1rem" }}>
                                <CommonPreview form={form} data={previewData} noUnpaidPreview />
                            </div>
                        </div>
                    </SectionCollapseInfo>
                    <UnpaidLeavesLazyTable
                        employee_id={form[FIELD.EMPLOYEE].id}
                        coverage_start_date={form[FIELD.COVERAGE_START_DATE]}
                        coverage_end_date={form[FIELD.COVERAGE_END_DATE]}
                    />
                </div>
            </BaseViewModal>
        </>
    );
}

UnpaidLeavesLazyTableModal.propTypes = {
    form: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onBack: PropTypes.func,
    previewData: PropTypes.object
};

export default UnpaidLeavesLazyTableModal;
