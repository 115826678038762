import React, { useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import debounce from "lodash/debounce";
import Table from "../../../../common/components/extra/table/Table";
import CardPage from "../../../../common/components/extra/CardPage";
import { useDailyLoginActivities } from "../../employeeWorkHistories/hooks";
import MyTooltip from "../../../../common/components/extra/Tooltip";
import Button from "../../../../common/components/extra/Button";
import Input, { INPUT_TYPE } from "../../../../common/components/extra/form/Input";
import ViewEmployeeModal from "../../employeeWorkHistories/ViewModal";
import ViewCheckinLogsModal from "../../employeeCheckinLogs/ViewCheckinLogsModal";
import CreateTableHeaders from "../../employeeWorkHistories/CreateTableHeaders";
import { ACTION_TYPE } from "../../employeeWorkHistories/const";

function EmployeesLoginActivities() {
    const [viewHistoryId, setViewHistoryId] = useState(null);
    const [viewCheckinLogsId, setViewCheckinLogsId] = useState(null);

    const [object, , { isLoading, isLoadingMore, loadMore, reset, search, sort }] = useDailyLoginActivities();

    const handleAction = (row, actionType) => {
        switch (actionType) {
            case ACTION_TYPE.VIEW:
                setViewHistoryId(row.id);
                break;
            case ACTION_TYPE.VIEW_CHECKIN: {
                setViewCheckinLogsId(row.id);
                break;
            }
            default:
                break;
        }
    };

    return (
        <div className="flex column gap-1 w100">
            <Input
                type={INPUT_TYPE.SEARCH}
                value={object.search || ""}
                onChange={debounce(search, 1000)}
                placeholder={"Search for Employees..."}
                disabled={isLoading}
                parentStyle={{ minWidth: "unset" }}
            />
            <CardPage
                className="tk-login-activities"
                title="Employees' Daily Time In/Out"
                headExtra={
                    <MyTooltip message="Refresh" className="pointer">
                        <Button
                            options={{ style: { padding: 0, margin: 0 } }}
                            beforeExtra={<RefreshIcon style={{ color: "white", width: "2rem" }} />}
                            onClick={reset}
                            mini
                            transparent
                        />
                    </MyTooltip>
                }
            >
                <Table
                    headers={CreateTableHeaders({ onAction: handleAction })}
                    data={object.data}
                    onScrollEnd={loadMore}
                    isLoading={isLoading}
                    isLoadingMore={isLoadingMore}
                    parentElClass=".tk-login-activities"
                    emptyProps={{ message: "No Record for Today" }}
                    activeSort={object.sort}
                    onSort={(sortBy, order) => sort({ sortBy, order })}
                    useParentWidth
                />
                {!!viewHistoryId && <ViewEmployeeModal id={viewHistoryId} open={!!viewHistoryId} onClose={() => setViewHistoryId(null)} readOnly />}
                {!!viewCheckinLogsId && (
                    <ViewCheckinLogsModal id={viewCheckinLogsId} open={!!viewCheckinLogsId} onClose={() => setViewCheckinLogsId(null)} />
                )}
            </CardPage>
        </div>
    );
}

export default EmployeesLoginActivities;

EmployeesLoginActivities.propTypes = {};
