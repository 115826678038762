import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import isNull from "lodash/isNull";
import { TOAST_TYPE, createEditingInfo, createToast, isEqualWithDates, sanitizeWords } from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import { SORT_ORDER, WORK_TYPE } from "../../../common/utilities/const";
import { selectLoading, selectSearching, selectTableConfig, selectWorkDetailsData, setLoading, setSearching, setState } from "./slice";
import { FIELDS, FORM_FIELDS } from "./const";
import {
    useCreateWorkDetailsMutation,
    useDeleteWorkDetailsMutation,
    useGetWorkDetailsMutation,
    useLoadAllLazyWorkDetailsMutation,
    useLoadAllWorkDetailsMutation,
    useUpdateWorkDetailsMutation
} from "../employeeWorkDetails/api";
import Tag from "../../../common/components/extra/Tag";
import Tooltip from "../../../common/components/extra/Tooltip";

const { NAME, TYPE, COMPANY_SITES } = FIELDS;

export const useGetWorkDetails = () => {
    const [data, setData] = useState({});
    const [getDetails, { isLoading }] = useGetWorkDetailsMutation();

    const setting = useAppSelector(selectUserSetting);

    const fetch = async (id) => {
        try {
            const result = await getDetails({ body: { id } });
            if (result.error) {
                throw new Error("Failed to fetch work details. Please try again later");
            }
            setData(result.data.data);
            return result.data.data.result;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return {};
        }
    };

    const checkAllowEditTime = () => {
        const editInfo = createEditingInfo({
            start: data.before,
            end: data.after,
            timezone: setting.timezone
        });

        return {
            isAllowed: !data.hasSites || editInfo.isEditAllowed,
            allowedTime: {
                after: editInfo.afterTime,
                before: editInfo.beforeTime
            }
        };
    };

    return [fetch, { data, isLoading, allowEditInfo: checkAllowEditTime() }];
};

export const useGetWorkDetailsByIds = (ids = []) => {
    const [object, setObject] = useState({
        data: [],
        mounted: false
    });

    const [getDetails, { isLoading }] = useGetWorkDetailsMutation();

    const updateObject = (newObj = {}) =>
        setObject((prev) => ({
            ...prev,
            ...newObj
        }));

    const fetch = async () => {
        try {
            const response = await getDetails({
                body: { ids }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data;
                updateObject({ data: resdata, mounted: true });
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            updateObject({ data: [], mounted: true });
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    return [object.data, isLoading, { isMounted: object.mounted }];
};

export const usePaginateWorkDetails = () => {
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useAppDispatch();
    const data = useAppSelector(selectWorkDetailsData);
    const tableConfig = useAppSelector(selectTableConfig);
    const searching = useAppSelector(selectSearching);

    const [load] = useLoadAllWorkDetailsMutation();

    const fetch = async (config) => {
        if (searching) {
            return;
        }
        if (!isLoading) {
            setIsLoading(true);
        }
        try {
            const response = await load({ body: { ...tableConfig, ...(config || {}) } });
            if (response.data && response.data.data) {
                if (typeof setState === "function") {
                    const result = response.data.data;
                    const oldConfig = { ...tableConfig, ...(config || {}) };
                    dispatch(
                        setState({
                            data: result.data,
                            tableConfig: {
                                ...oldConfig,
                                totalPage: result.totalPage,
                                totalCount: result.totalCount
                            }
                        })
                    );
                }
            }
            if (response.error) {
                throw new Error("Failed to fetch work details. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchFetching = async () => {
        try {
            dispatch(setSearching(true));
            await fetch();
        } finally {
            dispatch(setSearching(false));
        }
    };

    useEffect(() => {
        if (!data.length) {
            fetch();
        } else {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        handleSearchFetching();
    }, [tableConfig.search]);

    return [data, { isLoading, fetch }];
};

export const useLazyWorkDetails = ({
    defaultValue,
    initializing,
    isReadableSelected,
    onMount = () => {},
    isFilter,
    allowInUse,
    byEmployeeId
} = {}) => {
    const LOAD_MORE_OFFSET = 5;
    const DEFAULT_SIZE = 15;

    const [fetching, setFetching] = useState(true);
    const [isMounted, setMounted] = useState(false);

    const [object, setObject] = useState({
        data: [],
        sort: { sortBy: NAME.name, order: SORT_ORDER.ASC },
        totalCount: 0,
        cursor: "",
        search: ""
    });

    const hasMore = object.totalCount > object.data?.length || 0;

    const [load, { isLoading }] = useLoadAllLazyWorkDetailsMutation();

    const updateObject = (newObject = {}) => setObject((prev) => ({ ...prev, ...newObject }));

    const createFixedValuesFromDefault = () =>
        Array.isArray(defaultValue)
            ? defaultValue
                  .filter(() => {
                      return isReadableSelected;
                  })
                  .map((item) => item.id)
            : [];

    const createRowItem = (row) => {
        const temp = cloneDeep(row || {});

        if (!temp.id) {
            return null;
        }

        const title = sanitizeWords(temp[NAME.name]);
        temp.value = temp.id;

        if (isFilter) {
            temp.label = (
                <div className="flex gap-05 align-center overflow-hidden" style={{ minHeight: "1.5rem" }}>
                    <Tooltip element="span" className="text-ellipsis bold" message={title}>
                        {title}
                    </Tooltip>
                </div>
            );
        } else {
            const inUse = !allowInUse && temp.type !== WORK_TYPE.PROJECT && !!temp?.CompanySites?.length;
            temp.isDisabled = inUse;
            temp.label = (
                <div className="flex gap-05 align-center overflow-hidden" style={{ minHeight: "1.5rem" }}>
                    <Tooltip element="span" className="text-ellipsis semi-bold" message={title}>
                        {title}
                    </Tooltip>
                    {temp[TYPE.name] && <Tag className="yellow">{temp[TYPE.name]}</Tag>}
                    {inUse && (
                        <Tag
                            className="red"
                            tooltip={{
                                message: "This work type is already in use by another site. Only the 'Project' work type can be used multiple times."
                            }}
                        >
                            NOT AVAILABLE
                        </Tag>
                    )}
                </div>
            );
        }

        for (const key in temp) {
            if (Object.prototype.hasOwnProperty.call(temp, key)) {
                const value = temp[key];
                if (isNull(value)) {
                    temp[key] = "";
                }
            }
        }
        return temp;
    };

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        if (!sort) {
            sort = object.sort;
        }
        if (isReset) {
            config.cursor = "";
        }
        try {
            const response = await load({
                body: {
                    pageSize: DEFAULT_SIZE,
                    more: isReset ? DEFAULT_SIZE : LOAD_MORE_OFFSET,
                    byEmployeeId,
                    ...object.sort,
                    ...sort,
                    ...(config || {})
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data || [];
                const newdata = [...(resdata.data || [])];
                const sameCursor = isEqual(object.cursor, resdata.cursor);
                const temp = {
                    data: isReset ? newdata : !sameCursor ? [...object.data, ...newdata] : newdata,
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount
                };
                sort && (temp.sort = sort);
                temp.data = [...(temp.data || [])].map((item) => createRowItem(item, true));
                updateObject(temp);
                return temp.data;
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            sort && updateObject({ sort, data: [], totalCount: 0 });
        }
    };

    const loadMore = () => hasMore && fetch({ cursor: object.cursor });
    const reset = () => fetch({}, true);
    const search = (value = "") => fetch({ search: value }, true);
    const sort = ({ sortBy, order }) => fetch({ sort: { sortBy, order } }, true);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        isMounted &&
            !initializing &&
            reset()
                .then(onMount)
                .catch(() => {})
                .finally(() => setFetching(false));
    }, [isMounted, initializing]);

    return [
        object,
        updateObject,
        {
            fixedValues: createFixedValuesFromDefault(),
            initializing: fetching,
            isLoading,
            hasMore,
            fetch,
            reset,
            loadMore,
            search,
            sort,
            createRowItem
        }
    ];
};

export const useDeleteWorkDetails = () => {
    const dispatch = useAppDispatch();

    const [deleteWorkDetails] = useDeleteWorkDetailsMutation();

    const isLoading = useAppSelector(selectLoading);

    const remove = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await deleteWorkDetails({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to delete work shift.");
            }
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [remove, isLoading];
};

export const useUpsertWorkDetails = (updateId) => {
    const isCreate = !updateId;

    const [old, setOld] = useState(FORM_FIELDS);
    const [form, setForm] = useState(FORM_FIELDS);
    const [fetching, setFetching] = useState(true);

    const [getDetails, { allowEditInfo }] = useGetWorkDetails();
    const [create, { isLoading: createIsLoading }] = useCreateWorkDetailsMutation();
    const [update, { isLoading: updateIsLoading }] = useUpdateWorkDetailsMutation();

    const createVars = () => {
        return {
            isLoading: createIsLoading || updateIsLoading,
            invalidSiteCount:
                form[TYPE.name] && form[TYPE.name] !== WORK_TYPE.PROJECT && form[COMPANY_SITES.name] && form[COMPANY_SITES.name].length > 1,
            isIncomplete: !isCreate && !form[COMPANY_SITES.name]?.length
        };
    };

    const vars = createVars();

    const updateForm = (config = {}) => {
        setForm((prev) => ({ ...prev, ...config }));
    };

    const upsert = async () => {
        let result = null;
        try {
            const clonedform = cloneDeep(form);
            const siteIds = clonedform.CompanySites.map((cs) => cs.id);
            const dataSiteIds = (old && old.CompanySites.map((cs) => cs.id)) || [];
            clonedform.CompanySites && delete clonedform.CompanySites;
            if (!isEqual(siteIds, dataSiteIds)) {
                clonedform.siteIds = siteIds;
            } else {
                clonedform.siteIds = dataSiteIds;
            }
            if (isCreate) {
                result = await create({ body: clonedform });
            } else {
                result = await update({ body: clonedform, extraPath: updateId });
            }
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data) {
                if (result.data?.data) {
                    createToast(`Work Type ${isCreate ? "created" : "updated"} succesfully.`, TOAST_TYPE.SUCCESS);
                } else {
                    createToast(result.data.message, TOAST_TYPE.SUCCESS);
                }
            }
            return result.data.data;
        } catch (error) {
            return createToast(
                `Failed to ${!isCreate ? "update" : "create"} Work Type. ${error?.message || "Please try again later or contact support."} `,
                TOAST_TYPE.ERROR
            );
        }
    };

    useEffect(() => {
        if (updateId) {
            getDetails(updateId)
                .then((result) => {
                    setForm(result);
                    setOld(result);
                    setFetching(false);
                })
                .catch(() => setFetching(false));
        } else {
            setFetching(false);
        }
    }, []);

    return [
        form,
        updateForm,
        {
            upsert,
            isLoading: vars.isLoading,
            isGettingDetails: fetching,
            config: vars,
            hasChanges: isEqualWithDates(form, old),
            isEditAllowed: isCreate || allowEditInfo.isAllowed,
            allowedTime: allowEditInfo.allowedTime
        }
    ];
};
