import React from "react";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";
import { FIELD, SETTLEMENT_CATEGORY, SETTLEMENT_DAILY_RATE, SETTLEMENT_TYPE } from "./const";
import { sanitizeWords } from "../../../common/utilities/helper";
import Tooltip from "../../../common/components/extra/Tooltip";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import PropTypes from "prop-types";

const UsedSettingsInfo = ({ form, internalForm }) => {
    const setting = useAppSelector(selectUserSetting);

    const salarySetting = setting.salary;
    const settlement = salarySetting.settlement;

    const isPreviousEncashments = form[FIELD.CATEGORY] == SETTLEMENT_CATEGORY.PREVIOUS;
    const isFullFinal = form[FIELD.TYPE] == SETTLEMENT_TYPE.FULL_FINAL;

    if (!internalForm.selectedSupportedType && !isFullFinal) {
        return <></>;
    }

    return (
        <SectionCollapseInfo title="Using Settings" show alwaysOpen>
            <div className="flex column w100">
                {!isPreviousEncashments && (
                    <>
                        <div className="flex small-font align-center gap-05 w100">
                            <div className="flex align-center gap-03">
                                <span className="fade">Base Salary:</span>
                            </div>
                            <span className="semi-bold primary-color">{sanitizeWords(settlement.base_salary)}</span>
                            <Tooltip message="This will be used as the base salary for the calculation of the settlement amount." isIcon />
                        </div>
                        <div className="flex small-font align-center gap-05 w100">
                            <div className="flex align-center gap-03">
                                <span className="fade">Daily Rate:</span>
                            </div>
                            <span className="semi-bold primary-color">
                                {sanitizeWords(settlement.daily_rate_by)}{" "}
                                <span className="fade" style={{ fontStyle: "italic" }}>
                                    (
                                    {settlement.daily_rate_by == SETTLEMENT_DAILY_RATE.BY_30_DAYS
                                        ? "DAILY RATE = SALARY AMOUNT / 30 days"
                                        : "DAILY RATE = SALARY AMOUNT * (12 / 365.25)"}
                                    )
                                </span>
                            </span>
                            <Tooltip message="The method for calculating the DAILY SALARY RATE when creating a settlement." isIcon />
                        </div>
                    </>
                )}
                <div className="flex small-font align-center gap-05 w100">
                    <div className="flex align-center gap-03">
                        <span className="fade">Include Unpaid Leave Days:</span>
                    </div>
                    <span className="semi-bold primary-color">{sanitizeWords(settlement.includeUnpaidLeaveDays ? "Yes" : "No")}</span>
                    <Tooltip
                        message={
                            settlement.includeUnpaidLeaveDays
                                ? "UNPAID leave days records are counted as part of the Total Service Days"
                                : "UNPAID leave days records are NOT counted as part of the Total Service Days. Service days length will be adjusted."
                        }
                        isIcon
                    />
                </div>
            </div>
        </SectionCollapseInfo>
    );
};

export default UsedSettingsInfo;

UsedSettingsInfo.propTypes = {
    form: PropTypes.object,
    lastRecord: PropTypes.object,
    internalForm: PropTypes.object
};
