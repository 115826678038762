import { sanitizeWords, toTimeWithTimeZone } from "../../../common/utilities/helper";
import { FIELD, OTHER_SUPPORTED_SETTLEMENT_TYPE, SETTLEMENT_CATEGORY, SETTLEMENT_CLASSIFICATION, SETTLEMENT_STATUS, SETTLEMENT_TYPE } from "./const";

export const createNewEndDateTooltipMessage = (unpaidLeaveDays, selectedCoverageEndDate) =>
    `The end date has been adjusted to account for the exclusion of unpaid leave days from the calculated service days. An additional ${unpaidLeaveDays} days were added to the selected end date (${selectedCoverageEndDate}) to reflect the accurate service duration for entitlement or eligibility.`;

export const getInfoBasedOnSupportedtype = (supportedType) => {
    switch (supportedType) {
        case OTHER_SUPPORTED_SETTLEMENT_TYPE.GRATUITY_SETTLEMENT_ENCASHMENT:
            return {
                [FIELD.CATEGORY]: SETTLEMENT_CATEGORY.NORMAL,
                [FIELD.CLASSIFICATION]: SETTLEMENT_CLASSIFICATION.ENCASHMENT,
                [FIELD.TYPE]: SETTLEMENT_TYPE.GRATUITY
            };
        case OTHER_SUPPORTED_SETTLEMENT_TYPE.LEAVE_SETTLEMENT_ENCASHMENT:
            return {
                [FIELD.CATEGORY]: SETTLEMENT_CATEGORY.NORMAL,
                [FIELD.CLASSIFICATION]: SETTLEMENT_CLASSIFICATION.ENCASHMENT,
                [FIELD.TYPE]: SETTLEMENT_TYPE.LEAVE
            };
        case OTHER_SUPPORTED_SETTLEMENT_TYPE.PREVIOUS_GRATUITY_ENCASHMENT:
            return {
                [FIELD.CATEGORY]: SETTLEMENT_CATEGORY.PREVIOUS,
                [FIELD.CLASSIFICATION]: SETTLEMENT_CLASSIFICATION.ENCASHMENT,
                [FIELD.TYPE]: SETTLEMENT_TYPE.GRATUITY
            };
        case OTHER_SUPPORTED_SETTLEMENT_TYPE.PREVIOUS_LEAVE_ENCASHMENT:
            return {
                [FIELD.CATEGORY]: SETTLEMENT_CATEGORY.PREVIOUS,
                [FIELD.CLASSIFICATION]: SETTLEMENT_CLASSIFICATION.ENCASHMENT,
                [FIELD.TYPE]: SETTLEMENT_TYPE.LEAVE
            };
        default:
            break;
    }
};

export const getSupportedTypeByInfo = (config = {}) => {
    const { [FIELD.CATEGORY]: category, [FIELD.CLASSIFICATION]: classification, [FIELD.TYPE]: type } = config;

    if (category === SETTLEMENT_CATEGORY.NORMAL && classification === SETTLEMENT_CLASSIFICATION.ENCASHMENT && type === SETTLEMENT_TYPE.GRATUITY) {
        return OTHER_SUPPORTED_SETTLEMENT_TYPE.GRATUITY_SETTLEMENT_ENCASHMENT;
    }

    if (category === SETTLEMENT_CATEGORY.NORMAL && classification === SETTLEMENT_CLASSIFICATION.ENCASHMENT && type === SETTLEMENT_TYPE.LEAVE) {
        return OTHER_SUPPORTED_SETTLEMENT_TYPE.LEAVE_SETTLEMENT_ENCASHMENT;
    }

    if (category === SETTLEMENT_CATEGORY.PREVIOUS && classification === SETTLEMENT_CLASSIFICATION.ENCASHMENT && type === SETTLEMENT_TYPE.GRATUITY) {
        return OTHER_SUPPORTED_SETTLEMENT_TYPE.PREVIOUS_GRATUITY_ENCASHMENT;
    }

    if (category === SETTLEMENT_CATEGORY.PREVIOUS && classification === SETTLEMENT_CLASSIFICATION.ENCASHMENT && type === SETTLEMENT_TYPE.LEAVE) {
        return OTHER_SUPPORTED_SETTLEMENT_TYPE.PREVIOUS_LEAVE_ENCASHMENT;
    }

    // Return undefined if no match is found
    return undefined;
};

/**
 * @param {Object} config
 * @param {String} config.status
 * @param {String} config.finalized_date
 * @returns {{ label: String, tagColor: String }}
 */
export const getSettlementStatus = (config = {}) => {
    const finalizedDate = config[FIELD.FINALIZED_DATE];
    const isPending = config[FIELD.STATUS] == SETTLEMENT_STATUS.PENDING;
    const isPaid = config[FIELD.STATUS] == SETTLEMENT_STATUS.PAID;
    const isUnPaid = finalizedDate && isPending;

    return {
        label: isUnPaid ? "Unpaid" : sanitizeWords(config[FIELD.STATUS]),
        tagColor: isUnPaid ? "red" : isPending ? "yellow" : isPaid ? "green" : ""
    };
};

export const newCoverageEndDateisFuture = (timezone, newEndDate) => {
    const today = toTimeWithTimeZone(new Date(), timezone).format();
    const newCoverageEndDate = newEndDate && toTimeWithTimeZone(newEndDate, timezone);
    return (newCoverageEndDate && newCoverageEndDate.isAfter(today)) || false;
};
