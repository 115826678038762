import React, { useState } from "react";
import { useAppSelector } from "../../common/hooks/reduxHooks";
import PageLayout from "../../common/components/layout/PageLayout";
import Tabs from "../../common/components/extra/tabs/Tabs";

import { createPayrollTabs } from "../../features/company/companyPayroll/helper";
import { TABS } from "../../features/company/companyPayroll/const";
import { useDeductionStates, useOtherIncomeStates, usePenaltyStates, useSalaryStates } from "../../features/company/companyPayroll/hooks";
import { selectSidebarCollapse } from "../../features/common/slice";
import { getFilterCount } from "../../common/utilities/helper";

function CompanyPayrollPage() {
    const [activeTab, setActiveTab] = useState(TABS.CALCULATION.id);
    const [openAddModal, setOpenAddModal] = useState(false);

    const otherIncomeStates = useOtherIncomeStates();
    const deductionStates = useDeductionStates();
    const penaltyStates = usePenaltyStates();
    const salaryStates = useSalaryStates();
    const isSidebarCollapse = useAppSelector(selectSidebarCollapse);

    const handleAdd = () => setOpenAddModal(true);

    const handleSearch = (value) => getActiveState()?.onSearch?.(value);

    const getActiveState = () => {
        switch (activeTab) {
            case TABS.OTHER_INCOMES.id:
                return otherIncomeStates;
            case TABS.DEDUCTION.id:
                return deductionStates;
            case TABS.PENALTIES.id:
                return penaltyStates;
            case TABS.CALCULATION.id:
                return salaryStates;
            default:
                return {};
        }
    };

    const isLoading = getActiveState()?.isLoading;
    const isCalculations = activeTab == TABS.CALCULATION.id;

    const TAB_PROPS = {
        loading: !!isLoading,
        openAddModal,
        onAddModalClose: () => setOpenAddModal(false),
        scale: 0.99
    };

    return (
        <PageLayout
            className="tk-payroll"
            title="Manage Payroll"
            search={{
                onSearch: !isCalculations ? handleSearch : () => {},
                value: getActiveState().config?.search,
                placeholder: getActiveState().searchPlaceholder
            }}
            filter={getActiveState().Filter}
            onAdd={isCalculations ? null : handleAdd}
            hasFilter={!isCalculations}
            activeFilterCount={getFilterCount(getActiveState().config?.filter)}
        >
            <Tabs
                styles={{
                    parent: {
                        width: getActiveState()?.data?.length ? "min-content" : "100%"
                    },
                    head: {
                        width: isSidebarCollapse ? "calc(100vw - 11rem)" : "calc(100vw - 24rem)",
                        minWidth: "15rem"
                    },
                    body: {
                        padding: 0,
                        overflow: "unset",
                        flexGrow: "1",
                        height: isCalculations ? "calc(-20rem + 100vh)" : "calc(100vh - 27rem)",
                        width: "calc(100vw - 20.5rem)"
                    },
                    content: {
                        padding: 0
                    }
                }}
                items={createPayrollTabs(TABS, TAB_PROPS, activeTab)}
                onChange={(tab) => setActiveTab(tab.id)}
                hasBackground={!getActiveState()?.data?.length}
            />
        </PageLayout>
    );
}

export default CompanyPayrollPage;
