import React from "react";
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import NotFound from "../pages/NotFound";
import UnauthLayout from "../common/components/layout/Unauthlayout";
import Authlayout from "../common/components/layout/Authlayout";
import Navigation from "../common/classes/Navigation";
import CompanyUnauthlayout from "../common/components/layout/CompanyUnauthlayout";
import AdminUnauthlayout from "../common/components/layout/AdminUnauthlayout";
import useVerifySessionHooks from "../common/hooks/useVerifySessionHooks";
import Loader from "../common/components/extra/Loader";
import useGetUserType from "../common/hooks/useGetUserType";

function Router() {
    const userType = useGetUserType();
    const [verifySession, isLoading] = useVerifySessionHooks();

    React.useEffect(() => {
        polyfillCountryFlagEmojis();
        verifySession();
    }, []);

    if (isLoading) {
        return <Loader />;
    }

    const router = createBrowserRouter(
        createRoutesFromElements([
            <Route key={1} element={<UnauthLayout />}>
                <Route path="/admin" element={<AdminUnauthlayout />}>
                    {Navigation.AdminUnauthRoutes.map((r) => (
                        <Route key={r.path} path={r.path} element={r.component} />
                    ))}
                </Route>
                <Route path="/" element={<CompanyUnauthlayout />}>
                    {Navigation.CompanyUnauthRoutes.map((r) => (
                        <Route key={r.path} path={r.path} element={r.component} />
                    ))}
                </Route>
            </Route>,
            <Route key={2} element={<Authlayout />}>
                {Navigation.ProtectedRoutes.filter((r) => !r.notSupported).map((r) => {
                    // filter out only routes that is specific for company admin or super admin
                    if (!r.roles.includes(userType)) {
                        return;
                    }
                    if (r.isGroup && r.subpaths)
                        return Object.values(r.subpaths)
                            .filter((r) => !r.notSupported)
                            .map((subpath) => (
                                <Route key={`${r.path}${subpath.path}`} path={`${r.path}${subpath.path}`} element={subpath.component} />
                            ));
                    return <Route key={r.path} path={r.path} element={r.component} />;
                })}
            </Route>,
            <Route key={3} path="*" element={<NotFound />} />
        ])
    );

    return <RouterProvider router={router} future={{ v7_startTransition: true, v7_relativeSplatPath: true }} />;
}

export default Router;
