import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FormSection from "../../../common/components/extra/FormSection";
import Input from "../../../common/components/extra/form/Input";
import { useGetWorkHistory } from "./hooks";
import Button from "../../../common/components/extra/Button";
import BaseViewModal from "../../../common/components/layout/modalViewUpdateLayout/BaseViewModal";
import MyTooltip from "../../../common/components/extra/Tooltip";
import ViewMapModal from "../companySites/ViewMapModal";
import TimeInPhoto from "./TimeInPhoto";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import { WORK_HISTORY_STATUS } from "../../../common/utilities/const";
import { renderNA, sanitizeWords, toProperTimezoneConversion } from "../../../common/utilities/helper";
import Divider from "../../../common/components/extra/Divider";
import { getShiftStatus } from "./helper";
import ViewCheckinLogsButton from "../employeeCheckinLogs/ViewCheckinLogsButton";
import Overview from "./Overview";

const SITE_INPUT_STYLE = {
    overflow: "unset"
};

function ViewModal({ open, onClose, onEdit, id, onApprove }) {
    const [selectedSite, setSelectedSite] = useState(null);
    const [current, { isLoading, config, update }] = useGetWorkHistory(id);

    const user = useAppSelector(selectUser);
    const timezone = user.Setting.timezone;
    const todayDate = toProperTimezoneConversion(moment(), timezone).format("YYYY-MM-DD");
    const startShiftTime = current?.start_shift_time && toProperTimezoneConversion(current.start_shift_time, timezone).format("YYYY-MM-DD");
    const isCurrent = startShiftTime == todayDate;
    const isOnGoing = current && isCurrent && getShiftStatus(current?.shift_status).isOnGoing;
    const employeeId = current && current.employee.id;
    const showCheckinLogs = !isLoading && current && current.showCheckinLogs;

    const COMMON_PROPS = { readOnly: true, isLoading };

    const handleViewMap = (title, coordinates) => setSelectedSite({ title, coordinates });

    const siteval = (value, coordinates) => {
        if (!value) {
            return renderNA();
        }
        return (
            <MyTooltip className="flex gap-05 center pointer" message="View Map">
                <span>{value}</span>
                <Button
                    options={{ style: { padding: 0 } }}
                    beforeExtra={<LocationOnIcon />}
                    onClick={() => handleViewMap(value, coordinates)}
                    transparent
                    small
                />
            </MyTooltip>
        );
    };

    const handleApprove = async (isApproved) => {
        const status = isApproved ? WORK_HISTORY_STATUS.ADMIN_VERIFIED : WORK_HISTORY_STATUS.REJECTED;
        await update({ isApproved, isRejected: !isApproved });
        typeof onApprove == "function" && onApprove(id, status);
    };

    const headExtra = (
        <div className="flex gap-05">
            <ViewCheckinLogsButton id={id} disabled={isLoading} />
        </div>
    );

    return (
        <BaseViewModal
            open={open}
            onClose={onClose}
            onEdit={onEdit}
            disableEdit={!current || isOnGoing || isLoading}
            headExtra={showCheckinLogs && headExtra}
        >
            <div className="tk-employees-work-history__modal-content-view">
                <Overview row={config} isLoading={isLoading}>
                    {!isLoading && (
                        <TimeInPhoto
                            historyId={id}
                            src={config?.timedInPhoto?.src}
                            filename={config?.timedInPhoto?.filename}
                            companyId={user.id}
                            employeeId={employeeId}
                            onApprove={handleApprove}
                            isApproved={config.isApproved}
                            isRejected={config.isRejected}
                        />
                    )}
                </Overview>
                <FormSection header={config.isSplit ? "First Shift" : "Shift Details"}>
                    <Input label="Date" renderValue={config?.firstShift?.date} {...COMMON_PROPS} />
                    <Input label="Shift Type" renderValue={sanitizeWords(config?.shiftType)} {...COMMON_PROPS} />
                    <Input label="Start Time" renderValue={config?.firstShift?.timedIn} {...COMMON_PROPS} />
                    <Input label="End Time" renderValue={config?.firstShift?.timedOut} {...COMMON_PROPS} />
                    <Input label="Start of Break" renderValue={config?.firstShift?.breakStart} {...COMMON_PROPS} />
                    <Input label="End of Break" renderValue={config?.firstShift?.breakEnd} {...COMMON_PROPS} />
                    <Input
                        label="Site"
                        parentStyle={SITE_INPUT_STYLE}
                        renderValue={siteval(config?.firstShift?.site?.title, config?.firstShift?.coordinates)}
                        {...COMMON_PROPS}
                    />
                    {!!config?.firstShift?.timedInBy && (
                        <Input label="Timed In By" renderValue={config?.firstShift?.timedInBy?.name} {...COMMON_PROPS} />
                    )}
                    {!!config?.firstShift?.timedOutBy && (
                        <Input label="Timed Out By" renderValue={config?.firstShift?.timedOutBy?.name} {...COMMON_PROPS} />
                    )}
                    <Input label="Time Out Status" renderValue={config?.firstShift?.timeOutStatus} {...COMMON_PROPS} />
                </FormSection>

                {config.isSplit && (
                    <FormSection header="Second Shift">
                        <Input label="Date" renderValue={config?.secondShift?.date} {...COMMON_PROPS} />
                        <Input label="Timed In" renderValue={config?.secondShift?.timedIn} {...COMMON_PROPS} />
                        <Input label="Timed Out" renderValue={config?.secondShift?.timedOut} {...COMMON_PROPS} />
                        <Input label="Start of Break" renderValue={config?.secondShift?.breakStart} {...COMMON_PROPS} />
                        <Input label="End of Break" renderValue={config?.secondShift?.breakEnd} {...COMMON_PROPS} />
                        <Input
                            label="Site"
                            parentStyle={SITE_INPUT_STYLE}
                            renderValue={siteval(config?.secondShift?.site?.title, config?.secondShift?.coordinates)}
                            {...COMMON_PROPS}
                        />
                        {!!config?.secondShift?.timedInBy && (
                            <Input label="Timed In By" renderValue={config?.secondShift?.timedInBy?.name} {...COMMON_PROPS} />
                        )}
                        {!!config?.secondShift?.timedOutBy && (
                            <Input label="Timed Out By" renderValue={config?.secondShift?.timedOutBy?.name} {...COMMON_PROPS} />
                        )}
                        <Input label="Time Out Status" renderValue={config?.secondShift?.timeOutStatus} {...COMMON_PROPS} />
                    </FormSection>
                )}
                {config.overtime?.start && (
                    <FormSection header="Overtime">
                        <Input label="Date" renderValue={config?.overtime?.date} {...COMMON_PROPS} />
                        <Input label="Start Time" renderValue={config?.overtime?.start} {...COMMON_PROPS} />
                        <Input label="End Time" renderValue={config?.overtime?.end} {...COMMON_PROPS} />
                        <Input
                            label="Site"
                            parentStyle={SITE_INPUT_STYLE}
                            renderValue={siteval(config?.overtime?.site?.title, config?.overtime?.site?.coordinates)}
                            {...COMMON_PROPS}
                        />
                    </FormSection>
                )}
                <FormSection header="Work Shift">
                    <Input label="Title" renderValue={config?.workShift?.title} {...COMMON_PROPS} />
                    <Input label="Max OT Hours" renderValue={config?.workShift?.maxOT} afterExtra={renderNA("Hour(s)")} {...COMMON_PROPS} />
                    <Input
                        label="Max Break Hours"
                        renderValue={config?.workShift?.maxBreak}
                        afterExtra={renderNA("Hour(s)")}
                        parentStyle={
                            config.isSplit
                                ? {
                                      borderBottom: "unset"
                                  }
                                : {}
                        }
                        {...COMMON_PROPS}
                    />
                    {config.isSplit ? (
                        <>
                            <div className="flex column" style={{ marginTop: "1rem" }}>
                                <Divider title="First Shift" />
                                <Input label="Time" renderValue={config?.workShift?.shiftOne} {...COMMON_PROPS} />
                                <Input
                                    label="Break"
                                    renderValue={config?.workShift?.breakOne}
                                    parentStyle={{
                                        borderBottom: "unset"
                                    }}
                                    {...COMMON_PROPS}
                                />
                            </div>
                            <div className="flex column" style={{ marginTop: "1rem" }}>
                                <Divider title="Second Shift" />
                                <Input label="Time" renderValue={config?.workShift?.shiftTwo} {...COMMON_PROPS} />
                                <Input
                                    label="Break"
                                    renderValue={config?.workShift?.breakTwo}
                                    parentStyle={{
                                        borderBottom: "unset"
                                    }}
                                    {...COMMON_PROPS}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <Input label="Time" renderValue={config?.workShift?.shiftOne} {...COMMON_PROPS} />
                            <Input label="Break" renderValue={config?.workShift?.breakOne} {...COMMON_PROPS} />
                        </>
                    )}
                </FormSection>
                <FormSection header="Work Type">
                    <Input label="Name" renderValue={config?.workType?.name} {...COMMON_PROPS} />
                    <Input label="Code" renderValue={config?.workType?.code} {...COMMON_PROPS} />
                    <Input label="Type" renderValue={config?.workType?.type} {...COMMON_PROPS} />
                </FormSection>
                <FormSection header="Record Details">
                    {current?.submittedForm?.formName && (
                        <Input label="Form Request Name" renderValue={sanitizeWords(current?.submittedForm?.formName)} {...COMMON_PROPS} />
                    )}
                    <Input label="Created By" renderValue={config?.recordInfo?.isCreatedByAdmin ? "Admin" : "System"} {...COMMON_PROPS} />
                    <Input label="Created" renderValue={config?.recordInfo?.createdAt} {...COMMON_PROPS} />
                    {config?.recordInfo?.createdAt != config?.recordInfo?.updatedAt && (
                        <Input label="Updated" renderValue={config?.recordInfo?.updatedAt} {...COMMON_PROPS} />
                    )}
                </FormSection>
            </div>
            {!!selectedSite && (
                <ViewMapModal
                    open={!!selectedSite}
                    onClose={() => setSelectedSite(null)}
                    coordinates={selectedSite?.coordinates}
                    title={selectedSite?.title}
                />
            )}
        </BaseViewModal>
    );
}

ViewModal.propTypes = {
    open: PropTypes.bool,
    isLoading: PropTypes.bool,
    id: PropTypes.any,
    setting: PropTypes.object,
    onClose: PropTypes.func,
    onEdit: PropTypes.func,
    departments: PropTypes.array,
    cachedCurrent: PropTypes.bool,
    onApprove: PropTypes.func
};

export default ViewModal;
