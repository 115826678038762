import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../common/hooks/reduxHooks";
import {
    selectLoading,
    selectTableConfig,
    selectEmployeeSettlementsData,
    setEmployeeSettlementsData
} from "../../features/company/employeeSettlements/slice";
import PageLayout from "../../common/components/layout/PageLayout";
import Filter from "../../features/company/employeeSettlements/Filter";
import { usePaginateSettlements } from "../../features/company/employeeSettlements/hooks";
import Tabs from "../../common/components/extra/tabs/TabsV2";
import { selectSidebarCollapse } from "../../features/common/slice";
import { getFilterCount } from "../../common/utilities/helper";
import { ROUTE_DATA_TYPE_PARAM, TABS } from "../../features/company/employeeSettlements/const";
import EmployeeSettlements from "../../features/company/employeeSettlements/EmployeeSettlements";
import { TAB_ACTION } from "../../common/components/extra/tabs/const";

function EmployeeSettementsPage() {
    const [activeHash, setActiveHash] = useState(null);
    const [openAddModal, setOpenAddModal] = useState(false);

    const loading = useAppSelector(selectLoading);
    const config = useAppSelector(selectTableConfig);
    const data = useAppSelector(selectEmployeeSettlementsData);
    const isSidebarCollapse = useAppSelector(selectSidebarCollapse);

    const dispatch = useAppDispatch();

    const getDataParamFromActiveHash = () => {
        return activeHash == TABS.OTHER_SETTLEMENTS.id ? ROUTE_DATA_TYPE_PARAM.OTHERS : ROUTE_DATA_TYPE_PARAM.FINAL;
    };

    const [, { isLoading, onFilter, onSearch }] = usePaginateSettlements({
        readOnly: true,
        type: getDataParamFromActiveHash()
    });

    const handleSearch = (value) => {
        return onSearch(value, {}, { queryParams: getDataParamFromActiveHash() });
    };

    const handleFilter = (filter) => {
        return onFilter(filter, {}, { queryParams: getDataParamFromActiveHash() });
    };

    const handleAdd = () => {
        setOpenAddModal(true);
    };

    const handleTabChange = (_, hash, action) => {
        setActiveHash(hash);
        if (action === TAB_ACTION.CHANGING) {
            dispatch(setEmployeeSettlementsData([]));
        }
    };

    return (
        <PageLayout
            className="tk-settlements"
            title="Manage Settlements"
            isLoading={loading}
            search={{ onSearch: handleSearch, value: config.search }}
            filter={<Filter onFilter={handleFilter} isLoading={isLoading} />}
            onAdd={handleAdd}
            styles={{
                body: {
                    height: "calc(100vh - 20.5rem)"
                }
            }}
            activeFilterCount={getFilterCount(config.filter)}
            hasFilter
        >
            <Tabs
                id="settlement"
                styles={{
                    parent: {
                        width: data?.length ? "min-content" : "100%"
                    },
                    head: {
                        width: isSidebarCollapse ? "calc(100vw - 12rem)" : "calc(100vw - 24rem)",
                        minWidth: "15rem"
                    },
                    body: {
                        padding: 0,
                        overflow: "unset",
                        flexGrow: "1",
                        height: "calc(100vh - 27rem)"
                    },
                    content: {
                        padding: 0
                    }
                }}
                onChange={handleTabChange}
                hasBackground={!data.length}
            >
                <EmployeeSettlements
                    label={TABS.OTHER_SETTLEMENTS.label}
                    hash={TABS.OTHER_SETTLEMENTS.id}
                    openAddModal={openAddModal}
                    onAddModalClose={() => setOpenAddModal(false)}
                    type={ROUTE_DATA_TYPE_PARAM.OTHERS}
                />
                <EmployeeSettlements
                    label={TABS.FINAL_SETTLEMENTS.label}
                    hash={TABS.FINAL_SETTLEMENTS.id}
                    openAddModal={openAddModal}
                    onAddModalClose={() => setOpenAddModal(false)}
                    type={ROUTE_DATA_TYPE_PARAM.FINAL}
                />
            </Tabs>
        </PageLayout>
    );
}

export default EmployeeSettementsPage;
