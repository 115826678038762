import React, { useMemo } from "react";
import PaidIcon from "@mui/icons-material/Paid";
import Button from "../../../common/components/extra/Button";
import { createConfirmAlert, toTimeWithTimeZone } from "../../../common/utilities/helper";
import { useUpdateStatusEmployeeDeductions } from "./hooks";
import PropTypes from "prop-types";
import { DEDUCTION_PAYMENT_TYPE, DEDUCTION_STATUS, FIELD } from "./const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";

function MarkAsCompleted({ id, onFinish, current }) {
    const [update, isLoading] = useUpdateStatusEmployeeDeductions();

    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;
    const isAlreadyCompleted = current && current[FIELD.STATUS] == DEDUCTION_STATUS.COMPLETED;
    const isActive = current && current[FIELD.STATUS] == DEDUCTION_STATUS.ACTIVE;
    const isOneTimePayment = current && current[FIELD.PAYMENT_TYPE] == DEDUCTION_PAYMENT_TYPE.ONE_TIME;

    const isDateIsSameOrBeforeToday = useMemo(() => {
        if (!current[FIELD.END_DATE] && !current[FIELD.START_DATE]) {
            return false;
        }
        const today = toTimeWithTimeZone(new Date(), timezone).format();
        const endDate = toTimeWithTimeZone(isOneTimePayment ? current[FIELD.START_DATE] : current[FIELD.END_DATE], timezone);
        return endDate.isSameOrBefore(today);
    }, [current?.[FIELD.END_DATE], current?.[FIELD.START_DATE], isOneTimePayment]);

    if (!current || !isDateIsSameOrBeforeToday || !isActive) {
        return <></>;
    }

    return (
        <>
            <Button
                className="success"
                afterExtra={<PaidIcon style={{ width: "1.2rem" }} />}
                onClick={() =>
                    createConfirmAlert({
                        title: "Mark As Completed",
                        content:
                            "Are you sure you want to mark this record as completed? Once the status is set to completed, you will no longer be able to update this record.",
                        onConfirm: async (close) => {
                            close();
                            const result = await update(id, DEDUCTION_STATUS.COMPLETED);
                            if (!result.error) {
                                onFinish(result);
                            }
                        }
                    })
                }
                isLoading={isLoading}
                small
                mini
            >
                <span>{isAlreadyCompleted ? "Completed" : "Mark As Completed"}</span>
            </Button>
        </>
    );
}

export default MarkAsCompleted;

MarkAsCompleted.propTypes = {
    id: PropTypes.number,
    onFinish: PropTypes.func,
    current: PropTypes.object
};
