import React from "react";
import PropTypes from "prop-types";
import { createClass, createConfirmAlert, createGroup, formattedJoiErrorMessage, sanitizeWords } from "../../../common/utilities/helper";
import { SHIFT_TYPE } from "../../../common/utilities/const";
import Select from "../../../common/components/extra/select/Select";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import TimePickerRange, { EVENT } from "../../../common/components/extra/dateTime/TimePickerRange";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import SectionCollapseWarning from "../../../common/components/extra/section/SectionCollapseWarning";
import { BASE_CLASS, BREAK_TYPE, FIELDS, TIME_TYPE } from "./const";
import { useUpsertWorkShift } from "./hooks";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import Divider from "../../../common/components/extra/Divider";
import SelectConstant from "../../../common/components/extra/select/SelectConstant";

const {
    TITLE,
    SHIFT_TYPE: TYPE,
    MAX_OVERTIME,
    MAX_BREAK_DURATION,
    REQ_SHIFT_TIME,
    START_TIME,
    END_TIME,
    START_TIME_2,
    END_TIME_2,
    BREAK_TIME,
    BREAK_END_TIME,
    BREAK_TIME_2,
    BREAK_END_TIME_2,
    IS_HOLIDAY
} = FIELDS;

function UpdateModal({ open, onChange, onClose, onBack, id, onFinish, readOnly, fromHoliday, transparentOverlay }) {
    const [
        form,
        updateForm,
        {
            upsert,
            isLoading,
            config,
            hasChanges,
            hasInternalChanges,
            isEditAllowed,
            allowedTime,
            error,
            warning,
            internalForms,
            setInternalForms,
            hasError,
            validateOnChange
        }
    ] = useUpsertWorkShift(id);

    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    const isCreate = !id;

    const {
        isSplit,
        isNormal,
        hasShiftOne,
        hasShiftTwo,
        shiftOneValue,
        shiftTwoValue,
        breakValue,
        breakTwoValue,
        isShiftOneNextDay,
        isShiftTwoNextDay,
        remainingOTHoursExceededMax,
        isAllOtHoursOptionsDisabled,
        maxBreakDurationOption,
        maxBreakDuration,
        shiftHoursOption,
        shiftHours,
        maxOTHoursOption,
        maxOTHours,
        showEditCapabilityWarning,
        breakDiff,
        breakTwoDiff,
        breakOneLimitHours,
        breakTwoLimitHours,
        totalBreakDiff,
        isWithBreak,
        isFlexibleBreak,
        isShiftOneMaxSelection,
        shiftOneDiff,
        shiftTwoDiff
    } = config;

    const showMaxOTInput = (isNormal && hasShiftOne) || (isSplit && hasShiftOne && hasShiftTwo);

    const handleSave = async () => {
        const result = await upsert();
        if (!result.error) {
            typeof onFinish === "function" && onFinish(result, isCreate);
        }
    };

    const handleBreakTypeChange = (btype) => {
        setInternalForms({
            ...internalForms,
            breakType: btype.value
        });
    };

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        const config = { ...form };
        config[name] = value;

        switch (name) {
            case MAX_BREAK_DURATION.name: {
                config[name] = value;
                const exceeded = totalBreakDiff > config[name];
                if (exceeded) {
                    config[BREAK_TIME.name] = null;
                    config[BREAK_END_TIME.name] = null;
                    config[BREAK_TIME_2.name] = null;
                    config[BREAK_END_TIME_2.name] = null;
                }
                break;
            }
            case REQ_SHIFT_TIME.name:
            case MAX_OVERTIME.name: {
                config[name] = value;
                break;
            }
            case SHIFT_TYPE.name: {
                if (value === SHIFT_TYPE.SPLIT) {
                    config[START_TIME_2.name] = null;
                    config[END_TIME_2.name] = null;
                    config[BREAK_TIME_2.name] = null;
                    config[BREAK_END_TIME_2.name] = null;
                }
                break;
            }
            default:
                break;
        }
        updateForm(validateOnChange(config, name));
    };

    const handleTimeChange = (key, conf = {}, event) => {
        const temp = {};
        const start_time = conf.start && conf.start;
        const end_time = conf.end && conf.end;
        const isChanging = event == EVENT.CHANGING;
        switch (key) {
            case TIME_TYPE.SHIFT_ONE: {
                temp[START_TIME.name] = start_time;
                temp[END_TIME.name] = end_time;
                if (isChanging) {
                    if (isSplit) {
                        // reset shift two
                        temp[BREAK_TIME.name] = null;
                        temp[BREAK_END_TIME.name] = null;
                        temp[START_TIME_2.name] = null;
                        temp[END_TIME_2.name] = null;
                        temp[BREAK_TIME_2.name] = null;
                        temp[BREAK_END_TIME_2.name] = null;
                    } else {
                        // reset break
                        temp[BREAK_TIME.name] = null;
                        temp[BREAK_END_TIME.name] = null;
                    }
                }
                break;
            }
            case TIME_TYPE.SHIFT_TWO: {
                temp[START_TIME_2.name] = start_time;
                temp[END_TIME_2.name] = end_time;
                if (isChanging) {
                    // reset break
                    temp[BREAK_TIME_2.name] = null;
                    temp[BREAK_END_TIME_2.name] = null;
                }
                break;
            }
            case TIME_TYPE.BREAK: {
                temp[BREAK_TIME.name] = start_time;
                temp[BREAK_END_TIME.name] = end_time;
                break;
            }
            case TIME_TYPE.BREAK_TWO: {
                temp[BREAK_TIME_2.name] = start_time;
                temp[BREAK_END_TIME_2.name] = end_time;
                break;
            }
            default:
                break;
        }
        if (isChanging) {
            updateForm(validateOnChange(temp));
        } else {
            updateForm(temp);
        }
    };

    const handleToggle = (name, checked) => {
        updateForm({ [name]: checked });
    };

    const createShiftDivider = ({ isSecondShift, shiftDiff, breakDiff, style } = {}) => {
        let title = isSplit ? "Shift One" : "Shift";
        if (isSecondShift) title = "Shift Two";
        const subtext = isFlexibleBreak ? "flexible" : typeof breakDiff == "number" ? breakDiff + "hr" : "no";
        return (
            <Divider
                title={
                    <div className="flex gap-05 align-center">
                        <span>{title}</span>
                        {!!shiftDiff && (
                            <span className="fade small-font flex center">
                                ({`${shiftDiff}hr ${(isWithBreak && `& ${subtext} break`) || ""}`.trim()})
                            </span>
                        )}
                    </div>
                }
                style={style}
            />
        );
    };

    return (
        <BaseUpdateModal
            open={open}
            onChange={onChange}
            onClose={onClose}
            onBack={(!isCreate && onBack) || null}
            onSave={
                readOnly
                    ? null
                    : () =>
                          createConfirmAlert({
                              title: !isCreate ? "Update Work Shift" : "Create Work Shift",
                              content: `Are you sure you want to ${isCreate ? "create" : "update"} this work Shift? This cannot be undone.`,
                              onConfirm: async (close) => {
                                  close();
                                  await handleSave();
                              }
                          })
            }
            disableSave={hasError || (!hasChanges && !hasInternalChanges)}
            styles={{ content: { width: "50vw", maxWidth: "45rem", minWidth: "40rem" } }}
            isForm={!readOnly}
            transparentOverlay={transparentOverlay}
            isLoading={isLoading}
        >
            <div className={createClass(BASE_CLASS)}>
                <div className={createClass("__inner", BASE_CLASS)}>
                    {!readOnly && !isLoading && ((showEditCapabilityWarning && allowedTime) || error?.all) && (
                        <div className="flex column gap-05" style={{ marginBottom: "3rem" }}>
                            <SectionCollapseWarning show={!!showEditCapabilityWarning}>
                                Editing capabilities are limited while the work shift is active. Editing will be available before {allowedTime.before}{" "}
                                or after {allowedTime.after}.
                            </SectionCollapseWarning>
                            <SectionCollapseError show={!!error?.all}>
                                {formattedJoiErrorMessage({ error: { message: error?.all }, isCreate })}
                            </SectionCollapseError>
                        </div>
                    )}
                    <div className={createClass("__inner-row")} style={{ marginBottom: "3rem" }}>
                        {createGroup({
                            base: BASE_CLASS,
                            title: "Work Shift Information",
                            body: (
                                <>
                                    {!fromHoliday && (
                                        <Input
                                            type={INPUT_TYPE.TOGGLE}
                                            value={form[IS_HOLIDAY.name]}
                                            onChange={(e) => handleToggle(IS_HOLIDAY.name, Boolean(e.target.checked))}
                                            isLoading={isLoading}
                                            readOnly={readOnly}
                                            renderValue={readOnly ? (form[IS_HOLIDAY.name] ? "Yes" : "No") : null}
                                            {...IS_HOLIDAY}
                                        />
                                    )}
                                    <Input
                                        type={INPUT_TYPE.TEXT}
                                        onChange={handleChange}
                                        value={config[TITLE.name] || ""}
                                        isLoading={isLoading}
                                        readOnly={readOnly}
                                        renderValue={readOnly && sanitizeWords(form[TITLE.name])}
                                        {...TITLE}
                                    />
                                    <SelectConstant
                                        label={TYPE.label}
                                        name={TYPE.name}
                                        base={SHIFT_TYPE}
                                        value={form[TYPE.name]}
                                        onChange={(val) =>
                                            handleChange({
                                                target: {
                                                    name: TYPE.name,
                                                    value: val.value
                                                }
                                            })
                                        }
                                        menuPlacement="bottom"
                                        required={TYPE.required}
                                        readOnly={readOnly}
                                        isLoading={isLoading}
                                        isDisabled={!isEditAllowed}
                                        isOutlined
                                        disabledOutline
                                        {...TYPE}
                                    />
                                    <Select
                                        value={shiftHours}
                                        options={shiftHoursOption}
                                        onChange={(val) => handleChange({ target: { name: REQ_SHIFT_TIME.name, value: val.value } })}
                                        isDisabled={!isEditAllowed}
                                        isLoading={isLoading}
                                        readOnly={readOnly}
                                        tooltip={
                                            <div style={{ maxWidth: "15rem" }}>
                                                The shift will use this as the basis for the minimum required shift hours. Ensure that the total
                                                selected shift hours exceed/equal to the specified value.
                                            </div>
                                        }
                                        useSubTextStyle
                                        isOutlined
                                        disabledOutline
                                        {...{ ...REQ_SHIFT_TIME, default: null }}
                                    />
                                    <SelectConstant
                                        label="Break Type"
                                        value={internalForms.breakType}
                                        base={BREAK_TYPE}
                                        onChange={handleBreakTypeChange}
                                        isDisabled={!isEditAllowed}
                                        isLoading={isLoading}
                                        readOnly={readOnly}
                                        menuPlacement="bottom"
                                        isOutlined
                                        disabledOutline
                                        useSubTextStyle
                                    />
                                    {isFlexibleBreak && (
                                        <Select
                                            value={maxBreakDuration}
                                            options={maxBreakDurationOption}
                                            onChange={(val) => handleChange({ target: { name: MAX_BREAK_DURATION.name, value: val.value } })}
                                            isDisabled={!isEditAllowed}
                                            isLoading={isLoading}
                                            readOnly={readOnly}
                                            menuPlacement="bottom"
                                            subtext={{
                                                message:
                                                    "Subtract the current value to the total shift hours to get the equivalent required shift hours."
                                            }}
                                            isOutlined
                                            disabledOutline
                                            useSubTextStyle
                                            {...{ ...MAX_BREAK_DURATION, default: null }}
                                        />
                                    )}

                                    {createShiftDivider({ shiftDiff: shiftOneDiff, breakDiff, style: { marginTop: "1rem" } })}

                                    <TimePickerRange
                                        label="Time"
                                        range={shiftOneValue.range}
                                        constraint={shiftOneValue.constraint}
                                        onChange={(conf, event) => handleTimeChange(TIME_TYPE.SHIFT_ONE, conf, event)}
                                        disabled={!isEditAllowed}
                                        timezone={setting.timezone}
                                        isLoading={isLoading}
                                        readOnly={readOnly}
                                        menuPlacement="top"
                                        noBorder={isSplit && !maxBreakDuration.value}
                                        subtext={{
                                            message: <span>The selected time for this shift spans from the current day to the next day.</span>,
                                            hide: !isShiftOneNextDay,
                                            useSubTextStyle: true
                                        }}
                                        warning={[warning[START_TIME.name]]}
                                        error={[error?.[START_TIME.name]]}
                                        required
                                        useSubTextStyle
                                    />

                                    {isWithBreak && (
                                        <TimePickerRange
                                            label="Break"
                                            baseDate={shiftOneValue.range.start?.format?.()}
                                            range={breakValue.range}
                                            constraint={breakValue.constraint}
                                            onChange={(conf, event) => handleTimeChange(TIME_TYPE.BREAK, conf, event)}
                                            disabled={!isEditAllowed || !shiftOneValue.range.start || !shiftOneValue.range.end}
                                            timezone={setting.timezone}
                                            isLoading={isLoading}
                                            readOnly={readOnly}
                                            menuPlacement="top"
                                            limitHoursOptions={breakOneLimitHours}
                                            required={isSplit ? !breakTwoDiff : true}
                                            noBorder
                                            useSubTextStyle
                                            isDateWithinConstraint
                                        />
                                    )}

                                    {isSplit && (
                                        <>
                                            {createShiftDivider({
                                                isSecondShift: true,
                                                shiftDiff: shiftTwoDiff,
                                                breakDiff: breakTwoDiff,
                                                style: { marginTop: "1rem" }
                                            })}

                                            <TimePickerRange
                                                label="Time"
                                                range={shiftTwoValue.range}
                                                constraint={shiftTwoValue.constraint}
                                                onChange={(conf, event) => handleTimeChange(TIME_TYPE.SHIFT_TWO, conf, event)}
                                                disabled={!isEditAllowed || !shiftOneValue.range.start || !shiftOneValue.range.end}
                                                timezone={setting.timezone}
                                                isLoading={isLoading}
                                                menuPlacement="top"
                                                readOnly={readOnly}
                                                noBorder={!maxBreakDuration.value}
                                                subtext={{
                                                    message: (
                                                        <span>The selected time for this shift spans from the current day to the next day.</span>
                                                    ),
                                                    hide: !isShiftTwoNextDay,
                                                    useSubTextStyle: true
                                                }}
                                                offset={{ end: { min: isShiftOneNextDay ? 30 : 0 } }}
                                                warning={[warning?.[START_TIME_2.name]]}
                                                error={[error?.[START_TIME_2.name]]}
                                                forceNextDayBaseDate={isShiftOneNextDay || isShiftOneMaxSelection}
                                                disableNextDaySelectionEndDate={isShiftOneNextDay || isShiftOneMaxSelection}
                                                afterDate={form[FIELDS.END_TIME.name]}
                                                required
                                                useSubTextStyle
                                            />
                                            {isWithBreak && (
                                                <TimePickerRange
                                                    label="Break"
                                                    baseDate={shiftTwoValue.range.start?.format?.()}
                                                    range={breakTwoValue.range}
                                                    constraint={breakTwoValue.constraint}
                                                    onChange={(conf, event) => handleTimeChange(TIME_TYPE.BREAK_TWO, conf, event)}
                                                    disabled={!isEditAllowed || !shiftTwoValue.range.start || !shiftTwoValue.range.end}
                                                    timezone={setting.timezone}
                                                    isLoading={isLoading}
                                                    menuPlacement="top"
                                                    readOnly={readOnly}
                                                    limitHoursOptions={breakTwoLimitHours}
                                                    forceNextDayBaseDate={isShiftOneNextDay || isShiftOneMaxSelection}
                                                    disableNextDaySelectionEndDate={isShiftOneNextDay || isShiftOneMaxSelection}
                                                    required={!breakDiff}
                                                    noBorder
                                                    useSubTextStyle
                                                    isDateWithinConstraint
                                                />
                                            )}
                                        </>
                                    )}

                                    {showMaxOTInput && (
                                        <>
                                            <Divider title="Overtime" style={{ marginTop: "1rem" }} />
                                            <Select
                                                value={maxOTHours}
                                                options={maxOTHoursOption}
                                                onChange={(val) => handleChange({ target: { name: MAX_OVERTIME.name, value: val.value } })}
                                                isDisabled={!isEditAllowed}
                                                isLoading={isLoading}
                                                readOnly={readOnly}
                                                tooltip={
                                                    <div style={{ maxWidth: "20rem" }}>
                                                        <span>
                                                            Maximum allowable overtime is 12 hours, and shifts can span a maximum of 2 days. If
                                                            certain selections are disabled, it means those hours are not included in the current
                                                            shift.
                                                        </span>
                                                    </div>
                                                }
                                                subtext={{
                                                    message: isAllOtHoursOptionsDisabled
                                                        ? "Overtime hours are set to none because the shift already spans the full 2-day cycle."
                                                        : "Selections have been adjusted to only cover the remaining hours until the full 2-day cycle is reached.",
                                                    hide: remainingOTHoursExceededMax
                                                }}
                                                warning={[warning?.[MAX_OVERTIME.name]]}
                                                menuPlacement="top"
                                                isOutlined
                                                disabledOutline
                                                useSubTextStyle
                                                {...{ ...MAX_OVERTIME, default: null }}
                                            />
                                        </>
                                    )}
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
        </BaseUpdateModal>
    );
}

UpdateModal.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.any,
    onChange: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
    readOnly: PropTypes.bool,
    fromHoliday: PropTypes.bool,
    setting: PropTypes.object,
    transparentOverlay: PropTypes.bool
};

export default UpdateModal;
