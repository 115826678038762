import React from "react";
import PropTypes from "prop-types";
import Modal from "../../extra/Modal";
import BaseViewModalWrapper from "./BaseViewModalWrapper";
import { BTN_TYPE } from "../../extra/Button";

function BaseViewModal({ open, onChange, onClose, children, onEdit, headExtra, disableEdit, moreProps, ...props }) {
    return (
        <Modal
            {...props}
            open={open}
            onChange={onChange}
            onClose={onClose}
            styles={{
                ...(props.styles || {}),
                content: {
                    width: "50vw",
                    minWidth: "30rem",
                    ...(props?.styles?.content || {})
                }
            }}
        >
            <BaseViewModalWrapper onEdit={onEdit} headExtra={headExtra} moreProps={moreProps} disableEdit={disableEdit}>
                {children}
            </BaseViewModalWrapper>
        </Modal>
    );
}

BaseViewModal.propTypes = {
    open: PropTypes.bool,
    disableEdit: PropTypes.bool,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onEdit: PropTypes.func,
    children: PropTypes.any,
    headExtra: PropTypes.any,
    styles: PropTypes.shape({
        parent: PropTypes.object,
        content: PropTypes.object,
        body: PropTypes.object,
        title: PropTypes.object,
        form: PropTypes.object,
        footer: PropTypes.object
    }),
    moreProps: PropTypes.shape({
        isVertical: PropTypes.bool,
        isButton: PropTypes.bool,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                render: PropTypes.any
            })
        ),
        onSelect: PropTypes.func,
        styles: PropTypes.shape({
            parent: PropTypes.object,
            items: PropTypes.object
        }),
        buttonProps: PropTypes.shape({
            small: PropTypes.bool,
            transparent: PropTypes.bool,
            className: PropTypes.string,
            type: PropTypes.oneOf([0, ...Object.values(BTN_TYPE)]),
            children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
            beforeExtra: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
            afterExtra: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
            disabled: PropTypes.bool,
            isLoading: PropTypes.bool,
            onClick: PropTypes.func,
            options: PropTypes.object,
            mini: PropTypes.bool,
            noLabel: PropTypes.bool,
            tooltipProps: PropTypes.object
        })
    })
};

export default BaseViewModal;
