import cloneDeep from "lodash/cloneDeep";
import { createSlice } from "@reduxjs/toolkit";

const SLICE_NAME_KEY = "department";

export const LOAD_MORE_OFFSET = 5;
export const DEFAULT_SIZE = 15;

export const defaultConfig = {
    pageSize: DEFAULT_SIZE,
    search: "",
    sortBy: "title",
    order: "ASC",
    cursor: "",
    more: LOAD_MORE_OFFSET,
    totalCount: 0
};

const initialState = {
    data: [],
    tableConfig: defaultConfig,
    current: null,
    isSearching: false,
    loading: false
};

export const departmentSlice = createSlice({
    name: SLICE_NAME_KEY,
    initialState,
    reducers: {
        setSearching: (state, action) => {
            const clone = cloneDeep(state);
            clone.isSearching = action.payload;
            return clone;
        },
        setDeptData: (state, action) => {
            const clone = cloneDeep(state);
            const data = action.payload;
            Array.isArray(data) && (clone.data = data || []);
            return clone;
        },
        updateDeptData: (state, action) => {
            let clone = cloneDeep(state);
            const updateId = action.payload.id;
            const newdata = action.payload.data || {};
            updateId && (clone.data = clone.data.map((d) => (d.id == updateId ? { ...d, ...newdata } : d)));
            return clone;
        },
        setDeptTableConfig: (state, action) => {
            const clone = cloneDeep(state);
            const tableConfig = action.payload;
            tableConfig && (clone.tableConfig = { ...state.tableConfig, ...tableConfig });
            return clone;
        },
        setDeptLoading: (state, action) => {
            const clone = cloneDeep(state);
            const loading = action.payload;
            typeof loading === "boolean" && loading !== state.loading && (clone.loading = loading);
            return clone;
        },
        setDeptCurrent: (state, action) => {
            const clone = cloneDeep(state);
            const current = action.payload;
            clone.current = current;
            return clone;
        },
        setState: (state, action) => {
            const clone = cloneDeep(state);
            const payload = action.payload;
            "tableConfig" in action.payload && (clone.tableConfig = { ...state.tableConfig, ...payload.tableConfig });
            "data" in action.payload && (clone.data = payload.data || []);
            "loading" in action.payload && payload.loading !== state.loading && (clone.loading = payload.loading);
            "current" in action.payload && (clone.current = payload.current);
            return clone;
        },
        reset: () => initialState
    }
});

export const selectSearching = (state) => state[SLICE_NAME_KEY].isSearching;
export const selectDeptData = (state) => state[SLICE_NAME_KEY].data;
export const selectDeptTableConfig = (state) => state[SLICE_NAME_KEY].tableConfig;
export const selectDeptLoading = (state) => state[SLICE_NAME_KEY].loading;
export const selectDeptCurrent = (state) => state[SLICE_NAME_KEY].current;
export const { setSearching, setDeptData, setDeptTableConfig, setDeptLoading, setDeptCurrent, reset, updateDeptData, setState } =
    departmentSlice.actions;
export default departmentSlice.reducer;
