import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import TableView from "../../../common/components/extra/table/TableView";
import { ASSIGN_TYPE } from "../employees/const";
import { selectUser } from "../../common/slice";
import CreateTableHeaders from "./CreateTableHeaders";
import UpdateModal from "./UpdateModal";
import { useDeleteWorkShift, usePaginateWorkShift } from "./hooks";
import TransferAssignEmployeesModal from "../employees/TransferAssignEmployeesModal";

function WorkShift({ openAddModal, onAddModalClose, isHoliday }) {
    const [object, setObject] = useState({
        openViewModal: false,
        openEditModal: openAddModal,
        selected: null,
        openAssignEmployeesModal: false
    });

    const [data, { isLoading: isPaginating, fetch, tableConfig }] = usePaginateWorkShift({ isHoliday });
    const [remove] = useDeleteWorkShift();

    const user = useAppSelector(selectUser);

    const setting = user.Setting;
    const departments = (user.Setting && user.Setting.departments) || [];

    const updateObject = (newObj = {}) => setObject((prev) => ({ ...prev, ...newObj }));

    useEffect(() => {
        if (openAddModal && openAddModal !== object.openEditModal) {
            updateObject({ openEditModal: openAddModal });
        }
    }, [openAddModal]);

    const handleFinish = async () => {
        fetch({}, isHoliday);
        updateObject({ selected: null, viewSites: false, openEditModal: false });
        typeof onAddModalClose == "function" && onAddModalClose();
    };

    return (
        <>
            <TableView
                data={data}
                headers={CreateTableHeaders({
                    departments,
                    onEdit: (row) => updateObject({ openEditModal: true, selected: row }),
                    onDelete: (row) => remove(row.id).then(fetch),
                    onAssignEmployees: (row) => updateObject({ openAssignEmployeesModal: true, selected: row })
                })}
                refetch={(conf) => fetch(conf, isHoliday)}
                isLoading={isPaginating}
                tableConfig={tableConfig}
            />
            {object.openEditModal && (
                <UpdateModal
                    open={object.openEditModal}
                    onChange={(bool) => {
                        const temp = { openEditModal: bool };
                        if (!bool) {
                            temp.selected = null;
                            typeof onAddModalClose === "function" && onAddModalClose();
                        }
                        updateObject(temp);
                    }}
                    onFinish={handleFinish}
                    id={object?.selected?.id}
                    setting={setting}
                />
            )}
            {object.openAssignEmployeesModal && (
                <TransferAssignEmployeesModal
                    open={object.openAssignEmployeesModal}
                    onClose={() => updateObject({ selected: null, openAssignEmployeesModal: false })}
                    data={object.selected}
                    type={ASSIGN_TYPE.WORK_SHIFT}
                />
            )}
        </>
    );
}

WorkShift.propTypes = {
    openAddModal: PropTypes.bool,
    onAddModalClose: PropTypes.func,
    isHoliday: PropTypes.bool,
    isRegular: PropTypes.bool
};

export default WorkShift;
