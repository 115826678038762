import PropTypes from "prop-types";
import React, { useEffect, useState, Suspense, useMemo } from "react";
import isEqual from "lodash/isEqual";
import { TAB_ACTION } from "./const";
import { useLocation } from "react-router-dom";

const Tabs = ({
    id,
    children,
    defaultIndex = 0,
    className,
    styles = {},
    hasBackground,
    isCapsule,
    useStorage,
    onChange,
    fallback: globalFallback = <div>Loading...</div>
}) => {
    const location = useLocation();
    const userTypedHash = !useStorage && (location.hash || "").replace("#", "");
    const UNIQUE_STORAGE_ID = useMemo(() => "active-tab-" + id, []);

    const getDefaultActiveTab = () => {
        const foundTabByDefaultIndex = findTabByIndex(defaultIndex || 0);
        const hashToUse = userTypedHash || localStorage.getItem(UNIQUE_STORAGE_ID) || foundTabByDefaultIndex.hash;
        return findTabByHash(hashToUse);
    };

    const findTabByIndex = (idx) =>
        React.Children.map(children, (child, index) => ({ ...child.props, index })).find((child, index) => isEqual(index, idx));

    const findTabByHash = (hash) =>
        React.Children.map(children, (child, index) => ({ ...child.props, index })).find((child) => isEqual(child.hash, hash));

    const [isMounted, setMounted] = useState(false);
    const [activeIndex, setActiveIndex] = useState(getDefaultActiveTab()?.index || defaultIndex);

    useEffect(() => {
        if (isMounted) {
            const activeTab = getDefaultActiveTab();
            if (activeTab) {
                updateIndex(activeTab.index, activeTab.hash, TAB_ACTION.MOUNTING);
            }
        }
    }, [isMounted]);

    useEffect(() => {
        if (!isMounted) {
            setMounted(true);
        }
    }, []);

    const updateIndex = (newIndex, newHash, action) => {
        const savedHash = localStorage.getItem(UNIQUE_STORAGE_ID);
        setActiveIndex(Number(newIndex));
        onChange?.(Number(newIndex), newHash, action);
        if (!isEqual(newHash, savedHash) || !savedHash) {
            localStorage.setItem(UNIQUE_STORAGE_ID, newHash);
        }
        !useStorage && (window.location.hash = newHash);
    };

    return (
        <div className={`tk-tabs ${isCapsule ? "tab-capsule" : ""} ${className || ""}`.trim()} style={styles.parent}>
            <div className="tk-tabs__header" style={styles.head}>
                {React.Children.map(children, (child, index) => (
                    <div
                        key={index}
                        className={`tk-tabs__item head ${index === activeIndex ? "active" : ""}`.trim()}
                        onClick={() => updateIndex(index, child.props.hash, TAB_ACTION.CHANGING)}
                    >
                        <div className="text-ellipsis">{child.props.label}</div>
                    </div>
                ))}
            </div>
            <div className={"tk-tabs__body w100" + (hasBackground ? " has-bg" : "")} style={styles.body}>
                <div className="tk-tabs__item content" style={styles.content}>
                    {React.Children.map(children, (child, index) => {
                        if (index === activeIndex) {
                            const tabFallback = child.props.fallback || globalFallback;
                            return <Suspense fallback={tabFallback}>{React.cloneElement(child)}</Suspense>;
                        }
                        return null;
                    })}
                </div>
            </div>
        </div>
    );
};

export default Tabs;

Tabs.propTypes = {
    id: PropTypes.string.isRequired,
    defaultIndex: PropTypes.number,
    children: PropTypes.any,
    className: PropTypes.string,
    onChange: PropTypes.func,
    styles: PropTypes.shape({
        parent: PropTypes.object,
        head: PropTypes.object,
        body: PropTypes.object,
        content: PropTypes.object
    }),
    isCapsule: PropTypes.bool,
    hasBackground: PropTypes.bool,
    useStorage: PropTypes.bool,
    fallback: PropTypes.node
};
