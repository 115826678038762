import React, { useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import {
    TOAST_TYPE,
    addCommasToMoney,
    createToast,
    sanitizeDatesWithProperTimeConversionFromObject,
    toReadableSelectOptions
} from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import {
    selectCurrent,
    selectTableConfig,
    setCurrent,
    setState,
    selectLoading,
    setLoading,
    selectEmployeeDeductionsData,
    defaultConfig
} from "./slice";
import {
    useCancelEmployeeDeductionsMutation,
    useCreateEmployeeDeductionsMutation,
    useDeleteEmployeeDeductionsMutation,
    useGetEmployeeDeductionsMutation,
    useLoadAllEmployeeDeductionsMutation,
    useUpdateEmployeeDeductionsMutation,
    useUpdateStatusEmployeeDeductionsMutation
} from "./api";
import Tag from "../../../common/components/extra/Tag";
import { CUSTOM_ACTION, DATE_FIELDS, DEDUCTION_PAYMENT_TYPE, DEDUCTION_STATUS, DEDUCTION_TYPE, FIELD } from "./const";
import { selectUserSetting } from "../../common/slice";
import { createStatusTag } from "./helper";
import usePaginateFetch from "../../../common/hooks/usePaginateFetch";
import { STANDARD_DATE_FORMAT } from "../../../common/utilities/const";
import { useFetchRecord, useUpsertRecord } from "../../common/hooks";

const { EMPLOYEE, TYPE, PAYMENT_TYPE, AMOUNT, START_DATE, END_DATE, STATUS, CONFIRMED_DATE, NOTES } = FIELD;

export const useGetEmployeeDeductions = (id, callback) => {
    const [current, { isInitial, isLoading, updateCurrent, fetch, reset, clearCurrent, refetch }] = useFetchRecord(
        {
            id,
            rtk: {
                useGetMutation: useGetEmployeeDeductionsMutation,
                selectData: selectEmployeeDeductionsData,
                selectCurrent,
                setCurrent,
                setState
            },
            dateFields: DATE_FIELDS,
            dateFormat: STANDARD_DATE_FORMAT
        },
        { runOnMount: true, onMount: callback }
    );

    const createVars = (data) => {
        if (!data) return {};

        return {
            confirmedDate: data?.[CONFIRMED_DATE] || "",
            start_date: data?.[START_DATE] || "",
            end_date: data?.[END_DATE] || "",
            amount: addCommasToMoney(data.amount),
            isOneTimePayment: data?.[PAYMENT_TYPE] == DEDUCTION_PAYMENT_TYPE.ONE_TIME
        };
    };
    return [
        current,
        {
            isInitial,
            isLoading,
            update: updateCurrent,
            fetch,
            reset,
            clearCurrent,
            refetch,
            config: createVars(current)
        }
    ];
};

export const usePaginateEmployeeDeductions = ({ readOnly } = {}) => {
    const [load, isLoading, { onFilter, onSearch, data, tableConfig, onSort, onUpdate }] = usePaginateFetch(useLoadAllEmployeeDeductionsMutation, {
        redux: {
            dataSelector: selectEmployeeDeductionsData,
            tableConfigSelector: selectTableConfig,
            currentSelector: selectCurrent,
            setState
        },
        defaultConfig,
        onMountConfig: {},
        runOnMount: !readOnly
    });

    const fetch = async (config) => {
        try {
            const response = await load(config);
            if (response.error) {
                throw new Error("Failed to fetch data. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    return [data, { isLoading, fetch, update: onUpdate, onSearch, onFilter, tableConfig, onSort }];
};

export const useUpsertEmployeeDeductions = (updateId, callback) => {
    const isCreate = !updateId;

    const [current, { isLoading: isGettingRecord }] = useGetEmployeeDeductions(updateId, callback);
    const [form, updateForm, { upsert, isUpserting, isCreating, isUpdating, old, hasChanges }] = useUpsertRecord(
        {
            updateId,
            defaultForm: {
                [EMPLOYEE]: "",
                [TYPE]: "",
                [PAYMENT_TYPE]: DEDUCTION_PAYMENT_TYPE.INSTALLMENT,
                [AMOUNT]: "",
                [START_DATE]: "",
                [END_DATE]: "",
                [NOTES]: "",
                [STATUS]: DEDUCTION_STATUS.ACTIVE
            },
            current,
            isGettingRecord,
            rtk: {
                useCreateMutation: useCreateEmployeeDeductionsMutation,
                useUpdateMutation: useUpdateEmployeeDeductionsMutation,
                setCurrent
            },
            dateFields: DATE_FIELDS,
            dateFormat: STANDARD_DATE_FORMAT
        },
        {
            onBeforeUpsert: async (obj = {}) => {
                let clonedform = cloneDeep(obj);
                clonedform[EMPLOYEE]?.id && (clonedform[EMPLOYEE] = clonedform[EMPLOYEE].id);
                const isOneTime = clonedform[PAYMENT_TYPE] == DEDUCTION_PAYMENT_TYPE.ONE_TIME;
                isOneTime && (clonedform[END_DATE] = null);
                return clonedform;
            }
        }
    );

    const createVars = () => {
        const statusOpt = toReadableSelectOptions(DEDUCTION_STATUS).map((t) => ({ ...t, label: createStatusTag(t.value) }));
        const paymentTypeOpt = toReadableSelectOptions(DEDUCTION_PAYMENT_TYPE).map((t) => ({ ...t, label: <Tag className="flex">{t.label}</Tag> }));
        const typeOpt = toReadableSelectOptions(DEDUCTION_TYPE).map((t) => ({ ...t, label: <Tag className="flex">{t.label}</Tag> }));

        const status = statusOpt.find((status) => status.value == form[STATUS]) || "";
        const paymentType = paymentTypeOpt.find((category) => category.value == form[PAYMENT_TYPE]) || "";
        const type = typeOpt.find((type) => type.value == form[TYPE]) || "";

        return {
            statusOpt,
            paymentTypeOpt,
            typeOpt,
            [STATUS]: status,
            [PAYMENT_TYPE]: paymentType,
            [TYPE]: type,
            [START_DATE]: form[START_DATE],
            [END_DATE]: form[END_DATE],
            employee: { ...(current?.employee || {}), id: form[EMPLOYEE] },
            hasConfirmedDate: !isCreate && !!current?.[CONFIRMED_DATE],
            isCancelled: form[STATUS] == DEDUCTION_STATUS.CANCELED
        };
    };

    return [
        form,
        updateForm,
        {
            upsert,
            isGettingRecord,
            isUpserting,
            isCreating,
            isUpdating,
            config: createVars(),
            old,
            hasChanges
        }
    ];
};

export const useDeleteEmployeeDeductions = () => {
    const dispatch = useAppDispatch();

    const [deleteRecord] = useDeleteEmployeeDeductionsMutation();

    const isLoading = useAppSelector(selectLoading);

    const remove = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await deleteRecord({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to delete deduction.");
            }
            createToast("Deduction successfully removed.", TOAST_TYPE.SUCCESS);
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [remove, isLoading];
};

export const useCancelEmployeeDeductions = () => {
    const [notes, setNotes] = useState("");

    const dispatch = useAppDispatch();

    const [cancelRecord] = useCancelEmployeeDeductionsMutation();

    const isLoading = useAppSelector(selectLoading);
    const current = useAppSelector(selectCurrent);
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const cancel = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await cancelRecord({ extraPath: id, body: { notes } });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to cancel deduction.");
            }
            if (current) {
                dispatch(
                    setCurrent({
                        ...current,
                        ...response.data.data,
                        status: DEDUCTION_STATUS.CANCELED,
                        notes
                    })
                );
            }
            createToast("Deduction successfully cancelled.", TOAST_TYPE.SUCCESS);
            return sanitizeDatesWithProperTimeConversionFromObject(response.data.data, timezone, DATE_FIELDS, STANDARD_DATE_FORMAT);
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return { error };
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [cancel, { isLoading, setNotes, notes }];
};

export const useManageActions = ({ cb } = {}) => {
    const [cancelId, setCancelId] = useState(null);
    const [openViewModal, setOpenViewModal] = useState(false);

    const [, { fetch }] = usePaginateEmployeeDeductions({ readOnly: true });
    const [remove] = useDeleteEmployeeDeductions();

    const dispatch = useAppDispatch();

    const handleAction = async (row, actionType) => {
        switch (actionType) {
            case CUSTOM_ACTION.PREVIEW:
                setOpenViewModal(true);
                dispatch(setCurrent({ id: row.id }));
                return;
            case CUSTOM_ACTION.REMOVE:
                return await remove(row.id).then(async (result) => {
                    await fetch();
                    typeof cb == "function" && cb(result);
                });
            case CUSTOM_ACTION.CANCEL:
                setCancelId(row.id);
                return;
            default:
                return;
        }
    };

    return { onAction: handleAction, cancelId, setCancelId, openViewModal, setOpenViewModal };
};

export const useUpdateStatusEmployeeDeductions = () => {
    const [updateStatus, { isLoading }] = useUpdateStatusEmployeeDeductionsMutation();

    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const method = async (id, newStatus) => {
        try {
            const response = await updateStatus({
                extraPath: id,
                body: {
                    [FIELD.STATUS]: newStatus
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to update deduction.");
            }
            createToast("Deduction successfully updated.", TOAST_TYPE.SUCCESS);
            return sanitizeDatesWithProperTimeConversionFromObject(response.data.data, timezone, DATE_FIELDS, STANDARD_DATE_FORMAT);
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return { error };
        }
    };

    return [method, isLoading];
};
