import React from "react";
import PropTypes from "prop-types";
import { createClass, createConfirmAlert, createGroup } from "../../../common/utilities/helper";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import { useCancelEmployeePenalties } from "./hooks";
import { BASE_CLASS, FIELD, PENALTY_STATUS } from "./const";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { NOTES_MAX_LEN } from "../../../common/utilities/const";
import { useAppDispatch } from "../../../common/hooks/reduxHooks";
import { updateEmployeePenaltiesData } from "./slice";
import Text from "../../../common/components/extra/typography/Text";

function CancelPenaltyModal({ open, onClose, onFinish, id }) {
    const [cancel, { isLoading, setNotes, notes }] = useCancelEmployeePenalties(id);

    const disableSave = isLoading;
    const dispatch = useAppDispatch();

    const handleSave = async () => {
        const result = await cancel(id);
        if (!result?.error) {
            dispatch(
                updateEmployeePenaltiesData({
                    id,
                    data: { status: PENALTY_STATUS.CANCELED }
                })
            );
            typeof onFinish === "function" && onFinish(result);
        }
        return result?.error;
    };

    const createTitle = () => {
        return (
            <div className="flex gap-05">
                <span>Cancel Penalty</span>
            </div>
        );
    };

    return (
        <BaseUpdateModal
            title={createTitle()}
            open={open}
            onClose={onClose}
            onSave={(e) =>
                createConfirmAlert({
                    title: "Canceling Penalty",
                    content: `Are you sure you want to proceed? This cannot be undone.`,
                    onConfirm: async (close) => {
                        close();
                        const hasError = await handleSave(e);
                        if (!hasError) {
                            onClose();
                        }
                    }
                })
            }
            disableSave={disableSave}
            isLoading={isLoading}
            small
            isForm
        >
            {createGroup({
                base: createClass("__modal-content-update", BASE_CLASS),
                body: (
                    <div className="flex column">
                        <div className="flex column" style={{ marginTop: "1rem" }}>
                            <Input
                                name={FIELD.NOTES}
                                label="Notes/Description"
                                type={INPUT_TYPE.TEXTAREA}
                                value={notes}
                                parentStyle={{ height: "10rem", minHeight: "5rem" }}
                                onChange={(e) => setNotes(e.target.value)}
                                maxLength={NOTES_MAX_LEN}
                                required
                            />
                        </div>
                        <Text className="fade">Note: It is Required to add a note when canceling a penalty.</Text>
                    </div>
                )
            })}
        </BaseUpdateModal>
    );
}

CancelPenaltyModal.propTypes = {
    photo: PropTypes.string,
    open: PropTypes.bool,
    id: PropTypes.any,
    onClose: PropTypes.func,
    onFinish: PropTypes.func
};

export default CancelPenaltyModal;
