import PropTypes from "prop-types";
import React from "react";
import EditIcon from "@mui/icons-material/BorderColor";
import More from "../../common/More";
import Button, { BTN_TYPE } from "../../extra/Button";

function BaseViewModalWrapper({ children, onEdit, headExtra, moreProps, disableEdit }) {
    return (
        <div className="view-modal flex column gap-1" style={{ flexGrow: 1 }}>
            {(onEdit || headExtra || moreProps) && (
                <section className="flex gap-05 wrap">
                    {onEdit && (
                        <Button className="primary" onClick={onEdit} disabled={disableEdit} mini small>
                            <span>Edit</span>
                            <EditIcon />
                        </Button>
                    )}
                    {headExtra}
                    {moreProps && !!moreProps.items?.length && <More isButton isVertical {...(moreProps || {})} />}
                </section>
            )}
            {children}
        </div>
    );
}

export default BaseViewModalWrapper;

BaseViewModalWrapper.propTypes = {
    disableEdit: PropTypes.bool,
    onEdit: PropTypes.func,
    children: PropTypes.any,
    headExtra: PropTypes.any,
    moreProps: PropTypes.shape({
        isVertical: PropTypes.bool,
        isButton: PropTypes.bool,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                render: PropTypes.any
            })
        ),
        onSelect: PropTypes.func,
        styles: PropTypes.shape({
            parent: PropTypes.object,
            items: PropTypes.object
        }),
        buttonProps: PropTypes.shape({
            small: PropTypes.bool,
            transparent: PropTypes.bool,
            className: PropTypes.string,
            type: PropTypes.oneOf([0, ...Object.values(BTN_TYPE)]),
            children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
            beforeExtra: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
            afterExtra: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
            disabled: PropTypes.bool,
            isLoading: PropTypes.bool,
            onClick: PropTypes.func,
            options: PropTypes.object,
            mini: PropTypes.bool,
            noLabel: PropTypes.bool,
            tooltipProps: PropTypes.object
        })
    })
};
