import React from "react";
import PropTypes from "prop-types";
import Text from "../typography/Text";

function ConditionWrapper({ children, warnings = [], errors = [], subtext, errorProps = {}, warningProps = {}, useSubTextStyle, style = {} }) {
    const warnigOpt = Array.isArray(warnings) && warnings.filter(Boolean);
    const errorsOpt = Array.isArray(errors) && errors.filter(Boolean);

    const hasWarning = !!warnigOpt.length;
    const hasError = !!errorsOpt.length;
    const hasSubtext = subtext && !subtext.hide && !!subtext.message;

    const createClassname = () => {
        let str = "condition-wrapper flex column w100";
        if (hasWarning) str += " has-warning";
        if (hasError) str += " has-error";
        return str.trim();
    };

    return (
        <div className={createClassname()} style={{ width: "inherit", minHeight: "auto", ...style }}>
            {children}
            {(hasWarning || hasError || hasSubtext) && (
                <div className="subtext flex column">
                    {hasSubtext && (
                        <Text style={{ ...(subtext.style || {}) }} className={subtext.className} useSubTextStyle={useSubTextStyle}>
                            {subtext.message}
                        </Text>
                    )}
                    {hasWarning && (
                        <div className="flex column gap-05">
                            {warnigOpt.map((warning, idx) => (
                                <Text
                                    key={idx}
                                    style={{ ...(warningProps.style || {}) }}
                                    className={"warning-color" || warningProps.className}
                                    useSubTextStyle={useSubTextStyle}
                                >
                                    {warning}
                                </Text>
                            ))}
                        </div>
                    )}
                    {hasError && (
                        <div className="flex column gap-05">
                            {errorsOpt.map((error, idx) => (
                                <Text
                                    key={idx}
                                    style={{ ...(errorProps.style || {}) }}
                                    className={"danger-color" || errorProps.className}
                                    useSubTextStyle={useSubTextStyle}
                                >
                                    {error}
                                </Text>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default ConditionWrapper;

ConditionWrapper.propTypes = {
    children: PropTypes.any,
    warnings: PropTypes.array,
    errors: PropTypes.array,
    errorProps: PropTypes.object,
    warningProps: PropTypes.object,
    subtext: PropTypes.shape({
        style: PropTypes.object,
        className: PropTypes.string,
        message: PropTypes.any,
        hide: PropTypes.bool
    }),
    useSubTextStyle: PropTypes.bool,
    style: PropTypes.object
};
