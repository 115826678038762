import React, { useState } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import Modal from "../../../common/components/extra/Modal";
import AdvanceList from "../../../common/components/extra/AdvanceList";
import Tag from "../../../common/components/extra/Tag";
import { createFullName, createObjectFromKeys, flattenObject, isValid, sanitizeWords } from "../../../common/utilities/helper";
import { FALSE_VALUES } from "../../../common/utilities/const";
import { STATUS } from "../employees/const";
import { customSortKeys } from "../employees/helper";
import Tooltip from "../../../common/components/extra/Tooltip";
import useFetchCountries from "../../../common/hooks/useFetchCountries";

const BASE_CLASS = "tk-bulk-upload__select-changes-modal-content";

const createClass = (newStr = "") => `${BASE_CLASS}${newStr}`;

const LIST_STYLES = {
    parent: {
        maxWidth: "20rem",
        minWidth: "20rem"
    }
};

const NOT_EDITABLE_KEYS = []; // add keys if we want to prevent editing i.e: "employee.residenceID"

function SelectChangesModal({ open, onChange, onFinish, data = {} }) {
    const countries = useFetchCountries();
    const getNationality = (nationality) => countries.find((ctr) => ctr.cca2 == nationality) || "";

    const keys = Object.keys(
        data.duplicates
            .map((d) =>
                flattenObject(
                    Object.entries(d)
                        .filter(([, val]) => val.changes)
                        .reduce((prev, [key, value]) => ({ ...prev, [key]: value.changes }), {})
                )
            )
            .reduce((prev, curr) => ({ ...prev, ...curr }), {})
    );
    keys.sort((a, b) => customSortKeys(a, b, NOT_EDITABLE_KEYS));
    const defaultOriginal = createObjectFromKeys(
        keys,
        data.duplicates
            .map((d) =>
                flattenObject(
                    Object.entries(d)
                        .filter(([, val]) => val.original)
                        .reduce((prev, [key, value]) => ({ ...prev, [key]: value.original }), {})
                )
            )
            .reduce((prev, curr) => ({ ...prev, ...curr }), {})
    );

    const [form, setForm] = useState({
        duplicates: [],
        id: null,
        original: null,
        selected: defaultOriginal,
        ...data
    });

    const updateForm = (newForm = {}) => setForm({ ...form, ...newForm });

    const handleSave = async () => {
        const clonedform = cloneDeep(form);
        const selected = clonedform.selected || {};
        const acceptedKeys = clonedform.duplicates.map((cd) => cd.accepted).reduce((prev, curr) => ({ ...prev, ...curr }), {});
        const keepOriginal = !Object.keys(acceptedKeys || {}).length;
        for (const key in selected) {
            if (Object.hasOwnProperty.call(selected, key)) {
                const value = selected[key];
                if (FALSE_VALUES.includes(value)) {
                    delete selected[key];
                }
            }
        }
        clonedform.selected = selected;
        clonedform.keepOriginal = keepOriginal;
        clonedform.mixed = !keepOriginal ? getStatusFromDuplicates().key === STATUS.MIXED.key : false;
        clonedform.modified = keepOriginal ? false : clonedform.modified || false;
        typeof onFinish === "function" && onFinish(clonedform);
    };

    const handleListChange = (accepted, rowNum, key, checked) => {
        let clonedoriginal = cloneDeep(form.selected);
        let cloneduplicates = cloneDeep(form.duplicates);
        let idx = cloneduplicates.findIndex((cd) => cd.rowNum === rowNum);
        //  manipulate accepted property which determines if the current component property is checked
        cloneduplicates = cloneduplicates.map((cud) => {
            const changes = flattenObject(
                Object.entries(cud)
                    .map(([key, c]) => ({ [key]: c.changes }))
                    .reduce((prev, curr) => ({ ...prev, ...curr }), {})
            );
            const changesKeys = Object.keys(changes);
            delete changes.rowNum;
            delete changes.duplicateBy;
            delete changes.accepted;

            // if individual check
            if (key) {
                // we compare changes and accepted using the key params since it is available during individual check
                if (checked && isValid(changes[key]) && isValid(accepted[key]) && changes[key].toString() == accepted[key].toString()) {
                    cud.accepted[key] = accepted[key];
                } else {
                    delete cud.accepted[key];
                }
            }
            // if all is checked
            else {
                Object.entries(accepted).forEach(([k, value]) => {
                    // we compare the accepted and changes properties for check all and uncheck all using the accepted key value pair
                    if (checked && k && isValid(value) && isValid(changes[k]) && value.toString() === changes[k].toString()) {
                        cud.accepted[k] = value;
                    } else {
                        delete cud.accepted[k];
                    }
                });
                changesKeys.forEach((ck) => !accepted[ck] && delete cud.accepted[ck]);
            }
            return cud;
        });

        // assign the key
        cloneduplicates[idx].accepted = accepted;
        // collect all the keys first of the duplicate objects
        const acceptedKeys = cloneduplicates.map((cd) => cd.accepted).reduce((prev, curr) => ({ ...prev, ...curr }), {});
        // iterate the collected keys so that we get the updated selected keys and if not found in the accepted keys we use the default original value
        for (const key in defaultOriginal) {
            if (Object.hasOwnProperty.call(defaultOriginal, key)) {
                const item = defaultOriginal[key];
                if (key in acceptedKeys) {
                    const currentValue = acceptedKeys[key];
                    // if value is a falsy we dont allow it and use the original value instead
                    if (isValid(currentValue)) {
                        clonedoriginal[key] = currentValue;
                    } else {
                        clonedoriginal[key] = item;
                    }
                } else {
                    clonedoriginal[key] = item;
                }
            }
        }

        updateForm({ duplicates: cloneduplicates, selected: clonedoriginal });
    };

    const getStatusFromDuplicates = () => {
        const dups = form.duplicates;
        const hasAcceptedDups =
            dups.map((d) => ({ ...(d?.accepted || {}), row: d.rowNum })).filter((d) => Object.keys(d).filter(Boolean).length > 1) || [];
        const isMixed = hasAcceptedDups.length > 1;
        const isCustom = hasAcceptedDups.length === 1;
        const isOriginal = hasAcceptedDups.length <= 0;
        const status = isMixed ? STATUS.MIXED : isCustom ? STATUS.CUSTOM : isOriginal ? STATUS.ORIGINAL : STATUS.ORIGINAL;
        if (status.key === STATUS.CUSTOM.key) {
            // if status becomes custom it will contain 1 value anyway so we will get the first row only
            status.label = "ROW " + hasAcceptedDups[0].row;
        }
        return status;
    };

    const modalHeader = (
        <div className="flex center column">
            <span>Employee - {createFullName(form.original.first_name, form.original.last_name)}</span>
            <span>(Select Changes)</span>
        </div>
    );

    return (
        <Modal
            title={modalHeader}
            open={open}
            onChange={onChange}
            onSave={handleSave}
            disableSave={isEqual(form, data)}
            styles={{ content: { width: "80vw" }, body: { overflow: "auto" }, form: { padding: "0 5rem" }, footer: { paddingTop: "1rem" } }}
            saveLabel="Confirm Changes"
            hasHeaderStyle
            isForm
        >
            <div className={createClass()}>
                <div className={createClass("__inner")}>
                    <AdvanceList
                        head={{
                            extra: (
                                <div className="flex align-center gap-03 align-center" style={{ height: "1rem" }}>
                                    <div className="flex align-center fade semi-bold gap-03">Employee ID :</div>
                                    <span className="bold primary-color">{data.original.generatedID}</span>
                                    <Tooltip message="System Generated ID" isIcon />
                                </div>
                            ),
                            render: (value) => {
                                value = sanitizeWords(value.split(".").pop(), "_", false);
                                return (
                                    <span className="text-ellipsis" style={{ fontWeight: "bold" }} title={value}>
                                        {value}
                                    </span>
                                );
                            }
                        }}
                        styles={LIST_STYLES}
                        data={keys.map((k) => (k.includes("department_code") ? "department" : k))}
                        isArray
                    />
                    <AdvanceList
                        head={{
                            extra: <div></div>,
                            label: () => (
                                <div className={createClass("__list__head")} style={{ fontWeight: "bold" }}>
                                    Selected <Tag className={getStatusFromDuplicates().color}>{getStatusFromDuplicates().label}</Tag>
                                </div>
                            ),
                            render: (value, key) => {
                                value = Array.isArray(value) ? value.map((val) => val.toUpperCase()).join(", ") : value;
                                const isNationality = key == "employee.nationality";
                                if (isNationality) {
                                    const nationality = getNationality(value);
                                    value = (nationality && (nationality.demonyms?.eng?.m || nationality?.name?.common)) || value;
                                }
                                const isNotEditable = NOT_EDITABLE_KEYS.includes(key);
                                const isOriginal =
                                    !isNotEditable &&
                                    defaultOriginal[key] !== undefined &&
                                    isEqual(defaultOriginal[key].toString(), value.toString());
                                return (
                                    <div className="flex align-center">
                                        <span
                                            className="text-ellipsis semi-bold"
                                            title={sanitizeWords(value)}
                                            style={isOriginal ? { maxWidth: "13rem" } : {}}
                                        >
                                            {sanitizeWords(value)}
                                        </span>
                                        {isOriginal && (
                                            <>
                                                &nbsp;
                                                <span className="fade small-font">(Original)</span>
                                            </>
                                        )}
                                        {isNotEditable && (
                                            <>
                                                &nbsp;
                                                <span className="fade small-font">(Update Not Allowed)</span>
                                            </>
                                        )}
                                    </div>
                                );
                            }
                        }}
                        data={createObjectFromKeys(
                            keys,
                            flattenObject(Object.entries(form.selected).reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {})),
                            defaultOriginal
                        )}
                        styles={LIST_STYLES}
                    />
                    {form.duplicates.map((duplicate, idx) => {
                        return (
                            <AdvanceList
                                key={idx}
                                head={{
                                    extra: <div className="fade bold">Row # / Duplicate By</div>,
                                    label: () => (
                                        <div className={createClass("__list__head")}>
                                            Row {duplicate.rowNum}
                                            <Tag className="red">
                                                <span style={{ textTransform: "uppercase" }}>{sanitizeWords(duplicate.duplicateBy)}</span>
                                            </Tag>
                                        </div>
                                    ),
                                    render: (value, key) => {
                                        value = Array.isArray(value) ? value.map((val) => val.toUpperCase()).join(", ") : value;
                                        const isNationality = key == "employee.nationality";
                                        if (isNationality) {
                                            const nationality = getNationality(value);
                                            value = (nationality && (nationality.demonyms?.eng?.m || nationality?.name?.common)) || value;
                                        }
                                        return (
                                            <span className="text-ellipsis semi-bold" title={value}>
                                                {value ? sanitizeWords(value.toString()) : value}
                                            </span>
                                        );
                                    }
                                }}
                                data={createObjectFromKeys(
                                    keys,
                                    flattenObject(
                                        Object.entries(duplicate)
                                            .filter(([, val]) => val.changes)
                                            .reduce((prev, [key, value]) => ({ ...prev, [key]: value.changes }), {})
                                    )
                                )}
                                onChange={(keys, key, checked) => handleListChange(keys, duplicate.rowNum, key, checked)}
                                values={Object.keys(duplicate?.accepted || {})}
                                styles={LIST_STYLES}
                                disabledKeys={NOT_EDITABLE_KEYS}
                                hasCheckbox
                            />
                        );
                    })}
                </div>
            </div>
        </Modal>
    );
}

SelectChangesModal.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.object,
    onChange: PropTypes.func,
    onFinish: PropTypes.func
};

export default SelectChangesModal;
