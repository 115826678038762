import PropTypes from "prop-types";
import React from "react";

function BaseUpdateModalWrapper({ children, styles }) {
    return (
        <div className="update-modal h100">
            <div
                className="update-modal-inner h100"
                style={{
                    padding: "1.5rem",
                    ...(styles?.inner || {})
                }}
            >
                {children}
            </div>
        </div>
    );
}

export default BaseUpdateModalWrapper;

BaseUpdateModalWrapper.propTypes = {
    children: PropTypes.any,
    styles: PropTypes.shape({
        inner: PropTypes.object
    })
};
