import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { FILTER_TYPE } from "./const";
import useFilterManager from "../../../common/hooks/useFilterManager";
import FilterControl from "../../../common/components/extra/filter/FilterControl";
import Select from "../../../common/components/extra/select/Select";
import DateFilter from "../../../common/components/extra/filter/DateFilter";
import { DATE_RANGE, SHIFT_TYPE } from "../../../common/utilities/const";
import { sanitizeWords, toFilterOptions } from "../../../common/utilities/helper";
import { selectFilter, setFilter, resetFilter, defaultFilter } from "./slice";

const COMMON_PROPS = {
    style: { minWidth: "12rem", height: "2rem", maxWidth: "12rem" },
    styles: { control: { borderRadius: "25rem" } },
    menuPortalTarget: document.body,
    isClearable: true
};

function Filter({ onFilter, isLoading }) {
    const [filterConfig] = useState({
        [FILTER_TYPE.SHIFT_TYPE]: {
            options: toFilterOptions(SHIFT_TYPE).map((opt) => {
                return {
                    value: opt.value,
                    label: <span className="bold small-font">{sanitizeWords(!opt.value ? "All Type" : opt.label)}</span>
                };
            })
        }
    });

    const recordFilter = useAppSelector(selectFilter) || {};

    const [filter, { isClearDisabled, isFilterDisabled, getValueFromOpt, handleChange, handleReset, handleCustomDateChange, handleFilter }] =
        useFilterManager({ onFilter, filterConfig, defaultFilter, filter: recordFilter, isLoading, setFilter, resetFilter });

    return (
        <div className="tk-filter flex gap-05 w100 align-center">
            <div className="flex gap-05 wrap align-center">
                <Select
                    {...COMMON_PROPS}
                    options={filterConfig[FILTER_TYPE.SHIFT_TYPE].options}
                    value={getValueFromOpt(FILTER_TYPE.SHIFT_TYPE, filter[FILTER_TYPE.SHIFT_TYPE])}
                    onChange={(conf) => handleChange(FILTER_TYPE.SHIFT_TYPE, conf.value)}
                    placeholder="Shift Type"
                    isClearable={!!filter[FILTER_TYPE.SHIFT_TYPE]}
                    isDisabled={isLoading}
                />
                <DateFilter
                    filterProps={{
                        ...COMMON_PROPS,
                        value: filter[FILTER_TYPE.DATE]?.type,
                        onChange: (newvalue) => handleCustomDateChange(DATE_RANGE.TYPE, newvalue, FILTER_TYPE.DATE),
                        isDisabled: isLoading
                    }}
                    dateProps={{
                        onChange: (name, value) => handleCustomDateChange(name, value, FILTER_TYPE.DATE),
                        value: filter[FILTER_TYPE.DATE],
                        styles: {
                            date: {
                                ...COMMON_PROPS.style,
                                width: COMMON_PROPS.style.minWidth,
                                borderRadius: COMMON_PROPS.styles.control.borderRadius
                            }
                        }
                    }}
                    label="Created"
                />
            </div>
            <FilterControl
                isClearDisabled={isClearDisabled}
                isFilterDisabled={isFilterDisabled}
                onReset={handleReset}
                onFilter={handleFilter}
                isLoading={isLoading}
            />
        </div>
    );
}

export default Filter;

Filter.propTypes = {
    onFilter: PropTypes.func,
    isLoading: PropTypes.bool,
    isHoliday: PropTypes.bool
};
