import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import { createClass, createConfirmAlert, createGroup, formattedJoiErrorMessage } from "../../../common/utilities/helper";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
// import { useCreateSettlement, useGetPreview } from "./hooks";
import {
    BASE_CLASS_CREATE_FINAL_MODAL,
    FIELD,
    SETTLEMENT_CATEGORY,
    SETTLEMENT_CLASSIFICATION,
    SETTLEMENT_TYPE
    // FIELD,
    // OTHER_SUPPORTED_SETTLEMENT_TYPE,
    // SETTLEMENT_CATEGORY,
    // SETTLEMENT_CLASSIFICATION,
    // SETTLEMENT_TYPE
} from "./const";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import EmployeeSelectLazy from "../employees/EmployeeSelectLazy";
import { useCreateSettlement, useGetPreviewFinalSettlement } from "./hooks";
import Button from "../../../common/components/extra/Button";
import SelectConstant from "../../../common/components/extra/select/SelectConstant";
import ConfirmSettlementModal from "./ConfirmSettlementModal";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import UsedSettingsInfo from "./UsedSettingsInfo";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
// import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
// import { useAppSelector } from "../../../common/hooks/reduxHooks";
// import { selectUserSetting } from "../../common/slice";
// import EmployeeSelectLazy from "../employees/EmployeeSelectLazy";
// import SelectConstant from "../../../common/components/extra/select/SelectConstant";
// import Button from "../../../common/components/extra/Button";

function CreateFinalSettlementsModal({ open, onClose, onBack, onFinish }) {
    const [internalForm, setInternalForm] = useState({
        showConfirmModal: false
    });

    const [error, setError] = useState({ all: null });

    const [form, formOnChange, { isUpserting }] = useCreateSettlement({
        [FIELD.TYPE]: SETTLEMENT_TYPE.FULL_FINAL,
        [FIELD.CATEGORY]: SETTLEMENT_CATEGORY.NORMAL
    });
    const [preview, isGeneratingPreview, { data: rawPreviewData, reset: resetPreviewData }] = useGetPreviewFinalSettlement();

    const setting = useAppSelector(selectUserSetting);
    const salarySetting = setting.salary;
    const settlement = salarySetting.settlement;
    const includeUnpaidLeaveDaysToServiceDays = settlement.includeUnpaidLeaveDays;

    const previewData = useMemo(() => {
        if (!rawPreviewData) {
            return null;
        }
        const clone = cloneDeep(rawPreviewData || {});
        // we can transform data here...
        return clone;
    }, [rawPreviewData]);

    const minRequirementNotSatisfied = !form[FIELD.EMPLOYEE] || !form[FIELD.CLASSIFICATION] || !form[FIELD.LAST_WORKING_DATE];
    const showSave = !!rawPreviewData;
    const disableSave = isUpserting;
    const showGenerate = !previewData;
    const disableGenerate = minRequirementNotSatisfied;

    const onChange = (...rest) => {
        formOnChange(...rest);
        if (error.all) {
            setError({
                ...error,
                all: null
            });
        }
    };

    const updateInternals = (name, value) => {
        setInternalForm({
            ...internalForm,
            [name]: value
        });
    };

    const onEmployeeChange = (option) => {
        if (form[FIELD.EMPLOYEE]?.id && form[FIELD.EMPLOYEE]?.id == option[FIELD.EMPLOYEE]?.id) {
            return;
        }
        if (previewData && form[FIELD.EMPLOYEE]?.id) {
            createConfirmAlert({
                title: "Change Employee",
                content: "Are you sure you want to change the selected employee? This will reset the changes that you've made.",
                onConfirm: async (close) => {
                    close();
                    resetPreviewData();
                    onChange(option);
                }
            });
        } else {
            onChange(option);
        }
    };

    const handleGenerateComputation = async (newForm) => {
        const result = await preview(newForm);

        if (result?.error?.message) {
            setError({
                ...error,
                all: result?.error?.message
            });
        } else {
            onChange({});
            if (error.all) {
                setError({
                    ...error,
                    all: null
                });
            }
        }
        return result;
    };

    return (
        <>
            <BaseUpdateModal
                open={open}
                onClose={onClose}
                onBack={onBack}
                onSave={showSave ? () => updateInternals("showConfirmModal", true) : null}
                styles={{
                    content: {
                        height: "max-content",
                        maxHeight: "90vh",
                        width: "max-content",
                        minWidth: "45rem",
                        maxWidth: "50rem"
                    },
                    body: {
                        overflow: "auto"
                    },
                    inner: {
                        paddingTop: 0
                    }
                }}
                className={createClass(BASE_CLASS_CREATE_FINAL_MODAL)}
                disableSave={disableSave}
                isLoading={isUpserting}
                saveLabel="Confirm"
                transparentOverlay
                isForm
            >
                {error.all && (
                    <SectionCollapseError show={!!error.all} style={{ marginBottom: "1rem" }}>
                        {formattedJoiErrorMessage({
                            error: { message: error.all },
                            isCreate: true
                        })}
                    </SectionCollapseError>
                )}
                <div className="flex column gap-05" style={{ marginBottom: "1rem" }}>
                    <UsedSettingsInfo form={form} internalForm={internalForm} />
                </div>

                {createGroup({
                    title: "Create Final Settlement",
                    base: createClass(BASE_CLASS_CREATE_FINAL_MODAL),
                    body: (
                        <div className="flex column gap-05">
                            <EmployeeSelectLazy
                                label="Employee"
                                value={form[FIELD.EMPLOYEE]}
                                onChange={(target) =>
                                    onEmployeeChange({
                                        [FIELD.EMPLOYEE]: target
                                    })
                                }
                                isPortal
                                required
                                allowOnShift
                            />
                            <SelectConstant
                                label="Classification"
                                value={form[FIELD.CLASSIFICATION]}
                                base={SETTLEMENT_CLASSIFICATION}
                                onChange={(option) => onChange({ [FIELD.CLASSIFICATION]: option.value }, FIELD.CLASSIFICATION)}
                                menuPortalTarget={document.body}
                                wrapperStyle={{ flex: 1 }}
                                excludeKeys={[SETTLEMENT_CLASSIFICATION.ENCASHMENT]}
                                required
                                isOutlined
                                disabledOutline
                            />
                            <Input
                                label="Last Working Date"
                                type={INPUT_TYPE.DATE}
                                name={FIELD.LAST_WORKING_DATE}
                                onChange={(date) =>
                                    onChange({
                                        [FIELD.LAST_WORKING_DATE]: date
                                    })
                                }
                                selected={form[FIELD.LAST_WORKING_DATE] && new Date(form[FIELD.LAST_WORKING_DATE])}
                                noFuture
                                required
                            />
                            {showGenerate && (
                                <div className="generate-computation" style={{ margin: "auto" }}>
                                    <Button
                                        onClick={() => !disableGenerate && handleGenerateComputation(form)}
                                        tooltipProps={disableGenerate ? { message: "Select employee to enable." } : {}}
                                        disabled={disableGenerate}
                                        options={{
                                            style: { marginTop: ".5rem" }
                                        }}
                                        isLoading={isGeneratingPreview}
                                        small
                                        mini
                                    >
                                        Generate Computation
                                    </Button>
                                </div>
                            )}
                            <div className={createClass("__generated", BASE_CLASS_CREATE_FINAL_MODAL)}>
                                {previewData && JSON.stringify(previewData, null, 2)}
                            </div>
                        </div>
                    )
                })}
            </BaseUpdateModal>
            {internalForm.showConfirmModal && (
                <ConfirmSettlementModal
                    data={form}
                    previewData={previewData}
                    open={internalForm.showConfirmModal}
                    onClose={() => updateInternals("showConfirmModal", false)}
                    includeUnpaidLeaveDaysToServiceDays={includeUnpaidLeaveDaysToServiceDays}
                    onChange={onChange}
                    onFinish={() => {
                        onClose();
                        onFinish({});
                    }}
                />
            )}
        </>
    );
}

CreateFinalSettlementsModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func
};

export default CreateFinalSettlementsModal;
