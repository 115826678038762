import React, { useState } from "react";
import PropTypes from "prop-types";
import { createClass, createConfirmAlert, createGroup, sanitizeWords, toTimeWithTimeZone } from "../../../common/utilities/helper";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import { useUpsertEmployeePenalties } from "./hooks";
import { BASE_CLASS, FIELD } from "./const";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import { NOTES_MAX_LEN } from "../../../common/utilities/const";
import EmployeeSelectLazy from "../employees/EmployeeSelectLazy";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";
import Tag from "../../../common/components/extra/Tag";
import CompanyPenaltiesSelectLazy from "../companyPenalties/CompanyPenaltiesSelectLazy";

function UpdateModal({ open, onClose, onBack, onFinish, id }) {
    const isCreate = !id;

    const [form, updateForm, { upsert, hasChanges, isGettingRecord, isUpserting, config }] = useUpsertEmployeePenalties(id);
    const [error, setError] = useState({ all: null });

    const disableSave = isGettingRecord || (!isCreate && !hasChanges) || isUpserting;
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;
    const hideFields = config.hasConfirmedDate || config.isCancelled;

    const handleSave = async () => {
        const result = await upsert();
        if (!result.error) {
            if (error.all) {
                setError({ ...error, all: null });
            }
            typeof onFinish === "function" && onFinish(result);
        }
        if (result.error) {
            setError({ ...error, all: result.error.message });
        }
        return result.error;
    };

    const handleFormChange = ({ name, value } = {}) => {
        const temp = { [name]: value };
        switch (name) {
            case FIELD.START_DATE: {
                const start = toTimeWithTimeZone(value, timezone);
                const end = toTimeWithTimeZone(form[FIELD.END_DATE], timezone);
                if (end && start && start.isSameOrAfter(end)) {
                    temp[FIELD.END_DATE] = "";
                    setError({
                        ...error,
                        [FIELD.END_DATE]: "The end date has been reset. The start date cannot exceed the end date."
                    });
                }
                break;
            }
            case FIELD.END_DATE: {
                const start = toTimeWithTimeZone(form[FIELD.START_DATE], timezone);
                const end = toTimeWithTimeZone(value, timezone);
                if (start && end && end.isSame(start)) {
                    temp[FIELD.END_DATE] = ""; // just reset to show the placeholder
                }
                if (error[FIELD.END_DATE]) {
                    setError({ ...error, [FIELD.END_DATE]: null });
                }
                break;
            }
            default:
                if (error[FIELD.END_DATE]) {
                    setError({ ...error, [FIELD.END_DATE]: null });
                }
                break;
        }
        updateForm(temp);
    };

    const createTitle = () => {
        return (
            <div className="flex gap-05">
                <span className={!isCreate ? "fade" : ""}>{isCreate ? "Create" : "Update"} Penalty</span>
                {!isCreate && (
                    <>
                        <span className="fade">-</span>
                        <span>{sanitizeWords(config?.employee?.fullName)}</span>
                    </>
                )}
            </div>
        );
    };

    return (
        <BaseUpdateModal
            open={open}
            onClose={onClose}
            onBack={(!isCreate && onBack) || null}
            onSave={(e) =>
                createConfirmAlert({
                    title: !isCreate ? "Update Record" : "Create Record",
                    content: `Are you sure you want to ${isCreate ? "create" : "update"} this record? This cannot be undone.`,
                    onConfirm: async (close) => {
                        close();
                        const hasError = await handleSave(e);
                        if (!hasError) {
                            onBack();
                        }
                    }
                })
            }
            disableSave={disableSave}
            isLoading={isUpserting}
            isForm
        >
            {(!!error.all || hideFields) && (
                <div className="flex column gap-05" style={{ marginBottom: "1rem" }}>
                    <SectionCollapseError show={!!error.all}>{error.all}</SectionCollapseError>
                    <SectionCollapseInfo title="Reminder" show={hideFields}>
                        Only notes can be edited when the record is confirmed or canceled.
                    </SectionCollapseInfo>
                </div>
            )}
            {createGroup({
                base: createClass("__modal-content-update", BASE_CLASS),
                title: createTitle(),
                body: (
                    <div className="flex column gap-05">
                        {!hideFields && (
                            <>
                                {isCreate && (
                                    <EmployeeSelectLazy
                                        label="Employee"
                                        value={form[FIELD.EMPLOYEE]}
                                        onChange={(target) => handleFormChange({ name: FIELD.EMPLOYEE, value: target })}
                                        isDisabled={!isCreate}
                                        required
                                        allowOnShift
                                    />
                                )}
                                <CompanyPenaltiesSelectLazy
                                    label="Penalty"
                                    value={config[FIELD.COMPANY_PENALTY]}
                                    onChange={(target) => handleFormChange({ name: FIELD.COMPANY_PENALTY, value: target })}
                                    isSearchable={true}
                                    isDisabled={!isCreate}
                                    subtext={{
                                        hide: isCreate,
                                        message: (
                                            <span className="danger-color">
                                                Note: Changing penalty is not allowed. Either delete or cancel the penalty instead.
                                            </span>
                                        )
                                    }}
                                    useSubTextStyle
                                    required
                                    allowOnShift
                                    isOutlined
                                    disabledOutline
                                />
                                <SectionCollapseInfo title="Selected Penalty Info" show={!!config[FIELD.COMPANY_PENALTY]?.id}>
                                    <div className="flex column gap-05">
                                        <div className="flex gap-05" style={{ alignItems: "center" }}>
                                            <span className="fades small-font">Deduction From:</span>
                                            <Tag>{sanitizeWords(config[FIELD.COMPANY_PENALTY].deduction_type)}</Tag>
                                        </div>
                                        <div className="flex column gap-05">
                                            <span className="fade  small-font">Description:</span>
                                            <p className="black semi-bold" style={{ margin: 0 }}>
                                                {config[FIELD.COMPANY_PENALTY].description}
                                            </p>
                                        </div>
                                    </div>
                                </SectionCollapseInfo>
                                <Input
                                    type={INPUT_TYPE.DATE}
                                    name={FIELD.OCCURENCE_DATE}
                                    label={<span style={{ whiteSpace: "nowrap" }}>Occurence Date</span>}
                                    onChange={(date) => handleFormChange({ name: FIELD.OCCURENCE_DATE, value: date })}
                                    selected={config[FIELD.OCCURENCE_DATE] && new Date(config[FIELD.OCCURENCE_DATE])}
                                    noFuture
                                    required
                                />
                                <Input
                                    type={INPUT_TYPE.DATE}
                                    name={FIELD.EFFECTIVE_DATE}
                                    label={<span style={{ whiteSpace: "nowrap" }}>Effective On</span>}
                                    onChange={(date) => handleFormChange({ name: FIELD.EFFECTIVE_DATE, value: date })}
                                    selected={form[FIELD.EFFECTIVE_DATE] && new Date(form[FIELD.EFFECTIVE_DATE])}
                                    isLoading={isGettingRecord}
                                    noPast={isCreate}
                                    error={[error[FIELD.EFFECTIVE_DATE]]}
                                    isMonthYear
                                    required
                                    useSubTextStyle
                                />
                            </>
                        )}
                        <div className="flex column" style={{ marginTop: "1rem" }}>
                            <Input
                                name={FIELD.REASON}
                                label="Reason"
                                type={INPUT_TYPE.TEXTAREA}
                                value={form[FIELD.REASON]}
                                parentStyle={{ height: "10rem", minHeight: "5rem" }}
                                onChange={(e) => handleFormChange({ name: FIELD.REASON, value: e.target.value })}
                                maxLength={NOTES_MAX_LEN}
                                minLength={30}
                                required
                            />
                        </div>
                    </div>
                )
            })}
        </BaseUpdateModal>
    );
}

UpdateModal.propTypes = {
    photo: PropTypes.string,
    open: PropTypes.bool,
    id: PropTypes.any,
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func
};

export default UpdateModal;
