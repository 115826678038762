import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { TOAST_TYPE, createToast, toReadableFromDate, toReadableSelectOptions } from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import {
    selectCurrent,
    selectTableConfig,
    setCurrent,
    setState,
    selectLoading,
    setLoading,
    selectCompanyAnnouncementsData,
    updateCompanyAnnouncementsData,
    defaultConfig
} from "./slice";
import {
    useCreateCompanyAnnouncementsMutation,
    useDeleteCompanyAnnouncementsMutation,
    useGetCompanyAnnouncementsMutation,
    useLoadAllCompanyAnnouncementsMutation,
    usePublishCompanyAnnouncementMutation,
    useUpdateCompanyAnnouncementsMutation
} from "./api";
import Tag from "../../../common/components/extra/Tag";
import { ANNOUNCEMENT_TYPES } from "./const";
import { FIELD } from "./const";
import moment from "moment-timezone";
import { selectUserSetting } from "../../common/slice";
import usePaginateFetch from "../../../common/hooks/usePaginateFetch";

const { TITLE, DESCRIPTION, CONTENT, TYPE } = FIELD;

export const useGetCompanyAnnouncements = (id, callback) => {
    const [isMounted, setMounted] = useState(false);
    const [fetching, setFetching] = useState(!!id);
    const [getDetails] = useGetCompanyAnnouncementsMutation();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);
    const companyAnnouncementData = useAppSelector(selectCompanyAnnouncementsData);
    // this means it only have the id key inside means we have to fetch more
    const isInitial = current && Object.keys(current).length == 1;

    const createVars = (data) => {
        if (!data) return {};
        return {};
    };

    const fetch = async () => {
        if (!id) return;

        try {
            if (!isInitial && current.id === id) {
                setFetching(false);
                return Promise.resolve();
            }
            const result = await getDetails({ extraPath: id });
            if (result.error) {
                throw new Error("Failed to fetch Announcement. Please try again later");
            }
            dispatch(setCurrent(result.data.data));
            callback?.(result.data.data);
            return result.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return {};
        } finally {
            setFetching(false);
        }
    };
    const updateCurrent = (newCurrent = {}) => {
        const newObj = !current || (current && current.id !== newCurrent.id) ? newCurrent : { ...(current || {}), ...(newCurrent || {}) };
        dispatch(
            setState({
                current: newObj,
                data: companyAnnouncementData.map((d) => (d.id == newObj.id ? { ...d, ...newObj } : d))
            })
        );
    };

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            fetch();
        }
    }, [isMounted]);

    return [current, { isLoading: fetching, config: createVars(current), update: updateCurrent, fetch }];
};

export const usePaginateCompanyAnnouncements = ({ readOnly } = {}) => {
    const [load, isLoading, { onFilter, onSearch, data, onSort, onUpdate, tableConfig }] = usePaginateFetch(useLoadAllCompanyAnnouncementsMutation, {
        redux: {
            dataSelector: selectCompanyAnnouncementsData,
            tableConfigSelector: selectTableConfig,
            currentSelector: selectCurrent,
            setState
        },
        defaultConfig,
        onMountConfig: {},
        runOnMount: !readOnly
    });

    const fetch = async (config = {}) => {
        try {
            const response = await load(config);
            if (response.error) {
                throw new Error("Failed to fetch data. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    return [data, { isLoading, fetch, update: onUpdate, onSort, onFilter, tableConfig, onSearch }];
};

export const useUpsertCompanyAnnouncements = (updateId, callback) => {
    const isCreate = !updateId;

    const [old, setOld] = useState(null);
    const [form, setForm] = useState({
        [TITLE]: "",
        [TYPE]: "",
        [DESCRIPTION]: "",
        [CONTENT]: ""
    });

    const [, { isLoading }] = useGetCompanyAnnouncements(updateId, callback);

    const [create, { isLoading: createIsLoading }] = useCreateCompanyAnnouncementsMutation();
    const [update, { isLoading: updateIsLoading }] = useUpdateCompanyAnnouncementsMutation();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);

    const createVars = () => {
        const typeOpt = toReadableSelectOptions(ANNOUNCEMENT_TYPES).map((t) => ({
            ...t,
            label: <Tag className="flex">{t.label}</Tag>
        }));
        const type = typeOpt.find((type) => type.value == form[TYPE]) || "";
        return {
            typeOpt,
            type
        };
    };

    const upsert = async ({ isPublish = false } = {}) => {
        let result = null;
        try {
            const clonedform = cloneDeep(form);

            // determines if the announcement will be published
            clonedform.isPublish = isPublish;

            if (isCreate) {
                result = await create({ body: clonedform });
            } else {
                result = await update({ body: clonedform, extraPath: updateId });

                const res = result.data?.data;
                const lastPublishedDate = res.last_published_date;

                dispatch(
                    updateCompanyAnnouncementsData({
                        id: updateId,
                        data: { last_published_date: lastPublishedDate }
                    })
                );
                if (current && current.id == updateId) {
                    dispatch(
                        setCurrent({
                            ...current,
                            last_published_date: lastPublishedDate
                        })
                    );
                }
            }
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            createToast(`Announcement ${isCreate ? "created" : "updated"} succesfully.`, TOAST_TYPE.SUCCESS);
            return result.data?.data;
        } catch (error) {
            createToast(
                `Failed to ${!isCreate ? "update" : "create"} Announcement. ${error?.message || "Please try again later or contact support."} `,
                TOAST_TYPE.ERROR
            );
            return { error };
        }
    };

    const updateForm = (config = {}) => setForm({ ...form, ...config });

    useEffect(() => {
        const temp = {
            [TITLE]: current?.[TITLE] || form[TITLE],
            [TYPE]: current?.[TYPE] || form[TYPE],
            [DESCRIPTION]: current?.[DESCRIPTION] || form[DESCRIPTION],
            [CONTENT]: current?.[CONTENT] || form[CONTENT]
        };
        setForm(temp);
        setOld(temp);
    }, []);

    return [
        form,
        updateForm,
        {
            upsert,
            isGettingDetails: isLoading,
            isUpserting: createIsLoading || updateIsLoading,
            config: createVars(),
            old,
            hasChanges: !!(old && !isCreate && !isEqual(form, old))
        }
    ];
};

export const useDeleteCompanyAnnouncements = () => {
    const dispatch = useAppDispatch();

    const [deleteHoliday] = useDeleteCompanyAnnouncementsMutation();

    const isLoading = useAppSelector(selectLoading);

    const remove = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await deleteHoliday({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to delete Announcement.");
            }
            createToast("Announcement successfully deleted.", TOAST_TYPE.SUCCESS);
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [remove, isLoading];
};

export const usePublishCompanyAnnouncement = () => {
    const dispatch = useAppDispatch();

    const [publishAnnouncement] = usePublishCompanyAnnouncementMutation();

    const isLoading = useAppSelector(selectLoading);
    const current = useAppSelector(selectCurrent);

    const publish = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await publishAnnouncement({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to publish Announcement.");
            }
            const lastPublishedDate = response.data.data;

            dispatch(
                updateCompanyAnnouncementsData({
                    id,
                    data: { last_published_date: lastPublishedDate }
                })
            );
            if (current && current.id == id) {
                dispatch(
                    setCurrent({
                        ...current,
                        last_published_date: lastPublishedDate
                    })
                );
            }
            createToast("Announcement successfully published to Employees", TOAST_TYPE.SUCCESS);
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [publish, isLoading];
};

export const useGetNotifyAvailability = ({ date }) => {
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;
    const cooldown = Number(process.env.REACT_APP_MOBILE_PUSH_NOTIF_COOLDOWN || 1);

    const lastPublishedDate = moment(date).tz(timezone);
    const availabilityDate = moment(date).tz(timezone).add(cooldown, "hour");
    const today = moment().tz(timezone);
    const diff = today.diff(lastPublishedDate, "hour", true);
    const isOnCooldown = diff < cooldown;

    return { isOnCooldown, availabilityDate: toReadableFromDate(availabilityDate) };
};
