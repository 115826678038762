import cloneDeep from "lodash/cloneDeep";
import { createSlice } from "@reduxjs/toolkit";
import { PAGE_OPTIONS } from "../../../common/components/extra/table/const";
import { FILTER_TYPE, MAIN_FILTER } from "./const";
import { DATE_RANGE, FILTER_ALL_VALUE } from "../../../common/utilities/const";

const SLICE_NAME_KEY = "employees";

export const LOAD_MORE_OFFSET = 10;
export const DEFAULT_SIZE = 20;

const DATE_FILTER = {
    [DATE_RANGE.FROM]: FILTER_ALL_VALUE.value,
    [DATE_RANGE.TO]: FILTER_ALL_VALUE.value
};

export const defaultFilter = {
    [FILTER_TYPE.DEPARTMENT]: FILTER_ALL_VALUE.value,
    [FILTER_TYPE.DESIGNATION]: FILTER_ALL_VALUE.value,
    [FILTER_TYPE.ROLE]: FILTER_ALL_VALUE.value,
    [FILTER_TYPE.DEVICE]: FILTER_ALL_VALUE.value,
    [FILTER_TYPE.EXPIRY_DATE]: {
        value: [MAIN_FILTER.ALL_IDS],
        config: DATE_FILTER
    }
};

export const defaultConfig = {
    page: 1,
    pageSize: PAGE_OPTIONS[0],
    search: "",
    sortBy: "createdAt",
    order: "DESC",
    totalPage: 0,
    totalCount: 0,
    filter: defaultFilter
};

const initialState = {
    data: [],
    tableConfig: defaultConfig,
    current: null,
    loading: false,
    roles: []
};

export const employeesSlice = createSlice({
    name: SLICE_NAME_KEY,
    initialState,
    reducers: {
        setFilter: (state, action) => {
            const clone = cloneDeep(state);
            const { key, value, newObject } = action.payload || {};
            if (newObject) {
                clone.tableConfig.filter = newObject;
            } else {
                key && (clone.tableConfig.filter[key] = value);
            }
            return clone;
        },
        resetFilter: (state) => {
            const clone = cloneDeep(state);
            clone.tableConfig.filter = defaultFilter;
            return clone;
        },
        setClearCache: (state) => {
            const clone = cloneDeep(state);
            clone.data = [];
            clone.current = null;
            clone.loading = false;
            clone.tableConfig = defaultConfig;
            return clone;
        },
        setData: (state, action) => {
            const clone = cloneDeep(state);
            const data = action.payload;
            Array.isArray(data) && (clone.data = data || []);
            return clone;
        },
        updateData: (state, action) => {
            let clone = cloneDeep(state);
            const updateId = action.payload.id;
            const newdata = action.payload.data || {};
            updateId && (clone.data = clone.data.map((d) => (d.id == updateId ? { ...d, ...newdata } : d)));
            return clone;
        },
        setTableConfig: (state, action) => {
            const clone = cloneDeep(state);
            const tableConfig = action.payload;
            tableConfig && (clone.tableConfig = { ...state.tableConfig, ...tableConfig });
            return clone;
        },
        setLoading: (state, action) => {
            const clone = cloneDeep(state);
            const loading = action.payload;
            typeof loading === "boolean" && loading !== state.loading && (clone.loading = loading);
            return clone;
        },
        setCurrent: (state, action) => {
            const clone = cloneDeep(state);
            const current = action.payload;
            clone.current = current;
            return clone;
        },
        setEmployeeRoles: (state, action) => {
            const clone = cloneDeep(state);
            const roles = action.payload;
            roles && (clone.roles = roles);
            return clone;
        },
        setState: (state, action) => {
            const clone = cloneDeep(state);
            const payload = action.payload;
            "tableConfig" in action.payload && (clone.tableConfig = { ...state.tableConfig, ...payload.tableConfig });
            "data" in action.payload && (clone.data = payload.data || []);
            "loading" in action.payload && payload.loading !== state.loading && (clone.loading = payload.loading);
            "current" in action.payload && (clone.current = payload.current);
            "roles" in action.payload && (clone.roles = payload.roles);
            return clone;
        },
        reset: () => initialState
    }
});

export const selectFilter = (state) => state[SLICE_NAME_KEY].tableConfig.filter;
export const selectData = (state) => state[SLICE_NAME_KEY].data;
export const selectTableConfig = (state) => state[SLICE_NAME_KEY].tableConfig;
export const selectLoading = (state) => state[SLICE_NAME_KEY].loading;
export const selectCurrent = (state) => state[SLICE_NAME_KEY].current;
export const selectEmployeeRoles = (state) => state[SLICE_NAME_KEY].roles;
export const selectState = (state) => state[SLICE_NAME_KEY];
export const {
    setFilter,
    resetFilter,
    setClearCache,
    setData,
    updateData,
    setTableConfig,
    setLoading,
    setCurrent,
    setEmployeeRoles,
    setState,
    reset
} = employeesSlice.actions;
export default employeesSlice.reducer;
