import React from "react";
import { renderNA, sanitizeWords, toProperMoneyFormat, toTimeWithTimeZone } from "../../../common/utilities/helper";
import { selectUserSetting } from "../../common/slice";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { FIELD, SETTLEMENT_CATEGORY, SETTLEMENT_TYPE } from "./const";
import PropTypes from "prop-types";
import Tag from "../../../common/components/extra/Tag";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";
import { CommonPreview } from "./PreviewInfo";
import { DATE_FORMAT_OPTION } from "../companyForms/const";

function ConfirmSummaryInfo({ form, data }) {
    const setting = useAppSelector(selectUserSetting);

    const currency = setting.currency;
    const salarySetting = setting.salary;
    const settlement = salarySetting.settlement;
    const includeUnpaidLeaveDaysToServiceDays = settlement.includeUnpaidLeaveDays;

    const airTicketAmount = (form && form[FIELD.AIR_TICKET_AMOUNT]) || 0;
    const isLeaveType = form?.[FIELD.TYPE] && form[FIELD.TYPE] == SETTLEMENT_TYPE.LEAVE;
    const isGratuityType = form?.[FIELD.TYPE] && form[FIELD.TYPE] == SETTLEMENT_TYPE.GRATUITY;
    const isPreviousEncashments = form[FIELD.CATEGORY] == SETTLEMENT_CATEGORY.PREVIOUS;

    if (!data) {
        return <></>;
    }

    return (
        <SectionCollapseInfo title="Summary" show alwaysOpen>
            <div className="confirm-summary-info">
                <div className="flex column small-font gap-05" style={{ marginLeft: "1rem" }}>
                    {isLeaveType && (
                        <>
                            {!isPreviousEncashments && (
                                <>
                                    <span className="flex gap-03">
                                        Service Days:
                                        <span className="primary-color semi-bold">{data.leaveDetails.serviceDays} Days</span>{" "}
                                        <i>({sanitizeWords(data.leaveDetails.completedYears)} Years)</i>
                                    </span>
                                    <span className="flex gap-03">
                                        Salary Amount:
                                        <span className="primary-color semi-bold">{toProperMoneyFormat(data.salaryAmount, currency)}</span>
                                        <i>({sanitizeWords(data.base_salary)})</i>
                                    </span>
                                    <span className="flex gap-03">
                                        Entitled Days:
                                        <span className="primary-color semi-bold">{data.leaveDetails.entitledDays} Days</span>
                                    </span>
                                    {!!airTicketAmount && (
                                        <span className="flex gap-03">
                                            Air Ticket Amount:
                                            <span className="primary-color semi-bold">{toProperMoneyFormat(airTicketAmount, currency)}</span>
                                        </span>
                                    )}
                                </>
                            )}
                            <CommonPreview
                                form={form}
                                data={data}
                                includeUnpaidLeaveDaysToServiceDays={includeUnpaidLeaveDaysToServiceDays}
                                noUnpaidPreview
                            />
                            {!isPreviousEncashments && (
                                <>
                                    <span className="flex gap-03" style={{ height: "1.3rem", marginTop: "-3px" }}>
                                        Total Accumulated Leave:
                                        <span className="primary-color semi-bold">{data.leaveDetails.totalAccumulatedLeaveDays} Days</span>
                                    </span>
                                    <span className="flex gap-03 align-center">
                                        <span className="bold">Total Payable Amount:</span>
                                        {
                                            <Tag>
                                                <div className="flex gap-03 align-center base-font">
                                                    <span className="primary-color semi-bold">
                                                        {data.leaveDetails.totalPayableAmount
                                                            ? toProperMoneyFormat(
                                                                  Number(data.leaveDetails.totalPayableAmount) + Number(airTicketAmount),
                                                                  currency
                                                              )
                                                            : 0}
                                                    </span>
                                                </div>
                                            </Tag>
                                        }
                                    </span>
                                </>
                            )}
                        </>
                    )}
                    {isGratuityType && (
                        <>
                            {!isPreviousEncashments && (
                                <>
                                    <span className="flex gap-03">
                                        Service Days:
                                        <span className="primary-color semi-bold">{data.gratuityDetails.serviceDays} Days</span>{" "}
                                        <i>({sanitizeWords(data.gratuityDetails.completedYears)} Years)</i>
                                    </span>
                                    <span className="flex gap-03">
                                        Salary Amount:
                                        <span className="primary-color semi-bold">
                                            {data.salaryAmount} {currency}
                                        </span>
                                        <i>({sanitizeWords(data.base_salary)})</i>
                                    </span>
                                    <span className="flex gap-03">
                                        Entitled Days:
                                        <span className="primary-color semi-bold">{data.gratuityDetails.entitledDays} Days</span>
                                    </span>
                                    <div className="flex gap-03">
                                        <span className="fade">Re/Joining Date:</span>
                                        <span className="primary-color semi-bold">
                                            {data.gratuity.rejoining_date
                                                ? toTimeWithTimeZone(data.gratuity.rejoining_date, setting.timezone).format(
                                                      DATE_FORMAT_OPTION.FULL_DATE.format
                                                  )
                                                : renderNA("N/A")}
                                        </span>
                                    </div>
                                    <div className="flex gap-03">
                                        <span className="fade">Last Working Date:</span>
                                        <span className="primary-color semi-bold">
                                            {data.gratuity.last_working_date
                                                ? toTimeWithTimeZone(data.gratuity.last_working_date, setting.timezone).format(
                                                      DATE_FORMAT_OPTION.FULL_DATE.format
                                                  )
                                                : renderNA("N/A")}
                                        </span>
                                    </div>
                                    <div className="flex gap-03">
                                        <span className="fade">No. of Months:</span>
                                        <span className="primary-color semi-bold">{data.gratuity.no_of_months} Months</span>
                                        <i>({sanitizeWords(data.gratuity.no_of_years)} Years)</i>
                                    </div>
                                </>
                            )}
                            <CommonPreview form={form} data={data} includeUnpaidLeaveDaysToServiceDays={includeUnpaidLeaveDaysToServiceDays} />
                        </>
                    )}
                </div>
            </div>
        </SectionCollapseInfo>
    );
}

export default ConfirmSummaryInfo;

ConfirmSummaryInfo.propTypes = {
    form: PropTypes.object,
    data: PropTypes.object
};
