import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import GridView from "../../../common/components/extra/GridView";
import { createConfirmAlert } from "../../../common/utilities/helper";
import { ASSIGN_TYPE } from "../employees/const";
import { useAppDispatch } from "../../../common/hooks/reduxHooks";
import { setClearCache } from "../employees/slice";
import { setState, setSiteData } from "./slice";
import UpdateModal from "./UpdateModal";
import SiteCard from "./SiteCard";
import { useDeleteWorkSites, useLazyPageWorkSites } from "./hooks";
import TotalRecords from "../../../common/components/extra/filter/TotalRecords";
import TransferAssignEmployeesModal from "../employees/TransferAssignEmployeesModal";

function WorkSite({ openAddModal, onAddModalClose }) {
    const [object, setObject] = useState({
        openEditModal: openAddModal,
        openViewEmployees: false,
        selected: null,
        openAssignEmployeesModal: false
    });

    const [siteData, , { initializing, searching, isLoading, loadMore, reset, tableConfig }] = useLazyPageWorkSites({ readOnly: true });
    const [remove] = useDeleteWorkSites();
    const dispatch = useAppDispatch();

    const updateObject = (newObj = {}) => setObject((prev) => ({ ...prev, ...newObj }));

    useEffect(() => {
        if (openAddModal && openAddModal !== object.openEditModal) {
            updateObject({ openEditModal: openAddModal });
        }
    }, [openAddModal]);

    const handleModalEdit = (item) => {
        updateObject({ openEditModal: true, selected: item });
    };

    const handleFinish = (data, type) => {
        if (type === "update") {
            const updatedSiteData = siteData.map((site) => {
                if (site.id === data.id) {
                    return data;
                }
                return site;
            });
            dispatch(setState({ data: updatedSiteData }));
            dispatch(setClearCache([]));

            updateObject({ selected: null, openEditModal: false });
            typeof onAddModalClose === "function" && onAddModalClose();
        } else {
            reset().then(() => {
                updateObject({ selected: null, openEditModal: false });
                typeof onAddModalClose === "function" && onAddModalClose();
            });
        }
    };

    const handleDelete = async (row) => {
        return remove(row.id).then((res) => res && reset());
    };

    const handleAssignConfirm = (res) => {
        dispatch(
            setSiteData(
                siteData.map((site) => {
                    if (site.id === res.id) {
                        return res;
                    }
                    return site;
                })
            )
        );
    };

    return (
        <>
            <GridView
                items={siteData}
                renderItem={(item) => (
                    <SiteCard
                        data={item}
                        onEdit={() => handleModalEdit(item)}
                        onDelete={() =>
                            createConfirmAlert({
                                title: "Remove Work Site",
                                content: "Are you sure you want to remove this work site? This cannot be undone.",
                                onConfirm: async (close) => {
                                    close();
                                    typeof handleDelete === "function" && (await handleDelete(item));
                                }
                            })
                        }
                        onAssignEmployees={() => updateObject({ openAssignEmployeesModal: true, selected: item })}
                    />
                )}
                skeleton={!!siteData.length && <SiteCard loader />}
                onDelete={handleDelete}
                onLoadMore={loadMore}
                initializing={initializing || searching}
                isLoading={isLoading}
                styles={{ parent: { height: "calc(100vh - 18rem)" } }}
            />
            {!!tableConfig.totalCount && (
                <TotalRecords
                    value={tableConfig.totalCount}
                    style={{
                        position: "absolute",
                        marginTop: "1.3rem",
                        right: "7px",
                        bottom: "0"
                    }}
                />
            )}
            {object.openEditModal && (
                <UpdateModal
                    open={object.openEditModal}
                    onChange={(bool) => {
                        const temp = { openEditModal: bool };
                        if (!bool) {
                            temp.selected = null;
                            typeof onAddModalClose === "function" && onAddModalClose();
                        }
                        updateObject(temp);
                    }}
                    onFinish={handleFinish}
                    id={object?.selected?.id}
                />
            )}
            {object.openAssignEmployeesModal && (
                <TransferAssignEmployeesModal
                    open={object.openAssignEmployeesModal}
                    onClose={() => updateObject({ selected: null, openAssignEmployeesModal: false })}
                    data={object.selected}
                    type={ASSIGN_TYPE.SITE}
                    onConfirm={(_, result) => handleAssignConfirm(result.site)}
                    showSites
                    disableSupervisors
                    enableUncheckedOnshift
                />
            )}
        </>
    );
}

WorkSite.propTypes = {
    openAddModal: PropTypes.bool,
    onAddModalClose: PropTypes.func
};

export default WorkSite;
