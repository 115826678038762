import React from "react";
import { renderNA, sanitizeWords, toTimeWithTimeZone } from "../../../common/utilities/helper";
import TableList from "../../../common/components/extra/table/TableList";
import PropTypes from "prop-types";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import { useLazyUnpaidLeaves } from "../employeeLeaves/hooks";

const CreateTableHeaders = ({ timezone }) => {
    const headers = {
        TITLE: {
            key: "title",
            label: "Title",
            render: (row) => (
                <div className="flex column gap-05">
                    <div style={{ fontWeight: "bold" }}>{sanitizeWords(row.leave_type) || renderNA()}</div>
                    <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                        <span className="small-font fade">No. of Days:</span>
                        <span className="semi-bold">{row.total_days}</span>
                    </div>
                </div>
            )
        },
        JOINING_DATE: {
            key: "joining_date",
            label: "Re/Joining Date",
            style: { display: "flex", justifyContent: "center" },
            render: (row) => {
                const date = row.rejoined_date && toTimeWithTimeZone(row.rejoined_date, timezone);
                const startddd = date && date.format("ddd");
                const startFormat = date && date.format("MMM DD, YYYY");
                return (
                    <div className="flex semi-bold" style={{ whiteSpace: "nowrap" }}>
                        <span className="fade">{startddd}</span>&nbsp;
                        <span>{startFormat}</span>
                    </div>
                );
            }
        },
        LAST_WORKING_DATE: {
            key: "last_working_date",
            label: "Last Working Date",
            style: { display: "flex", justifyContent: "center" },
            render: (row) => {
                const date = row.last_working_date && toTimeWithTimeZone(row.last_working_date, timezone);
                const startddd = date && date.format("ddd");
                const startFormat = date && date.format("MMM DD, YYYY");
                return (
                    <div className="flex semi-bold" style={{ whiteSpace: "nowrap" }}>
                        <span className="fade">{startddd}</span>&nbsp;
                        <span>{startFormat}</span>
                    </div>
                );
            }
        }
    };
    return { data: Object.values(headers), original: headers };
};

const UnpaidLeavesLazyTable = ({ headExtra, footExtra, footStyle, employee_id, coverage_start_date, coverage_end_date, isRawResult }) => {
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const [object, , { loadMore, isLoading, initializing }] = useLazyUnpaidLeaves({
        employee_id,
        coverage_start_date,
        coverage_end_date,
        isRawResult
    });

    const { data: headers } = CreateTableHeaders({ timezone });

    return (
        <TableList
            headers={headers}
            data={object.data}
            height={35}
            minHeight="5rem"
            onLoadMore={loadMore}
            headExtra={headExtra}
            footExtra={footExtra}
            footStyle={footStyle}
            isLoading={initializing}
            isLoadingMore={isLoading}
            totalCount={object.totalCount}
            customEmptyEl="No Unpaid Leaves"
            small
        />
    );
};

export default UnpaidLeavesLazyTable;

UnpaidLeavesLazyTable.propTypes = {
    employee_id: PropTypes.number,
    coverage_start_date: PropTypes.any,
    coverage_end_date: PropTypes.any,
    isRawResult: PropTypes.bool,
    headExtra: PropTypes.any,
    footExtra: PropTypes.any,
    footStyle: PropTypes.object
};
