import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import {
    createClass,
    createConfirmAlert,
    createGroup,
    formattedJoiErrorMessage,
    toReadableFromDate,
    toTimeWithTimeZone
} from "../../../common/utilities/helper";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import { useCreateSettlement, useGetLastSettlementByType, useGetPreview, useSettlementInfo } from "./hooks";
import { BASE_CLASS_CREATE_OTHERS_MODAL, FIELD, OTHER_SUPPORTED_SETTLEMENT_TYPE, SETTLEMENT_CATEGORY, SETTLEMENT_TYPE } from "./const";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import EmployeeSelectLazy from "../employees/EmployeeSelectLazy";
import SelectConstant from "../../../common/components/extra/select/SelectConstant";
import Button from "../../../common/components/extra/Button";
import Text from "../../../common/components/extra/typography/Text";
import { DATE_FORMAT_OPTION } from "../companyForms/const";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import LastSettlementInfo from "./LastSettlementInfo";
import ConfirmSettlementInputs from "./ConfirmSettlementInputs";
import WarningSettlement from "./WarningSettlement";
import UsedSettingsInfo from "./UsedSettingsInfo";
import { getInfoBasedOnSupportedtype, newCoverageEndDateisFuture } from "./helper";
import PreviewInfo from "./PreviewInfo";
import ConfirmSettlementModal from "./ConfirmSettlementModal";
import { TEXT_SHORT_LEN } from "../../../common/utilities/const";
import AirTicketInputs from "./AirTicketInputs";

function CreateOtherSettlementsModal({ open, onClose, onBack, onFinish }) {
    const [error, setError] = useState({ all: null });
    const [internalForm, setInternalForm] = useState({
        selectedSupportedType: "",
        confirmedType: null,
        showConfirmModal: false
    });

    const [form, formOnChange, { create, isUpserting }] = useCreateSettlement();
    const [preview, isGeneratingPreview, { data: rawPreviewData, reset: resetPreviewData }] = useGetPreview();
    const [lastRecord, { fetch: fetchLastRecord, isLoading: isFetchingLastRecord, setLastRecord }] = useGetLastSettlementByType();

    const settlementInfo = useSettlementInfo(form, lastRecord, internalForm);
    const setting = useAppSelector(selectUserSetting);

    const timezone = setting.timezone;
    const salarySetting = setting.salary;
    const settlement = salarySetting.settlement;
    const includeUnpaidLeaveDaysToServiceDays = settlement.includeUnpaidLeaveDays;

    const previewData = useMemo(() => {
        if (!rawPreviewData) {
            return null;
        }
        const clone = cloneDeep(rawPreviewData);
        // remove the following fields that is suppose to only work if includeUnpaidLeaveDaysToServiceDays is false
        if (includeUnpaidLeaveDaysToServiceDays) {
            clone?.new_coverage_end_date && delete clone.new_coverage_end_date;
            clone?.unpaidLeaveDays && delete clone.unpaidLeaveDays;
            clone?.unpaidLeaveMonths && delete clone.unpaidLeaveMonths;
        }
        return clone;
    }, [rawPreviewData]);

    const airTicektDetails = previewData?.ticketDetails;

    const {
        employeeJoiningDate,
        coverageStartDateMinDate,
        coverageEndDateMinDate,
        hasConfirmedButNoLastRecord,
        coverageStartDateMaxDate,
        isNotAllowedToCreate,
        isEmployeeOnProbation,
        eligibility,
        hasNoLastRecord
    } = settlementInfo;

    const requiredFieldsNotMetForConfirm = !form[FIELD.EMPLOYEE] || !internalForm.selectedSupportedType || !!internalForm.confirmedType;
    const isNewCoverageEndDateIsFuture = newCoverageEndDateisFuture(timezone, previewData?.new_coverage_end_date);
    const isPreviousEncashments = form[FIELD.CATEGORY] == SETTLEMENT_CATEGORY.PREVIOUS;
    const disabledPreview = !form[FIELD.EMPLOYEE] || !form[FIELD.COVERAGE_END_DATE] || !internalForm.selectedSupportedType;
    const disableSave = isUpserting || isGeneratingPreview;
    const disableConfirm = isGeneratingPreview || requiredFieldsNotMetForConfirm || isNewCoverageEndDateIsFuture;
    const isLeaveType = form[FIELD.TYPE] == SETTLEMENT_TYPE.LEAVE;
    const joiningDate = settlementInfo.employeeJoiningDate;

    const allMinFieldsHasValue =
        (!isNotAllowedToCreate?.value && lastRecord && !!(form[FIELD.EMPLOYEE] && internalForm.selectedSupportedType)) || hasConfirmedButNoLastRecord;

    const showSave =
        (!isEmployeeOnProbation &&
            !isNotAllowedToCreate?.value &&
            isPreviousEncashments &&
            includeUnpaidLeaveDaysToServiceDays &&
            !!internalForm.confirmedType) ||
        (!!previewData && !isNewCoverageEndDateIsFuture);

    const onChange = (...rest) => {
        formOnChange(...rest);
        if (error.all) {
            setError({
                ...error,
                all: null
            });
        }
    };

    const updateInternals = (name, value) => {
        setInternalForm({
            ...internalForm,
            [name]: value
        });
        if (name === "selectedSupportedType") {
            onChange?.(getInfoBasedOnSupportedtype(value));
        }
    };

    const handleConfirm = async () => {
        if (internalForm.confirmedType !== internalForm.selectedSupportedType || error.all) {
            const result = await fetchLastRecord(form);

            if (result && !result?.error) {
                if (error?.all) {
                    setError({ ...error, all: null });
                }
                onChange?.({
                    [FIELD.COVERAGE_START_DATE]: result.noRecord
                        ? new Date(employeeJoiningDate)
                        : toTimeWithTimeZone(result[FIELD.COVERAGE_END_DATE], timezone).add(1, "day").format()
                });

                updateInternals("confirmedType", internalForm.selectedSupportedType);
            } else {
                setError({
                    ...error,
                    all: result.error.message
                });
            }
            return result;
        }
    };

    const handleGenerateComputation = async (newForm) => {
        const result = await preview(newForm);

        if (result?.error?.message) {
            setError({
                ...error,
                all: result?.error?.message
            });
        } else {
            const newForm = isLeaveType
                ? {
                      [FIELD.TOTAL_PAID_AMOUNT]: result.leaveDetails?.totalPayableAmount,
                      [FIELD.LEAVE_PAY_AMOUNT]: result.leaveDetails?.totalPayableAmount,
                      [FIELD.SERVICE_DURATION_DAYS]: result.leaveDetails.serviceDays,
                      leave_record_ids: (result?.leaves || []).map((lv) => lv.id)
                  }
                : {
                      [FIELD.GRATUITY_AMOUNT]: result.gratuityDetails.totalPayableAmount,
                      [FIELD.SERVICE_DURATION_DAYS]: result.gratuityDetails.serviceDays
                  };

            onChange(newForm);
            if (error.all) {
                setError({
                    ...error,
                    all: null
                });
            }
        }
        return result;
    };

    const onSettlementChange = (option) => {
        if (internalForm.selectedSupportedType && internalForm.selectedSupportedType === option.value) {
            return;
        }

        if (internalForm.selectedSupportedType && internalForm.confirmedType) {
            createConfirmAlert({
                title: "Change Settlement Type",
                content: "Are you sure you want to change the settlement type? This will reset the changes that you've made.",
                onConfirm: async (close) => {
                    close();
                    setLastRecord(null);
                    resetPreviewData();
                    setInternalForm({
                        selectedSupportedType: option.value,
                        confirmedType: null
                    });
                    onChange?.({
                        ...getInfoBasedOnSupportedtype(option.value),
                        [FIELD.COVERAGE_START_DATE]: null,
                        [FIELD.COVERAGE_END_DATE]: null
                    });
                }
            });
        } else {
            setInternalForm({
                selectedSupportedType: option.value,
                confirmedType: null
            });
            onChange?.(getInfoBasedOnSupportedtype(option.value));
        }
    };

    const onEmployeeChange = (option) => {
        if (form[FIELD.EMPLOYEE]?.id && form[FIELD.EMPLOYEE]?.id == option[FIELD.EMPLOYEE]?.id) {
            return;
        }
        if (form[FIELD.EMPLOYEE]?.id && internalForm.selectedSupportedType && internalForm.confirmedType) {
            createConfirmAlert({
                title: "Change Employee",
                content: "Are you sure you want to change the selected employee? This will reset the changes that you've made.",
                onConfirm: async (close) => {
                    close();
                    setLastRecord(null);
                    resetPreviewData();
                    updateInternals("confirmedType", null);
                    onChange(option);
                }
            });
        } else {
            updateInternals("confirmedType", null);
            onChange(option);
        }
    };

    const handleCoverageChange = (newConfig = {}, name) => {
        const value = newConfig[name];
        const oldValue = form[name];
        if (isEqual(value, oldValue)) {
            return;
        }
        if ((oldValue && internalForm.selectedSupportedType && internalForm.confirmedType && !isPreviousEncashments) || previewData) {
            createConfirmAlert({
                title: "Change Coverage Date",
                content: "Are you sure you want to change the coverage date? This will reset the changes that you've made.",
                onConfirm: async (close) => {
                    close();
                    resetPreviewData();
                    onChange(newConfig);
                }
            });
        } else {
            onChange(newConfig);
        }
    };

    const handleSave = async () => {
        const newCoverageEndDate =
            !includeUnpaidLeaveDaysToServiceDays &&
            previewData?.new_coverage_end_date &&
            toTimeWithTimeZone(previewData.new_coverage_end_date, setting.timezone).format();
        const result = await create(newCoverageEndDate ? { [FIELD.COVERAGE_END_DATE]: newCoverageEndDate } : {});
        if (!result.error) {
            if (error && error.all) {
                setError({
                    ...error,
                    all: null
                });
            }
            onFinish?.({});
        } else {
            setError({
                ...error,
                all: result.error.message
            });
        }
        return result;
    };

    const renderActiveInputs = () => {
        if (isPreviousEncashments && includeUnpaidLeaveDaysToServiceDays) {
            return (
                <ConfirmSettlementInputs
                    form={form}
                    onChange={onChange}
                    joiningDate={joiningDate}
                    includeUnpaidLeaveDaysToServiceDays={includeUnpaidLeaveDaysToServiceDays}
                />
            );
        }
        if (previewData && !isNewCoverageEndDateIsFuture) {
            return (
                <>
                    <PreviewInfo
                        form={form}
                        data={previewData}
                        style={{ marginTop: ".5rem" }}
                        includeUnpaidLeaveDaysToServiceDays={includeUnpaidLeaveDaysToServiceDays}
                    />
                    <Input
                        name={FIELD.DESCRIPTION}
                        label="Short Description"
                        type={INPUT_TYPE.TEXTAREA}
                        value={form[FIELD.DESCRIPTION] || ""}
                        parentStyle={{ height: "10rem", minHeight: "5rem", marginTop: ".5rem" }}
                        onChange={(e) =>
                            onChange({
                                [FIELD.DESCRIPTION]: e.target.value
                            })
                        }
                        maxLength={TEXT_SHORT_LEN}
                        required
                    />
                </>
            );
        }
    };

    return (
        <>
            <BaseUpdateModal
                open={open}
                onClose={onClose}
                onBack={onBack}
                onSave={
                    showSave
                        ? () =>
                              previewData
                                  ? updateInternals("showConfirmModal", true)
                                  : createConfirmAlert({
                                        title: "Create Record",
                                        content: "Are you sure you want to create this record? This cannot be undone.",
                                        onConfirm: async (close) => {
                                            close();
                                            const result = await handleSave();
                                            if (!result?.error) {
                                                onClose?.();
                                            }
                                        }
                                    })
                        : null
                }
                styles={{
                    content: {
                        height: "max-content",
                        maxHeight: "90vh",
                        width: "max-content",
                        minWidth: "45rem",
                        maxWidth: "50rem"
                    },
                    body: {
                        overflow: "auto"
                    },
                    inner: {
                        paddingTop: 0
                    }
                }}
                className={createClass(BASE_CLASS_CREATE_OTHERS_MODAL)}
                disableSave={disableSave}
                isLoading={isUpserting}
                saveLabel={previewData ? "Confirm" : "Save"}
                transparentOverlay
                isForm
            >
                {error.all && (
                    <SectionCollapseError show={!!error.all} style={{ marginBottom: "1rem" }}>
                        {formattedJoiErrorMessage({
                            error: { message: error.all },
                            isCreate: true
                        })}
                    </SectionCollapseError>
                )}

                <div className="flex column gap-05" style={{ marginBottom: "1rem" }}>
                    <WarningSettlement form={form} internalForm={internalForm} lastRecord={lastRecord} previewData={previewData} />
                    {!isNewCoverageEndDateIsFuture && !isNotAllowedToCreate?.value && <UsedSettingsInfo form={form} internalForm={internalForm} />}
                </div>

                {createGroup({
                    title: "Create Other Settlement",
                    base: createClass(BASE_CLASS_CREATE_OTHERS_MODAL),
                    body: (
                        <div className="flex column gap-05">
                            <EmployeeSelectLazy
                                label="Employee"
                                value={form[FIELD.EMPLOYEE]}
                                onChange={(target) =>
                                    !isGeneratingPreview &&
                                    onEmployeeChange({
                                        [FIELD.EMPLOYEE]: target
                                    })
                                }
                                isDisabled={isGeneratingPreview}
                                isPortal
                                required
                                allowOnShift
                            />
                            {employeeJoiningDate && (
                                <Text style={{ marginLeft: ".5rem" }} useSubTextStyle>
                                    Joining Date:&nbsp;
                                    <span className="primary-color bold">
                                        {toReadableFromDate(employeeJoiningDate, timezone, DATE_FORMAT_OPTION.FULL_DATE.format)}
                                    </span>
                                </Text>
                            )}
                            <div className="flex column gap-05">
                                <div className="flex gap-1 align-center">
                                    <SelectConstant
                                        label="Settlement Type"
                                        value={internalForm?.selectedSupportedType || ""}
                                        base={OTHER_SUPPORTED_SETTLEMENT_TYPE}
                                        onChange={(option) => !isGeneratingPreview && onSettlementChange(option)}
                                        menuPortalTarget={document.body}
                                        isDisabled={isGeneratingPreview}
                                        wrapperStyle={{ flex: 1 }}
                                        required
                                        isOutlined
                                        disabledOutline
                                    />
                                    {!lastRecord && !isEmployeeOnProbation && !isNotAllowedToCreate?.value && (
                                        <Button
                                            onClick={() => handleConfirm(form)}
                                            tooltipProps={requiredFieldsNotMetForConfirm ? { message: "Fill up the required fields to enable." } : {}}
                                            disabled={disableConfirm}
                                            isLoading={isFetchingLastRecord}
                                            small
                                            mini
                                        >
                                            <span>Confirm</span>
                                        </Button>
                                    )}
                                </div>

                                {eligibility && (
                                    <Text style={{ marginLeft: ".5rem" }} useSubTextStyle>
                                        Eligibility:&nbsp;
                                        <span className="primary-color bold">After {eligibility} Month(s)</span>&nbsp;
                                        {hasNoLastRecord && <span className="fade">of Joining Date</span>}
                                    </Text>
                                )}
                            </div>
                            {lastRecord && !lastRecord.noRecord && !isNewCoverageEndDateIsFuture && (
                                <LastSettlementInfo data={lastRecord} isNotAllowedToCreate={isNotAllowedToCreate} style={{ margin: "1rem 0" }} />
                            )}
                            {allMinFieldsHasValue && (
                                <>
                                    <Input
                                        type={INPUT_TYPE.DATE}
                                        label={<span style={{ whiteSpace: "nowrap" }}>Start Date</span>}
                                        onChange={(value) => handleCoverageChange({ [FIELD.COVERAGE_START_DATE]: value }, FIELD.COVERAGE_START_DATE)}
                                        selected={form[FIELD.COVERAGE_START_DATE] && new Date(form[FIELD.COVERAGE_START_DATE])}
                                        startDate={form[FIELD.COVERAGE_START_DATE] && new Date(form[FIELD.COVERAGE_START_DATE])}
                                        endDate={form[FIELD.COVERAGE_START_DATE] && new Date(form[FIELD.COVERAGE_END_DATE])}
                                        minDate={coverageStartDateMinDate && new Date(coverageStartDateMinDate)}
                                        maxDate={coverageStartDateMaxDate && new Date(coverageStartDateMaxDate)}
                                        subtext={{
                                            message: lastRecord?.noRecord
                                                ? "Notice: The start date will automatically be the joining date, as it is the employee's first settlement record."
                                                : "Notice: The start date will always be the day following the end date of the previous settlement record."
                                        }}
                                        disabled
                                        selectsStart
                                        required
                                        useSubTextStyle
                                        isFixed
                                    />
                                    <Input
                                        type={INPUT_TYPE.DATE}
                                        label={<span style={{ whiteSpace: "nowrap" }}>End Date</span>}
                                        onChange={(value) => handleCoverageChange({ [FIELD.COVERAGE_END_DATE]: value }, FIELD.COVERAGE_END_DATE)}
                                        selected={form[FIELD.COVERAGE_END_DATE] && new Date(form[FIELD.COVERAGE_END_DATE])}
                                        startDate={form[FIELD.COVERAGE_START_DATE] && new Date(form[FIELD.COVERAGE_START_DATE])}
                                        endDate={form[FIELD.COVERAGE_END_DATE] && new Date(form[FIELD.COVERAGE_END_DATE])}
                                        disabled={isGeneratingPreview}
                                        minDate={coverageEndDateMinDate && new Date(coverageEndDateMinDate)}
                                        required
                                        noFuture
                                        useSubTextStyle
                                        selectsEnd
                                        isFixed
                                    />
                                    {previewData && !isPreviousEncashments && isLeaveType && !isNewCoverageEndDateIsFuture && (
                                        <AirTicketInputs form={form} onChange={onChange} ticketDetails={airTicektDetails || {}} />
                                    )}
                                    {!previewData &&
                                        ((allMinFieldsHasValue && !isPreviousEncashments) ||
                                            (isPreviousEncashments && !includeUnpaidLeaveDaysToServiceDays)) && (
                                            <div className="generate-computation" style={{ margin: "auto" }}>
                                                <Button
                                                    onClick={() => !(disabledPreview || isGeneratingPreview) && handleGenerateComputation(form)}
                                                    tooltipProps={disabledPreview ? { message: "Fill up the required fields to enable." } : {}}
                                                    disabled={disabledPreview || !!error?.all}
                                                    isLoading={isGeneratingPreview}
                                                    small
                                                    mini
                                                >
                                                    Generate Computation
                                                </Button>
                                            </div>
                                        )}
                                    {renderActiveInputs()}
                                </>
                            )}
                        </div>
                    )
                })}
            </BaseUpdateModal>

            {internalForm.showConfirmModal && (
                <ConfirmSettlementModal
                    data={form}
                    previewData={previewData}
                    open={internalForm.showConfirmModal}
                    onClose={() => updateInternals("showConfirmModal", false)}
                    includeUnpaidLeaveDaysToServiceDays={includeUnpaidLeaveDaysToServiceDays}
                    onChange={onChange}
                    onFinish={() => {
                        onClose();
                        onFinish({});
                    }}
                />
            )}
        </>
    );
}

CreateOtherSettlementsModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onBack: PropTypes.func,
    onFinish: PropTypes.func
};

export default CreateOtherSettlementsModal;
