import React from "react";
import { sanitizeWords, toReadableSelectOptions } from "../../../utilities/helper";

export const createOptions = (base, cb, excludeKeys = []) => {
    if (!base) {
        return [];
    }
    let options = toReadableSelectOptions(base, (label) => (
        <div className="semi-bold">{typeof label == "string" ? sanitizeWords(label) : label}</div>
    )).filter((option) => !excludeKeys.includes(option.value));

    if (typeof cb == "function") {
        options = Object.values(base)
            .filter((key) => !excludeKeys.includes(key))
            .map((opt) => cb(opt));
    }
    return options;
};
