import React, { useState } from "react";
import PropTypes from "prop-types";
import Select from "../../../common/components/extra/select/Select";
import { sanitizeWords, toFilterOptions } from "../../../common/utilities/helper";
import { DATE_RANGE, FILTER_BOOLEAN } from "../../../common/utilities/const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import DateFilter from "../../../common/components/extra/filter/DateFilter";
import { COMPANY_HOLIDAY_CATEGORY, COMPANY_HOLIDAY_LEVEL, COMPANY_HOLIDAY_TYPE, FILTER_TYPE } from "./const";
import { defaultFilter, resetFilter, selectFilter, setFilter } from "./slice";
import useFilterManager from "../../../common/hooks/useFilterManager";
import FilterControl from "../../../common/components/extra/filter/FilterControl";

const COMMON_PROPS = {
    style: { minWidth: "10rem", height: "2rem" },
    styles: { control: { borderRadius: "25rem" } },
    menuPortalTarget: document.body,
    isClearable: true
};

function Filter({ onFilter, isLoading }) {
    const [filterConfig] = useState({
        [FILTER_TYPE.CATEGORY]: {
            options: toFilterOptions(COMPANY_HOLIDAY_CATEGORY).map((opt) => ({
                value: opt.value,
                label: (
                    <span className="bold small-font">
                        {sanitizeWords(!opt.value ? "All Category" : opt.value == COMPANY_HOLIDAY_CATEGORY.COMPANY_SPECIFIC ? "custom" : opt.label)}
                    </span>
                )
            }))
        },
        [FILTER_TYPE.LEVEL]: {
            options: toFilterOptions(COMPANY_HOLIDAY_LEVEL).map((opt) => ({
                value: opt.value,
                label: <span className="bold small-font">{sanitizeWords(!opt.value ? "All Level" : opt.label)}</span>
            }))
        },
        [FILTER_TYPE.TYPE]: {
            options: toFilterOptions(COMPANY_HOLIDAY_TYPE).map((opt) => ({
                value: opt.value,
                label: <span className="bold small-font">{sanitizeWords(!opt.value ? "All Type" : opt.label)}</span>
            }))
        },
        [FILTER_TYPE.YEARLY]: {
            options: toFilterOptions(FILTER_BOOLEAN).map((opt) => {
                if (opt.value == "0") {
                    opt.label = "Non-Annual";
                }
                if (opt.value == "1") {
                    opt.label = "Annual";
                }
                if (opt.value == "") {
                    opt.label = "Annual/Non-Annual";
                }
                return {
                    value: opt.value,
                    label: <span className="bold small-font">{opt.label}</span>
                };
            })
        }
    });

    const recordFilter = useAppSelector(selectFilter) || {};

    const [filter, { isClearDisabled, isFilterDisabled, getValueFromOpt, handleChange, handleReset, handleCustomDateChange, handleFilter }] =
        useFilterManager({ onFilter, filterConfig, defaultFilter, filter: recordFilter, isLoading, setFilter, resetFilter });

    return (
        <div className="tk-filter flex gap-05 w100" style={{ alignItems: "center" }}>
            <div className="flex gap-05 wrap" style={{ alignItems: "center" }}>
                <Select
                    {...COMMON_PROPS}
                    options={filterConfig[FILTER_TYPE.CATEGORY].options}
                    value={getValueFromOpt(FILTER_TYPE.CATEGORY, filter[FILTER_TYPE.CATEGORY])}
                    onChange={(conf) => handleChange(FILTER_TYPE.CATEGORY, conf.value)}
                    placeholder="Category"
                    isClearable={!!filter[FILTER_TYPE.CATEGORY]}
                    isDisabled={isLoading}
                />
                <Select
                    {...COMMON_PROPS}
                    options={filterConfig[FILTER_TYPE.LEVEL].options}
                    value={getValueFromOpt(FILTER_TYPE.LEVEL, filter[FILTER_TYPE.LEVEL])}
                    onChange={(conf) => handleChange(FILTER_TYPE.LEVEL, conf.value)}
                    placeholder="Level"
                    isClearable={!!filter[FILTER_TYPE.LEVEL]}
                    isDisabled={isLoading}
                />
                <Select
                    {...COMMON_PROPS}
                    options={filterConfig[FILTER_TYPE.TYPE].options}
                    value={getValueFromOpt(FILTER_TYPE.TYPE, filter[FILTER_TYPE.TYPE])}
                    onChange={(conf) => handleChange(FILTER_TYPE.TYPE, conf.value)}
                    placeholder="Type"
                    isClearable={!!filter[FILTER_TYPE.TYPE]}
                    isDisabled={isLoading}
                />
                <Select
                    {...COMMON_PROPS}
                    options={filterConfig[FILTER_TYPE.YEARLY].options}
                    value={getValueFromOpt(FILTER_TYPE.YEARLY, filter[FILTER_TYPE.YEARLY])}
                    onChange={(conf) => handleChange(FILTER_TYPE.YEARLY, conf.value)}
                    placeholder="Annual/Non-Annual"
                    isClearable={!!filter[FILTER_TYPE.YEARLY]}
                    isDisabled={isLoading}
                />
                <DateFilter
                    filterProps={{
                        ...COMMON_PROPS,
                        value: filter[FILTER_TYPE.DATE]?.type,
                        onChange: (newvalue) => handleCustomDateChange(DATE_RANGE.TYPE, newvalue, FILTER_TYPE.DATE),
                        isDisabled: isLoading
                    }}
                    dateProps={{
                        onChange: (name, value) => handleCustomDateChange(name, value, FILTER_TYPE.DATE),
                        value: filter[FILTER_TYPE.DATE],
                        styles: {
                            date: {
                                ...COMMON_PROPS.style,
                                width: COMMON_PROPS.style.minWidth,
                                borderRadius: COMMON_PROPS.styles.control.borderRadius
                            }
                        },
                        allowFutureDate: true
                    }}
                    label="Date"
                />
            </div>
            <FilterControl
                isClearDisabled={isClearDisabled}
                isFilterDisabled={isFilterDisabled}
                onReset={handleReset}
                onFilter={handleFilter}
                isLoading={isLoading}
            />
        </div>
    );
}

export default Filter;

Filter.propTypes = {
    onFilter: PropTypes.func,
    isLoading: PropTypes.bool
};
