import React from "react";
import cloneDeep from "lodash/cloneDeep";
import {
    TOAST_TYPE,
    addCommasToMoney,
    createToast,
    isPastMonth,
    sanitizeDatesWithProperTimeConversionFromObject,
    toReadableSelectOptions
} from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import {
    selectCurrent,
    selectTableConfig,
    setCurrent,
    setState,
    selectLoading,
    setLoading,
    selectEmployeeOtherIncomesData,
    defaultConfig
} from "./slice";
import {
    useCreateEmployeeOtherIncomesMutation,
    useDeleteEmployeeOtherIncomesMutation,
    useGetEmployeeOtherIncomesMutation,
    useLoadAllEmployeeOtherIncomesMutation,
    useUpdateEmployeeOtherIncomesMutation,
    useUpdateStatusEmployeeOtherIncomesMutation
} from "./api";
import Tag from "../../../common/components/extra/Tag";
import { DATE_FIELDS, OTHER_INCOME_LABEL, OTHER_INCOME_STATUS, OTHER_INCOME_TYPE } from "./const";
import { selectUserSetting } from "../../common/slice";
import { FIELD } from "./const";
import { createStatusTag } from "./helper";
import usePaginateFetch from "../../../common/hooks/usePaginateFetch";
import { STANDARD_DATE_FORMAT } from "../../../common/utilities/const";
import { useFetchRecord, useUpsertRecord } from "../../common/hooks";

const { EMPLOYEE, INCOME_TYPE, LABEL, AMOUNT, START_DATE, STATUS, CONFIRMED_DATE, NOTES } = FIELD;

export const useGetEmployeeOtherIncomes = (id, callback) => {
    const [current, { isInitial, isLoading, updateCurrent, fetch, reset, clearCurrent, refetch }] = useFetchRecord(
        {
            id,
            rtk: {
                useGetMutation: useGetEmployeeOtherIncomesMutation,
                selectData: selectEmployeeOtherIncomesData,
                selectCurrent,
                setCurrent,
                setState
            },
            dateFields: DATE_FIELDS,
            dateFormat: STANDARD_DATE_FORMAT
        },
        { runOnMount: true, onMount: callback }
    );

    const createVars = (data) => {
        if (!data) return {};

        return {
            confirmedDate: data?.[CONFIRMED_DATE] || "",
            start_date: data?.[START_DATE] || "",
            amount: addCommasToMoney(data.amount)
        };
    };

    return [
        current,
        {
            isInitial,
            isLoading,
            update: updateCurrent,
            fetch,
            reset,
            clearCurrent,
            refetch,
            config: createVars(current)
        }
    ];
};

export const usePaginateEmployeeOtherIncomes = ({ readOnly } = {}) => {
    const [load, isLoading, { onFilter, onSearch, data, tableConfig, onSort, onUpdate }] = usePaginateFetch(useLoadAllEmployeeOtherIncomesMutation, {
        redux: {
            dataSelector: selectEmployeeOtherIncomesData,
            tableConfigSelector: selectTableConfig,
            currentSelector: selectCurrent,
            setState
        },
        defaultConfig,
        onMountConfig: {},
        runOnMount: !readOnly
    });

    const fetch = async (config) => {
        try {
            const response = await load(config);
            if (response.error) {
                throw new Error("Failed to fetch data. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    return [data, { isLoading, fetch, update: onUpdate, onSearch, onFilter, tableConfig, onSort }];
};

export const useUpsertEmployeeOtherIncomes = (updateId, callback) => {
    const isCreate = !updateId;

    const [current, { isLoading: isGettingRecord }] = useGetEmployeeOtherIncomes(updateId, callback);
    const [form, updateForm, { upsert, isUpserting, isCreating, isUpdating, old, hasChanges }] = useUpsertRecord(
        {
            updateId,
            defaultForm: {
                [EMPLOYEE]: "",
                [INCOME_TYPE]: "",
                [LABEL]: "",
                [AMOUNT]: "",
                [START_DATE]: "",
                [STATUS]: "",
                [NOTES]: ""
            },
            current,
            isGettingRecord,
            rtk: {
                useCreateMutation: useCreateEmployeeOtherIncomesMutation,
                useUpdateMutation: useUpdateEmployeeOtherIncomesMutation,
                setCurrent
            },
            dateFields: DATE_FIELDS,
            dateFormat: STANDARD_DATE_FORMAT
        },
        {
            onBeforeUpsert: async (obj = {}) => {
                let clonedform = cloneDeep(obj);
                clonedform[EMPLOYEE]?.id && (clonedform[EMPLOYEE] = clonedform[EMPLOYEE].id);
                clonedform[STATUS] = clonedform[STATUS] == true ? OTHER_INCOME_STATUS.APPROVED : OTHER_INCOME_STATUS.PENDING;
                return clonedform;
            }
        }
    );

    const createVars = () => {
        const statusOpt = toReadableSelectOptions(OTHER_INCOME_STATUS).map((t) => ({ ...t, label: createStatusTag(t.value) }));
        const labelOpt = toReadableSelectOptions(OTHER_INCOME_LABEL).map((t) => ({ ...t, label: <Tag className="flex">{t.label}</Tag> }));
        const typeOpt = toReadableSelectOptions(OTHER_INCOME_TYPE).map((t) => ({ ...t, label: <Tag className="flex">{t.label}</Tag> }));

        const status = statusOpt.find((level) => level.value == form[STATUS]) || "";
        const label = labelOpt.find((category) => category.value == form[LABEL]) || "";
        const type = typeOpt.find((type) => type.value == form[INCOME_TYPE]) || "";

        return {
            statusOpt,
            labelOpt,
            typeOpt,
            [STATUS]: status?.value == OTHER_INCOME_STATUS.APPROVED,
            [LABEL]: label,
            [INCOME_TYPE]: type,
            [START_DATE]: form[START_DATE],
            employee: { ...(current?.employee || {}), id: form[EMPLOYEE] },
            isPastMonth: current && !isCreate && isPastMonth(current?.[START_DATE]),
            hasConfirmedDate: !isCreate && !!current?.[CONFIRMED_DATE]
        };
    };

    return [
        form,
        updateForm,
        {
            upsert,
            isGettingRecord,
            isUpserting,
            isCreating,
            isUpdating,
            config: createVars(),
            old,
            hasChanges
        }
    ];
};

export const useDeleteEmployeeOtherIncomes = () => {
    const dispatch = useAppDispatch();

    const [deleteRecord] = useDeleteEmployeeOtherIncomesMutation();

    const isLoading = useAppSelector(selectLoading);

    const remove = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await deleteRecord({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to delete other income.");
            }
            createToast("Other Income successfully removed.", TOAST_TYPE.SUCCESS);
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [remove, isLoading];
};

export const useUpdateStatusEmployeeOtherIncomes = () => {
    const [updateStatus, { isLoading }] = useUpdateStatusEmployeeOtherIncomesMutation();

    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const method = async (id, newStatus) => {
        try {
            const response = await updateStatus({
                extraPath: id,
                body: {
                    [FIELD.STATUS]: newStatus
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to update other income.");
            }
            createToast("Other Income successfully updated.", TOAST_TYPE.SUCCESS);
            return sanitizeDatesWithProperTimeConversionFromObject(response.data.data, timezone, DATE_FIELDS, STANDARD_DATE_FORMAT);
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return { error };
        }
    };

    return [method, isLoading];
};
