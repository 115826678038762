import React from "react";
import PropTypes from "prop-types";
import InfoIcon from "@mui/icons-material/Info";
import MyTooltip from "./Tooltip";

export const TAG_TYPE = {
    ON_SHIFT: 0x1,
    AVAILABLE: 0x2,
    APP_SUPERVISOR: 0x3,
    PENDING: 0x4,
    REJECTED: 0x5,
    VERIFIED: 0x6,
    DEPT_SUPERVISOR: 0x7,
    DEPT_MANAGER: 0x8
};

function Tag({ children, className, style, type, tooltip = {}, noTooltipIcon, matchCapsule, maxContent }) {
    let render = children;

    switch (type) {
        case TAG_TYPE.ON_SHIFT:
            className = "red";
            render = "ON SHIFT";
            break;
        case TAG_TYPE.AVAILABLE:
            className = "green";
            render = "AVAILABLE";
            break;
        case TAG_TYPE.APP_SUPERVISOR:
            className = "";
            render = "APP SUPERVISOR";
            break;
        case TAG_TYPE.DEPT_SUPERVISOR:
            className = "";
            render = children || "DEPT. SUPERVISOR";
            break;
        case TAG_TYPE.DEPT_MANAGER:
            className = "";
            render = children || "DEPT. MANAGER";
            break;
        case TAG_TYPE.PENDING:
            className = "yellow";
            render = "PENDING";
            break;
        case TAG_TYPE.REJECTED:
            className = "red";
            render = "REJECTED";
            break;
        case TAG_TYPE.VERIFIED:
            className = "green";
            render = render || "VERIFIED";
            break;
        default:
            break;
    }

    const newStyle = { ...(maxContent ? { width: "max-content", minWidth: "max-content", maxWdith: "max-content" } : {}), ...style };

    if (tooltip.message) {
        return (
            <MyTooltip
                element="div"
                className={`tk-tag flex align-center ${className}`.trim()}
                style={{ ...newStyle, ...(matchCapsule ? { height: "1.2rem" } : {}) }}
                message={tooltip.message}
            >
                <div className="flex align-center">{render}</div>{" "}
                {!noTooltipIcon && <InfoIcon style={{ width: ".8rem", height: "max-content", marginLeft: ".5rem" }} />}
            </MyTooltip>
        );
    }

    return (
        <div className={`tk-tag ${className}`.trim()} style={{ ...newStyle, ...(matchCapsule ? { height: "1.2rem" } : {}) }}>
            {render}
        </div>
    );
}

Tag.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    type: PropTypes.oneOf(Object.values(TAG_TYPE)),
    tooltip: PropTypes.shape({
        message: PropTypes.any
    }),
    noTooltipIcon: PropTypes.bool,
    matchCapsule: PropTypes.bool,
    maxContent: PropTypes.bool
};

export default Tag;
