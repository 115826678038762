import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CreateTableHeaders from "./CreateTableHeaders";
import TableView from "../../../common/components/extra/table/TableView";
import { usePaginateSettlements, useRemoveSettlement } from "./hooks";
import { TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";
import { setCurrent } from "./slice";
import { useAppDispatch } from "../../../common/hooks/reduxHooks";
import { ROUTE_DATA_TYPE_PARAM } from "./const";
import CreateModal from "./CreateModal";
import ViewModal from "./ViewModal";

function EmployeeSettlements({ openAddModal, onAddModalClose, type }) {
    const [openViewModal, setOpenViewModal] = useState(false);
    const [openCreateModal, setOpenCreateModal] = useState(openAddModal);
    const [selected, setSelected] = useState(null);

    const [data, { isLoading: isPaginating, fetch, tableConfig }] = usePaginateSettlements({
        type
    });

    const [remove] = useRemoveSettlement();

    const dispatch = useAppDispatch();

    const updateSelected = (row) => {
        setSelected(row);
        setOpenViewModal(!!row);
    };

    useEffect(() => {
        if (openAddModal && openAddModal !== openCreateModal) {
            setOpenCreateModal(openAddModal);
            setSelected(null);
            dispatch(setCurrent(null));
        }
    }, [openAddModal]);

    const handleModalEdit = () => {
        setOpenViewModal(false);
        setOpenCreateModal(true);
    };

    const handleFinish = (newData) => {
        fetch();
        setSelected(newData);
        typeof onAddModalClose === "function" && onAddModalClose();
    };

    const handleCreateFinish = () => {
        fetch();
        setOpenViewModal(true);
        setOpenCreateModal(false);
    };

    const handleAction = (row, actionType) => {
        switch (actionType) {
            case TABLE_ACTION_TYPE_COMMON.PREVIEW:
                updateSelected(row);
                break;
            case TABLE_ACTION_TYPE_COMMON.REMOVE:
                remove(row.id).then(fetch);
                break;
            default:
                break;
        }
    };

    const handleViewModalClose = () => updateSelected(null);

    const handleCreateModalClose = () => {
        typeof onAddModalClose === "function" && onAddModalClose();
        setOpenCreateModal(false);
    };

    return (
        <>
            <TableView
                data={data}
                headers={CreateTableHeaders({ onAction: handleAction })}
                refetch={fetch}
                tableConfig={tableConfig}
                isLoading={isPaginating}
            />
            {openViewModal && (
                <ViewModal
                    id={selected?.id}
                    open={openViewModal}
                    onClose={handleViewModalClose}
                    onEdit={handleModalEdit}
                    onUpdateFinish={handleFinish}
                />
            )}
            {openCreateModal && <CreateModal open={openCreateModal} onClose={handleCreateModalClose} onFinish={handleCreateFinish} />}
        </>
    );
}

EmployeeSettlements.propTypes = {
    openAddModal: PropTypes.bool,
    onAddModalClose: PropTypes.func,
    type: PropTypes.oneOf(Object.values(ROUTE_DATA_TYPE_PARAM))
};

export default EmployeeSettlements;
