import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import SiteIcon from "@mui/icons-material/FmdGood";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import { createClass, sanitizeWords, toReadableFromDate, toTimeWithTimeZone } from "../../../common/utilities/helper";
import { BASE_CLASS_CHECKIN_LOGS_MODAL } from "../employeeWorkHistories/const";
import GoogleMap from "../../../common/components/extra/map/GoogleMap";
import Accordion from "../../../common/components/extra/accordion/Accordion";
import { useGetCheckinsByHistory } from "./hooks";
import Tag from "../../../common/components/extra/Tag";
import MyTooltip from "../../../common/components/extra/Tooltip";
import { getBoundsLiteral } from "../../../common/components/extra/map/helper";
import Empty from "../../../common/components/extra/Empty";
import { siteIconProps, tagIconProps } from "./helper";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";

const DEF_SCALE = 0.7; // Start with scale 0.7
const TARGET_SCALE = 1.2; // End with scale 1.4
const STEP = 0.05; // Increase scale by 0.05 per interval
const INTERVAL = 50; // Time between each scale step

function ViewCheckinLogsModal({ id, open, onClose, onBack, transparentOverlay }) {
    const [mapConfig, setMapConfig] = useState({ map: null, maps: null });

    const [current, { isLoading, config, updateMarker }] = useGetCheckinsByHistory(id);

    const animateMarker = (idx) => {
        let scale = DEF_SCALE;

        // Set initial scale
        updateMarker(idx, { scale }, { scale });

        // Create an interval to increase the scale gradually
        const animationInterval = setInterval(() => {
            scale += STEP; // Gradually increase the scale

            if (scale >= TARGET_SCALE) {
                // When scale reaches or exceeds the target, set to 1.4 and stop animation
                updateMarker(idx, { scale: TARGET_SCALE });
                clearInterval(animationInterval);
            } else {
                // Otherwise, continue updating marker with the current scale
                updateMarker(idx, { scale });
            }
        }, INTERVAL);
    };

    const accordionOptions = useMemo(() => {
        const gotoMarker = (idx, coordinates) => {
            const bounds = getBoundsLiteral(mapConfig.maps, coordinates);
            bounds && mapConfig.map.fitBounds(bounds, 0);
            animateMarker(idx);
        };

        if (current) {
            return current.logs.map((log, idx) => {
                return {
                    title: (
                        <div className="flex gap-05 align-center w100" style={{ padding: ".5rem 0", justifyContent: "space-between" }}>
                            <Tag>
                                <span className="small-font fade">{current.logs.length - idx}</span>
                            </Tag>
                            <div className="left flex gap-05 align-center" style={{ flex: 1 }}>
                                <MyTooltip message={log.location_name} className="base-font bold text-ellipsis" style={{ maxWidth: "13rem" }}>
                                    {log.location_name}
                                </MyTooltip>
                            </div>
                            <div className="right flex align-center gap-05">
                                <MyTooltip
                                    message={`Go to ${log.location_name}`}
                                    className="hover-svg pointer"
                                    style={{ maxWidth: "13rem", ...siteIconProps(log.action) }}
                                    onClick={() => gotoMarker(idx, [{ lat: log.coordinates.latitude, lng: log.coordinates.longitude }])}
                                >
                                    <SiteIcon />
                                </MyTooltip>
                                <div className="left flex gap-05 align-center" style={{ flex: 1 }}>
                                    <MyTooltip
                                        message={toTimeWithTimeZone(log.date, config.timezone).format("MMM DD, YYYY hh:mm A")}
                                        className="base-font"
                                        style={{ maxWidth: "13rem" }}
                                    >
                                        <span className="fade small-font" style={{ textAlign: "right" }}>
                                            {toReadableFromDate(log.date, config.timezone)}
                                        </span>
                                    </MyTooltip>
                                </div>
                            </div>
                        </div>
                    ),
                    children: (
                        <div className="flex column" style={{ gap: ".2rem" }}>
                            <div className="flex gap-05 align-center">
                                <span className="fade">Type: </span>
                                <Tag className={tagIconProps(log.action)}>
                                    <span>{sanitizeWords(log.action)}</span>
                                </Tag>
                            </div>
                            <div className="flex gap-05 align-center">
                                <span className="fade">Location: </span>
                                <span className="semi-bold">{log.location_name}</span>
                            </div>
                            {log.address && (
                                <div className="flex gap-05 align-center">
                                    <span className="fade">Address: </span>
                                    <span className="semi-bold">{log.address}</span>
                                </div>
                            )}
                            <div className="flex gap-05 align-center">
                                <span className="fade">Recorded on: </span>
                                <span className="semi-bold">{toTimeWithTimeZone(log.date, config.timezone).format("MMM DD, YYYY hh:mm A")}</span>
                            </div>
                            <div className="flex gap-05 align-center">
                                <span className="fade">Log saved on: </span>
                                <span className="semi-bold">{toTimeWithTimeZone(log.createdAt, config.timezone).format("MMM DD, YYYY hh:mm A")}</span>
                            </div>
                        </div>
                    )
                };
            });
        }
        return [];
    }, [isLoading, mapConfig.map, mapConfig.maps]);

    const noData = !config.sites?.length && !config.markers?.length;

    return (
        <BaseUpdateModal
            title="View Check-In/Check-Out Logs"
            open={open}
            onClose={onClose}
            styles={{
                content: {
                    margin: "1rem",
                    height: "90vh",
                    minWidth: "25rem",
                    width: "90vw",
                    maxHeight: "unset"
                },
                body: {
                    overflow: "auto",
                    display: "flex"
                }
            }}
            transparentOverlay={transparentOverlay}
            onBack={onBack}
            small
        >
            <div className={createClass(" h100", BASE_CLASS_CHECKIN_LOGS_MODAL)}>
                <div className={createClass("__inner h100", BASE_CLASS_CHECKIN_LOGS_MODAL)}>
                    <div className={createClass("__inner-row flex gap-1 wrap h100", BASE_CLASS_CHECKIN_LOGS_MODAL)} style={{ marginTop: "1rem" }}>
                        {noData ? (
                            <Empty className="abs-center" isLoading={isLoading} message={"No Record Found"} />
                        ) : (
                            <>
                                <div className="left" style={{ flex: "50%", minWidth: "30rem", position: "relative" }}>
                                    <GoogleMap
                                        id="checkin-site-1"
                                        style={{ height: "100%", minHeight: "20rem" }}
                                        coordinates={config.sites}
                                        zoom={{ default: 12 }}
                                        markers={config.markers}
                                        markerProps={{ scale: 0.7, withLine: !config.coordinates?.length, onlyStartEnd: true }}
                                        isLoading={isLoading}
                                        onInitialize={(conf) => setMapConfig({ map: conf.map, maps: conf.maps })}
                                        allowControls
                                        noCenterMarkers
                                    />
                                </div>

                                <div className="flex column gap-1" style={{ flex: "40%", width: "40rem" }}>
                                    <SectionCollapseInfo title="Employee Details" show alwaysOpen>
                                        <div className="flex column gap-03">
                                            <div className="flex gap-05 align-center semi-bold">
                                                <span className="fade">Employee ID:</span>
                                                <span className="semi-bold primary-color">{current.employee.generatedID}</span>
                                            </div>
                                            <div className="flex gap-05 semi-bold">
                                                <span className="fade">Full Name:</span>
                                                <span className="semi-bold primary-color"> {sanitizeWords(current.employee.fullName)}</span>
                                            </div>
                                        </div>
                                    </SectionCollapseInfo>
                                    <div className="right flex column gap-05" style={{ maxHeight: "70vh", overflow: "auto", marginTop: "1rem" }}>
                                        <Accordion options={accordionOptions} defaultOpen />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </BaseUpdateModal>
    );
}

ViewCheckinLogsModal.propTypes = {
    id: PropTypes.any,
    open: PropTypes.bool,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
    transparentOverlay: PropTypes.bool
};

export default ViewCheckinLogsModal;
