import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import InfoCard from "../../../common/components/extra/card/InfoCard";
import Tabs from "../../../common/components/extra/tabs/Tabs";
import PaymentCreditCard from "./PaymentCreditCard";
import PaymentBankTransfer from "./PaymentBankTransfer";
import Button from "../../../common/components/extra/Button";
import {
    TOAST_TYPE,
    checkCreditCardHolderNameValid,
    checkCreditCardNumberValid,
    createConfirmAlert,
    createPromiseToast,
    createToast,
    toFormObject
} from "../../../common/utilities/helper";
import { PAYMENT_TABS, CREDIT_CARD_FIELDS, BANK_TRANSFER_FIELDS } from "./const";
import { useApplySubscriptionUpgrade } from "./hooks";
import { useParams } from "react-router-dom";
import { CREDIT_CARD_TYPE } from "../../../common/utilities/const";
// import { useGetBankTransferOptions } from "../companyPayment/hooks";
import Navigation from "../../../common/classes/Navigation";

const { CREDIT_CARD, BANK_TRANSFER } = PAYMENT_TABS;

function PaymentDetails({ onSubmit, onFail, subscriptionToUpgradeId }) {
    const [activeTab, setActiveTab] = useState(CREDIT_CARD.key);
    const [bankOptions, setBankOptions] = useState([]);

    const [form, setForm] = useState({
        [CREDIT_CARD.id]: toFormObject(CREDIT_CARD_FIELDS),
        [BANK_TRANSFER.id]: toFormObject(BANK_TRANSFER_FIELDS)
    });

    // const [getBankTransferOptions] = useGetBankTransferOptions();
    const [applyUpgrade, { isLoading }] = useApplySubscriptionUpgrade();

    const params = useParams();

    const isActiveCreditCard = activeTab == CREDIT_CARD.key;
    const transToken = params.token;

    useEffect(() => {
        // const getBankOptions = async () => {
        //     try {
        //         const result = await getBankTransferOptions(transToken);
        //         if (result.error) {
        //             throw new Error("Failed to get bank options.");
        //         }
        //         return result.option.map((bank) => ({
        //             ...bank,
        //             value: bank.bankCode,
        //             label: bank.bankName
        //         }));
        //     } catch (error) {
        //         return [];
        //     }
        // };
        // getBankOptions().then(setBankOptions);
    }, []);

    const updateForm = (target, newObject = {}) =>
        setForm({
            ...form,
            [target]: { ...form[target], ...newObject }
        });

    const creditCardValidation = async () => {
        if (!isActiveCreditCard) {
            return;
        }

        const payload = form[CREDIT_CARD.id];

        if (!checkCreditCardHolderNameValid(payload[CREDIT_CARD_FIELDS.CARD_HOLDER_NAME])) {
            throw new Error("Invalid Card Holder Name.");
        }
        if (payload[CREDIT_CARD_FIELDS.CARD_NUMBER]) {
            const result = checkCreditCardNumberValid(payload[CREDIT_CARD_FIELDS.CARD_NUMBER]);
            if (!result.valid) {
                throw new Error("Invalid Card Number.");
            }
            if (![CREDIT_CARD_TYPE.VISA, CREDIT_CARD_TYPE.MASTERCARD].includes(result.type)) {
                throw new Error("Only Visa or Mastercard is allowed.");
            }
        }
    };

    const handleSubmit = async () => {
        if (form?.[CREDIT_CARD.id]?.[CREDIT_CARD_FIELDS.EXPIRY_DATE]) {
            form[CREDIT_CARD.id][CREDIT_CARD_FIELDS.EXPIRY_DATE] = moment(form[CREDIT_CARD.id][CREDIT_CARD_FIELDS.EXPIRY_DATE]).format("MMYY");
        }
        const response = await applyUpgrade(subscriptionToUpgradeId, {
            type: activeTab,
            [CREDIT_CARD.key]: form[CREDIT_CARD.id],
            [BANK_TRANSFER.key]: form[BANK_TRANSFER.id],
            transToken
        });
        if (response?.error) {
            throw new Error(response.error);
        }
        if (response?.isFailedPayment) {
            typeof onFail == "function" && onFail(response);
            throw new Error("Payment failed. Please ensure your credentials are valid or try again later.");
        }
        // perform native routing here so that we dont need to reset anything
        window.location.href = Navigation.Routes.SUBSCRIPTION_PAY_CONFIRM.path.replace(":token", params.token);
        typeof onSubmit == "function" && onSubmit(response);
    };

    const toTabsWithComponents = (tabs) => {
        const newTabs = Object.values(tabs).map((tab) => {
            switch (tab.id) {
                case CREDIT_CARD.id: {
                    tab.component = (
                        <PaymentCreditCard
                            onChange={(name, value) => updateForm(CREDIT_CARD.id, { [name]: value })}
                            data={form[CREDIT_CARD.id]}
                            isLoading={isLoading}
                        />
                    );
                    break;
                }
                case BANK_TRANSFER.id: {
                    tab.component = (
                        <PaymentBankTransfer
                            onChange={(bankCode, instructions) =>
                                updateForm(BANK_TRANSFER.id, {
                                    [BANK_TRANSFER_FIELDS.BANK_CODE]: bankCode,
                                    [BANK_TRANSFER_FIELDS.INSTRUCTIONS]: instructions
                                })
                            }
                            data={form[BANK_TRANSFER.id]}
                            isLoading={isLoading}
                            token={transToken}
                            options={bankOptions}
                            onFetch={setBankOptions}
                        />
                    );
                    break;
                }
                default: {
                    break;
                }
            }
            return tab;
        });
        // return newTabs.filter((t) => (!bankOptions.length ? t?.id !== BANK_TRANSFER.id : true));
        return newTabs;
    };

    return (
        <form
            className="group"
            onSubmit={async (e) => {
                e.preventDefault();
                try {
                    await creditCardValidation();
                    createConfirmAlert({
                        title: "Confirm Payment",
                        content: "Are you sure you want to confirm and upgrade to the selected package?",
                        onConfirm: (onClose) => {
                            onClose();
                            createPromiseToast(handleSubmit, {
                                render: {
                                    error: (response) => response?.data?.message || "Payment failed",
                                    pending: () => "Processing  payment...",
                                    success: () => "Payment has been confirmed. Going to confirmation page."
                                }
                            }).catch(() => {});
                        }
                    });
                } catch (error) {
                    createToast(error.message, TOAST_TYPE.ERROR);
                }
            }}
        >
            <InfoCard className="card" header={<span className="bold">PAYMENT DETAILS</span>}>
                <Tabs
                    className="h100 w100"
                    styles={{ parent: { minWidth: "22rem" } }}
                    items={toTabsWithComponents(PAYMENT_TABS)}
                    onChange={(t) => setActiveTab(t.key)}
                    disableNavigate
                    hasBackground
                />
            </InfoCard>
            <Button options={{ type: "submit" }} className="primary" isLoading={isLoading} disabled={isLoading} small>
                Pay
            </Button>
        </form>
    );
}

export default PaymentDetails;

PaymentDetails.propTypes = {
    onSubmit: PropTypes.func,
    onFail: PropTypes.func,
    subscriptionToUpgradeId: PropTypes.any
};
