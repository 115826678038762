import React from "react";
import PropTypes from "prop-types";
import { sanitizeWords, toProperMoneyFormat, toReadableFromDate } from "../../../common/utilities/helper";
import { FIELD } from "./const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import { getSettlementStatus, getSupportedTypeByInfo } from "./helper";
import { DATE_FORMAT_OPTION } from "../companyForms/const";
import Tag from "../../../common/components/extra/Tag";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";

function LastSettlementInfo({ data, style }) {
    const setting = useAppSelector(selectUserSetting);

    const timezone = setting.timezone;
    const currency = setting.currency;

    const listItem = (label, value, subtext, option = {}) => (
        <li className="last-settlement-info__item">
            <div className="last-settlement-info__item__content">
                <span className="fade small-font">{label}:</span>
                {option.isParagraph ? <p className="fade">{value}</p> : <span className="primary-color semi-bold small-font">{value}</span>}
            </div>
            {subtext && (
                <div className="last-settlement-info__item__subtext">
                    <span className="fade small-font">{subtext.label}:</span>
                    <span className="primary-color semi-bold small-font">{subtext.value}</span>
                </div>
            )}
        </li>
    );

    const createValueWithAfterExtra = (value, afterExtra) => (
        <span className="semi-bold">
            <span className="primary-color">{value}</span>&nbsp;
            <span className="fade">{afterExtra}</span>
        </span>
    );

    return (
        <SectionCollapseInfo title="Last Settlement Record" style={style} show alwaysOpen>
            <div className="last-settlement-info w100">
                <div className="last-settlement-info__inner">
                    <div className="last-settlement-info__content flex column gap-03">
                        <ul>
                            {listItem("Type", sanitizeWords(getSupportedTypeByInfo(data)))}
                            {listItem(
                                "Status",
                                <Tag className={getSettlementStatus(data).tagColor} matchCapsule>
                                    {getSettlementStatus(data).label}
                                </Tag>
                            )}
                            {listItem(
                                "Start Date",
                                toReadableFromDate(data[FIELD.COVERAGE_START_DATE], timezone, DATE_FORMAT_OPTION.FULL_DATE.format)
                            )}
                            {listItem("End Date", toReadableFromDate(data[FIELD.COVERAGE_END_DATE], timezone, DATE_FORMAT_OPTION.FULL_DATE.format))}
                            {listItem("Total Paid Amount", createValueWithAfterExtra(toProperMoneyFormat(data[FIELD.TOTAL_PAID_AMOUNT]), currency))}
                            {listItem("Paid Date", toReadableFromDate(data[FIELD.PAID_DATE], timezone, DATE_FORMAT_OPTION.FULL_DATE.format))}
                            {listItem(
                                "Processed Date",
                                toReadableFromDate(data[FIELD.PROCESSED_DATE], timezone, DATE_FORMAT_OPTION.FULL_DATE.format)
                            )}
                            {listItem("Description", data[FIELD.DESCRIPTION], null, { isParagraph: true })}
                        </ul>
                    </div>
                </div>
            </div>
        </SectionCollapseInfo>
    );
}

export default LastSettlementInfo;

LastSettlementInfo.propTypes = {
    style: PropTypes.object,
    isNotAllowedToCreate: PropTypes.shape({
        value: PropTypes.bool,
        assumedEndDateMinDate: PropTypes.string
    }),
    data: PropTypes.shape({
        [FIELD.COVERAGE_START_DATE]: PropTypes.string,
        [FIELD.COVERAGE_END_DATE]: PropTypes.string,
        [FIELD.STATUS]: PropTypes.string,
        [FIELD.TOTAL_PAID_AMOUNT]: PropTypes.number,
        [FIELD.PAID_DATE]: PropTypes.string,
        [FIELD.PROCESSED_DATE]: PropTypes.string,
        [FIELD.DESCRIPTION]: PropTypes.string
    })
};
