import React from "react";
import PropTypes from "prop-types";
import EmployeeAvatar from "../../../common/components/extra/avatar/EmployeeAvatar";
import { EMPLOYEE_FILES, ROLE_LEVEL } from "../../../common/utilities/const";
import DeptPositionCapsule from "./DeptPositionCapsule";
import SitePositionCapsule from "./SitePositionCapsule";
import Loader from "../../../common/components/extra/Loader";
import { separateLastCharacters } from "../../../common/utilities/helper";
import { EMPLOYEE_LOCATION_TYPES, PASSWORD_KEYS_TO_USE } from "./const";
import { USER_LEVEL } from "../companyForms/const";

function Overview({ fullName, row, isLoading, department, supervisingDepts, managingDepts, supervisingSites, managingSites, designation }) {
    const hasDeptPos = !!supervisingDepts.length || !!managingDepts.length;
    const hasSitePos = !!supervisingSites.length || !!managingSites.length;

    const createAppRole = () => {
        if (row.Role.level == ROLE_LEVEL.HIGH) {
            return "APP SUPERVISOR";
        }
        if (row.Role.level == ROLE_LEVEL.LOW) {
            return "APP EMPLOYEE";
        }
    };

    return (
        <div className="tk-employees__modal-content-view__top-extra flex column gap-1">
            <EmployeeAvatar
                id={row?.id}
                filename={row?.uploads?.[EMPLOYEE_FILES.PHOTO.key]}
                fullname={fullName}
                designation={designation?.title}
                userLevel={row.user_level}
                isLoading={isLoading}
                onShift={row.isOnShift}
            />
            <div className="tk-employees__modal-content-view__top-extra__content flex column gap-1">
                {!isLoading && (hasDeptPos || hasSitePos || department?.title) && (
                    <div className="department-card flex column gap-05 center">
                        <div className="department semi-bold">{department?.title}</div>
                        {(hasDeptPos || hasSitePos) && (
                            <div className="tags flex wrap">
                                {hasDeptPos && <DeptPositionCapsule supervising={supervisingDepts} managing={managingDepts} width={8.7} />}
                                {hasSitePos && <SitePositionCapsule supervising={supervisingSites} managing={managingSites} width={8.7} />}
                            </div>
                        )}
                    </div>
                )}
                <div className="bottom flex gap-05 wrap">
                    <div className="flex column gap-03 align-center">
                        {isLoading ? <Loader style={{ width: "2rem" }} relative /> : <span className="bold small-font">{createAppRole()}</span>}
                        <span className="fade xs-font bold">App Role</span>
                    </div>
                    <div className="flex column gap-03 align-center">
                        {isLoading ? <Loader style={{ width: "2rem" }} relative /> : <span className="bold small-font">{row.location_type}</span>}
                        <span className="fade xs-font bold">Location Type</span>
                    </div>
                    <div className="flex column gap-03 align-center">
                        {isLoading ? (
                            <Loader style={{ width: "2rem" }} relative />
                        ) : (
                            <span className="bold small-font">
                                {separateLastCharacters(row.passwordKeyToUse?.toUpperCase() || "", 2, "ID") || "N/A"}
                            </span>
                        )}
                        <span className="fade xs-font bold">Password</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Overview;

Overview.propTypes = {
    fullName: PropTypes.string,
    supervisingDepts: PropTypes.array,
    managingDepts: PropTypes.array,
    supervisingSites: PropTypes.array,
    managingSites: PropTypes.array,
    isLoading: PropTypes.bool,
    row: PropTypes.shape({
        id: PropTypes.number,
        uploads: PropTypes.object,
        passwordKeyToUse: PropTypes.oneOf(Object.values(PASSWORD_KEYS_TO_USE).map((key) => key.value)),
        location_type: PropTypes.oneOf(Object.values(EMPLOYEE_LOCATION_TYPES)),
        isOnShift: PropTypes.bool,
        user_level: PropTypes.oneOf(Object.values(USER_LEVEL)),
        Role: PropTypes.shape({
            level: PropTypes.oneOf(Object.values(ROLE_LEVEL))
        })
    }),
    designation: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string
    }),
    department: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string
    })
};
