import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/components/extra/Modal";
import EmployeesTableLazy from "./EmployeesTableLazy";
import { ASSIGN_TYPE } from "./const";

const BASE_CLASS = "tk-employees__assign-modal";

const createClass = (newStr = "") => `${BASE_CLASS}${newStr}`;

function ExpiringEmployeesModal({ open, onClose }) {
    return (
        <>
            <Modal
                title={<div className="flex center">Expiring/Expired Employee Documents</div>}
                open={open}
                onClose={onClose}
                styles={{
                    content: { width: "40vw", height: "max-content", minWidth: "35rem" },
                    body: { overflow: "auto" },
                    form: { padding: "0 5rem" },
                    footer: { paddingTop: "1rem" }
                }}
                hasHeaderStyle
            >
                <div className={createClass()}>
                    <div className={createClass("__inner flex column gap-1")}>
                        <EmployeesTableLazy type={ASSIGN_TYPE.EXPIRING} minHeight="70vh" isExpiringWideUI readOnly />
                    </div>
                </div>
            </Modal>
        </>
    );
}

ExpiringEmployeesModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};

export default ExpiringEmployeesModal;
