import React, { useMemo } from "react";
import SectionCollapseWarning from "../../../common/components/extra/section/SectionCollapseWarning";
import { createFullName, sanitizeWords, toTimeWithTimeZone } from "../../../common/utilities/helper";
import PropTypes from "prop-types";
import { useSettlementInfo } from "./hooks";
import { newCoverageEndDateisFuture } from "./helper";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import { DATE_FORMAT_OPTION } from "../companyForms/const";

const WarningSettlement = ({ form, lastRecord, internalForm, previewData }) => {
    const settlementInfo = useSettlementInfo(form, lastRecord, internalForm);

    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const employee = settlementInfo.employee;
    const isEmployeeOnProbation = settlementInfo.isEmployeeOnProbation;
    const isNotAllowedToCreate = settlementInfo.isNotAllowedToCreate;
    const probationPeriod = settlementInfo.probationPeriod;
    const employeeProbationDate = settlementInfo.employeeProbationDate;

    const newCoverageEndDate =
        previewData?.new_coverage_end_date &&
        toTimeWithTimeZone(previewData?.new_coverage_end_date, timezone).format(DATE_FORMAT_OPTION.FULL_DATE.format);
    const isNewCoverageEndDateIsFuture = newCoverageEndDateisFuture(timezone, previewData?.new_coverage_end_date);

    const renderMessage = useMemo(() => {
        if (isEmployeeOnProbation) {
            return (
                <>
                    Creating a <span className="bold">{sanitizeWords(internalForm.selectedSupportedType)}</span> for&nbsp;
                    <span className="bold">{createFullName(employee.first_name, employee.last_name)}</span> is not allowed because employee is still
                    on probation period of <span className="bold">{probationPeriod} Months</span> which will end on&nbsp;
                    <span className="bold">{employeeProbationDate}</span>.
                </>
            );
        } else if (isNotAllowedToCreate?.value) {
            return (
                <div className="flex column gap-05">
                    <span>
                        The creation of a new <span className="bold">{sanitizeWords(internalForm.selectedSupportedType)}</span> for this employee is
                        restricted due to the following condition:
                    </span>
                    <ul className="flex column gap-05 indent-1">
                        {!!lastRecord && (
                            <li className="has-list-style indent-1">
                                The end date of the last recorded encashment is <span className="bold">{isNotAllowedToCreate.endDate}</span>.
                            </li>
                        )}
                        <li className="has-list-style indent-1">
                            According to the eligibility policy, the next {"encashment's"} coverage period can only begin {settlementInfo.eligibility}
                            &nbsp;months after {lastRecord ? "this date" : "joining date"}, which is{" "}
                            <span className="bold">{isNotAllowedToCreate.assumedEndDateMinDate}</span>.
                        </li>
                    </ul>
                    <span>Since the expected eligibility date has not yet arrived, a new encashment cannot be processed at this time.</span>
                </div>
            );
        } else if (isNewCoverageEndDateIsFuture) {
            return (
                <div className="flex column gap-05">
                    <span>
                        The creation of a new <span className="bold">{sanitizeWords(internalForm.selectedSupportedType)}</span> for this employee is
                        restricted due to the following condition:
                    </span>
                    <ul className="flex column gap-05 indent-1">
                        <li className="has-list-style indent-1">
                            The adjusted expected end date due to the unpaid leaves of&nbsp;
                            <span className="bold">({previewData.unpaidLeaveDays} days)</span> is&nbsp;
                            <span className="bold" style={{ textDecoration: "line-through" }}>
                                {isNotAllowedToCreate.endDate}
                            </span>
                            &nbsp;
                            {previewData.new_coverage_end_date && (
                                <>
                                    <span>&rarr;</span>&nbsp;
                                    <span className="semi-bold danger-color">{newCoverageEndDate}</span>
                                </>
                            )}
                        </li>
                    </ul>
                    <span>Since the expected eligibility date has not yet arrived, a new encashment cannot be processed at this time.</span>
                </div>
            );
        }
        return null;
    }, [isNotAllowedToCreate, employee, internalForm.selectedSupportedType, isNewCoverageEndDateIsFuture]);

    if ((!isEmployeeOnProbation && !isNotAllowedToCreate?.value && !isNewCoverageEndDateIsFuture) || !renderMessage) {
        return <></>;
    }

    return (
        <SectionCollapseWarning title={isEmployeeOnProbation ? "Warning" : "Notice: Leave Encashment Eligibility"} alwaysOpen show>
            {renderMessage}
        </SectionCollapseWarning>
    );
};

export default WarningSettlement;

WarningSettlement.propTypes = {
    form: PropTypes.object,
    lastRecord: PropTypes.object,
    internalForm: PropTypes.object,
    previewData: PropTypes.object
};
