import React, { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import isNull from "lodash/isNull";
import {
    useCreateDepartmentMutation,
    useDeleteDepartmentMutation,
    useGetDepartmentMutation,
    useLoadAllDepartmentLazyMutation,
    useUpdateDepartmentMutation
} from "./api";
import { TOAST_TYPE, assignDefaultToBase, createToast, sanitizeWords } from "../../../common/utilities/helper";
import { SORT_ORDER } from "../../../common/utilities/const";
import { DEFAULT_SIZE, FORM_FIELDS, INITIAL_FORM, LOAD_MORE_OFFSET } from "./const";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { updateUser } from "../../common/slice";
import { selectDeptCurrent, setDeptCurrent, updateDeptData, setState, selectDeptData } from "./slice";
import Tooltip from "../../../common/components/extra/Tooltip";

export const useGetDepartment = (id, useCache = true) => {
    const [isMounted, setMounted] = useState(false);
    const [fetching, setFetching] = useState(!!id);

    const [getDetails] = useGetDepartmentMutation();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectDeptCurrent) || {};
    const departmentData = useAppSelector(selectDeptData);

    const createVars = (data) => {
        if (!data) return {};
        return {};
    };

    const fetch = async ({ isForce } = {}) => {
        if (!id) {
            return current;
        }
        try {
            if (!isForce && useCache && current && current.id === id) {
                fetching && setFetching(false);
                return current;
            }
            const result = await getDetails({ extraPath: id });
            if (result.error) {
                throw new Error("Failed to fetch Department. Please try again later");
            }
            dispatch(setDeptCurrent(result.data.data));
            return result.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return { error };
        } finally {
            setFetching(false);
        }
    };

    const updateCurrent = (newCurrent = {}) => {
        const newObj = !current || (current && current.id !== newCurrent.id) ? newCurrent : { ...(current || {}), ...(newCurrent || {}) };
        dispatch(
            setState({
                current: newObj,
                data: departmentData.map((d) => (d.id == newObj.id ? { ...d, ...newObj } : d))
            })
        );
    };

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            fetch();
        }
    }, [isMounted]);

    return [current, { isLoading: fetching, config: createVars(current), update: updateCurrent, fetch }];
};

export const useLazyDepartments = ({ defaultValue, initializing, isReadableSelected, onMount = () => {}, isFilter, forEmpId, admin } = {}) => {
    const [fetching, setFetching] = useState(true);
    const [isMounted, setMounted] = useState(false);

    const [object, setObject] = useState({
        data: [],
        sort: { sortBy: "title", order: SORT_ORDER.ASC },
        totalCount: 0,
        cursor: "",
        search: ""
    });

    const hasMore = object.totalCount > object.data?.length || 0;

    const [load, { isLoading }] = useLoadAllDepartmentLazyMutation();

    const updateObject = (newObject = {}) => setObject((prev) => ({ ...prev, ...newObject }));

    const createFixedValuesFromDefault = () =>
        Array.isArray(defaultValue)
            ? defaultValue
                  .filter(() => {
                      return isReadableSelected;
                  })
                  .map((item) => item.id)
            : [];

    const createRowItem = (row) => {
        const temp = cloneDeep(row || {});

        if (!temp.id) {
            return null;
        }

        const title = sanitizeWords(temp.title);
        temp.value = temp.id;

        if (isFilter) {
            temp.label = (
                <div className="flex gap-05 align-center overflow-hidden small-font">
                    <Tooltip element="span" className="text-ellipsis bold" message={title} style={{ maxWidth: "10rem" }}>
                        {title}
                    </Tooltip>
                </div>
            );
        } else {
            temp.label = (
                <div className="flex gap-05 align-center overflow-hidden" style={{ minHeight: "1.5rem" }}>
                    <Tooltip element="span" className="text-ellipsis semi-bold" message={title} style={{ paddingRight: "4px" }}>
                        {title}
                    </Tooltip>
                </div>
            );
        }

        for (const key in temp) {
            if (Object.prototype.hasOwnProperty.call(temp, key)) {
                const value = temp[key];
                if (isNull(value)) {
                    temp[key] = "";
                }
            }
        }
        return temp;
    };

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        if (!sort) {
            sort = object.sort;
        }
        if (isReset) {
            config.cursor = "";
        }
        try {
            const response = await load({
                body: {
                    pageSize: DEFAULT_SIZE,
                    more: isReset ? DEFAULT_SIZE : LOAD_MORE_OFFSET,
                    ...object.sort,
                    ...sort,
                    ...(config || {}),
                    admin, // null means we get all departments regardles of for admin or not
                    forEmpId
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data || [];
                const newdata = [...(resdata.data || [])];
                const sameCursor = isEqual(object.cursor, resdata.cursor);
                const temp = {
                    data: isReset ? newdata : !sameCursor ? [...object.data, ...newdata] : newdata,
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount
                };
                sort && (temp.sort = sort);
                temp.data = [...(temp.data || [])].map((item) => createRowItem(item, true));
                updateObject(temp);
                return temp.data;
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            sort && updateObject({ sort, data: [], totalCount: 0 });
        }
    };

    const loadMore = () => hasMore && fetch({ cursor: object.cursor });
    const reset = () => fetch({}, true);
    const search = (value = "") => fetch({ search: value }, true);
    const sort = ({ sortBy, order }) => fetch({ sort: { sortBy, order } }, true);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        isMounted &&
            !initializing &&
            reset()
                .then(onMount)
                .catch(() => {})
                .finally(() => setFetching(false));
    }, [isMounted, initializing]);

    return [
        object,
        updateObject,
        {
            fixedValues: createFixedValuesFromDefault(),
            initializing: fetching,
            isLoading,
            hasMore,
            fetch,
            reset,
            loadMore,
            search,
            sort,
            createRowItem
        }
    ];
};

export const useRemoveDepartment = () => {
    const [remove, { isLoading }] = useDeleteDepartmentMutation();

    const onRemove = async ({ id, title } = {}) => {
        if (!id && !title) return createToast("Failed to remove Department", TOAST_TYPE.ERROR);
        const response = await remove({ extraPath: id });
        if (response.error) {
            return createToast(response.error?.data?.message, TOAST_TYPE.ERROR);
        }
        createToast(`${title || "Department"} has been removed.`, TOAST_TYPE.SUCCESS);
    };

    return [onRemove, isLoading];
};

export const useUpsertDepartments = (id, onFinish) => {
    let isCreate = !id;

    const [form, setForm] = useState({
        [FORM_FIELDS.TITLE]: INITIAL_FORM[FORM_FIELDS.TITLE],
        [FORM_FIELDS.SUPERVISOR]: INITIAL_FORM[FORM_FIELDS.SUPERVISOR],
        [FORM_FIELDS.MANAGER]: INITIAL_FORM[FORM_FIELDS.MANAGER]
    });

    const dispatch = useAppDispatch();

    const [current, { isGettingDepartment }] = useGetDepartment(id);
    const [create, { isLoading: createLoading }] = useCreateDepartmentMutation();
    const [update, { isLoading: updateLoading }] = useUpdateDepartmentMutation();

    const isLoading = createLoading || updateLoading;

    const updateForm = (config = {}) => setForm((prev) => ({ ...prev, ...config }));

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const config = { ...form };
        config[name] = value;
        updateForm(config);
    };

    const handleSave = async (newObject = {}) => {
        let result = null;
        try {
            if (isLoading) {
                return;
            }
            const clonedform = {
                ...cloneDeep(form),
                ...newObject
            };

            if (clonedform.title) {
                clonedform.title = clonedform.title.toUpperCase();
            }
            if (newObject?.id) {
                isCreate = false;
            }

            clonedform.manager_id = newObject?.manager_id || clonedform.manager.id;
            clonedform.supervisor_id = newObject?.supervisor_id || clonedform.supervisor.id;

            delete clonedform.supervisor;
            delete clonedform.manager;

            if (newObject.isAdmin) {
                clonedform.isAdmin = true;
            }

            if (isCreate) {
                delete clonedform.id;
                result = await create({ body: clonedform });
            } else {
                result = await update({
                    body: clonedform,
                    extraPath: id || newObject.id
                });
            }
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data) {
                const temp = cloneDeep(result?.data?.data || {});
                const newrecord = {
                    ...temp,
                    manager_id: temp.manager?.id || temp.manager_id,
                    supervisor_id: temp.supervisor?.id || temp.supervisor_id
                };
                if (temp) {
                    dispatch(setDeptCurrent(temp));
                    dispatch(updateDeptData({ id: temp.id, data: newrecord }));
                    dispatch(
                        updateUser({
                            hasInvalidDepartment: temp.hasInvalidDepartment,
                            hasInvalidAdminDepartment: temp.hasInvalidAdminDepartment
                        })
                    );
                    createToast(`Department ${isCreate ? "created" : "updated"} succesfully.`, TOAST_TYPE.SUCCESS);
                } else {
                    createToast(result.data.message, TOAST_TYPE.SUCCESS);
                }
                typeof onFinish === "function" && onFinish(newrecord, isCreate);
            }
            return null;
        } catch (error) {
            return createToast(
                `Failed to ${!isCreate ? "update" : "create"} Department. ${error?.message || "Please try again later or contact support."} `,
                TOAST_TYPE.ERROR
            );
        }
    };

    useEffect(() => {
        if (current?.id && current?.id == id) {
            setForm(assignDefaultToBase(form, current));
        }
    }, [current?.id]);

    return [form, isLoading, { onChange: handleChange, onSave: handleSave, isCreate, old: current, isGettingDepartment }];
};

export const useTransferDepartment = (id, toAdmin) => {
    const [transferingId, setTransferingId] = useState(null);
    const [update] = useUpdateDepartmentMutation();

    const dispatch = useAppDispatch();

    const handleTransfer = async (newId) => {
        try {
            if (transferingId) {
                return;
            }
            setTransferingId(newId);
            let result = await update({
                body: {
                    isAdmin: toAdmin
                },
                extraPath: newId || id
            });

            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data) {
                createToast(`Department transfered succesfully.`, TOAST_TYPE.SUCCESS);
            }
            setTransferingId(null);
            if (toAdmin) {
                dispatch(
                    updateUser({
                        hasInvalidAdminDepartment: false
                    })
                );
            }
            return null;
        } catch (error) {
            return createToast(`Failed to Transfer Department. ${error?.message || "Please try again later or contact support."} `, TOAST_TYPE.ERROR);
        }
    };

    return [handleTransfer, transferingId];
};
