import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import PreviewIcon from "@mui/icons-material/Visibility";
import { FIELD, SETTLEMENT_CATEGORY, SETTLEMENT_TYPE } from "./const";
import StaticLeaveTable from "./StaticLeaveTable";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";
import { selectUserSetting } from "../../common/slice";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { renderNA, sanitizeWords, toProperMoneyFormat, toTimeWithTimeZone } from "../../../common/utilities/helper";
import Tag from "../../../common/components/extra/Tag";
import UnpaidLeavesLazyTable from "./UnpaidLeavesLazyTable";
import UnpaidLeavesLazyTableModal from "./UnpaidLeavesLazyTableModal";
import { DATE_FORMAT_OPTION } from "../companyForms/const";
import Tooltip from "../../../common/components/extra/Tooltip";
import { createNewEndDateTooltipMessage } from "./helper";

export function CommonPreview({ form, data, includeUnpaidLeaveDaysToServiceDays, noUnpaidPreview }) {
    const [openUnpaidLeavesModal, setOpenUnpaidLeavesModal] = useState(false);

    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const selectedCoverageStartDate =
        form?.[FIELD.COVERAGE_START_DATE] &&
        toTimeWithTimeZone(form?.[FIELD.COVERAGE_START_DATE], timezone).format(DATE_FORMAT_OPTION.FULL_DATE.format);
    const selectedCoverageEndDate =
        form[FIELD.COVERAGE_END_DATE] && toTimeWithTimeZone(form[FIELD.COVERAGE_END_DATE], timezone).format(DATE_FORMAT_OPTION.FULL_DATE.format);
    const newCoverageEndDate =
        data.new_coverage_end_date && toTimeWithTimeZone(new Date(data.new_coverage_end_date), timezone).format(DATE_FORMAT_OPTION.FULL_DATE.format);

    return (
        <>
            {!includeUnpaidLeaveDaysToServiceDays && (
                <div className="flex column gap-03 small-font">
                    <div className="flex gap-03 align-center" style={{ height: "1.2rem", marginTop: "-3px" }}>
                        Total Unpaid Leave Days:
                        <div
                            className="flex gap-03 align-center pointer"
                            onClick={() => !!data.unpaidLeaveDays && !noUnpaidPreview && setOpenUnpaidLeavesModal(true)}
                        >
                            <span className="danger-color semi-bold">{data.unpaidLeaveDays || 0} Days</span>
                            {!!data.unpaidLeaveDays && !noUnpaidPreview && <PreviewIcon style={{ width: "1rem", height: "auto" }} />}
                        </div>
                    </div>
                    <ul className="flex column gap-03">
                        <span className="fade">Coverage Period:</span>
                        <li className="indent-1">
                            <span className="flex gap-03 align-center">
                                Start Date:
                                <span className="primary-color semi-bold">{selectedCoverageStartDate}</span>
                            </span>
                        </li>
                        <li className="indent-1">
                            <span className="flex gap-03 align-center" style={{ height: "1.3rem", marginTop: "-3px" }}>
                                End Date:
                                <span className="primary-color semi-bold" style={{ textDecoration: "line-through" }}>
                                    {selectedCoverageStartDate}
                                </span>
                                {newCoverageEndDate && (
                                    <>
                                        <span>&rarr;</span>
                                        <span className="semi-bold danger-color">{newCoverageEndDate}</span>
                                        <Tooltip message={createNewEndDateTooltipMessage(data.unpaidLeaveDays, selectedCoverageEndDate)} isIcon />
                                    </>
                                )}
                            </span>
                        </li>
                    </ul>
                </div>
            )}
            {openUnpaidLeavesModal && (
                <UnpaidLeavesLazyTableModal
                    form={form}
                    previewData={data}
                    open={openUnpaidLeavesModal}
                    onClose={() => setOpenUnpaidLeavesModal(false)}
                />
            )}
        </>
    );
}

function PreviewEncashmentInfo({ data, form, includeUnpaidLeaveDaysToServiceDays }) {
    const headExtra = useMemo(() => {
        return (
            <div className="flex column small-font gap-05" style={{ marginBottom: "1rem", marginLeft: "1rem" }}>
                <CommonPreview form={form} data={data} includeUnpaidLeaveDaysToServiceDays={includeUnpaidLeaveDaysToServiceDays} noUnpaidPreview />
            </div>
        );
    }, [data]);

    if (includeUnpaidLeaveDaysToServiceDays) {
        return <></>;
    }

    return (
        <>
            <UnpaidLeavesLazyTable
                employee_id={form[FIELD.EMPLOYEE].id}
                coverage_start_date={form[FIELD.COVERAGE_START_DATE]}
                coverage_end_date={form[FIELD.COVERAGE_END_DATE]}
                headExtra={headExtra}
                footStyle={{
                    justifyContent: "flex-end"
                }}
            />
        </>
    );
}

function LeavePreviewInfo({ data, form, includeUnpaidLeaveDaysToServiceDays }) {
    const setting = useAppSelector(selectUserSetting);
    const currency = setting.currency;
    const airTicketAmount = (form && form[FIELD.AIR_TICKET_AMOUNT]) || 0;

    const headExtra = useMemo(() => {
        return (
            <div className="flex column small-font gap-05" style={{ marginBottom: "1rem", marginLeft: "1rem" }}>
                <span className="flex gap-03">
                    Service Days:
                    <span className="primary-color semi-bold">{data.leaveDetails.serviceDays} Days</span>{" "}
                    <i>({sanitizeWords(data.leaveDetails.completedYears)} Years)</i>
                </span>
                <span className="flex gap-03">
                    Salary Amount:
                    <span className="primary-color semi-bold">
                        {data.salaryAmount} {currency}
                    </span>
                    <i>({sanitizeWords(data.base_salary)})</i>
                </span>
                <span className="flex gap-03">
                    Entitled Days:
                    <span className="primary-color semi-bold">{data.leaveDetails.entitledDays} Days</span>
                </span>
                <CommonPreview form={form} data={data} includeUnpaidLeaveDaysToServiceDays={includeUnpaidLeaveDaysToServiceDays} />
            </div>
        );
    }, [data]);

    const footExtra = useMemo(() => {
        return (
            <div className="flex column small-font gap-03" style={{ marginTop: "1rem", justifyContent: "flex-end", marginRight: "1rem" }}>
                <span className="flex gap-03">
                    Total Accumulated Leave:
                    <span className="primary-color semi-bold">{data.leaveDetails.totalAccumulatedLeaveDays} Days</span>
                </span>
                {!!airTicketAmount && (
                    <span className="flex gap-03">
                        Air Ticket Amount:
                        <span className="primary-color semi-bold">{toProperMoneyFormat(airTicketAmount, currency)}</span>
                    </span>
                )}
                <span className="flex gap-03 align-center">
                    <span className="bold">Total Payable Amount:</span>
                    {
                        <Tag style={{ height: "auto" }}>
                            <div className="flex gap-03 align-center base-font">
                                <span className="primary-color bold">
                                    {data.leaveDetails.totalPayableAmount
                                        ? toProperMoneyFormat(Number(data.leaveDetails.totalPayableAmount) + Number(airTicketAmount), currency)
                                        : 0}
                                </span>
                            </div>
                        </Tag>
                    }
                </span>
            </div>
        );
    }, [data, form[FIELD.AIR_TICKET_AMOUNT]]);

    return (
        <>
            <StaticLeaveTable
                data={data.leaves || []}
                headExtra={headExtra}
                footExtra={footExtra}
                footStyle={{
                    justifyContent: "flex-end"
                }}
            />
        </>
    );
}
function GratuityPreviewInfo({ data, form, includeUnpaidLeaveDaysToServiceDays }) {
    const setting = useAppSelector(selectUserSetting);

    const currency = setting.currency;
    const gratuity = data.gratuity;

    return (
        <div className="gratuity-preview flex column small-font gap-05">
            <span className="flex gap-03">
                Service Days:
                <span className="primary-color semi-bold">{data.gratuityDetails.serviceDays} Days</span>{" "}
                <i>({sanitizeWords(data.gratuityDetails.completedYears)} Years)</i>
            </span>
            <span className="flex gap-03">
                Salary Amount:
                <span className="primary-color semi-bold">
                    {data.salaryAmount} {currency}
                </span>
                <i>({sanitizeWords(data.base_salary)})</i>
            </span>
            <span className="flex gap-03">
                Entitled Days:
                <span className="primary-color semi-bold">{data.gratuityDetails.entitledDays} Days</span>
            </span>
            <div className="flex gap-03">
                <span className="fade">Re/Joining Date:</span>
                <span className="primary-color semi-bold">
                    {gratuity.rejoining_date
                        ? toTimeWithTimeZone(gratuity.rejoining_date, setting.timezone).format(DATE_FORMAT_OPTION.FULL_DATE.format)
                        : renderNA("N/A")}
                </span>
            </div>
            <div className="flex gap-03">
                <span className="fade">Last Working Date:</span>
                <span className="primary-color semi-bold">
                    {gratuity.last_working_date
                        ? toTimeWithTimeZone(gratuity.last_working_date, setting.timezone).format(DATE_FORMAT_OPTION.FULL_DATE.format)
                        : renderNA("N/A")}
                </span>
            </div>
            <div className="flex gap-03">
                <span className="fade">No. of Months:</span>
                <span className="primary-color semi-bold">{gratuity.no_of_months} Months</span>
                <i>({sanitizeWords(gratuity.no_of_years)} Years)</i>
            </div>
            <CommonPreview form={form} data={data} includeUnpaidLeaveDaysToServiceDays={includeUnpaidLeaveDaysToServiceDays} />
        </div>
    );
}
function PreviewInfo({ form, data, style, includeUnpaidLeaveDaysToServiceDays }) {
    const type = form?.[FIELD.TYPE];
    const category = form?.[FIELD.CATEGORY];
    const isLeaveType = type && type == SETTLEMENT_TYPE.LEAVE;
    const isGratuityType = type && type == SETTLEMENT_TYPE.GRATUITY;
    const isFinalType = type && type == SETTLEMENT_TYPE.FULL_FINAL;
    const isPrevious = category === SETTLEMENT_CATEGORY.PREVIOUS;
    const noCategory = !category;

    const renderer = () => {
        const commonProps = {
            data,
            form,
            includeUnpaidLeaveDaysToServiceDays
        };

        if (isPrevious) {
            return <PreviewEncashmentInfo {...commonProps} />;
        } else {
            if (isLeaveType) {
                return <LeavePreviewInfo {...commonProps} />;
            }
            if (isGratuityType) {
                return <GratuityPreviewInfo {...commonProps} />;
            }
        }
    };

    if (noCategory || (isPrevious && includeUnpaidLeaveDaysToServiceDays) || isFinalType) {
        return <></>;
    }

    return (
        <SectionCollapseInfo
            title={isPrevious ? "Previous Encashment Computation" : isLeaveType ? "Leave Salary Computation" : "Gratuity Computation"}
            style={style}
            show
            alwaysOpen
        >
            <div className="preview-settlement-info">
                <div className="preview-settlement-info__inner">
                    <div className="preview-settlement-info__content flex column gap-03">{renderer()}</div>
                </div>
            </div>
        </SectionCollapseInfo>
    );
}

export default PreviewInfo;

const CommonProps = {
    form: PropTypes.object,
    data: PropTypes.object,
    style: PropTypes.object,
    includeUnpaidLeaveDaysToServiceDays: PropTypes.bool
};

PreviewInfo.propTypes = CommonProps;
LeavePreviewInfo.propTypes = CommonProps;
PreviewEncashmentInfo.propTypes = CommonProps;
GratuityPreviewInfo.propTypes = CommonProps;
CommonPreview.propTypes = {
    ...CommonProps,
    noUnpaidPreview: PropTypes.bool
};
