import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import FileUploader from "../../../common/components/extra/fileUploader/FileUploader";
import { FILE_MIME_TYPES } from "../../../common/utilities/const";
import { ERROR_TYPE, FILE_STATES } from "../../../common/components/extra/fileUploader/const";
import Tag from "../../../common/components/extra/Tag";
import { TOAST_TYPE, createFullName, createToast, sanitizeWords } from "../../../common/utilities/helper";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { BASE_PATH_URI } from "../../../app/apiSlice";
import { selectUser } from "../../common/slice";
import Tooltip from "../../../common/components/extra/Tooltip";

const UPLOAD_ENDPOINT = BASE_PATH_URI + "/api/company/employee/bulk-steps/upload-csv";
const FILE_SIZE_LIMIT = 5; // 5MB

function BulkUploadExcelFile({ step, updateStep, token, onSuccess, active, onUpload, onRemoveUploads }) {
    const uploadRef = useRef(null);
    const [error, setError] = useState(null);
    const [warning, setWarning] = useState(null);
    const [trackedBatch, setTrackedBatch] = useState(null);
    const user = useAppSelector(selectUser);
    const companySubscription = user?.CompanySubscription?.Subscription;
    const employeeLimit = companySubscription?.employee_limit || 0;
    const uploadRefBtn = uploadRef?.current?.processUploadsRef?.current;

    const createDuplcateRecordError = (rows) => {
        return (
            <ul className="flex column gap-05">
                <span>Only one employee record is allowed per excel row.</span>
                {rows.map((r) => (
                    <li key={r.row}>
                        <details className="manage-duplicates__header" open>
                            <summary>
                                A conflict was detected in <span className="bold danger-color">ROW {r.row}</span>. Found{" "}
                                <span className="bold danger-color">{r.employees.length}</span> existing records in a single row, which is invalid.
                            </summary>
                            <ul style={{ paddingLeft: "2rem", gap: ".2rem", marginTop: ".5rem" }} className="flex column">
                                {r.employees.map((emp) => (
                                    <li key={emp.generatedID} className="has-list-style">
                                        <span className="text-ellipsis flex gap-05 align-center">
                                            <span className="bold">{emp.generatedID}</span>
                                            <span>-</span>
                                            <span className="flex">{createFullName(emp.first_name, emp.last_name)}</span>
                                            <Tag className="red">{sanitizeWords(emp.duplicateBy)}</Tag>
                                            <Tooltip
                                                message={`The ${sanitizeWords(emp.duplicateBy.replace("ID", "_ID"))} of the employee from the sheet located on row ${r.row} already exist.`}
                                                isIcon
                                            />
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </details>
                    </li>
                ))}
            </ul>
        );
    };

    const createIgnoreEmployeeWarningError = (rows) => {
        return (
            <ol className="flex column gap-05" style={{ paddingLeft: "1rem" }}>
                <li>
                    The current plan allows for a maximum of <strong style={{ color: "inherit" }}>{employeeLimit}</strong> employees. Upgrade to add
                    more.
                </li>
                <li>
                    <span>The following rows will not be inserted and ignored: </span>
                    <ul>
                        <li style={{ fontWeight: "600" }}>{rows.map((r) => r.rowNum).join(", ")}</li>
                    </ul>
                </li>
            </ol>
        );
    };

    useEffect(() => {
        if (!active) {
            setError(null);
        }
    }, [active]);

    useEffect(() => {
        if (active && step && step.isAttemptingNext) {
            if (trackedBatch) {
                uploadRefBtn && uploadRefBtn.click();
            }
        }
    }, [active && step && step.isAttemptingNext]);

    const uploadConfig = {
        config: { method: "POST" },
        destination: {
            url: UPLOAD_ENDPOINT,
            headers: { authorization: `Bearer ${token}` }
        }
    };

    const handleUploadAdd = (batch) => {
        setError(null);
        setWarning(null);
        updateStep({ error: false, warning: false, cached: false, canNext: true });
        setTrackedBatch(batch);
        typeof onUpload === "function" && onUpload(batch);
    };

    const handleUploadComplete = async (err, batch) => {
        setError(null);
        updateStep({ error: false });
        const item = (Array.isArray(batch.items) && batch.items.length > 0 && batch.items[0]) || {};
        const response = item?.uploadResponse?.data || {};
        let message = response.message;
        const hasCustomError = !!message.error;
        const errorResponse = response.status == 0;
        const cancelled = FILE_STATES.CANCELLED === item.state;

        // apply common error for failed
        if ((err && errorResponse) || cancelled || hasCustomError) {
            const isError = item.state === FILE_STATES.ERROR || cancelled || hasCustomError;
            if (hasCustomError) {
                message = createDuplcateRecordError(message.error.data);
            }
            if (isError) {
                setError({
                    type: ERROR_TYPE.CUSTOM,
                    code: "custom",
                    message: (
                        <ul>
                            <li
                                key={item.id}
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "auto 1fr",
                                    columnGap: ".5rem"
                                }}
                            >
                                <span style={{ fontWeight: 600 }}>File:</span>
                                <span>{item.file.name}</span>
                                <span style={{ fontWeight: 600 }}>Error:</span>
                                <span>{message || (cancelled && "Upload failed, file has been interrupted.") || "Upload failed."} </span>
                            </li>
                        </ul>
                    )
                });
                updateStep({ error: true });
            }
            return;
        }
        if (!!message.ignoredEmployees.length && !warning) {
            setWarning(createIgnoreEmployeeWarningError(message.ignoredEmployees));
            createToast(
                "Warning: The maximum limit of employees has been reached for the current plan. Some rows will be ignored.",
                TOAST_TYPE.WARNING
            );
        }
        typeof onSuccess === "function" &&
            onSuccess({
                employees: message.employees || [],
                ignoredEmployees: message.ignoredEmployees || [],
                hasWarning: !!message.ignoredEmployees.length
            });
        // reset track batched since it is completed
        setTrackedBatch(null);
    };

    const handleError = (error) => {
        setError(error);
        updateStep({ error: true });
    };

    const handleRemove = () => {
        updateStep({ canNext: false });
        typeof onRemoveUploads === "function" && onRemoveUploads();
    };

    return (
        <div className="tk-bulk-upload__excel" style={{ display: active ? "flex" : "none" }}>
            <FileUploader
                ref={uploadRef}
                accepts={[...Object.values(FILE_MIME_TYPES.EXCEL).flat()]}
                maxFileSize={FILE_SIZE_LIMIT}
                onUploadComplete={handleUploadComplete}
                onAdd={handleUploadAdd}
                error={error}
                onError={handleError}
                warning={warning}
                onRemove={handleRemove}
                instruction="Please make sure that you follow the required format for the excel file before uploading."
                {...uploadConfig}
            />
        </div>
    );
}

BulkUploadExcelFile.propTypes = {
    step: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        stepNumber: PropTypes.number,
        name: PropTypes.string,
        error: PropTypes.bool,
        isDone: PropTypes.bool,
        isAttemptingNext: PropTypes.bool,
        canNext: PropTypes.bool,
        loading: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
    }),
    token: PropTypes.string,
    onSuccess: PropTypes.func,
    updateStep: PropTypes.func,
    active: PropTypes.bool,
    destroyOnInactive: PropTypes.bool,
    currentData: PropTypes.any,
    onUpload: PropTypes.func,
    onRemoveUploads: PropTypes.func
};

export default BulkUploadExcelFile;
