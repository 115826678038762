export const BASE_CLASS = "tk-employee-penalties";

export const FIELD = {
    EMPLOYEE: "employee_id",
    COMPANY_PENALTY: "company_penalty_id",
    DEDUCTION_TYPE: "deduction_type",
    OCCURENCE_DATE: "occurence_date",
    EFFECTIVE_DATE: "effective_date",
    REASON: "reason",
    STATUS: "status",
    CONFIRMED_DATE: "confirmed_date"
};

export const FILTER_TYPE = {
    EMPLOYEE: 0x1,
    COMPANY_PENALTY: 0x2,
    TYPE: 0x3,
    STATUS: 0x4,
    OCCURENCE_DATE: 0x5,
    EFFECTIVE_DATE: 0x6,
    CONFIRMED_DATE: 0x7
};

export const PENALTY_STATUS = {
    ACTIVE: "ACTIVE",
    DEDUCTED: "DEDUCTED",
    CANCELED: "CANCELED"
};

export const PENALTY_DEDUCTION_TYPE = {
    FULL_SALARY: "FULL_SALARY",
    BASIC_SALARY: "BASIC_SALARY"
};

export const CUSTOM_ACTION = {
    PREVIEW: 0x2,
    REMOVE: 0x3,
    CANCEL: 0x4
};

export const PENALTY_LEVEL = {
    WARNING: "WARNING",
    DAY_DEDUCTION: "DAY_DEDUCTION",
    FIX_VALUE: "FIXED_VALUE",
    TERMINATION: "TERMINATION",
    CANCEL_DAY_RECORD: "CANCEL_DAY_RECORD"
};

export const DATE_FIELDS = [FIELD.OCCURENCE_DATE, FIELD.EFFECTIVE_DATE];
