import React, { useMemo } from "react";
import PaidIcon from "@mui/icons-material/Paid";
import Button from "../../../common/components/extra/Button";
import { createConfirmAlert, toTimeWithTimeZone } from "../../../common/utilities/helper";
import { useUpdateStatusEmployeeOtherIncomes } from "./hooks";
import PropTypes from "prop-types";
import { FIELD, OTHER_INCOME_STATUS } from "./const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";

function MarkAsPaid({ id, onFinish, current }) {
    const [update, isLoading] = useUpdateStatusEmployeeOtherIncomes();

    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;
    const isAlreadyPaid = current && current[FIELD.STATUS] == OTHER_INCOME_STATUS.PAID;

    const isDateIsSameOrBeforeToday = useMemo(() => {
        if (!current[FIELD.START_DATE]) {
            return false;
        }
        const today = toTimeWithTimeZone(new Date(), timezone).format();
        const startDate = toTimeWithTimeZone(current[FIELD.START_DATE], timezone);
        return startDate.isSameOrBefore(today);
    }, [current?.[FIELD.START_DATE]]);

    if (!current || !isDateIsSameOrBeforeToday) {
        return <></>;
    }

    return (
        <>
            <Button
                className="success"
                afterExtra={<PaidIcon style={{ width: "1.2rem" }} />}
                onClick={() =>
                    createConfirmAlert({
                        title: "Mark As Paid",
                        content:
                            "Are you sure you want to mark this record as paid? Once the status is set to paid, you will no longer be able to update this record.",
                        onConfirm: async (close) => {
                            close();
                            const result = await update(id, OTHER_INCOME_STATUS.PAID);
                            if (!result.error) {
                                onFinish(result);
                            }
                        }
                    })
                }
                isLoading={isLoading}
                disabled={isAlreadyPaid}
                small
                mini
            >
                <span>{isAlreadyPaid ? "Paid" : "Mark As Paid"}</span>
            </Button>
        </>
    );
}

export default MarkAsPaid;

MarkAsPaid.propTypes = {
    id: PropTypes.number,
    onFinish: PropTypes.func,
    current: PropTypes.object
};
