import React, { useMemo } from "react";
import PaidIcon from "@mui/icons-material/Paid";
import Button from "../../../common/components/extra/Button";
import { createConfirmAlert, toTimeWithTimeZone } from "../../../common/utilities/helper";
import { useUpdateStatusEmployeePenalties } from "./hooks";
import PropTypes from "prop-types";
import { FIELD, PENALTY_STATUS } from "./const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";

function MarkAsDeducted({ id, onFinish, current }) {
    const [update, isLoading] = useUpdateStatusEmployeePenalties();

    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;
    const isAlreadyDeducted = current && current[FIELD.STATUS] == PENALTY_STATUS.DEDUCTED;
    const isActive = current && current[FIELD.STATUS] == PENALTY_STATUS.ACTIVE;

    const isDateIsSameOrBeforeToday = useMemo(() => {
        if (!current[FIELD.EFFECTIVE_DATE]) {
            return false;
        }
        const today = toTimeWithTimeZone(new Date(), timezone).format();
        const effectiveDate = toTimeWithTimeZone(current[FIELD.EFFECTIVE_DATE], timezone);
        return effectiveDate.isSameOrBefore(today);
    }, [current?.[FIELD.EFFECTIVE_DATE]]);

    if (!current || !isDateIsSameOrBeforeToday || !isActive) {
        return <></>;
    }

    return (
        <>
            <Button
                className="success"
                afterExtra={<PaidIcon style={{ width: "1.2rem" }} />}
                onClick={() =>
                    createConfirmAlert({
                        title: "Mark As Deducted",
                        content:
                            "Are you sure you want to mark this record as deducted? Once the status is set to deducted, you will no longer be able to update this record.",
                        onConfirm: async (close) => {
                            close();
                            const result = await update(id, PENALTY_STATUS.DEDUCTED);
                            if (!result.error) {
                                onFinish(result);
                            }
                        }
                    })
                }
                isLoading={isLoading}
                small
                mini
            >
                <span>{isAlreadyDeducted ? "Deducted" : "Mark As Deducted"}</span>
            </Button>
        </>
    );
}

export default MarkAsDeducted;

MarkAsDeducted.propTypes = {
    id: PropTypes.number,
    onFinish: PropTypes.func,
    current: PropTypes.object
};
