import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Visibility";
import CancelIcon from "@mui/icons-material/DoDisturb";
import {
    createConfirmAlert,
    renderNA,
    sanitizeTimestamp,
    sanitizeWords,
    toOrdinal,
    toProperTimezoneConversion,
    toReadableFromDate
} from "../../../common/utilities/helper";
import Tag from "../../../common/components/extra/Tag";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import { createStatusTag } from "./helper";
import LetteredAvatar from "../../../common/components/extra/avatar/LetteredAvatar";
import { CUSTOM_ACTION, PENALTY_STATUS } from "./const";

const HEADERS = {
    EMPLOYEE: "employee",
    EFFECTIVE_DATE: "effective_date",
    OCCURENCE_DATE: "occurence_date",
    CONFIRMED_DATE: "confirmed_date",
    UPDATED_AT: "updatedAt",
    CREATED_AT: "createdAt",
    ACTIONS: "actions"
};

const HEADERS_TO_ARRAY = Object.values(HEADERS);

const CreateTableHeaders = ({ onAction }) => {
    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    const timezone = setting.timezone;

    return HEADERS_TO_ARRAY.map((head) => {
        const temp = { key: head, style: {} };
        switch (head) {
            case HEADERS.EMPLOYEE: {
                temp.sortKey = "index1";
                temp.label = "Employee";
                temp.style.width = "20rem";
                temp.style.margin = ".5rem 0";
                temp.fixed = true;
                temp.render = (_, row) => {
                    const fullName = row.employee.first_name + " " + row.employee.last_name;
                    const photo = row.employee.photo;
                    return (
                        <>
                            <div className="flex gap-1" style={{ alignItems: "center" }}>
                                <LetteredAvatar name={fullName} src={photo} size={30} small />
                                <div className="flex column gap-05 overflow-hidden">
                                    <div className="link-hover" style={{ fontWeight: "bold" }} onClick={() => onAction(row, CUSTOM_ACTION.PREVIEW)}>
                                        {sanitizeWords(fullName)}
                                    </div>
                                    <div className="flex gap-05 wrap">
                                        <span className="fade small-font flex" style={{ alignItems: "center" }}>
                                            Status:
                                        </span>
                                        <div className="flex gap-05">{createStatusTag(row.status)}</div>
                                    </div>
                                    <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                        <span className="small-font fade">Type:</span>
                                        <div className="flex gap-05 wrap">
                                            <Tag>{sanitizeWords(row.deduction_type)}</Tag>
                                        </div>
                                    </div>
                                    <div className="flex gap-05 wrap small-font">
                                        <span className="fade flex" style={{ alignItems: "center" }}>
                                            Penalty:
                                        </span>
                                        <div className="flex gap-05 bold small-font">{sanitizeWords(row.title)}</div>
                                    </div>
                                    {row.status != PENALTY_STATUS.CANCELED && (
                                        <div className="flex gap-05 wrap small-font">
                                            <span className="fade flex" style={{ alignItems: "center" }}>
                                                Occurence:
                                            </span>
                                            <div className="flex gap-05 bold">{toOrdinal(row?.penalty_measure?.occurence)} Offense</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    );
                };
                break;
            }
            case HEADERS.EFFECTIVE_DATE: {
                temp.sortKey = "index3";
                temp.label = "Effective Date";
                temp.render = (value) => {
                    return (
                        <div className="flex column gap-05 overflow-hidden">
                            <span className="text-ellipsis flex gap-05">
                                <span className="flex">
                                    <span className="semi-bold">{toProperTimezoneConversion(value, timezone).format("MMM YYYY") || renderNA()}</span>
                                </span>
                            </span>
                        </div>
                    );
                };
                break;
            }
            case HEADERS.OCCURENCE_DATE: {
                temp.sortKey = "index2";
                temp.label = "Occurence Date";
                temp.render = (value) => {
                    const startTime = value && toProperTimezoneConversion(value, timezone);
                    const startddd = startTime && startTime.format("ddd");
                    const startFormat = startTime && startTime.format("MMM DD, YYYY");
                    return (
                        <div className="flex column gap-05">
                            <span className="flex gap-05">
                                <span className="flex" style={{ whiteSpace: "nowrap" }}>
                                    <span className="fade semi-bold">{startddd}</span>&nbsp;
                                    <span className="semi-bold">{startFormat}</span>
                                </span>
                            </span>
                        </div>
                    );
                };
                break;
            }
            case HEADERS.CONFIRMED_DATE: {
                temp.sortKey = "index4";
                temp.label = "Confirmed Date";
                temp.render = (value) => {
                    return (
                        <div className="flex column gap-05 overflow-hidden">
                            <span className="text-ellipsis flex gap-05">
                                {value ? (
                                    <span className="text-ellipsis small-font">{toReadableFromDate(value, timezone) || renderNA()}</span>
                                ) : (
                                    <span className="small-font">{renderNA("Not Yet Confirmed")}</span>
                                )}
                            </span>
                        </div>
                    );
                };
                break;
            }
            case HEADERS.UPDATED_AT: {
                temp.sortKey = "updatedAt";
                temp.label = "Updated";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">
                            {sanitizeTimestamp(row.updatedAt, row.createdAt, setting.timezone) || renderNA()}
                        </span>
                    </div>
                );
                break;
            }
            case HEADERS.CREATED_AT: {
                temp.sortKey = "createdAt";
                temp.label = "Created";
                temp.render = (val) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">{toReadableFromDate(val, setting.timezone)}</span>
                    </div>
                );
                break;
            }
            case HEADERS.ACTIONS: {
                temp.childStyle = {
                    display: "flex",
                    gap: ".5rem",
                    justifyContent: "end",
                    paddingRight: "1rem",
                    alignItems: "center"
                };
                temp.render = (_, row) => (
                    <>
                        <PreviewIcon onClick={() => onAction(row, CUSTOM_ACTION.PREVIEW)} />
                        {!row.confirmed_date && (
                            <>
                                {row.status !== PENALTY_STATUS.CANCELED && (
                                    <CancelIcon
                                        style={{ color: "red" }}
                                        className="hover-svg"
                                        onClick={() => typeof onAction === "function" && onAction(row, CUSTOM_ACTION.CANCEL)}
                                    />
                                )}
                                <DeleteIcon
                                    style={{ color: "red" }}
                                    className="hover-svg"
                                    onClick={async () => {
                                        createConfirmAlert({
                                            title: "Remove Penalty",
                                            content: "Are you sure you want to remove this record? This cannot be undone.",
                                            onConfirm: (close) => {
                                                close();
                                                typeof onAction === "function" && onAction(row, CUSTOM_ACTION.REMOVE);
                                            }
                                        });
                                    }}
                                />
                            </>
                        )}
                    </>
                );
                break;
            }
            default:
                break;
        }
        return temp;
    });
};

export default CreateTableHeaders;
