import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { useEffect } from "react";
import { useAppSelector } from "../../hooks/reduxHooks";
import { selectSidebarCollapse, selectUser } from "../../../features/common/slice";
import { getCountryName } from "../../utilities/helper";

function RealTimeClock({ isTwelveHour, withInfo = true }) {
    const user = useAppSelector(selectUser);
    const isSidebarCollapse = useAppSelector(selectSidebarCollapse);
    const timezone = user?.Setting?.timezone;
    const format = isTwelveHour ? "hh:mm:ss A" : "HH:mm:ss A";
    const currentTime = useRef(moment.tz(timezone).format(format));
    const [, forceUpdate] = useState(0); // Dummy state for re-render

    useEffect(() => {
        if (timezone) {
            const ref = setInterval(() => {
                currentTime.current = moment.tz(timezone).format(format);
                forceUpdate((prev) => prev + 1); // Trigger re-render
            }, 1000);
            return () => clearInterval(ref);
        }
    }, [timezone, format]);

    return (
        <div className="flex column gap-03 center">
            <div className="tk-time-clock bold fade">{currentTime.current}</div>
            {withInfo && user.country && timezone && (
                <div className="flex center wrap gap-03" style={{ textAlign: "center" }}>
                    <span className="fade small-font semi-bold align-center">
                        {isSidebarCollapse ? user.country.iso2Alpha : getCountryName(user.country.iso2Alpha)}
                    </span>
                    <span className="fade small-font semi-bold align-center">({timezone})</span>
                </div>
            )}
        </div>
    );
}

RealTimeClock.propTypes = {
    isTwelveHour: PropTypes.bool,
    withInfo: PropTypes.bool
};

export default RealTimeClock;
