import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import {
    DEFAULT_SIZE,
    LOAD_MORE_OFFSET,
    reset,
    selectDate,
    selectEmployeeSalaryData,
    selectError,
    selectLoading,
    selectSearching,
    selectTableConfig,
    setClearCache,
    setDate,
    setEmployeeSalaryData,
    setError,
    setLoading,
    setTableConfig
} from "./slice";
import { CLIENT_ERROR_CODE, COMPANY_SALARY_FILETYPE, COMPANY_SALARY_STATUS, DOWNLOAD_TYPE } from "./const";
import {
    useConfirmEmployeeSalaryMutation,
    useDownloadFileEmployeeSalaryMutation,
    useGenerateEmployeeSalaryMutation,
    useLoadLazyEmployeeSalaryMutation,
    useLoadPreviewEmployeeSalaryMutation
} from "./api";
import { TOAST_TYPE, createToast, toTimeWithTimeZone } from "../../../common/utilities/helper";
import { selectUser, selectUserSetting } from "../../common/slice";
import { STANDARD_DATE_FORMAT } from "../../../common/utilities/const";

export const useInitialLoadPreviewSalary = () => {
    const [fetchInitial, { isLoading }] = useLoadPreviewEmployeeSalaryMutation();

    const dispatch = useAppDispatch();
    const tableConfig = useAppSelector(selectTableConfig);
    const data = useAppSelector(selectEmployeeSalaryData);
    const error = useAppSelector(selectError);
    const settings = useAppSelector(selectUserSetting);
    const salarySettings = settings.salary;
    const roundOtInMinutes = salarySettings.roundOtInMinutes;

    const updateConfig = (newObject = {}) => dispatch(setTableConfig(newObject));
    const setErrorConfig = (conf) => dispatch(setError(conf ? { code: conf.code, message: conf.message } : null));
    const setData = (arr = []) => dispatch(setEmployeeSalaryData(arr));
    const clear = () => dispatch(setClearCache());

    const hasMore = tableConfig.totalCount > data?.length || 0;
    const isNotYetGenerated = error && error.code == CLIENT_ERROR_CODE.SALARY_REPORT_NOT_FOUND.code;
    const isNotAllowed = error && error.code == CLIENT_ERROR_CODE.SALARY_GENERATE_NOT_ALLOWED.code;
    const isNoRecordsAvailable = error && error.code == CLIENT_ERROR_CODE.SALARY_NO_RECORDS_AVAILABLE;
    const isPending = tableConfig?.dbSalary?.status == COMPANY_SALARY_STATUS.PENDING;
    const isConfirmed = tableConfig?.dbSalary?.status == COMPANY_SALARY_STATUS.CONFIRMED;

    const fetch = async (monthOf) => {
        if (isLoading || !monthOf) {
            return Promise.resolve();
        }
        try {
            const response = await fetchInitial({
                body: {
                    otRoundOff: roundOtInMinutes,
                    monthOf: toTimeWithTimeZone(monthOf, settings.timezone).format(STANDARD_DATE_FORMAT),
                    defaultSize: DEFAULT_SIZE,
                    timezone: settings.timezone
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data || [];
                const error = resdata.error;
                const newdata = resdata.data;
                if (!error) {
                    const res = {
                        cursor: resdata.cursor,
                        totalCount: resdata.totalCount,
                        dbSalary: resdata.dbSalary,
                        salaryBasePeriod: resdata.salaryBasePeriod
                    };
                    setData(newdata);
                    updateConfig(res);
                    setErrorConfig(null);
                    return res;
                } else {
                    setErrorConfig(error);
                    clear();
                }
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
        }
    };

    return [data, { isLoading, hasMore, fetch, updateConfig, isNotYetGenerated, isNotAllowed, isPending, isConfirmed, isNoRecordsAvailable }];
};

export const useGenerateSalary = () => {
    const [generate, { isLoading }] = useGenerateEmployeeSalaryMutation();

    const dispatch = useAppDispatch();

    const date = useAppSelector(selectDate);
    const updateConfig = (newObject = {}) => dispatch(setTableConfig(newObject));
    const setData = (arr = []) => dispatch(setEmployeeSalaryData(arr));
    const error = useAppSelector(selectError);
    const settings = useAppSelector(selectUserSetting);
    const salarySettings = settings.salary;
    const roundOtInMinutes = salarySettings.roundOtInMinutes;

    const generateSalary = async ({ isStrictChecking } = {}) => {
        if (isLoading) {
            return Promise.resolve();
        }
        if (!date) {
            throw new Error("Date is required.");
        }
        try {
            const response = await generate({
                body: {
                    monthOf: toTimeWithTimeZone(date, settings.timezone).format(STANDARD_DATE_FORMAT),
                    defaultSize: DEFAULT_SIZE,
                    timezone: settings.timezone,
                    isStrictTiming: isStrictChecking,
                    otRoundOff: roundOtInMinutes
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data;
                const newdata = resdata.data;
                const res = {
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount,
                    dbSalary: resdata.dbSalary,
                    salaryBasePeriod: resdata.salaryBasePeriod
                };
                setData(newdata);
                updateConfig(res);
                if (error) {
                    dispatch(setError(null));
                }
                return res;
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
        }
    };

    return [generateSalary, isLoading];
};

export const useConfirmSalary = () => {
    const [confirm, { isLoading }] = useConfirmEmployeeSalaryMutation();

    const dispatch = useAppDispatch();
    const tableConfig = useAppSelector(selectTableConfig);
    const date = useAppSelector(selectDate);
    const dbSalary = tableConfig.dbSalary;
    const salaryBasePeriod = tableConfig.salaryBasePeriod;
    const settings = useAppSelector(selectUserSetting);
    const salarySettings = settings.salary;
    const roundOtInMinutes = salarySettings.roundOtInMinutes;

    const confirmSalary = async () => {
        if (isLoading) {
            return Promise.resolve();
        }
        if (!date) {
            throw new Error("Date is required.");
        }
        if (!dbSalary || !salaryBasePeriod) {
            throw new Error("Some salary required fields are missing.");
        }
        try {
            const response = await confirm({
                body: {
                    monthOf: toTimeWithTimeZone(date, settings.timezone).format(STANDARD_DATE_FORMAT),
                    dbSalary,
                    salaryBasePeriod,
                    timezone: settings.timezone,
                    otRoundOff: roundOtInMinutes
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            const newconfig = {
                dbSalary: {
                    ...dbSalary,
                    status: COMPANY_SALARY_STATUS.CONFIRMED
                }
            };
            dispatch(setTableConfig(newconfig));
            createToast("Salary successfully confirmed.", TOAST_TYPE.SUCCESS);
            return { ...tableConfig, ...newconfig };
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
        }
    };

    return [confirmSalary, isLoading];
};

export const useLazySalary = () => {
    const [load, { isLoading }] = useLoadLazyEmployeeSalaryMutation();

    const dispatch = useAppDispatch();
    const tableConfig = useAppSelector(selectTableConfig);
    const data = useAppSelector(selectEmployeeSalaryData);
    const date = useAppSelector(selectDate);
    const loading = useAppSelector(selectLoading);
    const settings = useAppSelector(selectUserSetting);

    const updateConfig = (newObject = {}) => dispatch(setTableConfig(newObject));
    const setIsLoading = (bool) => dispatch(setLoading(bool));
    const setData = (arr = []) => dispatch(setEmployeeSalaryData(arr));

    const hasMore = tableConfig.totalCount > data?.length || 0;
    const filePath = tableConfig?.dbSalary?.salary_filename;

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        if (loading) {
            return Promise.resolve();
        }
        if (!loading) {
            setIsLoading(true);
        }
        if (!sort) {
            sort = {
                sortBy: tableConfig.sortBy,
                order: tableConfig.order
            };
        }
        if (isReset) {
            config.cursor = null;
            config.defaultSize = DEFAULT_SIZE;
        }
        try {
            const response = await load({
                body: {
                    more: LOAD_MORE_OFFSET,
                    monthOf: toTimeWithTimeZone(date, settings.timezone).format(STANDARD_DATE_FORMAT),
                    filePath,
                    timezone: settings.timezone,
                    ...sort,
                    ...(config || {})
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                let temp = {};
                const resdata = response.data.data || [];
                const newdata = resdata.data;
                temp = {
                    data: isReset ? newdata : data.concat(newdata),
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount,
                    ...sort
                };
                setData(temp.data);
                updateConfig({
                    cursor: temp.cursor,
                    totalCount: temp.totalCount,
                    sortBy: temp.sortBy,
                    order: temp.order
                });

                return temp.data;
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    const loadMore = async () => hasMore && (await fetch({ cursor: tableConfig.cursor }));
    const search = (value = "") => fetch({ search: value });
    const sort = ({ sortBy, order }) => fetch({ sort: { sortBy, order } }, true);
    const filter = (obj = {}) => fetch(obj);

    return [data, { isLoadingMore: isLoading, hasMore, fetch, filter, loadMore, search, sort, updateConfig }];
};

export const useDownloadSalary = () => {
    const user = useAppSelector(selectUser);
    const setting = useAppSelector(selectUserSetting);

    const createWpsObject = (newObj = {}) => {
        const today = moment().tz(setting.timezone);
        return {
            employerEID: user.establishment_id,
            fileCreationDate: today,
            fileCreationTime: today,
            payerEID: "", // from user
            salaryPeriod: "",
            totalSalaries: "",
            totalRecords: "",
            payerQID: "", // from user
            payerBankShortName: "", // from user
            payerIban: "", // from user
            ...(newObj || {})
        };
    };

    const [wpsHeaderData, setWpsHeaderData] = useState(createWpsObject());

    const [download, { isLoading }] = useDownloadFileEmployeeSalaryMutation();

    const tableConfig = useAppSelector(selectTableConfig);
    const salary = tableConfig?.dbSalary;
    const disableSave = !wpsHeaderData.payerBankShortName || !wpsHeaderData.payerIban;
    const totalRecords = tableConfig.totalCount;
    const totalSalaries = salary?.total_salaries;

    const getSalaryPeriodDetails = () => {
        if (!salary?.salary_date) {
            return {};
        }
        const period = moment(salary.salary_date);
        const salaryPeriod = period.format("YYYYMM");
        const salaryMonth = period.format("MMMM");
        const salaryYear = period.format("YYYY");
        return {
            period,
            salaryPeriod,
            salaryMonth,
            salaryYear
        };
    };

    const downloadFile = async (fileType) => {
        if (!salary?.id || !fileType) return;
        try {
            const body = {
                salaryId: salary.id,
                fileType,
                timezone: setting.timezone
            };

            if (fileType == COMPANY_SALARY_FILETYPE.WPS_FILE) {
                wpsHeaderData.salaryPeriod = getSalaryPeriodDetails().salaryPeriod;
                wpsHeaderData.totalRecords = totalRecords;
                wpsHeaderData.totalSalaries = totalSalaries;
                wpsHeaderData.fileCreationDate = wpsHeaderData?.fileCreationDate?.format?.("YYYYMMDD");
                wpsHeaderData.fileCreationTime = wpsHeaderData?.fileCreationTime?.format("hh:mm")?.split?.(":")?.join?.("");
                body.wpsHeaderData = createWpsObject(wpsHeaderData);
            }
            const response = await download({ isBlob: true, body });

            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data.blob) {
                const filename = response.data.headers.get("X-Filename");
                dl(response.data.blob, filename);
                createToast("File exported successfully.");
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            return { error };
        }
    };

    const dl = (blob, filename) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    };

    const handlFieldUpdate = (obj = {}) => {
        setWpsHeaderData({ ...wpsHeaderData, ...obj });
    };

    return [
        downloadFile,
        isLoading,
        {
            wpsHeaderData,
            setWpsHeaderData: handlFieldUpdate,
            salaryMonth: getSalaryPeriodDetails().salaryMonth,
            salaryYear: getSalaryPeriodDetails().salaryYear,
            totalSalaries,
            totalRecords,
            disableSave
        }
    ];
};

export const useSalaryStateManager = () => {
    const [oldStrictChecking, setOldStringChecking] = useState(false);
    const [isStrictChecking, setStrictChecking] = useState(false);
    const [isMounted, setMounted] = useState(false);

    const dispatch = useAppDispatch();
    const date = useAppSelector(selectDate);
    const tableConfig = useAppSelector(selectTableConfig);
    const isSearching = useAppSelector(selectSearching);

    const [data, { fetch, isNotYetGenerated, isNotAllowed, isPending, isConfirmed, isNoRecordsAvailable }] = useInitialLoadPreviewSalary();
    const [, { loadMore, search, sort, filter, isLoadingMore }] = useLazySalary();
    const [generateSalary, isGenerating] = useGenerateSalary();
    const [confirmSalary, isConfirming] = useConfirmSalary();
    const [download, isDownloading, { setWpsHeaderData, wpsHeaderData }] = useDownloadSalary();

    const isLoading = isGenerating || isConfirming || isSearching;
    const isStrictCheckingChanged = oldStrictChecking != isStrictChecking;

    const handleDateChange = async (newdate) => {
        if (!newdate) {
            dispatch(reset());
        } else {
            dispatch(setDate(newdate));
            const result = await fetch(newdate);
            if (result) {
                const dbSalary = result.dbSalary;
                setStrictChecking(dbSalary?.is_strict_timing);
                setOldStringChecking(dbSalary?.is_strict_timing);
            } else {
                setStrictChecking(false);
                setOldStringChecking(false);
            }
            return result;
        }
    };

    const onGeneratePreview = async () => {
        const res = await generateSalary({ isStrictChecking });
        if (res) {
            const dbSalary = res.dbSalary;
            setStrictChecking(dbSalary?.is_strict_timing);
            setOldStringChecking(dbSalary?.is_strict_timing);
        }
    };

    const onConfirm = async () => {
        const res = await confirmSalary();
        if (res) {
            const dbSalary = res.dbSalary;
            setStrictChecking(dbSalary?.is_strict_timing);
        }
    };

    const onDownload = (type) => {
        switch (type) {
            case DOWNLOAD_TYPE.SALARY:
                return download(COMPANY_SALARY_FILETYPE.SALARY_COPY);
            case DOWNLOAD_TYPE.WPS:
                return download(COMPANY_SALARY_FILETYPE.WPS_FILE);
            default:
                break;
        }
    };

    useEffect(() => {
        setMounted(true);
    }, [isMounted]);

    useEffect(() => {
        if (isMounted) {
            search(tableConfig.search);
        }
    }, [tableConfig.search]);

    return [
        data,
        {
            tableConfig,
            date,
            setDate: handleDateChange,
            isLoading,
            isNotAllowed,
            isNotYetGenerated,
            onGeneratePreview,
            onConfirm,
            onDownload,
            isDownloading,
            onFilter: filter,
            sort,
            loadMore,
            isLoadingMore,
            isPending,
            isConfirmed,
            isNoRecordsAvailable,
            isStrictChecking,
            setStrictChecking,
            isStrictCheckingChanged,
            setWpsHeaderData,
            wpsHeaderData
        }
    ];
};
