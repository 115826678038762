import React, { useState } from "react";
import PropTypes from "prop-types";
import { createClass, createConfirmAlert, createGroup, formattedJoiErrorMessage } from "../../../common/utilities/helper";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import { useCreateSettlement } from "./hooks";
import { BASE_CLASS_CONFIRM_MODAL, BASE_CLASS_CREATE_OTHERS_MODAL } from "./const";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import ConfirmSettlementInputs from "./ConfirmSettlementInputs";
import ConfirmSummaryInfo from "./ConfirmSummaryInfo";

function ConfirmSettlementModal({ open, onClose, onBack, onFinish, data = {}, previewData, includeUnpaidLeaveDaysToServiceDays }) {
    const [error, setError] = useState({ all: null });

    const [form, onChange, { create, isUpserting }] = useCreateSettlement(data);

    const disableSave = isUpserting;

    const handleSave = async () => {
        const result = await create();
        if (!result.error) {
            if (error.all) {
                setError({ ...error, all: null });
            }
            typeof onFinish === "function" && onFinish(result);
        } else {
            setError({
                ...error,
                all: result.error.message
            });
        }
        return result;
    };

    return (
        <>
            <BaseUpdateModal
                open={open}
                onClose={onClose}
                onBack={onBack}
                onSave={() =>
                    createConfirmAlert({
                        title: "Confirm Settlement",
                        content: "Are you sure you want to create this record? This cannot be undone.",
                        onConfirm: async (close) => {
                            close();
                            const result = await handleSave();
                            if (!result?.error) {
                                onClose?.();
                            }
                        }
                    })
                }
                styles={{
                    content: {
                        height: "max-content",
                        maxHeight: "90vh",
                        width: "max-content",
                        minWidth: "40rem",
                        maxWidth: "45rem"
                    },
                    body: {
                        overflow: "auto"
                    },
                    inner: {
                        paddingTop: 0
                    }
                }}
                className={createClass(BASE_CLASS_CONFIRM_MODAL)}
                disableSave={disableSave}
                isLoading={isUpserting}
                isForm
            >
                {error.all && (
                    <SectionCollapseError show={!!error.all} style={{ marginBottom: "1rem" }}>
                        {formattedJoiErrorMessage({
                            error: { message: error.all },
                            isCreate: true
                        })}
                    </SectionCollapseError>
                )}
                {createGroup({
                    base: createClass(BASE_CLASS_CREATE_OTHERS_MODAL),
                    body: (
                        <div className="flex column gap-1">
                            <ConfirmSummaryInfo form={form} data={previewData} />
                            <ConfirmSettlementInputs
                                form={form}
                                onChange={onChange}
                                includeUnpaidLeaveDaysToServiceDays={includeUnpaidLeaveDaysToServiceDays}
                            />
                        </div>
                    )
                })}
            </BaseUpdateModal>
        </>
    );
}

ConfirmSettlementModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onBack: PropTypes.func,
    onFinish: PropTypes.func,
    data: PropTypes.object,
    previewData: PropTypes.object,
    includeUnpaidLeaveDaysToServiceDays: PropTypes.bool
};

export default ConfirmSettlementModal;
