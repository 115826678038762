import React, { useState } from "react";
import PropTypes from "prop-types";
import RightIcon from "@mui/icons-material/East";
import Dropdown, { getDropdownOptionValue } from "../../../common/components/extra/dropdown/Dropdown";
import DatePickerRange from "../../../common/components/extra/dateTime/DatePickerRange";
import { sanitizeWords, toFilterOptions } from "../../../common/utilities/helper";
import { useEffect } from "react";
import { CUSTOM_DATE_FILTER, MAIN_FILTER } from "./const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectCountry } from "../../common/slice";

const COMMON_STYLE = {
    minWidth: "10rem",
    height: "2rem",
    borderRadius: "25rem"
};

// fixes issue for duplicate keys when selecting expiring id
const ExpiringIdLabel = ({ text }) => (
    <span>
        {text} <span className="fade">(60 Days)</span>
    </span>
);

const DATE_OPTIONS = toFilterOptions(CUSTOM_DATE_FILTER, true).map((opt) => {
    const option = {
        label: sanitizeWords(opt.label, "_", false).replace(" Id", " ID"),
        value: opt.value
    };
    switch (opt.value) {
        case CUSTOM_DATE_FILTER.EXPIRING_ID:
            option.label = <ExpiringIdLabel text={option.label} />;
            break;
        default:
            break;
    }
    return option;
});

function FilterIDS({ onChange, value }) {
    const country = useAppSelector(selectCountry);
    const isInUAE = country === "ae";
    const [dateRange, setDateRange] = useState({
        to: "",
        from: ""
    });

    const MAIN_OPTIONS = toFilterOptions(MAIN_FILTER, true)
        .filter((opt) => {
            // filter out BY_LABOR_CARD if country is not ae/uae
            return opt.value !== MAIN_FILTER.BY_LABOR_CARD || isInUAE;
        })
        .map((opt) => {
            const option = {
                label: sanitizeWords(opt.label, "_", false).replace(" Id", " ID"),
                value: opt.value
            };
            switch (opt.value) {
                case MAIN_FILTER.ALL_IDS:
                    option.label = "All IDs";
                    break;
                case MAIN_FILTER.ALL_EXPIRED_ID:
                    break;
                case MAIN_FILTER.ALL_EXPIRING_ID:
                    option.label = <ExpiringIdLabel text={option.label} />;
                    break;
                default:
                    option.submenu = DATE_OPTIONS;
                    break;
            }
            return option;
        });

    const [config, setConfig] = useState({
        value: null,
        selected: null,
        ...getDropdownOptionValue({
            options: MAIN_OPTIONS,
            depthLevel: [...(value?.value || [])].length,
            value: [...(value?.value || [])].pop() || MAIN_FILTER.ALL_IDS
        })
    });

    const checkIfCustom = (value = {}) => {
        return (
            value &&
            Object.values(value)
                .map((conf) => conf.value)
                .includes(CUSTOM_DATE_FILTER.CUSTOM)
        );
    };

    const isCustom = checkIfCustom(config.value);

    const defaultValue = getDropdownOptionValue({
        options: MAIN_OPTIONS,
        depthLevel: 0,
        value: MAIN_FILTER.ALL_IDS
    }).selected;

    const updateDateRange = (key, value) => setDateRange({ ...dateRange, [key]: value });

    const handleDropdownChange = (value, selected) => {
        setConfig({ value, selected });
        const isNewValueCustom = checkIfCustom(value);
        !isNewValueCustom &&
            onChange?.({
                value: Object.values(value).map((obj) => obj.value),
                config: { from: "", to: "" }
            });
    };

    useEffect(() => {
        if (isCustom) {
            onChange?.({
                value: config.value && Object.values(config.value).map((obj) => obj.value),
                config: dateRange
            });
        }
    }, [dateRange, config.selected]);

    useEffect(() => {
        if (value && value.value.includes(defaultValue.value)) {
            const result = getDropdownOptionValue({
                options: MAIN_OPTIONS,
                depthLevel: 0,
                value: MAIN_FILTER.ALL_IDS
            });
            setConfig(result);
        }
    }, [value]);

    return (
        <div className="tk-employees__filter-expiry-date flex gap-05 align-center">
            <Dropdown
                prefix="Expiry Date"
                value={config.selected}
                defaultValue={defaultValue}
                onChange={handleDropdownChange}
                options={MAIN_OPTIONS}
                isPortal
                isClearable
                rounded
                mini
            />
            {isCustom && (
                <>
                    <RightIcon className="fade" />
                    <DatePickerRange
                        styles={{ date: COMMON_STYLE }}
                        onChange={updateDateRange}
                        value={dateRange}
                        isClearable
                        isFixed
                        allowFutureDate
                    />
                </>
            )}
        </div>
    );
}

export default FilterIDS;

FilterIDS.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.any,
        depthLevel: PropTypes.number
    })
};

ExpiringIdLabel.propTypes = {
    text: PropTypes.any
};
