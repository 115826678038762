import React, { useState } from "react";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import { createGroup, sanitizeWords } from "../../../common/utilities/helper";
import DepartmentsSelectLazy from "../departments/DepartmentsSelectLazy";
import DesignationsSelectLazy from "../designations/DesignationsSelectLazy";
import EmployeeSelectLazy from "./EmployeeSelectLazy";
import Empty from "../../../common/components/extra/Empty";
import Tag, { TAG_TYPE } from "../../../common/components/extra/Tag";
import UpdateModal from "../departments/UpdateModal";
import AssignDeptPositionButton from "./AssignDeptPositionButton";

const createManagingDepts = (current) => {
    const supervisingDepts = (current.supervisingDepts || []).map((sv) => ({ ...sv, isSupervisor: true }));
    const managingDepts = (current.managingDepts || []).map((mgr) => ({ ...mgr, isManager: true }));
    const concatenated = [...supervisingDepts, ...managingDepts];
    return Object.values(
        concatenated.reduce((acc, person) => {
            // If the person already exists, merge the current properties with the new ones
            if (acc[person.id]) {
                acc[person.id] = { ...acc[person.id], ...person };
            } else {
                acc[person.id] = person;
            }
            return acc;
        }, {})
    );
};

function UpdateDepartmentInputs({ form, base, isFetching, onChange, current, onFinish, readOnly }) {
    const [selected, setSelected] = useState(null);

    let departments = isFetching ? [] : createManagingDepts(current);

    if (isFetching) {
        form = {};
        current = {};
    }
    const handleFinish = () => {
        setSelected(null);
        onFinish?.();
    };

    return (
        <>
            {createGroup({
                base,
                title: current.first_name && current.last_name && sanitizeWords(`${current.first_name} ${current.last_name}`),
                body: (
                    <>
                        <DepartmentsSelectLazy
                            label="Department"
                            name="CompanyDepartment"
                            onChange={(val) => onChange({ target: { name: "CompanyDepartment", value: val } })}
                            value={form.CompanyDepartment}
                            readOnly={readOnly}
                            isPortal
                            required
                        />
                        <DesignationsSelectLazy
                            label="Designation"
                            name="CompanyDesignation"
                            onChange={(val) => onChange({ target: { name: "CompanyDesignation", value: val } })}
                            value={form.CompanyDesignation}
                            readOnly={readOnly}
                            isPortal
                            required
                        />
                        <EmployeeSelectLazy
                            label="Direct Supervisor"
                            value={form.directSupervisor}
                            onChange={(target) => onChange({ target: { name: "directSupervisor", value: target || null } })}
                            readOnly={readOnly}
                            excludeIds={[current.id]}
                            isLoading={isFetching}
                            isPortal
                            isSearchable
                            isClearable
                            allowOnShift
                        />
                        <EmployeeSelectLazy
                            label="Direct Manager"
                            value={form.directManager}
                            onChange={(target) => onChange({ target: { name: "directManager", value: target || null } })}
                            menuPortalTarget={document.body}
                            readOnly={readOnly}
                            excludeIds={[current.id]}
                            isLoading={isFetching}
                            isPortal
                            isSearchable
                            isClearable
                            allowOnShift
                        />
                    </>
                )
            })}
            {createGroup({
                base,
                title: (
                    <div className="flex align-center" style={{ justifyContent: "space-between" }}>
                        Managing Departments
                        {!readOnly && <AssignDeptPositionButton id={current.id} disabled={isFetching} />}
                    </div>
                ),
                body: (
                    <>
                        {!departments.length ? (
                            <Empty
                                style={{ margin: "2rem auto" }}
                                className="small-font"
                                message={<span className="fade small-font">Not Assigned to any Department as Supervisor/Manager</span>}
                                isLoading={isFetching}
                                noicon
                            />
                        ) : (
                            <ul className="styled-list">
                                {departments.map((department) => (
                                    <li key={department.id} onClick={() => setSelected(department)}>
                                        <div className="flex gap-05 align-center">
                                            <div className="flex align-center gap-05" style={{ flex: 1 }}>
                                                <span className="small-font semi-bold">{department.title}</span>
                                                <div className="flex gap-05">
                                                    {department.isSupervisor && <Tag type={TAG_TYPE.DEPT_SUPERVISOR}>SUPERVISOR</Tag>}
                                                    {department.isManager && <Tag type={TAG_TYPE.DEPT_MANAGER}>MANAGER</Tag>}
                                                </div>
                                            </div>
                                            {!readOnly && <EditIcon className="hover-svg" style={{ width: "1rem" }} />}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </>
                )
            })}

            {!!selected?.id && (
                <UpdateModal
                    attachedEmployee={current}
                    id={selected?.id}
                    open={!!selected?.id}
                    onClose={() => setSelected(null)}
                    onFinish={handleFinish}
                    readOnlyTitle
                />
            )}
        </>
    );
}

export default UpdateDepartmentInputs;

UpdateDepartmentInputs.propTypes = {
    base: PropTypes.string,
    form: PropTypes.object,
    current: PropTypes.object,
    isFetching: PropTypes.bool,
    onChange: PropTypes.func,
    onFinish: PropTypes.func,
    readOnly: PropTypes.bool
};
