import React, { useState } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Delete";
import TransferIcon from "@mui/icons-material/CompareArrows";
import Divider from "../../../common/components/extra/Divider";
import { createConfirmAlert, sanitizeWords } from "../../../common/utilities/helper";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import TableList from "../../../common/components/extra/table/TableList";
import Button, { BTN_TYPE } from "../../../common/components/extra/Button";
import MyTooltip, { PLACES } from "../../../common/components/extra/Tooltip";
import UpdateModal from "./UpdateModal";
import { useLazyDepartments, useRemoveDepartment, useTransferDepartment } from "./hooks";
import SectionCollapseWarning from "../../../common/components/extra/section/SectionCollapseWarning";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import Tag from "../../../common/components/extra/Tag";
import InvalidDeptWarning from "./InvalidDeptWarning";
import { setDeptCurrent } from "./slice";
import { ACTION } from "./const";
import Loader from "../../../common/components/extra/Loader";
import { useRefresh } from "../../common/hooks";

function Departments({ admin, style }) {
    const [openModal, setOpenModal] = useState(false);

    const [transfer, transferingId] = useTransferDepartment(null, !admin); // if admin transfer to other dept which is false isAdmin

    const [object, updateObject, { isLoading, loadMore, onSort, search, reset }] = useLazyDepartments({ admin: !!admin });
    const [onRemove] = useRemoveDepartment();

    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const [refresh] = useRefresh();

    const hasInvalidDepartment = admin ? user.hasInvalidAdminDepartment : user.hasInvalidDepartment;
    const disableTransfer = admin && object.data.length <= 1;

    const handleAction = async (type, config) => {
        switch (type) {
            case ACTION.REMOVE:
                return onRemove(config).then(reset).finally(refresh);
            case ACTION.UPDATE:
                updateObject({ selected: config });
                setOpenModal(true);
                refresh();
                return;
            case ACTION.TRANSFER:
                return transfer(config.id).then(reset).finally(refresh);
            default:
                break;
        }
    };

    const { data: headers } = CreateTableHeaders({ admin, onAction: handleAction, transferingId, disableTransfer });

    const handleFinish = async () => {
        await reset();
        setOpenModal(false);
    };

    const handleAdd = () => {
        setOpenModal(!openModal);
        updateObject({ selected: null });
        dispatch(setDeptCurrent(null));
    };

    return (
        <>
            <div className="flex column gap-05" style={{ margin: "unset", ...(style || {}) }}>
                <Divider title={admin ? "Manage Admin Departments" : "Manage Other Departments"} />
                <div className="fade small-font flex column gap-03">
                    {admin ? (
                        <>
                            <span> Members of the Admin Department will have high privileges for the following features: </span>
                            <p style={{ marginBottom: 0 }}>
                                Form Submissions: All employees within the Admin Department will be notified via mobile app whenever a form is
                                submitted. In addition, they will have full access to view all submitted forms.
                            </p>
                        </>
                    ) : (
                        "Efficiently organize your workforce with departments."
                    )}
                </div>
                {hasInvalidDepartment && (
                    <SectionCollapseWarning title="Important Notice" show alwaysOpen>
                        {admin
                            ? "At least one admin department is required, and must have both a Manager and a Supervisor."
                            : "A Department must have both a Manager and a Supervisor."}
                    </SectionCollapseWarning>
                )}
                <div className="flex column gap-05">
                    <div className="flex gap-05" style={{ alignItems: "center" }}>
                        <Input
                            type={INPUT_TYPE.SEARCH}
                            onChange={debounce(search, 500)}
                            placeholder="Search title..."
                            parentStyle={{
                                minHeight: "1rem",
                                height: "100%"
                            }}
                            wrapperStyle={{ flex: 1 }}
                        />
                        <Button type={BTN_TYPE.ADD} onClick={handleAdd}>
                            Add
                        </Button>
                    </div>
                    <TableList
                        headers={headers}
                        data={object.data}
                        onSort={onSort}
                        activeSort={object.sort}
                        onLoadMore={loadMore}
                        height={35}
                        isLoadingMore={isLoading}
                        small
                    />
                </div>
            </div>
            {openModal && (
                <UpdateModal
                    id={object.selected?.id}
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    onFinish={handleFinish}
                    admin={admin}
                    withTransfer={!disableTransfer}
                />
            )}
        </>
    );
}

export default Departments;

Departments.propTypes = {
    admin: PropTypes.bool,
    style: PropTypes.object
};

const CreateTableHeaders = ({ admin, onAction, transferingId, disableTransfer }) => {
    const headers = {
        EMPLOYEE: {
            key: "title",
            sortKey: "title",
            label: "Title",
            render: (row) => (
                <div className="flex column gap-05" style={{ margin: "1rem 0" }}>
                    <span className="text-ellipsis">
                        <span className="bold">{row.title}</span>
                    </span>
                    {!!row.supervisor_id && row.supervisor && (
                        <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                            <span className="small-font fade">Supervisor:</span>
                            <span className="semi-bold">{sanitizeWords(`${row.supervisor.first_name} ${row.supervisor.last_name}`)}</span>
                        </div>
                    )}
                    {!!row.manager_id && row.manager && (
                        <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                            <span className="small-font fade">Manager:</span>
                            <span className="semi-bold">{sanitizeWords(`${row.manager.first_name} ${row.manager.last_name}`)}</span>
                        </div>
                    )}
                    <div className="flex gap-05 wrap small-font" style={{ alignItems: "center", marginTop: "-.3rem" }}>
                        <span className="small-font fade">Total Employees:</span>
                        <span className="semi-bold">{<Tag>{row.usageCount !== 0 ? (row.usageCount + "").padStart(2, "0") : 0}</Tag>}</span>
                    </div>
                </div>
            )
        },
        ACTION: {
            key: "action",
            style: { display: "flex", justifyContent: "flex-end", paddingRight: ".5rem" },
            render: (row) => {
                return (
                    <div className="flex gap-05 align-center">
                        <InvalidDeptWarning data={row} />
                        {!disableTransfer && (
                            <MyTooltip position={PLACES.LEFT} message={`Transfer to ${admin ? "Other" : "Admin"} Departments.`}>
                                <Button
                                    onClick={() =>
                                        createConfirmAlert({
                                            title: `Transfer Department to ${admin ? "Other" : "Admin"} Departments?`,
                                            content: `You are about to transfer this department to ${admin ? "Other" : "Admin"} Departments. You can always reverse this action later if needed.`,
                                            onConfirm: (onClose) => {
                                                onAction?.(ACTION.TRANSFER, row);
                                                onClose();
                                            }
                                        })
                                    }
                                    options={{ style: { padding: 0, boxShadow: "unset", marginLeft: "auto", maxWidth: "max-content" } }}
                                    transparent
                                >
                                    {transferingId && transferingId == row.id ? (
                                        <Loader svgStyle={{ width: "2.2rem" }} relative />
                                    ) : (
                                        <TransferIcon className="fade hover-svg" style={{ width: "1.8rem", height: "auto" }} />
                                    )}
                                </Button>
                            </MyTooltip>
                        )}
                        <Button
                            onClick={() => onAction?.(ACTION.UPDATE, row)}
                            options={{ style: { padding: 0, boxShadow: "unset", marginLeft: "auto", maxWidth: "max-content" } }}
                            transparent
                        >
                            <EditIcon className="fade hover-svg" style={{ width: "1.4rem" }} />
                        </Button>
                        <MyTooltip position={PLACES.LEFT} message={!!row.usageCount && `Used by ${row.usageCount} Employees.`}>
                            <Button
                                onClick={() =>
                                    createConfirmAlert({
                                        title: "Remove Department",
                                        content: "Are you sure? This cannot be undone.",
                                        onConfirm: (onClose) => {
                                            onAction?.(ACTION.REMOVE, row);
                                            onClose();
                                        }
                                    })
                                }
                                options={{ style: { padding: 0, boxShadow: "unset", marginLeft: "auto", maxWidth: "max-content" } }}
                                disabled={!!row.usageCount}
                                transparent
                            >
                                <RemoveIcon className="hover-svg" style={{ width: "1.4rem", color: "red" }} />
                            </Button>
                        </MyTooltip>
                    </div>
                );
            }
        }
    };
    return { data: Object.values(headers), original: headers };
};
