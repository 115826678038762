import React, { useState } from "react";
import PropTypes from "prop-types";
import ArticleIcon from "@mui/icons-material/Article";
import { blobToBase64, isFileObject } from "../../../common/utilities/helper";
import { FIELD, SETTLEMENT_CATEGORY, SETTLEMENT_STATUS } from "./const";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import SelectConstant from "../../../common/components/extra/select/SelectConstant";
import { COMMON_DOCUMENT_MIME_TYPES_ACCEPTS, DEFAULT_FILE_LIMIT_SIZE, NOTES_MAX_LEN, TEXT_SHORT_LEN } from "../../../common/utilities/const";
import ViewFileModal from "../employees/ViewFileModal";
import { VIEW_MODAL_TYPE } from "../employees/const";

function ConfirmSettlementInputs({ onChange, form = {}, joiningDate, includeUnpaidLeaveDaysToServiceDays }) {
    const [internalForm, setInternalForm] = useState({ previewFileProof: null });
    const [proofSrc, setProofSrc] = useState(null);

    const setting = useAppSelector(selectUserSetting);

    const isPreviousEncashments = form[FIELD.CATEGORY] == SETTLEMENT_CATEGORY.PREVIOUS;

    const updateInternals = (name, value) => {
        setInternalForm({
            ...internalForm,
            [name]: value
        });
    };

    return (
        <>
            <div className="flex column gap-05">
                <SelectConstant
                    label="Status"
                    value={form[FIELD.STATUS]}
                    base={isPreviousEncashments ? { [SETTLEMENT_STATUS.PAID]: SETTLEMENT_STATUS[SETTLEMENT_STATUS.PAID] } : SETTLEMENT_STATUS}
                    onChange={(target) =>
                        onChange({
                            [FIELD.STATUS]: target.value
                        })
                    }
                    required
                    isOutlined
                    disabledOutline
                />
                {isPreviousEncashments && (
                    <Input
                        label="Total Paid Amount"
                        type={INPUT_TYPE.NUMBER}
                        name={FIELD.TOTAL_PAID_AMOUNT}
                        value={form[FIELD.TOTAL_PAID_AMOUNT] || ""}
                        onChange={(e) =>
                            onChange({
                                [FIELD.TOTAL_PAID_AMOUNT]: e.target.value
                            })
                        }
                        afterExtra={<span>{setting.currency}</span>}
                        required
                    />
                )}
                {form[FIELD.STATUS] == SETTLEMENT_STATUS.PAID && (
                    <Input
                        label="Paid Date"
                        type={INPUT_TYPE.DATE}
                        name={FIELD.PAID_DATE}
                        onChange={(date) =>
                            onChange({
                                [FIELD.PAID_DATE]: date
                            })
                        }
                        selected={form[FIELD.PAID_DATE] && new Date(form[FIELD.PAID_DATE])}
                        minDate={joiningDate && new Date(joiningDate)}
                        required
                    />
                )}
                <Input
                    label="Processed Date"
                    type={INPUT_TYPE.DATE}
                    name={FIELD.PROCESSED_DATE}
                    onChange={(date) =>
                        onChange({
                            [FIELD.PROCESSED_DATE]: date
                        })
                    }
                    selected={form[FIELD.PROCESSED_DATE] && new Date(form[FIELD.PROCESSED_DATE])}
                    minDate={joiningDate && new Date(joiningDate)}
                    noFuture
                    required
                />
                {isPreviousEncashments && includeUnpaidLeaveDaysToServiceDays && (
                    <Input
                        name={FIELD.DESCRIPTION}
                        label="Short Description"
                        type={INPUT_TYPE.TEXTAREA}
                        value={form[FIELD.DESCRIPTION] || ""}
                        parentStyle={{ height: "10rem", minHeight: "5rem" }}
                        onChange={(e) =>
                            onChange({
                                [FIELD.DESCRIPTION]: e.target.value
                            })
                        }
                        maxLength={TEXT_SHORT_LEN}
                        required
                    />
                )}
                <Input
                    type={INPUT_TYPE.UPLOAD}
                    label="Document"
                    name={FIELD.DOCUMENT}
                    internalIcon={<ArticleIcon />}
                    onChange={async (e) => {
                        onChange({
                            [FIELD.DOCUMENT]: e.target?.files?.[0]
                        });
                        const newSrc = await blobToBase64(e.target?.files?.[0]);
                        setProofSrc(newSrc);
                    }}
                    onView={form[FIELD.DOCUMENT] ? () => updateInternals("previewFileProof", true) : null}
                    value={form[FIELD.DOCUMENT]}
                    accept={COMMON_DOCUMENT_MIME_TYPES_ACCEPTS.toString()}
                    sizeLimit={DEFAULT_FILE_LIMIT_SIZE}
                    haslabel
                />
                <Input
                    name={FIELD.REMARKS}
                    label="Remarks"
                    type={INPUT_TYPE.TEXTAREA}
                    value={form[FIELD.REMARKS] || ""}
                    parentStyle={{ height: "10rem", minHeight: "5rem" }}
                    onChange={async (e) =>
                        onChange({
                            [FIELD.REMARKS]: e.target.value
                        })
                    }
                    maxLength={NOTES_MAX_LEN}
                />
            </div>
            {!!internalForm.previewFileProof && (
                <ViewFileModal
                    open={!!internalForm.previewFileProof}
                    onClose={() => updateInternals("previewFileProof", null)}
                    type={VIEW_MODAL_TYPE.SETTLEMENT_DOC_FILE}
                    data={{
                        id: form?.[FIELD.EMPLOYEE]?.id,
                        src: proofSrc || null,
                        filename: form[FIELD.DOCUMENT]
                    }}
                    nofetch={isFileObject(form[FIELD.DOCUMENT])}
                />
            )}
        </>
    );
}

ConfirmSettlementInputs.propTypes = {
    onChange: PropTypes.func,
    form: PropTypes.object,
    joiningDate: PropTypes.string,
    includeUnpaidLeaveDaysToServiceDays: PropTypes.bool
};

export default ConfirmSettlementInputs;
