import React from "react";
import PropTypes from "prop-types";
import Departments from "../departments/Departments";
import Designations from "../designations/Designations";
import Card from "../../../common/components/extra/Card";
import Tabs from "../../../common/components/extra/tabs/TabsV2";
import { POSITION_TABS } from "./const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import WarningColorSwitcher from "../../../common/components/extra/WarningColorSwitcher";
import MyTooltip from "../../../common/components/extra/Tooltip";
import { createMiniTootlip } from "../../../common/utilities/helper";

function PositionTab({ styles = {} }) {
    const user = useAppSelector(selectUser);
    const hasInvalidDepartment = user.hasInvalidDepartment;
    const hasInvalidAdminDepartment = user.hasInvalidAdminDepartment;

    return (
        <div className="work-tab flex column gap-1" style={styles.parent || {}}>
            <Card className="position-settings box-shadow-mini">
                <Tabs id="position" useStorage isCapsule>
                    <Departments
                        hash={POSITION_TABS.ADMIN_DEPTS.id}
                        label={
                            <div className="flex gap-05 center">
                                {POSITION_TABS.ADMIN_DEPTS.label}
                                {hasInvalidAdminDepartment && (
                                    <MyTooltip
                                        message={createMiniTootlip(
                                            "There should be at least one admin department and must have both a Manager and a Supervisor."
                                        )}
                                    >
                                        <WarningColorSwitcher style={{ width: "1.3rem" }} warning />
                                    </MyTooltip>
                                )}
                            </div>
                        }
                        admin
                    />
                    <Departments
                        hash={POSITION_TABS.OTHER_DEPTS.id}
                        label={
                            <div className="flex gap-05 center">
                                {POSITION_TABS.OTHER_DEPTS.label}
                                {hasInvalidDepartment && (
                                    <MyTooltip message={createMiniTootlip("A Department must have both a Manager and a Supervisor.")}>
                                        <WarningColorSwitcher style={{ width: "1.3rem" }} warning />
                                    </MyTooltip>
                                )}
                            </div>
                        }
                    />
                    <Designations hash={POSITION_TABS.DESIGNATIONS.id} label={POSITION_TABS.DESIGNATIONS.label} />
                </Tabs>
            </Card>
        </div>
    );
}

export default PositionTab;

PositionTab.propTypes = {
    loading: PropTypes.bool,
    setParentLoading: PropTypes.func,
    styles: PropTypes.shape({
        parent: PropTypes.object
    })
};
