import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { createMockData, toTimeWithTimeZone } from "../../../common/utilities/helper";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { FIELD } from "./const";
import { DATE_FORMAT_OPTION } from "../companyForms/const";
import Select from "../../../common/components/extra/select/Select";

const AirTicketInputs = ({ form, ticketDetails, onChange }) => {
    const [useAirTicket, setUseAirTicket] = useState(false);

    const setting = useAppSelector(selectUserSetting);

    const timezone = setting.timezone;
    const availableCanBeUsedTickets = ticketDetails.available;
    const remainingMonthForNextTicketEntitlement = ticketDetails.remainingMonthsForNextEntitlement;
    const nextEligibleDate =
        ticketDetails.nextEligibleDate && toTimeWithTimeZone(ticketDetails.nextEligibleDate, timezone).format(DATE_FORMAT_OPTION.FULL_DATE.format);
    const isAirTicketEligible = !!availableCanBeUsedTickets;

    const options = useMemo(() => {
        return createMockData(availableCanBeUsedTickets, (num) => ({ value: num + 1, label: num + 1 }));
    }, [availableCanBeUsedTickets]);

    const subtextMessages = [
        <React.Fragment key={1}>
            Available Tickets:&nbsp;
            <span className={`${!availableCanBeUsedTickets ? "danger-color" : "primary-color"} bold`}>{availableCanBeUsedTickets} Tickets</span>
            ;&nbsp;
        </React.Fragment>,
        <React.Fragment key={2}>
            Next Ticket Entitlement: <span className="primary-color bold">{nextEligibleDate}</span>;&nbsp;
        </React.Fragment>,
        <React.Fragment key={3}>
            Remaining Months:&nbsp;
            <span className="primary-color bold">{remainingMonthForNextTicketEntitlement} Month(s)</span>
        </React.Fragment>
    ];

    return (
        <div className="flex column gap-03" style={useAirTicket ? { borderLeft: "3px solid gray", paddingLeft: "5px" } : {}}>
            <Input
                type={INPUT_TYPE.TOGGLE}
                label="Include Air Ticket"
                value={useAirTicket}
                onChange={(e) => {
                    setUseAirTicket(e.target.checked);
                    if (!e.target.checked) {
                        onChange?.({
                            [FIELD.AIR_TICKET_AMOUNT]: null,
                            [FIELD.TICKET_ENTITLEMENT]: null
                        });
                    }
                }}
                subtext={{
                    message: subtextMessages
                }}
                tooltip={!isAirTicketEligible && "No Available Tickets"}
                disabled={!isAirTicketEligible}
                useSubTextStyle
            />
            {useAirTicket && isAirTicketEligible && (
                <ul className="flex column gap-03">
                    <li className="indent-1">
                        <Select
                            label="No. of Tickets to Include"
                            value={
                                form[FIELD.TICKET_ENTITLEMENT]
                                    ? {
                                          value: form[FIELD.TICKET_ENTITLEMENT] || "",
                                          label: (
                                              <div>
                                                  <span className="semi-bold">
                                                      <span>{form[FIELD.TICKET_ENTITLEMENT]}</span>&nbsp;
                                                      <span className="fade">Ticket(s)</span>
                                                  </span>
                                              </div>
                                          )
                                      }
                                    : null
                            }
                            options={options}
                            onChange={(option) =>
                                onChange({
                                    [FIELD.TICKET_ENTITLEMENT]: option.value
                                })
                            }
                            menuPortalTarget={document.body}
                            isOutlined
                            disabledOutline
                            required
                        />
                    </li>
                    <li className="indent-1">
                        <Input
                            label="Amount"
                            type={INPUT_TYPE.NUMBER}
                            name={FIELD.AIR_TICKET_AMOUNT}
                            value={form[FIELD.AIR_TICKET_AMOUNT] || ""}
                            onChange={(e) =>
                                onChange({
                                    [FIELD.AIR_TICKET_AMOUNT]: e.target.value
                                })
                            }
                            afterExtra={<span>{setting.currency}</span>}
                            required
                        />
                    </li>
                </ul>
            )}
        </div>
    );
};

export default AirTicketInputs;

AirTicketInputs.propTypes = {
    onChange: PropTypes.func,
    form: PropTypes.object,
    ticketDetails: PropTypes.shape({
        available: PropTypes.number,
        remainingMonthsForNextEntitlement: PropTypes.number,
        nextEligibleDate: PropTypes.string
    })
};
