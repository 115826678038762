export const BASE_CLASS = "tk-employees-settlement";

export const BASE_CLASS_VIEW_MODAL = BASE_CLASS + "__modal-content-view";
export const BASE_CLASS_CREATE_MODAL = BASE_CLASS + "__modal-content-create";
export const BASE_CLASS_CREATE_MODAL_INPUTS = BASE_CLASS + "__modal-content-create__inputs";
export const BASE_CLASS_CREATE_FINAL_MODAL = BASE_CLASS + "__modal-content-create__final";
export const BASE_CLASS_CREATE_OTHERS_MODAL = BASE_CLASS + "__modal-content-create__others";
export const BASE_CLASS_CONFIRM_MODAL = BASE_CLASS + "__modal-content-confirm";

export const TABS = {
    FINAL_SETTLEMENTS: { id: "final-settlements", label: "Final Settlements" },
    OTHER_SETTLEMENTS: { id: "other-settlements", label: "Other Settlements" }
};

export const NEXT_DAY = 1;

export const ROUTE_DATA_TYPE_PARAM = {
    OTHERS: "others",
    FINAL: "final"
};

export const FILTER_TYPE = {
    EMPLOYEE: 0x1,
    CATEGORY: 0x2,
    TYPE: 0x3,
    STATUS: 0x4,
    CLASSIFICATION: 0x5,
    PROCESSED_DATE: 0x6,
    PAID_DATE: 0x7
};

export const FIELD = {
    EMPLOYEE: "employee",
    DESCRIPTION: "description",
    CATEGORY: "category",
    TYPE: "type",
    CLASSIFICATION: "classification",
    STATUS: "status",
    BASE_SALARY: "base_salary",
    PROCESSED_DATE: "processed_date",
    LAST_WORKING_DATE: "last_working_date",
    COVERAGE_START_DATE: "coverage_start_date",
    COVERAGE_END_DATE: "coverage_end_date",
    NOTICE_PAY_AMOUNT: "notice_pay_amount",
    AIR_TICKET_AMOUNT: "air_ticket_amount",
    PAID_DATE: "paid_date",
    REMARKS: "remarks",
    DOCUMENT: "document_filename",
    // System Calculated Values
    SERVICE_DURATION_DAYS: "service_duration_days",
    GRATUITY_AMOUNT: "gratuity_amount",
    LEAVE_ENTITLEMENT: "leave_entitlement",
    LEAVE_PAY_AMOUNT: "leave_pay_amount",
    TICKET_ENTITLEMENT: "ticket_entitlement",
    REMAINING_SALARY_AMOUNT: "remaining_salary_amount",
    OTHER_INCOME_AMOUNT: "other_income_amount",
    DEDUCTION_PENALTIES_AMOUNT: "deductions_penalties_amount",
    TOTAL_PAID_AMOUNT: "total_paid_amount",
    FINALIZED_DATE: "finalized_date"
};

export const SETTLEMENT_CATEGORY = {
    NORMAL: "NORMAL", // typical listing of a new record
    PREVIOUS: "PREVIOUS" // should be used for recording previous records received by an employee prior to system usage
};

export const SETTLEMENT_TYPE = {
    LEAVE: "LEAVE", // can be given after 1 year of service as per company
    GRATUITY: "GRATUITY", // can be given every after 1 year of service as per company
    FULL_FINAL: "FULL_FINAL" // normally, given during employee resignation, termination, end-of-contract and retirement
};

export const SETTLEMENT_STATUS = {
    PENDING: "PENDING",
    PAID: "PAID" // required for PREVIOUS category
};

export const BASE_SALARY = {
    BASIC_SALARY: "BASIC_SALARY",
    FULL_SALARY: "FULL_SALARY"
};

export const SETTLEMENT_DAILY_RATE = {
    BY_30_DAYS: "BY_30_DAYS", // value / 30 days
    PRO_RATA_YEAR: "PRO_RATA_YEAR" // value * (12/365.25) considering leap year
};

export const SETTLEMENT_CLASSIFICATION = {
    ENCASHMENT: "ENCASHMENT", // use for PARTIAL, LEAVE AND GRATUITY only
    RESIGNATION: "RESIGNATION", // use for FULL_FINAL, contract status RESIGNED
    TERMINATION: "TERMINATION", // use for FULL_FINAL, contract status TERMINATED
    END_OF_CONTRACT: "END_OF_CONTRACT", // use for FULL_FINAL, contract status CONTRACT_ENDED
    RETIREMENT: "RETIREMENT" // use for FULL_FINAL, contract status RETIRED
};

export const OTHER_SUPPORTED_SETTLEMENT_TYPE = {
    PREVIOUS_LEAVE_ENCASHMENT: "PREVIOUS_LEAVE_ENCASHMENT",
    PREVIOUS_GRATUITY_ENCASHMENT: "PREVIOUS_GRATUITY_ENCASHMENT",
    LEAVE_SETTLEMENT_ENCASHMENT: "LEAVE_SETTLEMENT_ENCASHMENT",
    GRATUITY_SETTLEMENT_ENCASHMENT: "GRATUITY_SETTLEMENT_ENCASHMENT"
};
