import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useLazySelectPenalties } from "./hooks";
import { ROUTE_TYPE } from "./const";
import useSelectManageInfoLazy from "../../../common/components/extra/select/hooks";
import CustomSelect from "../../../common/components/extra/select/CustomSelect";

function CompanyPenaltiesSelectLazy(props) {
    const ref = useRef(null);
    const [searchText, setSearchText] = useState("");
    const [defaultValue, setDefaultValue] = useState(Array.isArray(props.value) ? props.value : props?.value);

    const [object, , { isLoading, initializing: dataInitializing, loadMore, search, createRowItem, fixedValues }] = useLazySelectPenalties({
        defaultValue,
        initializing: props.isLoading,
        isReadableSelected: props.isReadableSelected,
        value: props?.value,
        type: props.type,
        isMulti: props?.isMulti,
        isFilter: props.isFilter
    });

    const initializing = props.isLoading || dataInitializing;

    const [value, onChange] = useSelectManageInfoLazy({
        isMulti: props.isMulti,
        value: props.value,
        data: object.data,
        createRowItem,
        isLoading: initializing,
        onChange: props.onChange
    });

    useEffect(() => {
        if (!props.isLoading) {
            setDefaultValue(Array.isArray(props.value) ? props.value : props?.value);
        }
    }, [props.isLoading]);

    const handleSearch = (newInput) => {
        if (initializing) {
            return;
        }
        setSearchText(newInput);
        search(newInput);
    };

    return (
        <div ref={ref} className="flex gap-1 center w100" style={{ ...props.parentStyle, ...(props.isFilter ? { width: "max-content" } : {}) }}>
            <CustomSelect
                {...props}
                {...(props.isFilter
                    ? {
                          isFilter: true,
                          noRequiredInput: true,
                          label: "",
                          isMulti: false,
                          isClearable: true,
                          isPortal: true
                      }
                    : {})}
                placeholder="Select a Penalty"
                filterNoSelectedPlaceholder="All Penalty"
                filterOption={(option, newInputs) => {
                    const keywords = option?.keywords?.trim()?.toLowerCase() || "";
                    const search = newInputs?.toLowerCase().trim();
                    if (keywords.includes(search)) {
                        return true;
                    } else {
                        return false;
                    }
                }}
                onChange={onChange}
                value={value}
                options={object.data}
                onMenuScrollToBottom={() => loadMore({ search: searchText })}
                onSearch={handleSearch}
                initializing={initializing}
                isOptionsLoading={isLoading}
                style={{ flex: 1, height: "100%" }}
                fixedValues={fixedValues}
                isClearable={props.isClearable && !props.isReadableSelected}
                isSearchable
            />
        </div>
    );
}

CompanyPenaltiesSelectLazy.propTypes = {
    onChange: PropTypes.func,
    isOutlined: PropTypes.bool,
    label: PropTypes.string,
    noborder: PropTypes.bool,
    style: PropTypes.object,
    value: PropTypes.any,
    isMulti: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(ROUTE_TYPE)),
    isLoading: PropTypes.bool,
    isFilter: PropTypes.bool,
    parentStyle: PropTypes.object,
    onShiftSiteId: PropTypes.number,
    isReadableSelected: PropTypes.bool,
    isClearable: PropTypes.bool
};

export default CompanyPenaltiesSelectLazy;
