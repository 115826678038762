import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import ArticleIcon from "@mui/icons-material/Article";
import {
    blobToBase64,
    createClass,
    createConfirmAlert,
    createGroup,
    formattedJoiErrorMessage,
    getTotalDaysFromDateRange,
    isFileObject,
    sanitizeWords,
    toTimeWithTimeZone
} from "../../../common/utilities/helper";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import { useUpsertLeave } from "./hooks";
import { BASE_CLASS_UPDATE_MODAL, FIELD, FIELD_OBJECT, LEAVE_PAYMENT_TYPE, LEAVE_TYPE } from "./const";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import { DEFAULT_FILE_LIMIT_SIZE, FILE_MIME_TYPES, NOTES_MAX_LEN } from "../../../common/utilities/const";
import EmployeeSelectLazy from "../employees/EmployeeSelectLazy";
import SelectConstant from "../../../common/components/extra/select/SelectConstant";
import { isDateRangeSame } from "../companyHolidays/helper";
import LeaveSummary from "./LeaveSummary";
import useCurrentCountryGlobals from "../../../common/hooks/useCurrentCountryGlobals";
import { VIEW_MODAL_TYPE } from "../employees/const";
import ViewFileModal from "../employees/ViewFileModal";
import UpdateStatusModal from "../employeeSubmittedForms/UpdateStatusModal";
import { SUBMITTED_FORM_ACTION_TYPE } from "../employeeSubmittedForms/const";

function UpdateModal({
    open,
    onClose,
    onBack,
    onFinish,
    id,
    saveLabel,
    fromSubmittedForm = null,
    optionals = [],
    readonlyFields = [],
    onSubmittedFormUpdateStatus
}) {
    const [submittedFormExtraModal, setSubmittedFormExtraModal] = useState(false);
    const [error, setError] = useState({ all: null });
    const [previewFileProof, setPreviewFileProof] = useState(null);
    const [proofSrc, setProofSrc] = useState(null);

    const isCreate = !id;

    const [form, updateForm, { upsert, hasChanges, isGettingRecord, isUpserting }] = useUpsertLeave(id, fromSubmittedForm);

    const { leaveTypesObj } = useCurrentCountryGlobals();

    const disableSave = isGettingRecord || (!isCreate && !hasChanges) || isUpserting;
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const startDate = form[FIELD.START_DATE];
    const endDate = form[FIELD.END_DATE];

    const isDateSame = isDateRangeSame(startDate, endDate, timezone);

    const totalDays = useMemo(
        () => getTotalDaysFromDateRange(form[FIELD.START_DATE], form[FIELD.END_DATE], timezone),
        [form[FIELD.START_DATE], form[FIELD.END_DATE]]
    );

    const isFieldReadOnly = (fieldName) => {
        return readonlyFields.includes(fieldName);
    };

    const isFieldOptional = (fieldName) => {
        return optionals.includes(fieldName);
    };

    const handleSave = async () => {
        const result = await upsert();
        if (!result.error) {
            if (error.all) {
                setError({ ...error, all: null });
            }
            typeof onFinish === "function" && onFinish(result);
        } else {
            setError({
                ...error,
                all: result.error.message
            });
        }
        return result;
    };

    const createTitle = () => {
        return (
            <div className="flex gap-05">
                <span className={!isCreate ? "fade" : ""}>{isCreate ? "Create" : "Update"} Leave Form</span>
                {!isCreate && (
                    <>
                        <span className="fade">-</span>
                        <span>{sanitizeWords(form?.employee?.fullName)}</span>
                    </>
                )}
            </div>
        );
    };

    const handleFormChange = async ({ name, value } = {}) => {
        const temp = { [name]: value };
        switch (name) {
            case FIELD.PROOF_FILE: {
                const newSrc = await blobToBase64(value);
                setProofSrc(newSrc);
                break;
            }
            case FIELD.START_DATE: {
                const start = (value && toTimeWithTimeZone(value)) || "";
                const end = (endDate && toTimeWithTimeZone(endDate)) || "";
                if (end && start && moment(start.format()).isAfter(end.format())) {
                    setError({
                        ...error,
                        [FIELD.START_DATE]: "Invalid Date: End Date has been cleared, Start Date cannot exceed the End Date."
                    });
                    temp[FIELD.END_DATE] = null;
                } else {
                    if (form[FIELD.END_DATE]) {
                        temp[FIELD.END_DATE] = toTimeWithTimeZone(endDate).endOf("day").format();
                    } else {
                        temp[FIELD.END_DATE] = toTimeWithTimeZone(start).endOf("day").format();
                    }
                    if (error[FIELD.START_DATE]) {
                        setError({ ...error, [FIELD.START_DATE]: null });
                    }
                }
                break;
            }
            case FIELD.END_DATE: {
                const start = (startDate && toTimeWithTimeZone(startDate)) || "";
                const end = (value && toTimeWithTimeZone(value)) || "";
                if (!value || (end && start && moment(end.format()).isAfter(start.format()))) {
                    if (error[FIELD.START_DATE]) {
                        setError({ ...error, [FIELD.START_DATE]: null });
                    }
                }
                break;
            }
            default:
                break;
        }
        if (error.all) {
            setError({
                ...error,
                all: null
            });
        }
        updateForm(temp);
    };

    return (
        <>
            <BaseUpdateModal
                open={open}
                onClose={onClose}
                onBack={(!isCreate && onBack) || null}
                onSave={() =>
                    !disableSave && !fromSubmittedForm
                        ? createConfirmAlert({
                              title: !isCreate ? "Update Record" : "Create Record",
                              content: `Are you sure you want to ${isCreate ? "create" : "update"} this record? This cannot be undone.`,
                              onConfirm: async (close) => {
                                  close();
                                  const result = await handleSave();
                                  if (!result?.error) {
                                      onBack?.();
                                  }
                              }
                          })
                        : setSubmittedFormExtraModal(true)
                }
                styles={{
                    content: {
                        height: "max-content",
                        minHeight: "45rem",
                        maxHeight: "90vh"
                    },
                    body: {
                        overflow: "auto"
                    }
                }}
                className={createClass(BASE_CLASS_UPDATE_MODAL)}
                disableSave={disableSave}
                isLoading={isUpserting}
                saveLabel={saveLabel}
                isForm
            >
                {createGroup({
                    base: createClass(BASE_CLASS_UPDATE_MODAL),
                    title: createTitle(),
                    body: (
                        <div className="flex column gap-05">
                            {error.all && (
                                <SectionCollapseError show={!!error.all} style={{ marginBottom: "1rem" }}>
                                    {formattedJoiErrorMessage({
                                        error: { message: error.all },
                                        isCreate
                                    })}
                                </SectionCollapseError>
                            )}
                            <div className="flex gap-1 wrap">
                                <div className="flex column gap-05" style={{ flex: 1 }}>
                                    <EmployeeSelectLazy
                                        label="Employee"
                                        value={form[FIELD.EMPLOYEE]}
                                        onChange={(target) =>
                                            handleFormChange({
                                                name: FIELD.EMPLOYEE,
                                                value: target
                                            })
                                        }
                                        isDisabled={!isCreate || isFieldReadOnly(FIELD.EMPLOYEE)}
                                        required
                                        allowOnShift
                                    />
                                    <LeaveSummary employeeId={form[FIELD.EMPLOYEE]?.id} />
                                </div>
                                <div
                                    className="flex column gap-05"
                                    style={{
                                        flex: 1,
                                        alignItems: "space-between"
                                    }}
                                >
                                    <div className="flex column gap-05">
                                        <SelectConstant
                                            label={FIELD_OBJECT[FIELD.LEAVE_TYPE].label}
                                            value={form[FIELD.LEAVE_TYPE]}
                                            base={leaveTypesObj}
                                            onChange={(target) =>
                                                handleFormChange({
                                                    name: FIELD.LEAVE_TYPE,
                                                    value: target.value
                                                })
                                            }
                                            isLoading={isGettingRecord}
                                            isDisabled={!isCreate || isFieldReadOnly(FIELD.LEAVE_TYPE)}
                                            required={!isFieldOptional(FIELD.LEAVE_TYPE)}
                                            isOutlined
                                            disabledOutline
                                        />
                                        {form[FIELD.LEAVE_TYPE] == LEAVE_TYPE.COMPENSATORY_LEAVE && (
                                            <Input
                                                label={FIELD_OBJECT[FIELD.OFFSET_HOURS].label}
                                                type={INPUT_TYPE.NUMBER}
                                                name={FIELD.OFFSET_HOURS}
                                                value={form[FIELD.OFFSET_HOURS] || ""}
                                                onChange={(e) =>
                                                    handleFormChange({
                                                        name: FIELD.OFFSET_HOURS,
                                                        value: e.target.value
                                                    })
                                                }
                                                afterExtra={<span>Hour(s)</span>}
                                                isLoading={isGettingRecord}
                                                disabled={!isCreate || isFieldReadOnly(FIELD.OFFSET_HOURS)}
                                                required={!isFieldOptional(FIELD.OFFSET_HOURS)}
                                            />
                                        )}
                                        <SelectConstant
                                            label={FIELD_OBJECT[FIELD.PAYMENT_TYPE].label}
                                            value={form[FIELD.PAYMENT_TYPE]}
                                            base={LEAVE_PAYMENT_TYPE}
                                            onChange={(target) =>
                                                handleFormChange({
                                                    name: FIELD.PAYMENT_TYPE,
                                                    value: target.value
                                                })
                                            }
                                            isLoading={isGettingRecord}
                                            isDisabled={!isCreate || isFieldReadOnly(FIELD.PAYMENT_TYPE)}
                                            required={!isFieldOptional(FIELD.PAYMENT_TYPE)}
                                            isOutlined
                                            disabledOutline
                                        />
                                        <Input
                                            label={FIELD_OBJECT[FIELD.START_DATE].label}
                                            type={INPUT_TYPE.DATE}
                                            name={FIELD.START_DATE}
                                            onChange={(date) => handleFormChange({ name: FIELD.START_DATE, value: date })}
                                            selected={form[FIELD.START_DATE] && new Date(form[FIELD.START_DATE])}
                                            startDate={form[FIELD.START_DATE] && new Date(form[FIELD.START_DATE])}
                                            endDate={form[FIELD.END_DATE] && new Date(form[FIELD.END_DATE])}
                                            isLoading={isGettingRecord}
                                            error={[error[FIELD.START_DATE]]}
                                            disabled={!isCreate || isFieldReadOnly(FIELD.START_DATE)}
                                            required={!isFieldOptional(FIELD.START_DATE)}
                                            selectsStart
                                            useSubTextStyle
                                        />
                                        <Input
                                            label={FIELD_OBJECT[FIELD.END_DATE].label}
                                            type={INPUT_TYPE.DATE}
                                            name={FIELD.END_DATE}
                                            onChange={(date) => handleFormChange({ name: FIELD.END_DATE, value: date })}
                                            placeholder={form[FIELD.START_DATE] ? "Whole Day" : ""}
                                            selected={!isDateSame && form[FIELD.END_DATE] && new Date(form[FIELD.END_DATE])}
                                            startDate={form[FIELD.START_DATE] && new Date(form[FIELD.START_DATE])}
                                            endDate={form[FIELD.END_DATE] && new Date(form[FIELD.END_DATE])}
                                            minDate={form[FIELD.START_DATE] && new Date(form[FIELD.START_DATE])}
                                            required={!isFieldOptional(FIELD.END_DATE) && !isDateSame}
                                            isLoading={isGettingRecord}
                                            disabled={!isCreate || isFieldReadOnly(FIELD.END_DATE)}
                                            subtext={{
                                                hide: !totalDays || !form[FIELD.START_DATE] || !form[FIELD.END_DATE],
                                                message: `Total Days: ${totalDays} Day(s)`
                                            }}
                                            useSubTextStyle
                                            selectsEnd
                                        />
                                        <Input
                                            label={FIELD_OBJECT[FIELD.PROOF_FILE].label}
                                            type={INPUT_TYPE.UPLOAD}
                                            name={FIELD.PROOF_FILE}
                                            internalIcon={<ArticleIcon />}
                                            onChange={(e) =>
                                                handleFormChange({
                                                    name: FIELD.PROOF_FILE,
                                                    value: e.target?.files?.[0]
                                                })
                                            }
                                            onView={form[FIELD.PROOF_FILE] ? () => setPreviewFileProof(true) : null}
                                            value={form[FIELD.PROOF_FILE]}
                                            accept={FILE_MIME_TYPES.PDF}
                                            sizeLimit={DEFAULT_FILE_LIMIT_SIZE}
                                            disabled={isGettingRecord || isFieldReadOnly(FIELD.PROOF_FILE)}
                                            required={!isFieldOptional(FIELD.PROOF_FILE)}
                                            haslabel
                                        />
                                    </div>
                                    <Input
                                        name={FIELD.REASON}
                                        label={FIELD_OBJECT[FIELD.REASON].label}
                                        type={INPUT_TYPE.TEXTAREA}
                                        value={form[FIELD.REASON] || ""}
                                        parentStyle={{ height: "10rem", minHeight: "5rem" }}
                                        onChange={(e) =>
                                            handleFormChange({
                                                name: FIELD.REASON,
                                                value: e.target.value
                                            })
                                        }
                                        maxLength={NOTES_MAX_LEN}
                                        disabled={isFieldReadOnly(FIELD.REASON)}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                })}
            </BaseUpdateModal>
            {!!previewFileProof && (
                <ViewFileModal
                    open={!!previewFileProof}
                    onClose={() => setPreviewFileProof(null)}
                    type={VIEW_MODAL_TYPE.LEAVE_PROOF_FILE}
                    data={{
                        id: form?.employee?.id,
                        src: proofSrc || null,
                        filename: form[FIELD.PROOF_FILE]
                    }}
                    nofetch={isFileObject(form[FIELD.PROOF_FILE])}
                />
            )}
            {submittedFormExtraModal && (
                <UpdateStatusModal
                    open={submittedFormExtraModal}
                    onClose={() => setSubmittedFormExtraModal(false)}
                    actionType={SUBMITTED_FORM_ACTION_TYPE.COMPLETE}
                    onConfirm={async (form) => {
                        const result = await handleSave();
                        if (!result.error) {
                            await onSubmittedFormUpdateStatus?.({
                                ...form,
                                leaveId: result.id
                            });
                        } else {
                            setSubmittedFormExtraModal(false);
                        }
                    }}
                />
            )}
        </>
    );
}

UpdateModal.propTypes = {
    photo: PropTypes.string,
    open: PropTypes.bool,
    id: PropTypes.any,
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func,
    saveLabel: PropTypes.string,
    fromSubmittedForm: PropTypes.shape({
        [FIELD.EMPLOYEE]: PropTypes.shape({
            id: PropTypes.number,
            first_name: PropTypes.string,
            last_name: PropTypes.string
        }),
        [FIELD.REASON]: PropTypes.string,
        [FIELD.START_DATE]: PropTypes.string,
        [FIELD.END_DATE]: PropTypes.string,
        [FIELD.LEAVE_TYPE]: PropTypes.oneOf(Object.values(LEAVE_TYPE))
    }),
    optionals: PropTypes.arrayOf(PropTypes.oneOf(Object.values(FIELD))),
    readonlyFields: PropTypes.arrayOf(PropTypes.oneOf(Object.values(FIELD))),
    onSubmittedFormUpdateStatus: PropTypes.func
};

export default UpdateModal;
