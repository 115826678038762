import React, { useState } from "react";
import { useAppSelector } from "../../common/hooks/reduxHooks";
import { selectLoading } from "../../features/company/companySites/slice";
import WorkSite from "../../features/company/companySites/WorkSite";
import PageLayout from "../../common/components/layout/PageLayout";
import { useLazyPageWorkSites } from "../../features/company/companySites/hooks";

function CompanySitesPage() {
    const [openAddModal, setOpenAddModal] = useState(false);

    const [, , { onSearch, tableConfig }] = useLazyPageWorkSites({ readOnly: true });

    const loading = useAppSelector(selectLoading);
    const handleAdd = () => setOpenAddModal(true);

    return (
        <PageLayout
            className="tk-site"
            title="Manage Work Sites"
            isLoading={loading}
            search={{ onSearch, value: tableConfig.search, placeholder: "Search title & location..." }}
            onAdd={handleAdd}
        >
            <WorkSite openAddModal={openAddModal} onAddModalClose={() => setOpenAddModal(false)} />
        </PageLayout>
    );
}

export default CompanySitesPage;
