import React, { useState } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import TableList from "../table/TableList";
import { SORT_ORDER } from "../../../utilities/const";
import Button from "../Button";
// import DoubleRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import RightIcon from "@mui/icons-material/KeyboardArrowRight";
// import DoubleLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import LeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Input, { INPUT_TYPE } from "../form/Input";
import Loader from "../Loader";
import { createConfirmAlert } from "../../../utilities/helper";

export const TRANSFER_CONTROL = {
    ALL_TO_RIGHT: 0x1,
    TO_RIGHT: 0x2,
    TO_LEFT: 0x3,
    ALL_TO_LEFT: 0x4
};

export const TRANSFER_SIDE = {
    LEFT: 0x1,
    RIGHT: 0x2
};

const createInitial = (obj = {}) => {
    return {
        unique: "id",
        headers: [],
        data: [],
        onSort: () => {},
        sort: { sortBy: "id", order: SORT_ORDER.ASC },
        onLoadMore: () => {},
        onCheckChange: () => {},
        isLoading: false,
        defaultChecked: [],
        onSearch: () => {},
        topExtra: "",
        totalCount: 0,
        isTotalRecordsLeft: false,
        search: "",
        ...obj
    };
};

function Transfer({ left, right, height, onTransfer, controls, isLoading }) {
    const leftData = createInitial(left);
    const rightData = createInitial(right);

    const [leftCheckedItems, setLeftCheckedItems] = useState(leftData.defaultChecked);
    const [rightCheckedItems, setRightCheckedItems] = useState(rightData.defaultChecked);

    const disableLeft = !rightCheckedItems.length;
    const disableRight = !leftCheckedItems.length;
    const confirmAlertAllToRight = controls?.[TRANSFER_CONTROL.ALL_TO_RIGHT]?.confirmAlert || {};
    const confirmAlertToRight = controls?.[TRANSFER_CONTROL.TO_RIGHT]?.confirmAlert || {};
    const confirmAlertToLeft = controls?.[TRANSFER_CONTROL.TO_LEFT]?.confirmAlert || {};
    const confirmAlertAllToLeft = controls?.[TRANSFER_CONTROL.ALL_TO_LEFT]?.confirmAlert || {};

    const handleTransfer = async (type) => {
        if (isLoading) {
            return Promise.resolve();
        }
        switch (type) {
            case TRANSFER_CONTROL.TO_RIGHT:
                createConfirmAlert({
                    title: confirmAlertToRight.title || "Transfer Selection to Right",
                    content: confirmAlertToRight.content || "Are you sure? This cannot be undone.",
                    onConfirm: async (close) => {
                        close();
                        await onTransfer?.(type, leftCheckedItems);
                        setLeftCheckedItems([]);
                    }
                });
                break;
            case TRANSFER_CONTROL.TO_LEFT:
                createConfirmAlert({
                    title: confirmAlertToLeft.title || "Transfer Selection to Left",
                    content: confirmAlertToLeft.content || "Are you sure? This cannot be undone.",
                    onConfirm: async (close) => {
                        close();
                        await onTransfer?.(type, rightCheckedItems);
                        setRightCheckedItems([]);
                    }
                });
                break;
            case TRANSFER_CONTROL.ALL_TO_RIGHT:
                createConfirmAlert({
                    title: confirmAlertAllToRight.title || "Transfer All to Right",
                    content: confirmAlertAllToRight.content || "Are you sure? This cannot be undone.",
                    onConfirm: async (close) => {
                        close();
                        await onTransfer?.(type);
                        setLeftCheckedItems([]);
                    }
                });
                break;
            case TRANSFER_CONTROL.ALL_TO_LEFT:
                createConfirmAlert({
                    title: confirmAlertAllToLeft.title || "Transfer All to Left",
                    content: confirmAlertAllToLeft.content || "Are you sure? This cannot be undone.",
                    onConfirm: async (close) => {
                        close();
                        await onTransfer?.(type);
                        setRightCheckedItems([]);
                    }
                });
                break;
            default:
                await onTransfer?.(type);
                break;
        }
    };

    const handleCheckChange = (val, key, keys, type, ids, removedKeys, _, transferType) => {
        if (isLoading) {
            return Promise.resolve();
        }

        let uniqueKey = "";

        if (transferType == TRANSFER_SIDE.LEFT) {
            uniqueKey = left.unique;
            let temp = { [uniqueKey]: [] };
            if (keys[uniqueKey]) {
                temp[uniqueKey] = keys[uniqueKey];
            } else {
                temp[uniqueKey] = keys;
            }
            setLeftCheckedItems(temp[uniqueKey]);
            leftData?.onCheckChange?.(val, key, keys, type, ids, removedKeys, transferType);
        }

        if (transferType == TRANSFER_SIDE.RIGHT) {
            uniqueKey = right.unique;
            let temp = { [uniqueKey]: [] };
            if (keys[uniqueKey]) {
                temp[uniqueKey] = keys[uniqueKey];
            } else {
                temp[uniqueKey] = keys;
            }
            setRightCheckedItems(temp[uniqueKey]);
            rightData?.onCheckChange?.(val, key, keys, type, ids, removedKeys, transferType);
        }
    };

    return (
        <div className="tk-transfer flex gap-1">
            <div className="tk-transfer__left flex column gap-1">
                {(leftData.topExtra || leftData.onSearch) && (
                    <div className="tk-transfer__left__top flex column gap-1">
                        {leftData.onSearch && (
                            <Input
                                type={INPUT_TYPE.SEARCH}
                                onChange={debounce(leftData.onSearch, 1000)}
                                value={leftData.search || ""}
                                parentStyle={{ height: "2rem" }}
                                disabled={isLoading}
                            />
                        )}
                        {leftData.topExtra}
                    </div>
                )}
                <TableList
                    uniqueKey={leftData.unique}
                    headers={leftData.headers}
                    data={leftData.data}
                    onSort={leftData.onSort}
                    activeSort={leftData.sort}
                    checked={{ [leftData.unique]: leftCheckedItems }}
                    onLoadMore={leftData.onLoadMore}
                    onCheckChange={(...rest) => handleCheckChange(...rest, TRANSFER_SIDE.LEFT)}
                    isLoadingMore={leftData.isLoading}
                    height={height}
                    totalCount={leftData.totalCount}
                    disableCheckbox={isLoading}
                    isLoading={isLoading}
                    isTotalRecordsLeft={leftData.isTotalRecordsLeft}
                    isFixedHeight
                    showCheckCount
                />
            </div>
            <div className="tk-transfer__controls flex column gap-1">
                {/* <Button
                    beforeExtra={<DoubleRightIcon />}
                    onClick={() => handleTransfer(TRANSFER_CONTROL.ALL_TO_RIGHT)}
                    tooltipProps={{ message: controls?.[TRANSFER_CONTROL.ALL_TO_RIGHT]?.tooltip || "Add All from Left to Right" }}
                    disabled={isLoading}
                /> */}
                <Button
                    className="primary"
                    beforeExtra={<RightIcon />}
                    onClick={() => (disableRight ? null : handleTransfer(TRANSFER_CONTROL.TO_RIGHT))}
                    disabled={disableRight || isLoading}
                    tooltipProps={{
                        message: disableRight
                            ? "Please select an item from the Left to start transfer."
                            : controls?.[TRANSFER_CONTROL.TO_RIGHT]?.tooltip || "Add Selections from Left to Right"
                    }}
                />
                <Button
                    className="primary"
                    beforeExtra={<LeftIcon />}
                    onClick={() => (disableLeft ? null : handleTransfer(TRANSFER_CONTROL.TO_LEFT))}
                    disabled={disableLeft || isLoading}
                    tooltipProps={{
                        message: disableLeft
                            ? "Please select an item from the Right to start transfer."
                            : controls?.[TRANSFER_CONTROL.TO_LEFT]?.tooltip || "Add Selections from Right to Left"
                    }}
                />
                {/* <Button
                    beforeExtra={<DoubleLeftIcon />}
                    onClick={() => handleTransfer(TRANSFER_CONTROL.ALL_TO_LEFT)}
                    tooltipProps={{ message: controls?.[TRANSFER_CONTROL.ALL_TO_LEFT]?.tooltip || "Add All from Right to Left" }}
                    disabled={isLoading}
                /> */}
                {isLoading && (
                    <div className="tk-transfer__loading flex center">
                        <Loader style={{ width: "3rem" }} relative />
                        <span className="normal-font danger-color bold">Processing, Please Wait...</span>
                    </div>
                )}
            </div>
            <div className="tk-transfer__right flex column gap-1">
                {(rightData.topExtra || rightData.onSearch) && (
                    <div className="tk-transfer__right__top flex column gap-1">
                        {rightData.onSearch && (
                            <Input
                                type={INPUT_TYPE.SEARCH}
                                onChange={debounce(rightData.onSearch, 1000)}
                                value={rightData.search || ""}
                                parentStyle={{ height: "2rem" }}
                                disabled={isLoading}
                            />
                        )}
                        {rightData.topExtra}
                    </div>
                )}
                <TableList
                    uniqueKey={rightData.unique}
                    headers={rightData.headers}
                    data={rightData.data}
                    onSort={rightData.onSort}
                    activeSort={rightData.sort}
                    checked={{ [rightData.unique]: rightCheckedItems }}
                    onLoadMore={rightData.onLoadMore}
                    onCheckChange={(...rest) => handleCheckChange(...rest, TRANSFER_SIDE.RIGHT)}
                    isLoadingMore={rightData.isLoading}
                    height={height}
                    totalCount={rightData.totalCount}
                    disableCheckbox={isLoading}
                    isLoading={isLoading}
                    isTotalRecordsLeft={rightData.isTotalRecordsLeft}
                    isFixedHeight
                    showCheckCount
                />
            </div>
        </div>
    );
}

export default Transfer;

const itemProp = PropTypes.shape({
    unique: PropTypes.string,
    headers: PropTypes.array,
    data: PropTypes.array,
    defaultChecked: PropTypes.array,
    onSort: PropTypes.func,
    sort: PropTypes.shape({
        sortBy: PropTypes.string,
        order: PropTypes.oneOf(Object.values(SORT_ORDER))
    }),
    onLoadMore: PropTypes.func,
    onCheckChange: PropTypes.func,
    isLoading: PropTypes.bool,
    isTotalRecordsLeft: PropTypes.bool,
    topExtra: PropTypes.any,
    totalCount: PropTypes.number
});

Transfer.propTypes = {
    left: itemProp,
    right: itemProp,
    height: PropTypes.number,
    onTransfer: PropTypes.func,
    isLoading: PropTypes.bool,
    controls: PropTypes.shape({
        [TRANSFER_CONTROL.ALL_TO_RIGHT]: PropTypes.shape({
            tooltip: PropTypes.any,
            confirmAlert: PropTypes.shape({
                title: PropTypes.any,
                content: PropTypes.any
            })
        }),
        [TRANSFER_CONTROL.TO_RIGHT]: PropTypes.shape({
            tooltip: PropTypes.any,
            confirmAlert: PropTypes.shape({
                title: PropTypes.any,
                content: PropTypes.any
            })
        }),
        [TRANSFER_CONTROL.TO_LEFT]: PropTypes.shape({
            tooltip: PropTypes.any,
            confirmAlert: PropTypes.shape({
                title: PropTypes.any,
                content: PropTypes.any
            })
        }),
        [TRANSFER_CONTROL.ALL_TO_LEFT]: PropTypes.shape({
            tooltip: PropTypes.any,
            confirmAlert: PropTypes.shape({
                title: PropTypes.any,
                content: PropTypes.any
            })
        })
    })
};
