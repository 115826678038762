import React from "react";
import { renderNA, sanitizeWords, toTimeWithTimeZone } from "../../../common/utilities/helper";
import TableList from "../../../common/components/extra/table/TableList";
import PropTypes from "prop-types";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";

const CreateTableHeaders = ({ timezone }) => {
    const headers = {
        TITLE: {
            key: "title",
            label: "Title",
            render: (row) => (
                <div className="flex column gap-05">
                    <div style={{ fontWeight: "bold" }}>{sanitizeWords(row.leave_type) || renderNA()}</div>
                    <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                        <span className="small-font fade">No. of Months:</span>
                        <span className="semi-bold">{row.no_of_months}</span>
                    </div>
                </div>
            )
        },
        JOINING_DATE: {
            key: "joining_date",
            label: "Re/Joining Date",
            style: { display: "flex", justifyContent: "center" },
            render: (row) => {
                const date = row.joining_date && toTimeWithTimeZone(row.joining_date, timezone);
                const startddd = date && date.format("ddd");
                const startFormat = date && date.format("MMM DD, YYYY");
                return (
                    <div className="flex semi-bold" style={{ whiteSpace: "nowrap" }}>
                        <span className="fade">{startddd}</span>&nbsp;
                        <span>{startFormat}</span>
                    </div>
                );
            }
        },
        LAST_WORKING_DATE: {
            key: "last_working_date",
            label: "Last Working Date",
            style: { display: "flex", justifyContent: "center" },
            render: (row) => {
                const date = row.joining_date && toTimeWithTimeZone(row.joining_date, timezone);
                const startddd = date && date.format("ddd");
                const startFormat = date && date.format("MMM DD, YYYY");
                return (
                    <div className="flex semi-bold" style={{ whiteSpace: "nowrap" }}>
                        <span className="fade">{startddd}</span>&nbsp;
                        <span>{startFormat}</span>
                    </div>
                );
            }
        },
        ACCUMULATED: {
            key: "accumulated_leave",
            label: "Accumulated",
            style: { display: "flex", justifyContent: "center" },
            render: (row) => (
                <div className="flex">
                    <span className="text-ellipsis">{sanitizeWords(row.accumulated.toString()) || renderNA()}</span>
                </div>
            )
        }
    };
    return { data: Object.values(headers), original: headers };
};

const StaticLeaveTable = ({ data, headExtra, footExtra, footStyle }) => {
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const { data: headers } = CreateTableHeaders({ timezone });

    return (
        <TableList
            headers={headers}
            data={data}
            height={40}
            minHeight="auto"
            headExtra={headExtra}
            footExtra={footExtra}
            footStyle={footStyle}
            small
        />
    );
};

export default StaticLeaveTable;

StaticLeaveTable.propTypes = {
    data: PropTypes.array,
    headExtra: PropTypes.any,
    footExtra: PropTypes.any,
    footStyle: PropTypes.object
};
