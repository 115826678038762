import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import isNull from "lodash/isNull";
import { TOAST_TYPE, createToast, sanitizeWords } from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectCurrent, selectTableConfig, setCurrent, setState, selectLoading, setLoading, selectCompanyFormsData, defaultConfig } from "./slice";
import {
    useCreateCompanyFormsMutation,
    useUpdateCompanyFormsMutation,
    useDeleteCompanyFormsMutation,
    useGetCompanyFormsMutation,
    useLoadAllCompanyFormsMutation,
    useGetActiveFieldsMutation,
    useValidateCompanyFormsMutation,
    useLoadAllLazyCompanyFormTemplatesMutation,
    useCheckTargetSendFormAvailabilityMutation
} from "./api";
import { FIELD_OBJECT, FILTER_TYPE, FORM_CATEGORY, FORM_CLASS, FORM_FIELD_GROUP, FORM_FIELD_TYPE, USER_LEVEL, VALIDATION_FIELD } from "./const";
import { FIELD } from "./const";
import usePaginateFetch from "../../../common/hooks/usePaginateFetch";
import { getValidationInfo, isDroppedItemsHasError, validateField } from "./helper";
import { SORT_ORDER } from "../../../common/utilities/const";
import { selectUser, updateUser } from "../../common/slice";
import useCurrentCountryGlobals from "../../../common/hooks/useCurrentCountryGlobals";
import Tooltip from "../../../common/components/extra/Tooltip";

const { NAME, TYPE, FIELDS, STATUS, CATEGORY, MIN_USER_LEVEL, APPROVAL_TYPE, APPROVAL_LEVELS, CLASS } = FIELD;

const LOAD_MORE_OFFSET = 10;
const DEFAULT_SIZE = 20;

export const useGetCompanyForm = (id, callback, useCache = true) => {
    const [isMounted, setMounted] = useState(false);
    const [fetching, setFetching] = useState(!!id);

    const [getDetails] = useGetCompanyFormsMutation();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);
    const companyFormData = useAppSelector(selectCompanyFormsData);

    const createVars = (data) => {
        if (!data) {
            return {};
        }
        return {};
    };

    const fetch = async ({ isForce } = {}) => {
        if (!id) {
            return;
        }
        try {
            if (!isForce && useCache && current && current.id === id) {
                fetching && setFetching(false);
                return current;
            }
            const result = await getDetails({ extraPath: id });
            if (result.error) {
                throw new Error("Failed to fetch data. Please try again later");
            }
            const data = result.data.data;
            dispatch(setCurrent(data));
            callback?.(data);
            return data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return {};
        } finally {
            setFetching(false);
        }
    };

    const updateCurrent = (newCurrent = {}) => {
        const newObj = !current || (current && current.id !== newCurrent.id) ? newCurrent : { ...(current || {}), ...(newCurrent || {}) };
        dispatch(
            setState({
                current: newObj,
                data: companyFormData.map((d) => (d.id == newObj.id ? { ...d, ...newObj } : d))
            })
        );
    };

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            fetch();
        }
    }, [isMounted]);

    return [current, { isLoading: fetching, config: createVars(current), update: updateCurrent, fetch }];
};

export const usePaginateCompanyForm = ({ readOnly, isHR, isEMP } = {}) => {
    const getCategory = (config) => (config.isHR ? FORM_CATEGORY.HR : config.isEMP ? FORM_CATEGORY.EMPLOYEE : "");

    const [load, isLoading, { onFilter, onSearch, onSort, data, tableConfig, resetTableConfig, onUpdate }] = usePaginateFetch(
        useLoadAllCompanyFormsMutation,
        {
            redux: {
                dataSelector: selectCompanyFormsData,
                tableConfigSelector: selectTableConfig,
                currentSelector: selectCurrent,
                setState
            },
            defaultConfig,
            onMountConfig: { filter: { [FILTER_TYPE.CATEGORY]: getCategory({ isHR, isEMP }) } },
            runOnMount: !readOnly
        }
    );

    const fetch = async (config) => {
        try {
            const body = cloneDeep(config || {});
            const newFilter = {
                ...body.filter,
                [FILTER_TYPE.CATEGORY]: getCategory(body)
            };
            delete body.isHR;
            delete body.isEMP;
            const response = await load({
                ...body,
                filter: newFilter
            });
            if (response?.error) {
                throw new Error("Failed to fetch company forms. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    const handleSearch = (value, extra = {}) =>
        onSearch(value, {
            filter: {
                ...tableConfig.filter,
                [FILTER_TYPE.CATEGORY]: getCategory(extra)
            }
        });

    const handleFilter = (newFilter, extra = {}) =>
        onFilter(
            {
                ...tableConfig.filter,
                ...newFilter,
                [FILTER_TYPE.CATEGORY]: getCategory(extra)
            },
            extra
        );

    const handleSort = (sort = {}, extra = {}) =>
        onSort(sort, {
            filter: {
                ...tableConfig.filter,
                [FILTER_TYPE.CATEGORY]: getCategory(extra)
            }
        });

    const handleResetTable = (extra = {}) =>
        resetTableConfig({
            filter: {
                ...tableConfig.filter,
                [FILTER_TYPE.CATEGORY]: getCategory(extra)
            }
        });

    return [
        data,
        {
            isLoading,
            tableConfig,
            fetch,
            update: onUpdate,
            onFilter: handleFilter,
            onSearch: handleSearch,
            onSort: handleSort,
            resetTableConfig: handleResetTable
        }
    ];
};

export const useUpsertCompanyForm = (updateId, callback) => {
    const isCreate = !updateId;

    const [old, setOld] = useState(null);
    const [form, setForm] = useState({
        [NAME]: FIELD_OBJECT[NAME].default,
        [TYPE]: FIELD_OBJECT[TYPE].default,
        [CLASS]: FIELD_OBJECT[CLASS].default,
        [FIELDS]: FIELD_OBJECT[FIELDS].default,
        [CATEGORY]: FIELD_OBJECT[CATEGORY].default,
        [MIN_USER_LEVEL]: FIELD_OBJECT[MIN_USER_LEVEL].default,
        [APPROVAL_LEVELS]: FIELD_OBJECT[APPROVAL_LEVELS].default,
        [STATUS]: FIELD_OBJECT[STATUS].default
    });

    const [data, { isLoading: isGettingForm }] = useGetCompanyForm(updateId, callback);

    const [create, { isLoading: createIsLoading }] = useCreateCompanyFormsMutation();
    const [update, { isLoading: updateIsLoading }] = useUpdateCompanyFormsMutation();

    const countryGlobals = useCurrentCountryGlobals();

    const isLeaveClass = form[CLASS] == FORM_CLASS.LEAVE_FORM;

    const user = useAppSelector(selectUser);
    const dispatch = useAppDispatch();

    const createVars = () => {
        return {
            isLoading: isGettingForm || createIsLoading || updateIsLoading
        };
    };

    const upsert = async (newForm) => {
        let result = null;
        try {
            const clonedform = cloneDeep(newForm);

            clonedform.fields = clonedform.fields.map((field) => {
                delete field.data.sortable;
                const fieldGroup = field.data.group;
                const fieldForAdmin = field.data.validation[VALIDATION_FIELD.FOR_ADMIN];

                if (field.data.type == FORM_FIELD_TYPE.GROUP) {
                    field.data.validation.value = field.data.validation.value.map((f) => {
                        const fgroup = f.data.group;
                        const forAdmin = f.data.validation[VALIDATION_FIELD.FOR_ADMIN];
                        if (!forAdmin && fgroup != FORM_FIELD_GROUP.EMPLOYEE_DETAILS && fgroup != FORM_FIELD_GROUP.WORK_DETAILS) {
                            delete f.data.validation[VALIDATION_FIELD.IS_BOLD];
                            delete f.data.validation[VALIDATION_FIELD.IS_COLUMN];
                        }
                        return f;
                    });
                } else {
                    if (!fieldForAdmin && fieldGroup != FORM_FIELD_GROUP.EMPLOYEE_DETAILS && fieldGroup != FORM_FIELD_GROUP.WORK_DETAILS) {
                        delete field.data.validation[VALIDATION_FIELD.IS_BOLD];
                        delete field.data.validation[VALIDATION_FIELD.IS_COLUMN];
                    }
                }
                if (!field.error) {
                    delete field.error;
                }

                if (isLeaveClass && field.data.type == FORM_FIELD_TYPE.DROPDOWN && field.data.validation.dbName == "leave_type") {
                    field.data.validation.value = countryGlobals.leaveTypesOptions.map((option) => ({
                        value: option,
                        label: sanitizeWords(option)
                    }));
                }

                return field;
            });

            if (isCreate) {
                result = await create({ body: clonedform });
            } else {
                result = await update({ body: clonedform, extraPath: updateId });
            }
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            // update user to remove form warning message
            if ((user.missingRequiredFormClass || []).includes(clonedform[CLASS])) {
                dispatch(
                    updateUser({
                        missingRequiredFormClass: (user.missingRequiredFormClass || []).filter((mrc) => mrc != clonedform[CLASS])
                    })
                );
            }
            if (result.data) {
                if (result.data?.data) {
                    createToast(`Company Form ${isCreate ? "created" : "updated"} succesfully.`, TOAST_TYPE.SUCCESS);
                } else {
                    createToast(result.data.message, TOAST_TYPE.SUCCESS);
                }
            }
            return result.data.data;
        } catch (error) {
            createToast(
                `Failed to ${!isCreate ? "update" : "create"} Company Form. ${error?.message || "Please try again later or contact support."} `,
                TOAST_TYPE.ERROR
            );
            return { error };
        }
    };

    const updateForm = (config = {}) => setForm({ ...form, ...config });

    useEffect(() => {
        if (!isCreate && !isGettingForm) {
            const temp = {
                [NAME]: data?.[NAME],
                [TYPE]: data?.[TYPE],
                [CLASS]: data?.[CLASS],
                [FIELDS]: data?.[FIELDS],
                [CATEGORY]: data?.[CATEGORY],
                [MIN_USER_LEVEL]: data?.[MIN_USER_LEVEL] || USER_LEVEL.ZERO,
                [APPROVAL_TYPE]: data?.[APPROVAL_TYPE],
                [APPROVAL_LEVELS]: data?.[APPROVAL_LEVELS] || [],
                [STATUS]: data?.[STATUS]
            };
            setForm(temp);
            setOld(temp);
        }
    }, [data, updateId, isGettingForm]);

    const vars = createVars();

    return [
        form,
        updateForm,
        {
            upsert,
            isGettingForm,
            isUpserting: createIsLoading || updateIsLoading,
            isLoading: vars.isLoading,
            config: vars,
            old,
            hasChangesToBaseForm: !!(old && !isCreate && !isEqual(JSON.stringify(form), JSON.stringify(old)))
        }
    ];
};

export const useDeleteCompanyForm = () => {
    const dispatch = useAppDispatch();

    const [deleteCompanyForm] = useDeleteCompanyFormsMutation();

    const isLoading = useAppSelector(selectLoading);

    const remove = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await deleteCompanyForm({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to delete company form.");
            }
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [remove, isLoading];
};

export const useGetActiveFields = () => {
    const [fetching, setFetching] = useState(true);
    const [load] = useGetActiveFieldsMutation();
    const [fields, setFields] = useState([]);
    const [searchFields, setSearchFields] = useState([]);

    const fetch = async () => {
        if (!fetching) {
            setFetching(true);
        }
        try {
            const response = await load();
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to load fields.");
            }
            const data = response.data.data;
            setFields(data);
            setSearchFields(data);
            return data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        if (!fields.length) {
            fetch();
        }
    }, []);

    const fieldsToGroup = () => {
        return searchFields.reduce(
            (prev, current) => ({
                ...prev,
                [current.group]: [...(prev?.[current.group] || [])].concat(current)
            }),
            {}
        );
    };

    const onSearch = (value) => {
        setSearchFields(fields.filter((field) => field.name.includes(value.toLowerCase().trim())));
    };

    return [fieldsToGroup(), fetching, { refetch: fetch, onSearch, original: fields }];
};

export const useValidateCompanyForms = () => {
    const [validating, setValidating] = useState(true);
    const [isValid, setValid] = useState(false);

    const [validateForm] = useValidateCompanyFormsMutation();

    const params = useParams();
    const updateId = params.id;
    const location = useLocation();
    const current = useAppSelector(selectCurrent);
    const isUpdateRoute = location.pathname.includes("/update");

    const checkValidity = async () => {
        if (!updateId || !!current || !isUpdateRoute) {
            setValidating(false);
            if (current) {
                setValid(true);
            }
            return;
        }
        if (!validating) {
            setValidating(true);
        }
        try {
            const response = await validateForm({ extraPath: updateId });
            const data = response.data.data;
            if (response.error) {
                setValid(false);
                return false;
            }
            setValid(data.isValid);
            return data.isValid;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            setValidating(false);
        }
    };

    useEffect(() => {
        checkValidity();
    }, []);

    return [isUpdateRoute ? isValid : true, validating];
};

export const useManageValidationInfo = (data = {}) => {
    return getValidationInfo(data);
};

export const useValidateDrops = (droppedItems = []) => {
    const validatedDroppedItems = useMemo(() => {
        let temp = {
            error: null,
            newDroppedItems: cloneDeep(droppedItems),
            hasDropError: false
        };

        if (!temp.newDroppedItems.length) {
            temp.error = "At least one element is required";
        }

        temp.newDroppedItems = temp.newDroppedItems.map((item) => {
            const itemData = item.data;
            const fieldObj = validateField(itemData);
            if (fieldObj.error) {
                item.error = "Error: Invalid field, doesnt meet the requirements";
            }
            return item;
        });

        if (isDroppedItemsHasError(temp.newDroppedItems)) {
            temp.error = "Please satisfy all the required fields";
            temp.hasDropError = true;
        }

        return temp;
    }, [droppedItems]);

    return validatedDroppedItems;
};

export const useLazyFormTemplates = ({ initializing, isReadableSelected, onMount = () => {}, defaultValue, filterByClass } = {}) => {
    const [fetching, setFetching] = useState(true);
    const [isMounted, setMounted] = useState(false);

    const [object, setObject] = useState({
        data: [],
        sort: { sortBy: "name", order: SORT_ORDER.ASC },
        totalCount: 0,
        cursor: "",
        search: ""
    });

    const hasMore = object.totalCount > object.data?.length || 0;

    const [load, { isLoading }] = useLoadAllLazyCompanyFormTemplatesMutation();

    const updateObject = (newObject = {}) => setObject((prev) => ({ ...prev, ...newObject }));

    const createFixedValuesFromDefault = () =>
        Array.isArray(defaultValue)
            ? defaultValue
                  .filter(() => {
                      return isReadableSelected;
                  })
                  .map((item) => item.id)
            : [];

    const createRowItem = (row) => {
        const temp = cloneDeep(row || {});
        if (!temp.id) {
            return null;
        }
        const title = sanitizeWords(temp.name);
        temp.value = temp.id;
        temp.label = (
            <div className="flex gap-05 align-center overflow-hidden small-font" style={{ minHeight: "1.5rem" }}>
                <Tooltip element="span" className="text-ellipsis semi-bold" message={title}>
                    {title}
                </Tooltip>
            </div>
        );
        for (const key in temp) {
            if (Object.prototype.hasOwnProperty.call(temp, key)) {
                const value = temp[key];
                if (isNull(value)) {
                    temp[key] = "";
                }
            }
        }
        return temp;
    };

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        if (!sort) {
            sort = object.sort;
        }
        if (isReset) {
            config.cursor = "";
        }
        try {
            const response = await load({
                body: {
                    pageSize: DEFAULT_SIZE,
                    more: isReset ? DEFAULT_SIZE : LOAD_MORE_OFFSET,
                    filter: { [FILTER_TYPE.CLASS]: filterByClass },
                    ...object.sort,
                    ...sort,
                    ...(config || {})
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data || [];
                const newdata = resdata.data.map(createRowItem);
                const sameCursor = isEqual(object.cursor, resdata.cursor);
                const temp = {
                    data: isReset ? newdata : !sameCursor ? object.data.concat(newdata) : object.data,
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount
                };
                sort && (temp.sort = sort);
                updateObject(temp);
                return temp.data;
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            sort && updateObject({ sort, data: [], totalCount: 0 });
        }
    };

    const loadMore = () => hasMore && fetch({ cursor: object.cursor });
    const reset = () => fetch({}, true);
    const search = (value = "") => fetch({ search: value }, true);
    const sort = ({ sortBy, order }) => fetch({ sort: { sortBy, order } }, true);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        isMounted &&
            !initializing &&
            reset()
                .then(onMount)
                .catch(() => {})
                .finally(() => setFetching(false));
    }, [isMounted, initializing]);

    useEffect(() => {
        reset().then(onMount);
    }, [filterByClass]);

    return [
        object,
        updateObject,
        {
            initializing: fetching,
            isLoading,
            hasMore,
            fetch,
            reset,
            loadMore,
            search,
            onSort: sort,
            createRowItem,
            fixedValues: createFixedValuesFromDefault()
        }
    ];
};

export const useCheckAvailability = () => {
    // use to prevent fetching if already called
    const [alreadyCheckedAvailabilities, setAlreadyCheckedAvailabilities] = useState({});

    const [checkAvailability, { isLoading }] = useCheckTargetSendFormAvailabilityMutation();

    const createCacheKey = (formId, targetEmployeeId) => `${formId}-${targetEmployeeId}`;

    const upsertAlreadyCheckedAvailabilities = (id, value) => {
        setAlreadyCheckedAvailabilities((prev) => {
            const cloned = cloneDeep(prev);
            cloned[id] = value;
            return cloned;
        });
    };

    const fetch = async (formId, targetEmployeeId, force) => {
        try {
            if (!force && createCacheKey(formId, targetEmployeeId) in alreadyCheckedAvailabilities) {
                return alreadyCheckedAvailabilities[createCacheKey(formId, targetEmployeeId)];
            }
            const result = await checkAvailability({
                body: { targetEmployeeId, formId }
            });
            if (result.error) {
                throw new Error("Failed to check availability. Please try again later");
            }
            upsertAlreadyCheckedAvailabilities(createCacheKey(formId, targetEmployeeId), result.data.data);
            return result.data.data;
        } catch (error) {
            upsertAlreadyCheckedAvailabilities(createCacheKey(formId, targetEmployeeId), { error });
            return { error };
        }
    };

    return [fetch, isLoading];
};
