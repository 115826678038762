import React, { useState } from "react";
import PropTypes from "prop-types";
import FormSection from "../../../common/components/extra/FormSection";
import Input from "../../../common/components/extra/form/Input";
import { useGetCompanyHolidays } from "./hooks";
import BaseViewModal from "../../../common/components/layout/modalViewUpdateLayout/BaseViewModal";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import {
    createClass,
    renderNA,
    sanitizeTimestamp,
    sanitizeWords,
    toProperTimezoneConversion,
    toReadableFromDate
} from "../../../common/utilities/helper";
import { BASE_CLASS, COMPANY_HOLIDAY_LEVEL } from "./const";
import Button from "../../../common/components/extra/Button";
import Divider from "../../../common/components/extra/Divider";
import ViewGeozonesModal from "../companySites/ViewGeozonesModal";
import ViewWorkDetailsModal from "../employeeWorkDetails/ViewWorkDetailsModal";
import ViewWorkShiftsModal from "../employeeWorkShift/ViewWorkShiftsModal";
import { isDateRangeSame } from "./helper";

function ViewModal({ open, onClose, onEdit, id }) {
    const [viewObject, setViewObject] = useState({ type: null, data: null });

    const [current, { isLoading, config }] = useGetCompanyHolidays(id);

    const user = useAppSelector(selectUser);
    const timezone = user.Setting.timezone;
    const showWorkTypes = viewObject.type === COMPANY_HOLIDAY_LEVEL.BY_WORK_TYPE;
    const showWorkShifts = viewObject.type === COMPANY_HOLIDAY_LEVEL.BY_WORK_SHIFT;
    const showWorkSites = viewObject.type === COMPANY_HOLIDAY_LEVEL.BY_WORK_SITE;

    const isDateSame = isDateRangeSame(current?.start_date, current?.end_date, timezone);

    const COMMON_PROPS = { readOnly: true, isLoading };

    const handleViewChange = (newObject = {}) => setViewObject({ ...viewObject, ...newObject });

    const renderAffected = () => {
        const renderViewButton = (type, affectedData) => (
            <div>
                <Button
                    options={{ style: { textAlign: "right", paddingRight: 0, textDecoration: "underline" } }}
                    onClick={() => handleViewChange({ type, data: affectedData })}
                    transparent
                    small
                >
                    View
                </Button>
            </div>
        );

        switch (current?.level) {
            case COMPANY_HOLIDAY_LEVEL.BY_WORK_SHIFT:
                return (
                    <Input
                        label="Affected Work Shift"
                        renderValue={renderViewButton(current?.level, current?.work_shift_ids || [])}
                        {...COMMON_PROPS}
                    />
                );
            case COMPANY_HOLIDAY_LEVEL.BY_WORK_TYPE:
                return (
                    <Input
                        label="Affected Work Types"
                        renderValue={renderViewButton(current?.level, current?.work_type_ids || [])}
                        {...COMMON_PROPS}
                    />
                );
            case COMPANY_HOLIDAY_LEVEL.BY_WORK_SITE:
                return (
                    <Input
                        label=" Affected Work Sites"
                        renderValue={renderViewButton(current?.level, current?.work_site_ids || [])}
                        {...COMMON_PROPS}
                    />
                );
            default:
                return "";
        }
    };

    return (
        <BaseViewModal open={open} onClose={onClose} onEdit={onEdit} disableEdit={!current}>
            <div className={createClass("__modal-content-view flex column gap-1", BASE_CLASS)}>
                <FormSection header="Holiday Details">
                    <Input label="Name" renderValue={sanitizeWords(current?.name)} {...COMMON_PROPS} />
                    <Input
                        label={!isDateSame ? "Start Date" : "Date"}
                        renderValue={toProperTimezoneConversion(current?.start_date, timezone)?.format?.("MMM DD, YYYY")}
                        {...COMMON_PROPS}
                    />
                    {!isDateSame && (
                        <Input
                            label="End Date"
                            renderValue={toProperTimezoneConversion(current?.end_date, timezone)?.format?.("MMM DD, YYYY")}
                            {...COMMON_PROPS}
                        />
                    )}
                    <Input label="Is Yearly?" renderValue={current?.is_yearly ? "Yes" : "No"} {...COMMON_PROPS} />
                    <Input label="Type" renderValue={sanitizeWords(current?.type)} {...COMMON_PROPS} />
                    <Input label="Level" renderValue={sanitizeWords(current?.level)} {...COMMON_PROPS} />
                    <Input label="Category" renderValue={sanitizeWords(current?.category)} {...COMMON_PROPS} />
                    {renderAffected()}
                    <Input
                        label="Note"
                        renderValue={<p style={{ marginLeft: "2rem" }}>{current && sanitizeWords(current.notes)}</p>}
                        {...COMMON_PROPS}
                    />
                </FormSection>
                {config.workShift && (
                    <FormSection header="Work Shift">
                        <Input label="Title" renderValue={config?.workShift?.title} {...COMMON_PROPS} />
                        <Input label="Max OT Hours" renderValue={config?.workShift?.maxOT} afterExtra={renderNA("Hour(s)")} {...COMMON_PROPS} />
                        <Input label="Max Break Hours" renderValue={config?.workShift?.maxBreak} afterExtra={renderNA("Hour(s)")} {...COMMON_PROPS} />
                        <Input
                            label="Required Shift"
                            renderValue={config?.workShift?.requiredShiftTime}
                            afterExtra={renderNA("Hour(s)")}
                            parentStyle={
                                config.workShift.isSplit
                                    ? {
                                          borderBottom: "unset"
                                      }
                                    : {}
                            }
                            {...COMMON_PROPS}
                        />
                        {config.workShift.isSplit ? (
                            <>
                                <div className="flex column" style={{ marginTop: "1rem" }}>
                                    <Divider title="First Shift" />
                                    <Input label="Time" renderValue={config?.workShift?.shiftOne} {...COMMON_PROPS} />
                                    <Input
                                        label="Break"
                                        renderValue={config?.workShift?.breakOne}
                                        parentStyle={{ borderBottom: "unset" }}
                                        {...COMMON_PROPS}
                                    />
                                </div>
                                <div className="flex column" style={{ marginTop: "1rem" }}>
                                    <Divider title="Second Shift" />
                                    <Input label="Time" renderValue={config?.workShift?.shiftTwo} {...COMMON_PROPS} />
                                    <Input
                                        label="Break"
                                        renderValue={config?.workShift?.breakTwo}
                                        parentStyle={{ borderBottom: "unset" }}
                                        {...COMMON_PROPS}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <Input label="Time" renderValue={config?.workShift?.shiftOne} {...COMMON_PROPS} />
                                <Input label="Break" renderValue={config?.workShift?.breakOne} {...COMMON_PROPS} />
                            </>
                        )}
                    </FormSection>
                )}
                <FormSection header="Record Details">
                    <Input label="Created" renderValue={current && toReadableFromDate(current?.createdAt, timezone)} {...COMMON_PROPS} />
                    <Input label="Updated" renderValue={sanitizeTimestamp(current?.updatedAt, current?.createdAt, timezone)} {...COMMON_PROPS} />
                </FormSection>
            </div>
            {!!showWorkSites && (
                <ViewGeozonesModal
                    open={showWorkSites}
                    onChange={(bool) => handleViewChange({ type: bool ? COMPANY_HOLIDAY_LEVEL.BY_WORK_SITE : null })}
                    data={viewObject.data.map((d) => d.id)}
                />
            )}
            {!!showWorkTypes && (
                <ViewWorkDetailsModal
                    open={showWorkTypes}
                    onChange={(bool) => handleViewChange({ type: bool ? COMPANY_HOLIDAY_LEVEL.BY_WORK_TYPE : null })}
                    data={viewObject.data.map((d) => d.id)}
                />
            )}
            {!!showWorkShifts && (
                <ViewWorkShiftsModal
                    open={!!showWorkShifts}
                    onChange={(bool) => handleViewChange({ type: bool ? COMPANY_HOLIDAY_LEVEL.BY_WORK_SHIFT : null })}
                    data={viewObject.data.map((d) => d.id)}
                />
            )}
        </BaseViewModal>
    );
}

ViewModal.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.any,
    onClose: PropTypes.func,
    onEdit: PropTypes.func
};

export default ViewModal;
