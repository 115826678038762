import { useMemo, useState } from "react";
import isObject from "lodash/isObject";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import isEqual from "lodash/isEqual";

export const useSelectManageInfoLazy = ({ isMulti, value, data, createRowItem, isLoading, onChange }) => {
    const [cachedValue, setCachedValue] = useState(null);

    const isValidOption = (item) => item !== "" && !isNull(item) && !isUndefined(item);

    const newValue = useMemo(() => {
        const hasValue =
            !!value &&
            (Array.isArray(value)
                ? !!value.filter((val) => {
                      if ("value" in val) {
                          return isValidOption(val.value);
                      } else if ("id" in val) {
                          return isValidOption(val.id);
                      }
                  }).length
                : isObject(value) && "value" in value
                  ? isValidOption(value.value)
                  : isValidOption(value.id));
        let temp =
            value &&
            (Array.isArray(value)
                ? (data || []).filter((item) => value.some((option) => isEqual(option.value, item.value)))
                : value?.id && data.find((item) => isEqual(item.value, value.value)));
        if (hasValue) {
            if (Array.isArray(cachedValue || value)) {
                temp = (cachedValue || value || []).map((item) => createRowItem(item)).filter(Boolean);
            } else {
                temp = createRowItem(cachedValue || value);
            }
        }
        return temp;
    }, [value, isMulti, cachedValue, data]);

    const handleChange = (value, type) => {
        onChange?.(value, type);
        setCachedValue(value);
    };

    return [(!isLoading && newValue) || null, handleChange];
};

export default useSelectManageInfoLazy;
