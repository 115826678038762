import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { createConfirmAlert, createFullName, createGroup, sanitizeWords, toTimeWithTimeZone } from "../../../common/utilities/helper";
import { selectUserSetting } from "../../common/slice";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import Button from "../../../common/components/extra/Button";
import Loader from "../../../common/components/extra/Loader";
import { useUpsertWorkHistory } from "./hooks";
import { BASE_CLASS, EDIT_TYPE, FIELDS, TIME_TYPE } from "./const";
import FormUpdateInputs from "./FormUpdateInputs";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";
import Tag from "../../../common/components/extra/Tag";
import { WORK_HISTORY_TYPE } from "../../../common/utilities/const";
import Select from "../../../common/components/extra/select/Select";
import { COMPANY_HOLIDAY_TYPE } from "../companyHolidays/const";
import EmployeeSelectLazy from "../employees/EmployeeSelectLazy";
import UpdateStatusModal from "../employeeSubmittedForms/UpdateStatusModal";
import { SUBMITTED_FORM_ACTION_TYPE } from "../employeeSubmittedForms/const";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";

const {
    START_SHIFT_TIME,
    END_SHIFT_TIME,
    START_SHIFT_TIME_TWO,
    END_SHIFT_TIME_TWO,
    START_BREAK_TIME,
    END_BREAK_TIME,
    START_BREAK_TIME_TWO,
    END_BREAK_TIME_TWO,
    START_OVERTIME,
    END_OVERTIME,
    EMPLOYEE_ID,
    WORK_TYPE_ID,
    WORK_SHIFT_ID,
    SITE_ONE_ID,
    SITE_TWO_ID,
    TYPE
} = FIELDS;

function UpdateModal({
    siteId,
    open,
    onClose,
    onBack,
    onFinish,
    data = {},
    newDate,
    editType = EDIT_TYPE.DEFAULT,
    noSuccessMessage,
    employee,
    onExistingRecordClick,
    saveLabel,
    // submitted form props
    onSubmittedFormUpdateStatus,
    submittedFormId
}) {
    const isCreate = !data;

    const [date, setDate] = useState(newDate);
    const [submittedFormExtraModal, setSubmittedFormExtraModal] = useState(false);
    const [error, setError] = useState("");

    const [form, updateForm, { upsert, config, hasChanges }] = useUpsertWorkHistory(data, date, noSuccessMessage, employee, editType);

    const setting = useAppSelector(selectUserSetting);
    const record = config.record;
    const holiday = config.holiday?.value;
    const disableDateField = !isCreate || !!newDate;
    const isGettingRecord = record.isLoading;
    const hasNoDateChanges = record.oldDate && isEqual(date, record.oldDate);
    const showRevert = !newDate && !!(!hasNoDateChanges && record.oldDate);
    const disableFields = isGettingRecord || showRevert;
    const hasRecord = !!record?.record;
    const disableButtons = !date || isGettingRecord || !!hasNoDateChanges || !form[EMPLOYEE_ID.name];
    const showInputs = record.isFetched;
    const getHolidayInfo = config.getHolidayInfo;
    const getUpdateRecord = config.getUpdateRecord;
    const setHoliday = config.setHoliday;
    const isAlreadyExisting = !!record.hasExistingRecord && !!date;

    const isReadOnly = !isCreate && editType == EDIT_TYPE.READ_ONLY;
    const isOTOffDayEditType = editType == EDIT_TYPE.OT_OFF_DAY;
    const isTimingEditType = editType == EDIT_TYPE.TIMING;
    const isOTEditType = editType == EDIT_TYPE.OVERTIME;
    const isDefault = editType == EDIT_TYPE.DEFAULT;

    const disableSave =
        isGettingRecord || !hasRecord || (!isCreate && !hasChanges) || (!isCreate && !record.isFetched) || isReadOnly || isAlreadyExisting || error;

    const trackHoliday = useMemo(() => {
        return getHolidayInfo({
            workTypeId: form[WORK_TYPE_ID.name]?.id,
            siteOneId: form[SITE_ONE_ID.name]?.id,
            siteTwoId: form[SITE_TWO_ID.name]?.id,
            workShiftId: form[WORK_SHIFT_ID.name]?.id
        });
    }, [holiday?.id, form[WORK_TYPE_ID.name]?.id, form[WORK_SHIFT_ID.name]?.id, form[SITE_ONE_ID.name]?.id, form[SITE_TWO_ID.name]?.id]);

    const handleSave = async () => {
        const result = await upsert(trackHoliday, { submittedFormId });
        if (!result.error) {
            if (error) {
                setError("");
            }
            typeof onFinish === "function" && onFinish(result);
        } else {
            setError(result.error.message);
        }
        return result;
    };

    const handleFormChange = async ({ name, value } = {}) => {
        const temp = {
            [name]: value
        };
        switch (name) {
            case EMPLOYEE_ID.name: {
                temp[name] = {
                    id: value.id,
                    first_name: value.first_name,
                    last_name: value.last_name,
                    residenceID: value.residenceID,
                    department: value.CompanyDepartment.title,
                    designation: value.CompanyDesignation.title,
                    full_name: `${value.first_name} ${value.last_name}`
                };
                await handleApplyChange(temp);
                break;
            }
            case WORK_SHIFT_ID.name: {
                temp[name] = {
                    id: value.id,
                    title: value.title,
                    end_time: value.end_time,
                    break_time: value.break_time,
                    end_time_2: value.end_time_2,
                    is_holiday: value.is_holiday,
                    shift_type: value.shift_type,
                    start_time: value.start_time,
                    break_time_2: value.break_time_2,
                    max_overtime: value.max_overtime,
                    start_time_2: value.start_time_2,
                    break_end_time: value.break_end_time,
                    break_end_time_2: value.break_end_time_2,
                    max_break_duration: value.max_break_duration,
                    required_shift_time: value.required_shift_time
                };
                break;
            }
            case WORK_TYPE_ID.name: {
                temp[name] = {
                    id: value.id,
                    code: value.code,
                    name: value.name,
                    type: value.type
                };
                break;
            }
            case SITE_ONE_ID.name:
            case SITE_TWO_ID.name: {
                temp[name] = {
                    id: value.id,
                    title: value.title,
                    coordinates: value.coordinates
                };
                break;
            }
            default:
                temp[name] = value;
                break;
        }

        if (error) {
            setError("");
        }

        updateForm(temp);
    };

    const handleTimeChange = (key, conf = {}, event) => {
        const temp = {};
        const start = conf.start;
        const end = conf.end;

        const clearBreakEvents = ["changing-time-one", "changing-time-two", "cleared-time-one", "cleared-time-two"];

        if (error) {
            setError("");
        }

        switch (key) {
            case TIME_TYPE.SHIFT_ONE: {
                temp[START_SHIFT_TIME.name] = start;
                temp[END_SHIFT_TIME.name] = end;
                const isEndBeingCleared = form[END_SHIFT_TIME.name] && !end;

                // clear break
                if (clearBreakEvents.includes(event) || isEndBeingCleared) {
                    temp[START_BREAK_TIME.name] = null;
                    temp[END_BREAK_TIME.name] = null;
                }
                // clear shift two
                if (event == "changing-time-two" || event == "cleared-time-two" || isEndBeingCleared) {
                    temp[START_SHIFT_TIME_TWO.name] = null;
                    temp[END_SHIFT_TIME_TWO.name] = null;
                    temp[START_OVERTIME.name] = null;
                    temp[END_OVERTIME.name] = null;
                }
                break;
            }
            case TIME_TYPE.SHIFT_TWO: {
                temp[START_SHIFT_TIME_TWO.name] = start;
                temp[END_SHIFT_TIME_TWO.name] = end;
                const isEndBeingCleared = form[END_SHIFT_TIME_TWO.name] && !end;

                // clear break
                if (clearBreakEvents.includes(event) || isEndBeingCleared) {
                    temp[START_BREAK_TIME_TWO.name] = null;
                    temp[END_BREAK_TIME_TWO.name] = null;
                }
                // clear overtime
                if (event == "changing-time-two" || event == "cleared-time-two" || isEndBeingCleared) {
                    temp[START_OVERTIME.name] = null;
                    temp[END_OVERTIME.name] = null;
                }
                break;
            }
            case TIME_TYPE.BREAK_ONE: {
                temp[START_BREAK_TIME.name] = start;
                temp[END_BREAK_TIME.name] = end;
                break;
            }
            case TIME_TYPE.BREAK_TWO: {
                temp[START_BREAK_TIME_TWO.name] = start;
                temp[END_BREAK_TIME_TWO.name] = end;
                break;
            }
            case TIME_TYPE.OVERTIME: {
                temp[START_OVERTIME.name] = start;
                temp[END_OVERTIME.name] = end;
                break;
            }
            default:
                break;
        }

        updateForm(temp);
    };

    const handleGetUpdateRecord = async (start, obj = {}) =>
        getUpdateRecord(start, obj[EMPLOYEE_ID.name] || form[EMPLOYEE_ID.name] || employee, isCreate);

    useEffect(() => {
        const start = data?.start_shift_time;
        if (start && !isCreate) {
            setDate(start && toTimeWithTimeZone(start, setting.timezone).format("YYYY-MM-DD"));
            handleGetUpdateRecord(start);
        }
    }, []);

    useEffect(() => {
        if (newDate) {
            setDate(newDate && toTimeWithTimeZone(newDate, setting.timezone).format("YYYY-MM-DD"));
            handleGetUpdateRecord(newDate);
            if (isOTOffDayEditType) {
                updateForm({ [FIELDS.TYPE.name]: WORK_HISTORY_TYPE.OT_OFF_DAY });
            } else if (isTimingEditType) {
                updateForm({ [FIELDS.TYPE.name]: WORK_HISTORY_TYPE.NORMAL });
            } else if (isOTEditType) {
                updateForm({
                    [FIELDS.OVERTIME_SITE_ID]: { id: siteId },
                    [FIELDS.TYPE.name]: WORK_HISTORY_TYPE.NORMAL
                });
            }
        }
    }, [newDate, editType, siteId]);

    useEffect(() => {
        if (
            form[TYPE.name] !== WORK_HISTORY_TYPE.OT_OFF_DAY &&
            (trackHoliday?.isAffected || trackHoliday?.isAffectedAll) &&
            trackHoliday.type == COMPANY_HOLIDAY_TYPE.OFF
        ) {
            updateForm({ [TYPE.name]: WORK_HISTORY_TYPE.OT_OFF_DAY });
        }
    }, [trackHoliday?.isOff, trackHoliday?.isAffected]);

    const handleExistingRecordClick = () => {
        const isAlreadyExisting = !!record.hasExistingRecord;
        if (isAlreadyExisting) {
            onExistingRecordClick?.({ isAlreadyExisting, id: record.id, date: record.date });
        }
    };

    const handleApplyChange = (newObj = {}) =>
        date &&
        form[EMPLOYEE_ID.name] &&
        handleGetUpdateRecord(toTimeWithTimeZone(date, setting.timezone).format("YYYY-MM-DD"), { [EMPLOYEE_ID.name]: newObj[EMPLOYEE_ID.name] });

    const renderEmployeeSelectLazy = (
        <EmployeeSelectLazy
            label="Employee"
            value={form[EMPLOYEE_ID.name]}
            onChange={(target) => handleFormChange({ name: EMPLOYEE_ID.name, value: target })}
            isDisabled={disableFields || !isCreate || !!employee}
            required
            allowOnShift
        />
    );

    return (
        <>
            <BaseUpdateModal
                open={open}
                onClose={onClose}
                onBack={(!isCreate && onBack) || null}
                onSave={() =>
                    !disableSave &&
                    (isDefault
                        ? createConfirmAlert({
                              title: !isCreate ? "Update Record" : "Create Record",
                              content: `Are you sure you want to ${isCreate ? "create" : "update"} this record? This cannot be undone.`,
                              onConfirm: async (close) => {
                                  close();
                                  const result = await handleSave();
                                  if (!result?.error) {
                                      onBack?.();
                                  }
                              }
                          })
                        : setSubmittedFormExtraModal(true))
                }
                disableSave={!!disableSave}
                saveLabel={saveLabel}
                isForm
            >
                {error && (
                    <SectionCollapseError style={{ marginBottom: "1rem" }} show alwaysOpen>
                        <span style={{ maxWidth: "20rem" }}>{error}</span>
                    </SectionCollapseError>
                )}
                {createGroup({
                    base: BASE_CLASS,
                    title: isCreate ? (
                        "Create History Record"
                    ) : (
                        <span>
                            <span className="fade">Update Record</span>
                            {(data?.employee || employee) &&
                                ` - ${createFullName((data?.employee || employee)?.first_name, (data?.employee || employee)?.last_name)}`}
                        </span>
                    ),
                    body: (
                        <>
                            <div className="flex column gap-05 w100">
                                <span className="fade small-font bold" style={{ color: "red", marginTop: ".3rem" }}>
                                    {isCreate
                                        ? showRevert
                                            ? "New date has been selected. Please click 'Change' to generate the latest records."
                                            : !date && "Please select a date and an employee then click 'Apply' to generate the necessary records."
                                        : "The selections below are based on the start date of the record."}
                                </span>
                                {isCreate && renderEmployeeSelectLazy}
                                <div className="flex">
                                    {isCreate ? (
                                        <Input
                                            name="date"
                                            label="Date"
                                            type={INPUT_TYPE.DATE}
                                            onChange={(ndate) => setDate(ndate)}
                                            selected={date && new Date(date)}
                                            disabled={disableDateField}
                                            wrapperStyle={{ width: "100%" }}
                                            noCurrent
                                            noFuture
                                            required
                                        />
                                    ) : (
                                        <Input label="Date" renderValue={date} wrapperStyle={{ width: "100%" }} readOnly />
                                    )}
                                    <div className="flex gap-1" style={{ marginLeft: "1rem" }}>
                                        {isCreate && !disableDateField && (
                                            <Button
                                                onClick={handleApplyChange}
                                                disabled={disableButtons}
                                                options={{ style: { padding: 0 } }}
                                                small
                                                transparent
                                            >
                                                {hasRecord && record.oldDate ? "Change" : "Apply"}
                                            </Button>
                                        )}
                                        {showRevert && !disableDateField && (
                                            <Button
                                                className="danger"
                                                onClick={() => setDate(record.oldDate)}
                                                disabled={disableButtons}
                                                options={{ style: { padding: 0 } }}
                                                small
                                                transparent
                                            >
                                                Revert
                                            </Button>
                                        )}
                                    </div>
                                </div>
                                {!isCreate && renderEmployeeSelectLazy}
                            </div>

                            {isAlreadyExisting ? (
                                <div className="flex gap-05 center small-font" style={{ marginTop: "2rem" }}>
                                    <span>A Record already exist for this employee.</span>
                                    <span
                                        style={{
                                            textDecoration: "underline",
                                            fontStyle: "italic",
                                            color: "red",
                                            cursor: "pointer"
                                        }}
                                        onClick={handleExistingRecordClick}
                                    >
                                        Click to go to the existing record.
                                    </span>
                                </div>
                            ) : (
                                <>
                                    {record.hasHolidays && ((!isDefault && isCreate) || isDefault) && (
                                        <Select
                                            label="Holiday"
                                            value={config.holiday}
                                            options={record.options}
                                            isDisabled={disableFields}
                                            onChange={setHoliday}
                                            required={isCreate}
                                            isOutlined
                                            disabledOutline
                                        />
                                    )}
                                    {record.hasHolidays && holiday && (
                                        <>
                                            <SectionCollapseInfo title={<span className="small-font">Holiday Info</span>} show>
                                                <ul className="flex column gap-05 small-font">
                                                    The selected date is a holiday:
                                                    <li
                                                        className="flex gap-05 wrap"
                                                        style={{ marginLeft: ".5rem", marginTop: ".5rem", alignItems: "center" }}
                                                    >
                                                        Name:
                                                        <span className="bold primary-color" style={{ alignItems: "center" }}>
                                                            {sanitizeWords(holiday.name)}
                                                        </span>
                                                    </li>
                                                    <li className="flex gap-05 wrap" style={{ marginLeft: ".5rem", alignItems: "center" }}>
                                                        Type: <Tag>{sanitizeWords(trackHoliday.type)}</Tag>
                                                    </li>
                                                    {!!trackHoliday?.affected?.length && (
                                                        <li style={{ marginLeft: ".5rem", alignItems: "center" }} className="flex gap-05 wrap">
                                                            Affected:
                                                            {trackHoliday.affected.map((item) => (
                                                                <Tag key={item}>{sanitizeWords(item)}</Tag>
                                                            ))}
                                                        </li>
                                                    )}
                                                    {!!trackHoliday?.isAffectedAll && (
                                                        <li style={{ marginLeft: ".5rem", alignItems: "center" }} className="flex gap-05 wrap">
                                                            Affected: <Tag>{sanitizeWords("ALL")}</Tag>
                                                        </li>
                                                    )}
                                                </ul>
                                            </SectionCollapseInfo>
                                        </>
                                    )}
                                    {isGettingRecord ? (
                                        <div className="flex center" style={{ marginTop: "1rem" }}>
                                            <Loader style={{ width: "3rem" }} relative />
                                            <span className="fade">
                                                Generating Record for Date: {toTimeWithTimeZone(date, setting.timezone).format("MMM-DD-YYYY")}
                                            </span>
                                        </div>
                                    ) : (
                                        !!showInputs && (
                                            <FormUpdateInputs
                                                schedType={config.scheduleType}
                                                form={form}
                                                date={date}
                                                config={config}
                                                disableFields={disableFields}
                                                handleFormChange={handleFormChange}
                                                handleTimeChange={handleTimeChange}
                                                setting={setting}
                                                isOff={trackHoliday?.isOff && (trackHoliday?.isAffected || trackHoliday?.isAffectedAll)}
                                                isUpdate={!isCreate}
                                                hasChanges={!isCreate && hasChanges}
                                                editType={editType || EDIT_TYPE.DEFAULT}
                                                hasDefaultEmployee={!!employee}
                                            />
                                        )
                                    )}
                                </>
                            )}
                        </>
                    )
                })}
            </BaseUpdateModal>
            {submittedFormExtraModal && (
                <UpdateStatusModal
                    open={submittedFormExtraModal}
                    onClose={() => setSubmittedFormExtraModal(false)}
                    actionType={SUBMITTED_FORM_ACTION_TYPE.COMPLETE}
                    onConfirm={async (form) => {
                        const result = await handleSave();
                        if (!result.error) {
                            await onSubmittedFormUpdateStatus?.({
                                ...form,
                                historyId: result.id
                            });
                        } else {
                            setSubmittedFormExtraModal(false);
                        }
                    }}
                />
            )}
        </>
    );
}

UpdateModal.propTypes = {
    photo: PropTypes.string,
    open: PropTypes.bool,
    data: PropTypes.object,
    setting: PropTypes.object,
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func,
    workshifts: PropTypes.array,
    departments: PropTypes.array,
    newDate: PropTypes.any,
    editType: PropTypes.oneOf(Object.values(EDIT_TYPE)),
    submittedFormApproveId: PropTypes.any,
    noSuccessMessage: PropTypes.bool,
    onExistingRecordClick: PropTypes.func,
    employee: PropTypes.shape({
        id: PropTypes.any,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        index1: PropTypes.string
    }),
    saveLabel: PropTypes.any,
    siteId: PropTypes.number,
    onSubmittedFormUpdateStatus: PropTypes.func,
    submittedFormId: PropTypes.number
};

export default UpdateModal;
