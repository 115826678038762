import React, { useState } from "react";
import { useAppSelector } from "../../common/hooks/reduxHooks";
import { selectLoading, selectTableConfig, selectCompanyFormsData } from "../../features/company/companyForms/slice";
import PageLayout from "../../common/components/layout/PageLayout";
import CompanyForms from "../../features/company/companyForms/CompanyForms";
import useNavigate from "../../common/hooks/useNavigate";
import Navigation from "../../common/classes/Navigation";
import Filter from "../../features/company/companyForms/Filter";
import { usePaginateCompanyForm } from "../../features/company/companyForms/hooks";
import Tabs from "../../common/components/extra/tabs/Tabs";
import { FILTER_TYPE, TABS } from "../../features/company/companyForms/const";
import { selectSidebarCollapse } from "../../features/common/slice";
import { getFilterCount } from "../../common/utilities/helper";
import MissingRequiredFormInfo from "../../features/company/companyForms/MissingRequiredFormInfo";

export const createTabs = (tabs, props) => {
    return Object.values(tabs)
        .filter((tab) => tab.isActive)
        .map((tab) => {
            switch (tab.id) {
                case TABS.EMP_COMPANY_FORM.id: {
                    tab.component = <CompanyForms {...props} height={29} isEMP />;
                    break;
                }
                case TABS.HR_COMPANY_FORM.id: {
                    tab.component = <CompanyForms {...props} height={29} isHR />;
                    break;
                }
                default: {
                    break;
                }
            }
            return tab;
        });
};

function CompanyFormsPage() {
    const [activeTab, setActiveTab] = useState(TABS.EMP_COMPANY_FORM.id);
    const [openAddModal, setOpenAddModal] = useState(false);

    const navigate = useNavigate();

    const loading = useAppSelector(selectLoading);
    const config = useAppSelector(selectTableConfig);
    const data = useAppSelector(selectCompanyFormsData);
    const isSidebarCollapse = useAppSelector(selectSidebarCollapse);

    const [, { isLoading, onFilter, onSearch, resetTableConfig }] = usePaginateCompanyForm({
        readOnly: true,
        isHR: activeTab == TABS.HR_COMPANY_FORM.id,
        isEMP: activeTab == TABS.EMP_COMPANY_FORM.id
    });

    const handleSearch = (value) => {
        return onSearch(value, {
            isHR: activeTab == TABS.HR_COMPANY_FORM.id,
            isEMP: activeTab == TABS.EMP_COMPANY_FORM.id
        });
    };

    const handleFilter = (filter) => {
        return onFilter(filter, {
            isHR: activeTab == TABS.HR_COMPANY_FORM.id,
            isEMP: activeTab == TABS.EMP_COMPANY_FORM.id
        });
    };

    const handleAdd = () => {
        navigate(Navigation.Routes.COMPANY_FORM_CREATE.path);
    };

    const onTabChange = (tab) => {
        setActiveTab(tab.id);
        resetTableConfig({
            isHR: tab.id == TABS.HR_COMPANY_FORM.id,
            isEMP: tab.id == TABS.EMP_COMPANY_FORM.id
        });
    };

    return (
        <PageLayout
            className="tk-company-forms"
            title="Manage Forms"
            isLoading={loading}
            search={{ onSearch: handleSearch, value: config.search, placeholder: "Search name..." }}
            filter={<Filter onFilter={handleFilter} isLoading={isLoading} />}
            onAdd={handleAdd}
            styles={{
                body: {
                    height: "calc(100vh - 20.5rem)"
                }
            }}
            activeFilterCount={getFilterCount(config.filter, { exclude: [FILTER_TYPE.CATEGORY] })}
            rightExtra={
                <div style={{ marginRight: "1rem" }}>
                    <MissingRequiredFormInfo />
                </div>
            }
            hasFilter
        >
            <Tabs
                styles={{
                    parent: {
                        width: data?.length ? "min-content" : "100%"
                    },
                    head: {
                        width: isSidebarCollapse ? "calc(100vw - 12rem)" : "calc(100vw - 24rem)",
                        minWidth: "15rem"
                    },
                    body: {
                        padding: 0,
                        overflow: "unset",
                        flexGrow: "1",
                        height: "calc(100vh - 27rem)"
                    },
                    content: {
                        padding: 0
                    }
                }}
                items={createTabs(
                    TABS,
                    {
                        openAddModal,
                        onAddModalClose: () => setOpenAddModal(false)
                    },
                    activeTab
                )}
                onChange={onTabChange}
                hasBackground={!data?.length}
            />
        </PageLayout>
    );
}

export default CompanyFormsPage;
