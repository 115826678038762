import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import FormSection from "../../../common/components/extra/FormSection";
import Input from "../../../common/components/extra/form/Input";
import { useGetLeave, useGetLeaveExtraInfo } from "./hooks";
import BaseViewModal from "../../../common/components/layout/modalViewUpdateLayout/BaseViewModal";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import {
    createClass,
    getTotalDaysFromDateRange,
    renderNA,
    sanitizeTimestamp,
    sanitizeWords,
    toProperTimezoneConversion,
    toReadableFromDate
} from "../../../common/utilities/helper";
import { BASE_CLASS_VIEW_MODAL, FIELD, FIELD_OBJECT, LEAVE_STATUS, LEAVE_TYPE } from "./const";
import Button from "../../../common/components/extra/Button";
import ViewEmployeeModal from "../employees/ViewModal";
import ViewFileModal from "../employees/ViewFileModal";
import { VIEW_MODAL_TYPE } from "../employees/const";
import LeaveSummary from "./LeaveSummary";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";
import EditRejoinedDetailsButton from "./EditRejoinedDetailsButton";
import CancelStatusButton from "./CancelStatusButton";
import Tag from "../../../common/components/extra/Tag";

function ViewModal({ open, onClose, onEdit, id, onUpdateFinish }) {
    const [previewFileProof, setPreviewFileProof] = useState(null);
    const [viewEmployee, setViewEmployee] = useState(false);

    const [current, { isLoading }] = useGetLeave(id, { runOnMount: true });
    const config = useGetLeaveExtraInfo(current);

    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;
    const isCanceled = current && current[FIELD.STATUS] == LEAVE_STATUS.CANCELED;
    const isApproved = current && current[FIELD.STATUS] == LEAVE_STATUS.APPROVED;

    const totalDays = useMemo(
        () => current && getTotalDaysFromDateRange(current?.[FIELD.START_DATE], current?.[FIELD.END_DATE], timezone),
        [current?.[FIELD.START_DATE], current?.[FIELD.END_DATE]]
    );

    const COMMON_PROPS = { readOnly: true, isLoading };

    const lastUploadedAt = useMemo(() => {
        if (!current?.[FIELD.PROOF_FILE]) {
            return "";
        }
        const unix = current[FIELD.PROOF_FILE].split("-").pop().split(".").shift();
        return toReadableFromDate(toProperTimezoneConversion(Number(unix), timezone));
    }, [current?.[FIELD.PROOF_FILE]]);

    const renderEmployee = () => {
        return (
            <div className="flex" style={{ alignItems: "center" }}>
                <span>{sanitizeWords(current?.employee?.fullName)}</span>
                <Button
                    options={{ style: { textAlign: "right", paddingRight: 0, textDecoration: "underline" } }}
                    onClick={() => setViewEmployee(true)}
                    transparent
                    small
                >
                    View
                </Button>
            </div>
        );
    };

    return (
        <>
            <BaseViewModal
                open={open}
                onClose={onClose}
                onEdit={!current || config.isStarted || config.isEnded ? null : onEdit}
                disableEdit={!current || config.isStarted || config.isEnded}
                headExtra={current && <CancelStatusButton />}
            >
                <div className={createClass(" flex column gap-1", BASE_CLASS_VIEW_MODAL)}>
                    {config.isEnded && !isCanceled && (
                        <SectionCollapseInfo title="Notice" alwaysOpen show>
                            <div className="small-font">
                                Editing will not be allowed, because leave is already running/ended. Click to&nbsp;
                                <EditRejoinedDetailsButton onFinish={onUpdateFinish} />
                            </div>
                        </SectionCollapseInfo>
                    )}
                    <FormSection header="Leave Details">
                        <Input label="Employee" renderValue={renderEmployee()} {...COMMON_PROPS} />
                        <div className="flex gap-05 wrap">
                            <div className="flex" style={{ flex: 1, padding: ".5rem" }}>
                                <LeaveSummary employeeId={current?.employee?.id} />
                            </div>
                            <div className="flex column" style={{ flex: 1, minWidth: "17rem" }}>
                                <Input
                                    label={FIELD_OBJECT[FIELD.STATUS].label}
                                    renderValue={
                                        current?.[FIELD.STATUS] && (
                                            <Tag className={isCanceled ? "dark-gray" : isApproved ? "green" : ""}>
                                                {sanitizeWords(current[FIELD.STATUS])}
                                            </Tag>
                                        )
                                    }
                                    {...COMMON_PROPS}
                                />
                                <Input
                                    label={FIELD_OBJECT[FIELD.START_DATE].label}
                                    renderValue={toProperTimezoneConversion(current?.[FIELD.START_DATE], timezone)?.format?.("ddd MMM DD, YYYY")}
                                    {...COMMON_PROPS}
                                />
                                <Input
                                    label={FIELD_OBJECT[FIELD.END_DATE].label}
                                    renderValue={
                                        totalDays == 1
                                            ? renderNA(<span className="semi-bold">Whole Day</span>)
                                            : toProperTimezoneConversion(current?.[FIELD.END_DATE], timezone)?.format?.("ddd MMM DD, YYYY")
                                    }
                                    subtext={{
                                        hide: !totalDays || !current?.[FIELD.START_DATE] || !current?.[FIELD.END_DATE],
                                        message: `Total Days: ${totalDays} Day(s)`
                                    }}
                                    useSubTextStyle
                                    {...COMMON_PROPS}
                                />
                                <Input
                                    label={FIELD_OBJECT[FIELD.LEAVE_TYPE].label}
                                    renderValue={sanitizeWords(current?.[FIELD.LEAVE_TYPE])}
                                    {...COMMON_PROPS}
                                />
                                <Input
                                    label={FIELD_OBJECT[FIELD.PAYMENT_TYPE].label}
                                    renderValue={sanitizeWords(current?.[FIELD.PAYMENT_TYPE])}
                                    {...COMMON_PROPS}
                                />
                                {current?.[FIELD.LEAVE_TYPE] == LEAVE_TYPE.COMPENSATORY_LEAVE && (
                                    <Input
                                        label="Offset"
                                        renderValue={current?.[FIELD.OFFSET_HOURS]}
                                        afterExtra={<span>Hour(s)</span>}
                                        {...COMMON_PROPS}
                                    />
                                )}
                                <Input
                                    label={FIELD_OBJECT[FIELD.PROOF_FILE].label}
                                    renderValue={
                                        !current?.[FIELD.PROOF_FILE] ? (
                                            <span className="fade small-font">No File Uploaded</span>
                                        ) : (
                                            <Button
                                                options={{ style: { textAlign: "right", paddingRight: 0, textDecoration: "underline" } }}
                                                onClick={() => setPreviewFileProof(true)}
                                                transparent
                                                small
                                            >
                                                View
                                            </Button>
                                        )
                                    }
                                    subtext={{
                                        hide: !lastUploadedAt,
                                        message: `Last Uploaded At: ${lastUploadedAt} `
                                    }}
                                    useSubTextStyle
                                    {...COMMON_PROPS}
                                />
                            </div>
                        </div>
                        <Input
                            label={FIELD_OBJECT[FIELD.REASON].label}
                            renderValue={<p style={{ marginLeft: "2rem" }}>{current?.[FIELD.REASON]}</p>}
                            {...COMMON_PROPS}
                        />
                    </FormSection>
                    <FormSection header="Rejoined Details">
                        <Input
                            label={FIELD_OBJECT[FIELD.REJOINED_DATE].label}
                            renderValue={current && toReadableFromDate(current?.[FIELD.REJOINED_DATE], timezone)}
                            {...COMMON_PROPS}
                        />
                        <Input
                            label={FIELD_OBJECT[FIELD.RECORDED_DATE].label}
                            renderValue={current && toReadableFromDate(current?.[FIELD.RECORDED_DATE], timezone)}
                            {...COMMON_PROPS}
                        />
                        <Input
                            label={FIELD_OBJECT[FIELD.REJOINED_NOTES].label}
                            renderValue={<p style={{ marginLeft: "2rem" }}>{current?.[FIELD.REJOINED_NOTES]}</p>}
                            {...COMMON_PROPS}
                        />
                    </FormSection>
                    <FormSection header="Record Details">
                        <Input label="Created" renderValue={current && toReadableFromDate(current?.createdAt, timezone)} {...COMMON_PROPS} />
                        <Input label="Updated" renderValue={sanitizeTimestamp(current?.updatedAt, current?.createdAt, timezone)} {...COMMON_PROPS} />
                    </FormSection>
                </div>
            </BaseViewModal>
            {!!viewEmployee && (
                <ViewEmployeeModal open={viewEmployee} onClose={() => setViewEmployee(false)} id={current.employee.id} setting={setting} readOnly />
            )}
            {!!previewFileProof && (
                <ViewFileModal
                    open={!!previewFileProof}
                    onClose={() => setPreviewFileProof(null)}
                    type={VIEW_MODAL_TYPE.LEAVE_PROOF_FILE}
                    data={{
                        id: current.employee.id,
                        filename: current[FIELD.PROOF_FILE]
                    }}
                />
            )}
        </>
    );
}

ViewModal.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.any,
    onClose: PropTypes.func,
    onEdit: PropTypes.func,
    onUpdateFinish: PropTypes.func
};

export default ViewModal;
