import React, { useState } from "react";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/DoDisturb";
import FormSection from "../../../common/components/extra/FormSection";
import Input from "../../../common/components/extra/form/Input";
import { useGetEmployeePenalties, useManageActions } from "./hooks";
import BaseViewModal from "../../../common/components/layout/modalViewUpdateLayout/BaseViewModal";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import {
    addCommasToMoney,
    createClass,
    createConfirmAlert,
    sanitizeTimestamp,
    sanitizeWords,
    toOrdinal,
    toProperTimezoneConversion,
    toReadableFromDate
} from "../../../common/utilities/helper";
import { BASE_CLASS, CUSTOM_ACTION, FIELD, PENALTY_LEVEL, PENALTY_STATUS } from "./const";
import Button from "../../../common/components/extra/Button";
import ViewEmployeeModal from "../employees/ViewModal";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";
import CancelDeductionModal from "./CancelPenaltyModal";
import { createStatusTag } from "./helper";
import Tag from "../../../common/components/extra/Tag";
import MarkAsDeducted from "./MarkAsDeducted";

const { DEDUCTION_TYPE, OCCURENCE_DATE, EFFECTIVE_DATE, REASON, STATUS, CONFIRMED_DATE } = FIELD;

function ViewModal({ open, onClose, onEdit, id }) {
    const [viewEmployee, setViewEmployee] = useState(false);
    const [current, { isInitial, isLoading, config, update }] = useGetEmployeePenalties(id);

    const { onAction: handleAction, cancelId, setCancelId } = useManageActions();

    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const isCancelled = !isInitial && !isLoading && !!current && current.status == PENALTY_STATUS.CANCELED;
    const disableEdit = isInitial || isLoading || !current || config.hasConfirmedDate || isCancelled;
    const showCancel = !isInitial && !isLoading && !config.hasConfirmedDate && !isCancelled;
    const showDelete = !isInitial && !isLoading && !config.hasConfirmedDate;
    const showCancelOrDelete = showCancel || showDelete;

    const COMMON_PROPS = { readOnly: true, isLoading };

    const handleViewEmployee = () => setViewEmployee(true);

    const renderEmployee = () => {
        return (
            <div className="flex" style={{ alignItems: "center" }}>
                <span>{sanitizeWords(current?.employee?.fullName)}</span>
                <Button
                    options={{ style: { textAlign: "right", paddingRight: 0, textDecoration: "underline" } }}
                    onClick={handleViewEmployee}
                    transparent
                    small
                >
                    View
                </Button>
            </div>
        );
    };

    const renderMeasureLevel = (level) => {
        switch (level) {
            case PENALTY_LEVEL.DAY_DEDUCTION:
                return (
                    <>
                        <Input label="Level" renderValue={<Tag className="brown">{sanitizeWords(level)}</Tag>} {...COMMON_PROPS} />
                        <Input label="Deduction" renderValue={config.measure.value} afterExtra={<span>Day</span>} {...COMMON_PROPS} />
                    </>
                );
            case PENALTY_LEVEL.FIX_VALUE:
                return (
                    <>
                        <Input label="Level" renderValue={<Tag>{sanitizeWords(level)}</Tag>} {...COMMON_PROPS} />
                        <Input
                            label="Deduction"
                            renderValue={"- " + addCommasToMoney(config.measure.value)}
                            afterExtra={<span>{setting.currency}</span>}
                            {...COMMON_PROPS}
                        />
                    </>
                );
            case PENALTY_LEVEL.CANCEL_DAY_RECORD:
                return (
                    <>
                        <Input label="Level" renderValue={<Tag className="yellow">{sanitizeWords(level)}</Tag>} {...COMMON_PROPS} />
                    </>
                );
            case PENALTY_LEVEL.TERMINATION:
                return (
                    <>
                        <Input label="Level" renderValue={<Tag className="red">{sanitizeWords(level)}</Tag>} {...COMMON_PROPS} />
                    </>
                );
            case PENALTY_LEVEL.WARNING:
                return (
                    <>
                        <Input label="Level" renderValue={<Tag className="yellow">{sanitizeWords(level)}</Tag>} {...COMMON_PROPS} />
                    </>
                );
            default:
                return <></>;
        }
    };

    const renderHeadExtra = (
        <div className="flex gap-05">
            {showCancel && (
                <Button className="danger" beforeExtra={<CancelIcon />} onClick={() => handleAction({ id }, CUSTOM_ACTION.CANCEL)} small mini>
                    <span>Cancel</span>
                </Button>
            )}
            {showDelete && (
                <Button
                    className="danger"
                    beforeExtra={<DeleteIcon />}
                    onClick={() =>
                        createConfirmAlert({
                            title: "Remove Penalty",
                            content: "Are you sure you want to remove this record? This cannot be undone.",
                            onConfirm: async (close) => {
                                close();
                                handleAction({ id }, CUSTOM_ACTION.REMOVE).then(onClose);
                            }
                        })
                    }
                    small
                    mini
                >
                    <span>Remove</span>
                </Button>
            )}
            <MarkAsDeducted id={id} onFinish={update} current={current} />
        </div>
    );

    return (
        <BaseViewModal open={open} onClose={onClose} onEdit={onEdit} disableEdit={disableEdit} headExtra={renderHeadExtra}>
            <div className={createClass("__modal-content-view flex column gap-1", BASE_CLASS)}>
                {!isInitial && !isLoading && !showCancelOrDelete && (
                    <SectionCollapseInfo title="Reminder" show>
                        Delete and Cancel option is unavailable once the record is confirmed or included in the salary computation.
                    </SectionCollapseInfo>
                )}
                <FormSection header="Penalty Details">
                    <Input label="Employee" renderValue={renderEmployee()} {...COMMON_PROPS} />
                    <Input label="Deduction Type" renderValue={sanitizeWords(current?.[DEDUCTION_TYPE])} {...COMMON_PROPS} />
                    <Input
                        label="Effective Date"
                        renderValue={toProperTimezoneConversion(current?.[EFFECTIVE_DATE])?.format?.("MMM YYYY")}
                        {...COMMON_PROPS}
                    />
                    <Input
                        label="Occurence Date"
                        renderValue={toReadableFromDate(current?.[OCCURENCE_DATE], timezone, "MMM DD, YYYY")}
                        {...COMMON_PROPS}
                    />
                    <Input
                        label="Reason"
                        renderValue={<p style={{ marginLeft: "2rem" }}>{current && sanitizeWords(current?.[REASON])}</p>}
                        {...COMMON_PROPS}
                    />
                </FormSection>
                <FormSection header="Applied Measure">
                    <Input label="Title" renderValue={sanitizeWords(current?.title)} {...COMMON_PROPS} />
                    <Input
                        label="Occurence"
                        renderValue={toOrdinal(config?.measure?.occurence)}
                        afterExtra={<span>Offense</span>}
                        {...COMMON_PROPS}
                    />
                    {renderMeasureLevel(config?.measure?.level)}
                </FormSection>
                <FormSection header="Record Details">
                    <Input label="Status" renderValue={createStatusTag(current?.[STATUS])} {...COMMON_PROPS} />
                    <Input label="Confirmed Date" renderValue={toReadableFromDate(current?.[CONFIRMED_DATE], timezone)} {...COMMON_PROPS} />
                    <Input label="Created" renderValue={current && toReadableFromDate(current?.createdAt, timezone)} {...COMMON_PROPS} />
                    <Input label="Updated" renderValue={sanitizeTimestamp(current?.updatedAt, current?.createdAt, timezone)} {...COMMON_PROPS} />
                </FormSection>
            </div>
            {!!viewEmployee && current?.employee?.id && (
                <ViewEmployeeModal id={current.employee.id} open={viewEmployee} onClose={() => setViewEmployee(false)} readOnly />
            )}
            {!!cancelId && (
                <CancelDeductionModal open={!!cancelId} onClose={() => setCancelId(null)} onFinish={() => setCancelId(null)} id={cancelId} />
            )}
        </BaseViewModal>
    );
}

ViewModal.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.any,
    onClose: PropTypes.func,
    onEdit: PropTypes.func
};

export default ViewModal;
