import { createSlice } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";
import { SIGNUP_FLOW } from "./const";

const SLICE_NAME_KEY = "auth";

const defaultSignUpFlow = {
    current: SIGNUP_FLOW.SIGNUP,
    data: {
        token: "",
        role_id: null,
        id: "",
        email: ""
    }
};

const initialState = {
    user: null,
    role: null,
    accessToken: null,
    industries: [],
    countries: [],
    banks: [],
    country: null,
    loading: false,
    unverifiedStatus: null,
    signup: defaultSignUpFlow,
    viewport: {
        isMobile: false,
        isTablet: false,
        isPC: false,
        width: null,
        height: null
    },
    isSidebarCollapsed: false
};

export const authSlice = createSlice({
    name: SLICE_NAME_KEY,
    initialState,
    reducers: {
        setSidebarCollapse: (state, action) => {
            let newstate = cloneDeep(state);
            const payload = action.payload;
            newstate.isSidebarCollapsed = !!payload;
            return newstate;
        },
        setSignup: (state, action) => {
            let newstate = cloneDeep(state);
            const { current, data } = action.payload;
            current && (newstate.current = current);
            data && (newstate.signup.data = { ...state.signup.data, ...(data || {}) });
            return newstate;
        },
        setUnverfiedStatus: (state, action) => {
            let newstate = cloneDeep(state);
            const payload = action.payload;
            newstate.unverifiedStatus = payload;
            return newstate;
        },
        setUser: (state, action) => {
            let newstate = cloneDeep(state);
            const { user, role, accessToken } = action.payload || {};
            newstate.user = user;
            newstate.role = role;
            newstate.accessToken = accessToken;
            return newstate;
        },
        clearUser: (state) => {
            let newstate = cloneDeep(state);
            newstate.user = null;
            newstate.role = null;
            newstate.accessToken = null;
            newstate.unverifiedStatus = null;
            return newstate;
        },
        updateUser: (state, action) => {
            let newstate = cloneDeep(state);
            if (action.payload) newstate.user = { ...(newstate.user || {}), ...(action.payload || {}) };
            return newstate;
        },
        updateUserSalarySettings: (state, action) => {
            let newstate = cloneDeep(state);
            if (!newstate.user?.Setting?.salary) {
                newstate.user.Setting.salary = {};
            }
            if (action.payload) {
                newstate.user.Setting.salary = {
                    ...newstate.user.Setting.salary,
                    ...(action.payload || {})
                };
            }
            return newstate;
        },
        setIndustries: (state, action) => {
            let newstate = cloneDeep(state);
            if (Array.isArray(action.payload)) newstate.industries = action.payload;
            return newstate;
        },
        setCountries: (state, action) => {
            let newstate = cloneDeep(state);
            if (Array.isArray(action.payload)) newstate.countries = action.payload;
            return newstate;
        },
        setBanks: (state, action) => {
            let newstate = cloneDeep(state);
            if (Array.isArray(action.payload)) newstate.banks = action.payload;
            return newstate;
        },
        setCountry: (state, action) => {
            let newstate = cloneDeep(state);
            if (action.payload) newstate.country = action.payload;
            return newstate;
        },
        setViewport: (state, action) => {
            let newstate = cloneDeep(state);
            newstate.viewport = action.payload;
            return newstate;
        },
        setLoading: (state, action) => {
            let newstate = cloneDeep(state);
            if (!!action.payload !== state.loading) newstate.loading = !!action.payload;
            return newstate;
        },
        setGlobal: (state, action) => {
            let newstate = cloneDeep(state);
            const { industries, countries, banks, country } = action.payload || {};
            Array.isArray(industries) && (newstate.industries = industries);
            Array.isArray(countries) && (newstate.countries = countries);
            Array.isArray(banks) && (newstate.banks = banks);
            country && (newstate.country = country);
            return newstate;
        },
        clearSignUp: (state) => {
            let newstate = cloneDeep(state);
            newstate.signup = initialState.signup;
            return newstate;
        },
        reset: (state) => {
            // we dont reset role because we use it on our routes for determining the correct route
            return { ...initialState, signup: state.signup, role: state.role };
        }
    }
});

export const selectAuth = (state) => state[SLICE_NAME_KEY];
export const selectUser = (state) => state[SLICE_NAME_KEY].user;
export const selectUserCountrySettings = (state) => state[SLICE_NAME_KEY].user?.countrySettings;
export const selectUnverifiedStatus = (state) => state[SLICE_NAME_KEY].unverifiedStatus;
export const selectSignup = (state) => state[SLICE_NAME_KEY].signup;
export const selectUserCompanySubscription = (state) => state[SLICE_NAME_KEY].user?.CompanySubscription;
export const selectUserSetting = (state) => state[SLICE_NAME_KEY].user?.Setting;
export const selectToken = (state) => state[SLICE_NAME_KEY].accessToken;
export const selectRole = (state) => state[SLICE_NAME_KEY].role;
export const selectIndustries = (state) => state[SLICE_NAME_KEY].industries;
export const selectCountries = (state) => state[SLICE_NAME_KEY].countries;
export const selectBanks = (state) => state[SLICE_NAME_KEY].banks;
export const selectCountry = (state) => state[SLICE_NAME_KEY].country;
export const selectLoading = (state) => state[SLICE_NAME_KEY].loading;
export const selectViewport = (state) => state[SLICE_NAME_KEY].viewport;
export const selectSidebarCollapse = (state) => state[SLICE_NAME_KEY].isSidebarCollapsed;

export const {
    setSidebarCollapse,
    setUser,
    setUnverfiedStatus,
    setSignup,
    clearUser,
    updateUser,
    setIndustries,
    setCurrentIndustries,
    setLoading,
    setCountries,
    setBanks,
    setCountry,
    setGlobal,
    updateUserSalarySettings,
    reset,
    clearSignUp,
    setViewport
} = authSlice.actions;
export default authSlice.reducer;
