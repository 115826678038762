import cloneDeep from "lodash/cloneDeep";
import { createSlice } from "@reduxjs/toolkit";
import { SORT_ORDER, VIEW_TYPES } from "../../../common/utilities/const";
import { FIELDS } from "./const";

const SLICE_NAME_KEY = "site";

export const DEFAULT_SIZE = 20;
export const LOAD_MORE_OFFSET = 10;

export const defaultConfig = {
    pageSize: DEFAULT_SIZE,
    search: "",
    sortBy: FIELDS.TITLE.name,
    order: SORT_ORDER.ASC,
    cursor: "",
    more: LOAD_MORE_OFFSET,
    totalCount: 0
};

const initialState = {
    data: [],
    tableConfig: defaultConfig,
    current: null,
    loading: false,
    isSearching: false,
    view: VIEW_TYPES.CARD
};

export const siteSlice = createSlice({
    name: SLICE_NAME_KEY,
    initialState,
    reducers: {
        setSearching: (state, action) => {
            const clone = cloneDeep(state);
            clone.isSearching = action.payload;
            return clone;
        },
        setClearCache: (state) => {
            const clone = cloneDeep(state);
            clone.data = [];
            clone.current = null;
            clone.loading = false;
            clone.tableConfig = defaultConfig;
            return clone;
        },
        setSiteData: (state, action) => {
            const clone = cloneDeep(state);
            const data = action.payload;
            Array.isArray(data) && (clone.data = action.payload || []);
            return clone;
        },
        setTableConfig: (state, action) => {
            const clone = cloneDeep(state);
            const tableConfig = action.payload;
            tableConfig && (clone.tableConfig = { ...state.tableConfig, ...tableConfig });
            return clone;
        },
        setLoading: (state, action) => {
            const clone = cloneDeep(state);
            const loading = action.payload;
            typeof loading === "boolean" && loading !== state.loading && (clone.loading = loading);
            return clone;
        },
        setView: (state, action) => {
            const clone = cloneDeep(state);
            const view = action.payload;
            view && view !== state.view && (clone.view = view);
            return clone;
        },
        setCurrent: (state, action) => {
            const clone = cloneDeep(state);
            const current = action.payload;
            clone.current = current;
            return clone;
        },
        setState: (state, action) => {
            const clone = cloneDeep(state);
            const { tableConfig, view, data, current, loading } = action.payload;
            tableConfig && (clone.tableConfig = { ...state.tableConfig, ...tableConfig });
            data && (clone.data = data || []);
            view && view !== state.view && (clone.view = view);
            typeof loading === "boolean" && loading !== state.loading && (clone.loading = loading);
            current && (clone.current = current);
            return clone;
        },
        reset: () => initialState
    }
});

export const selectSearching = (state) => state[SLICE_NAME_KEY].isSearching;
export const selectWorkSiteData = (state) => state[SLICE_NAME_KEY].data;
export const selectTableConfig = (state) => state[SLICE_NAME_KEY].tableConfig;
export const selectLoading = (state) => state[SLICE_NAME_KEY].loading;
export const selectView = (state) => state[SLICE_NAME_KEY].view;
export const selectCurrent = (state) => state[SLICE_NAME_KEY].current;
export const selectState = (state) => state[SLICE_NAME_KEY];
export const { setSearching, setClearCache, setSiteData, setTableConfig, setLoading, setView, setCurrent, setState, reset } = siteSlice.actions;
export default siteSlice.reducer;
