import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import FormSection from "../../../common/components/extra/FormSection";
import Input from "../../../common/components/extra/form/Input";
import { useGetSettlement } from "./hooks";
import BaseViewModal from "../../../common/components/layout/modalViewUpdateLayout/BaseViewModal";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import { createClass, sanitizeTimestamp, sanitizeWords, toReadableFromDate, toTimeWithTimeZone } from "../../../common/utilities/helper";
import { BASE_CLASS_VIEW_MODAL, FIELD } from "./const";
import Button from "../../../common/components/extra/Button";
import ViewEmployeeModal from "../employees/ViewModal";
import ViewFileModal from "../employees/ViewFileModal";
import { VIEW_MODAL_TYPE } from "../employees/const";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";

function ViewModal({ open, onClose, onEdit, id }) {
    const [previewFileProof, setPreviewFileProof] = useState(null);
    const [viewEmployee, setViewEmployee] = useState(false);

    const [current, { isLoading }] = useGetSettlement(id, { runOnMount: true });

    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const COMMON_PROPS = { readOnly: true, isLoading };

    const lastUploadedAt = useMemo(() => {
        if (!current?.[FIELD.DOCUMENT]) {
            return "";
        }
        const unix = current[FIELD.DOCUMENT].split("-").pop().split(".").shift();
        return toReadableFromDate(toTimeWithTimeZone(Number(unix), timezone));
    }, [current?.[FIELD.DOCUMENT]]);

    const renderEmployee = () => {
        return (
            <div className="flex" style={{ alignItems: "center" }}>
                <span>{sanitizeWords(current?.employee?.fullName)}</span>
                <Button
                    options={{ style: { textAlign: "right", paddingRight: 0, textDecoration: "underline" } }}
                    onClick={() => setViewEmployee(true)}
                    transparent
                    small
                >
                    View
                </Button>
            </div>
        );
    };

    return (
        <>
            <BaseViewModal open={open} onClose={onClose} onEdit={onEdit} disableEdit={!current}>
                <div className={createClass(" flex column gap-1", BASE_CLASS_VIEW_MODAL)}>
                    <SectionCollapseInfo title="Notice" alwaysOpen show>
                        <div className="small-font">Editing will not be allowed, because leave is already running/ended. Click to&nbsp;</div>
                    </SectionCollapseInfo>
                    <FormSection header="Leave Details">
                        <Input label="Employee" renderValue={renderEmployee()} {...COMMON_PROPS} />
                        <div className="flex gap-05 wrap">
                            <div className="flex column" style={{ flex: 1, minWidth: "17rem" }}>
                                <Input
                                    label="Document"
                                    renderValue={
                                        !current?.[FIELD.DOCUMENT] ? (
                                            <span className="fade small-font">No File Uploaded</span>
                                        ) : (
                                            <Button
                                                options={{ style: { textAlign: "right", paddingRight: 0, textDecoration: "underline" } }}
                                                onClick={() => setPreviewFileProof(true)}
                                                transparent
                                                small
                                            >
                                                View
                                            </Button>
                                        )
                                    }
                                    subtext={{
                                        hide: !lastUploadedAt,
                                        message: `Last Uploaded At: ${lastUploadedAt} `
                                    }}
                                    useSubTextStyle
                                    {...COMMON_PROPS}
                                />
                            </div>
                        </div>
                        <Input
                            label="Short Description"
                            renderValue={<p style={{ marginLeft: "2rem" }}>{current?.[FIELD.DESCRIPTION]}</p>}
                            {...COMMON_PROPS}
                        />
                    </FormSection>
                    <FormSection header="Record Details">
                        <Input label="Created" renderValue={current && toReadableFromDate(current?.createdAt, timezone)} {...COMMON_PROPS} />
                        <Input label="Updated" renderValue={sanitizeTimestamp(current?.updatedAt, current?.createdAt, timezone)} {...COMMON_PROPS} />
                    </FormSection>
                </div>
            </BaseViewModal>
            {!!viewEmployee && (
                <ViewEmployeeModal open={viewEmployee} onClose={() => setViewEmployee(false)} id={current.employee.id} setting={setting} readOnly />
            )}
            {!!previewFileProof && (
                <ViewFileModal
                    open={!!previewFileProof}
                    onClose={() => setPreviewFileProof(null)}
                    type={VIEW_MODAL_TYPE.LEAVE_PROOF_FILE}
                    data={{
                        id: current.employee.id,
                        filename: current[FIELD.PROOF_FILE]
                    }}
                />
            )}
        </>
    );
}

ViewModal.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.any,
    onClose: PropTypes.func,
    onEdit: PropTypes.func,
    onUpdateFinish: PropTypes.func
};

export default ViewModal;
