import { toFormFields } from "../../../common/utilities/helper";

export const FILTER_TYPE = {
    SHIFT_TYPE: 0x1,
    DATE: 0x2
};

export const HOL_PREFIX = "hol - ";

export const TABS = {
    REGULAR_WORKSHIFT: { id: "regular-workshift", label: "Regular", isActive: true },
    HOLIDAY_WORKSHIFT: { id: "holiday-workshift", label: "Holiday", isActive: true }
};

export const BASE_CLASS = "tk-employees-work-shift__modal-content-update";

export const DEFAULT_SHIFT_TIME = 8;

export const DEFAULT_MAX_OT = 2;

export const DEFAULT_MAX_BREAK = 1;

export const TIME_TYPE = {
    SHIFT_ONE: 0x1,
    SHIFT_TWO: 0x2,
    BREAK: 0x3,
    BREAK_TWO: 0x4
};

export const FIELDS = {
    TITLE: {
        name: "title",
        label: "Title",
        required: true
    },
    SHIFT_TYPE: {
        name: "shift_type",
        label: "Shift Type",
        required: true
    },
    MAX_OVERTIME: {
        name: "max_overtime",
        label: "Max OT hours/day",
        required: true,
        default: DEFAULT_MAX_OT,
        constraint: { min: 0, max: 12 }
    },
    MAX_BREAK_DURATION: {
        name: "max_break_duration",
        label: "Max Break hours/day",
        required: true,
        default: DEFAULT_MAX_BREAK,
        constraint: { min: 0, max: 16 }
    },
    REQ_SHIFT_TIME: {
        name: "required_shift_time",
        label: "Required Shift hours/day",
        required: true,
        default: DEFAULT_SHIFT_TIME,
        constraint: { min: 3, max: 16 }
    },
    START_TIME: {
        name: "start_time",
        label: "Shift Time 1",
        required: true
    },
    END_TIME: {
        name: "end_time",
        label: "Shift Time 1",
        required: true
    },
    START_TIME_2: {
        name: "start_time_2",
        label: "Shift Time 2",
        required: true
    },
    END_TIME_2: {
        name: "end_time_2",
        label: "Shift Time 2",
        required: true
    },
    BREAK_TIME: {
        name: "break_time",
        label: "Break Time"
    },
    BREAK_END_TIME: {
        name: "break_end_time",
        label: "Break Time"
    },
    BREAK_TIME_2: {
        name: "break_time_2",
        label: "Break Time 2"
    },
    BREAK_END_TIME_2: {
        name: "break_end_time_2",
        label: "Break Time 2"
    },
    IS_HOLIDAY: {
        name: "is_holiday",
        label: "Holiday"
    }
};

export const FORM_FIELDS = toFormFields(FIELDS, "default");

export const BREAK_TYPE = {
    WITH_BREAK: "WITH_BREAK_TIME",
    NO_BREAK: "NO_BREAK_TIME",
    FLEXIBLE_BREAK: "FLEXIBLE_BREAK_TIME"
};

export const DATE_FIELDS = [
    FIELDS.START_TIME.name,
    FIELDS.END_TIME.name,
    FIELDS.START_TIME_2.name,
    FIELDS.END_TIME_2.name,
    FIELDS.BREAK_TIME.name,
    FIELDS.BREAK_END_TIME.name,
    FIELDS.BREAK_TIME_2.name,
    FIELDS.BREAK_END_TIME_2.name
];
