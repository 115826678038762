export const BASE_CLASS = "tk-employee-deductions";

export const FIELD = {
    EMPLOYEE: "employee_id",
    TYPE: "deduction_type",
    PAYMENT_TYPE: "payment_type",
    STATUS: "status",
    AMOUNT: "amount",
    START_DATE: "start_date",
    END_DATE: "end_date",
    NOTES: "notes",
    CONFIRMED_DATE: "confirmed_date"
};

export const FILTER_TYPE = {
    EMPLOYEE: 0x1,
    TYPE: 0x2,
    PAYMENT_TYPE: 0x3,
    STATUS: 0x4,
    DATE: 0x5,
    CONFIRMED_DATE: 0x6
};

export const DEDUCTION_STATUS = {
    ACTIVE: "ACTIVE",
    COMPLETED: "COMPLETED",
    CANCELED: "CANCELED"
};

export const DEDUCTION_TYPE = {
    LOAN: "LOAN",
    HOUSING_RENT: "HOUSING_RENT",
    TRANSPORTATION: "TRANSPORTATION",
    TRAFFIC_VIOLATION: "TRAFFIC_VIOLATION",
    FOOD: "FOOD",
    MEDICAL: "MEDICAL",
    SALARY_ADVANCE: "SALARY_ADVANCE",
    EXTRA_PAID: "EXTRA_PAID",
    INSURANCE: "INSURANCE",
    UNIFORM: "UNIFORM",
    TAX: "TAX",
    OTHERS: "OTHERS"
};

export const DEDUCTION_PAYMENT_TYPE = {
    ONE_TIME: "ONE_TIME",
    INSTALLMENT: "INSTALLMENT"
};

export const CUSTOM_ACTION = {
    CANCEL: 0x1,
    PREVIEW: 0x2,
    REMOVE: 0x3
};

export const DATE_FIELDS = [FIELD.START_DATE, FIELD.END_DATE];
