import { useEffect, useMemo, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { useGetCheckinLogsByHistoryMutation } from "./api";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectCheckinLogsData, selectCurrent, setCurrent, setState } from "./slice";
import { selectUserSetting } from "../../common/slice";
import { createToast, TOAST_TYPE, toTimeWithTimeZone } from "../../../common/utilities/helper";
import { siteIconProps } from "./helper";
import { DATE_TIME_FORMAT } from "../companyForms/const";

export const useGetCheckinsByHistory = (id) => {
    const [fetching, setFetching] = useState(true);
    const [getDetails] = useGetCheckinLogsByHistoryMutation();

    const [logs, setLogs] = useState([]);
    const [sites, setSites] = useState([]);

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);
    const setting = useAppSelector(selectUserSetting);
    const checkinLogsData = useAppSelector(selectCheckinLogsData);
    const timezone = setting.timezone;

    const config = useMemo(() => {
        let temp = { timezone, setting };
        if (!current) return temp;

        temp.markers = logs;
        temp.sites = sites;

        return temp;
    }, [current, logs, sites]);

    const fetch = async (newid, force) => {
        newid = newid || id;
        try {
            if (!force && (!newid || (current && current.id === newid))) {
                setFetching(false);
                return Promise.resolve();
            }
            const result = await getDetails({ extraPath: newid });
            if (result?.error) {
                throw new Error("Failed to fetch checkins. Please try again later");
            }
            const data = cloneDeep(result.data.data);
            dispatch(setCurrent(data));
            setLogs(
                data.logs.map((cur, idx) => ({
                    id: cur.id,
                    title: cur.location_name + " " + "(" + toTimeWithTimeZone(cur.date, timezone).format(DATE_TIME_FORMAT) + ")",
                    address: cur.address,
                    background: siteIconProps(cur.action).color,
                    color: "white",
                    point: {
                        lat: cur.coordinates.latitude,
                        lng: cur.coordinates.longitude
                    },
                    children: data.logs.length - idx
                }))
            );
            setSites(
                data.sites.map((site) => ({
                    title: site.title,
                    polygon: site.coordinates
                }))
            );
            return data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return {};
        } finally {
            setFetching(false);
        }
    };

    const updateCurrent = (newCurrent = {}) => {
        const newObj = !current || (current && current.id !== newCurrent.id) ? newCurrent : { ...(current || {}), ...(newCurrent || {}) };
        dispatch(
            setState({
                current: newObj,
                data: checkinLogsData.map((d) => (d.id == newObj.id ? { ...d, ...newObj } : d))
            })
        );
    };

    const clearCurrent = () => dispatch(setCurrent(null));

    const updateMarker = (index, newObject = {}, forAllObj = {}) => {
        let temp = cloneDeep(logs.map((log) => ({ ...log, ...forAllObj })));
        temp[index] = { ...(temp[index] || {}), ...newObject };
        setLogs(temp);
    };

    useEffect(() => {
        fetch();
    }, []);

    return [current, { isLoading: fetching, config, clearCurrent, updateCurrent, updateMarker, fetch }];
};
