import React from "react";
import PropTypes from "prop-types";
import LockResetIcon from "@mui/icons-material/LockReset";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { ReactComponent as ID_SVG } from "../../../assets/images/id-icon.svg";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import {
    isFileObject,
    sanitizeWords,
    setPreventDefault,
    toReadablePhone,
    toTimeWithTimeZone,
    transformCountries
} from "../../../common/utilities/helper";
import Divider from "../../../common/components/extra/Divider";
import { selectCountries, selectIndustries } from "../../common/slice";
import Button from "../../../common/components/extra/Button";
import { UPLOAD_FIELDS, VIEW_MODAL_TYPE } from "../../admin/companies/const";
import ViewModalSub from "../../admin/companies/ViewModalSub";
import Card from "../../../common/components/extra/Card";
import Select from "../../../common/components/extra/select/Select";
import ChangePassword from "./ChangePassword";
import Text from "../../../common/components/extra/typography/Text";
import MyTooltip from "../../../common/components/extra/Tooltip";
import { useManageProfileState } from "./hooks";
import { PROFILE_FIELDS } from "./const";
import Controls from "./Controls";
import CompanyAvatar from "../../admin/companies/CompanyAvatar";

const { COMPANY_CONTRACT, LICENSE_TRADE, LICENSE_COMMERCIAL, ESTABLISHMENT_ID } = UPLOAD_FIELDS;

function ProfileCompanyTab({ setParentLoading, onLoading, styles }) {
    const [
        profile,
        isGettingProfile,
        {
            isLoading,
            hasChanges,
            error,
            hasError,
            hasContract,
            handleCancel,
            handleViewChange,
            handleSubmit,
            handleLogoChange,
            user,
            setting,
            isEditing,
            toggleEdit,
            isChangePass,
            viewObject,
            toggleChangePass,
            handleChange
        }
    ] = useManageProfileState((bool) => {
        setParentLoading?.(bool);
        onLoading?.(bool);
    });

    const industries = useAppSelector(selectIndustries).map((ind) => ({
        ...ind,
        value: ind.id,
        label: <span className="semi-bold">{ind.name}</span>
    }));
    const supportedCountries = transformCountries(useAppSelector(selectCountries), { isRight: true });
    const useArabicName = setting.use_arabic_name;
    const status = user.status;
    const showRequestingContractText = status.isRenewingContract || status.isRequestingContract;

    const getIndustry = (val) => industries.find((industry) => industry.value === val) || "";
    const getCountry = (cca2) => supportedCountries.find((country) => country.value === cca2) || "";

    const inputPropsValue = (value, style = {}, { noSanitize } = {}) => {
        if (isEditing) {
            return {
                value,
                style
            };
        } else {
            return {
                renderValue: typeof value == "string" ? (noSanitize ? value : sanitizeWords(value)) : value,
                readOnly: true,
                parentStyle: style
            };
        }
    };

    const renderInput = ({ name, label, value, onChange, options, afterExtra, ...rest } = {}) => {
        if (isEditing) {
            return (
                <Select
                    options={options}
                    onChange={(val) => onChange({ target: { name, value: val.value } })}
                    value={value}
                    name={name}
                    label={label}
                    placeholder=""
                    style={{ flex: "30%" }}
                    isClearable={false}
                    menuPortalTarget={document.body}
                    isOutlined
                    disabledOutline
                    {...rest}
                />
            );
        }
        return (
            <Input
                type={INPUT_TYPE.TEXT}
                name={name}
                label={label}
                onChange={onChange}
                afterExtra={afterExtra}
                isLoading={isGettingProfile}
                {...inputPropsValue(typeof value === "object" && "label" in value ? value.label : value)}
            />
        );
    };

    const createFileDetails = ({ title, name, expiry, fileObj, modalTypeKey } = {}) => {
        const previewInputChange = () => {
            const isFileObj = isFileObject(profile.uploads[fileObj.key]);
            const src = (isFileObj && profile.uploads[fileObj.key] && URL.createObjectURL(profile.uploads[fileObj.key])) || "";

            handleViewChange({
                type: modalTypeKey,
                data: {
                    id: user.id,
                    src,
                    filename: isFileObj ? profile.uploads[fileObj.key]?.name : profile?.uploads?.[fileObj.key]
                }
            });
        };
        return (
            <>
                <Divider title={title} />
                <Input
                    type={INPUT_TYPE.TEXT}
                    name={name}
                    label="Number (ID)"
                    onChange={handleChange}
                    minLength={3}
                    isLoading={isGettingProfile}
                    tooltip="The expiry date will be updated once our support team validates your uploaded files."
                    required
                    {...inputPropsValue(profile[name], {}, { noSanitize: true })}
                />

                {!isEditing && (
                    <Input
                        type={INPUT_TYPE.TEXT}
                        name={expiry}
                        label="Expiry Date"
                        onChange={handleChange}
                        minLength={3}
                        isLoading={isGettingProfile}
                        afterExtra={
                            <MyTooltip message="View File" onClick={previewInputChange}>
                                <AttachFileIcon className="hover-svg primary-color" style={{ width: "1.1rem" }} />
                            </MyTooltip>
                        }
                        subtext={{
                            hide: name !== PROFILE_FIELDS.CONTRACT_NUMBER || (name == PROFILE_FIELDS.CONTRACT_NUMBER && !showRequestingContractText),
                            message: (
                                <div>
                                    Notice: Your Contract Request has been submitted. Please ensure your contact details are up-to-date. One of our
                                    staff members will reach out to you soon.
                                </div>
                            )
                        }}
                        useSubTextStyle
                        required
                        {...inputPropsValue(
                            toTimeWithTimeZone(profile[expiry], setting.timezone)?.format?.("MMM DD, YYYY"),
                            {},
                            { noSanitize: true }
                        )}
                    />
                )}
                {isEditing && (
                    <Input
                        type={INPUT_TYPE.UPLOAD}
                        name={"uploads." + fileObj.key}
                        icon={<ID_SVG />}
                        accept={fileObj.accepts}
                        sizeLimit={fileObj.size}
                        label="File"
                        onChange={handleChange}
                        onView={previewInputChange}
                        {...inputPropsValue(!profile.uploads[fileObj.key] ? true : profile.uploads[fileObj.key])}
                        hidelabel
                    />
                )}
            </>
        );
    };

    if (isChangePass) {
        return <ChangePassword onCancel={toggleChangePass} onSubmit={toggleChangePass} />;
    }

    return (
        <Card className="box-shadow-mini" styles={styles}>
            <form className="profile-tab flex column" onSubmit={setPreventDefault(handleSubmit)}>
                <div className="flex column" style={{ gap: "2rem" }}>
                    <section className="flex column gap-05 center">
                        <CompanyAvatar editMode={isEditing} onChange={handleLogoChange} size={90} />
                        {!isGettingProfile && profile && (
                            <div className="name">
                                <span style={{ fontWeight: "600", textTransform: "capitalize", textAlign: "center" }}>
                                    {useArabicName ? profile.arabic_name : sanitizeWords(profile.name)}{" "}
                                    {!useArabicName && <span className="fade">({setting.prefix})</span>}
                                </span>
                            </div>
                        )}
                    </section>
                    <section className="flex column gap-05">
                        {!isEditing && (
                            <div className="flex column gap-05">
                                <Input
                                    type={INPUT_TYPE.TEXT}
                                    label="Prefix"
                                    renderValue={setting.prefix}
                                    isLoading={isGettingProfile}
                                    tooltip="Cannot be Changed."
                                    readOnly
                                />

                                <Input
                                    type={INPUT_TYPE.TEXT}
                                    label="English Name"
                                    renderValue={sanitizeWords(profile.name)}
                                    isLoading={isGettingProfile}
                                    tooltip="Cannot be Changed."
                                    readOnly
                                />
                            </div>
                        )}
                        <Input
                            type={INPUT_TYPE.TEXT}
                            name="arabic_name"
                            label="Arabic Name"
                            onChange={handleChange}
                            {...inputPropsValue(profile.arabic_name)}
                            subtext={{
                                hide: !error["arabic_name"],
                                message: (
                                    <Text className="danger-color" useSubTextStyle>
                                        {error["arabic_name"]}
                                    </Text>
                                )
                            }}
                            isLoading={isGettingProfile}
                        />
                        {!isEditing && (
                            <div className="flex column gap-05">
                                <Input
                                    type={INPUT_TYPE.TEXT}
                                    label="Country"
                                    renderValue={getCountry(profile.country).label}
                                    isLoading={isGettingProfile}
                                    tooltip="Cannot be Changed."
                                    readOnly
                                />
                            </div>
                        )}
                        {renderInput({
                            name: "industry_type",
                            label: "Industry",
                            onChange: handleChange,
                            value: getIndustry(profile?.industry_type),
                            options: industries
                        })}
                        <Input
                            type={INPUT_TYPE.TEXT}
                            name="address"
                            label="Address"
                            onChange={handleChange}
                            {...inputPropsValue(profile.address)}
                            isLoading={isGettingProfile}
                            required
                        />
                        {!isEditing && (
                            <Input
                                type={INPUT_TYPE.TEXT}
                                name="name"
                                label="Email Address"
                                renderValue={profile.email}
                                isLoading={isGettingProfile}
                                readOnly
                            />
                        )}
                    </section>
                    {!isEditing && hasContract && (
                        <section className="flex column gap-05">
                            {createFileDetails({
                                title: "Contract Details",
                                name: PROFILE_FIELDS.CONTRACT_NUMBER,
                                expiry: PROFILE_FIELDS.CONTRACT_EXPIRY_DATE,
                                fileObj: COMPANY_CONTRACT,
                                modalTypeKey: VIEW_MODAL_TYPE.CONTRACT_COPY
                            })}
                        </section>
                    )}
                    <section className="flex column gap-05">
                        {createFileDetails({
                            title: "License Trade Details",
                            name: PROFILE_FIELDS.LICENSE_TRADE_NUMBER,
                            expiry: PROFILE_FIELDS.LICENSE_TRADE_EXPIRY_DATE,
                            fileObj: LICENSE_TRADE,
                            modalTypeKey: VIEW_MODAL_TYPE.LICENSE_TRADE_COPY
                        })}
                    </section>
                    <section className="flex column gap-05">
                        {createFileDetails({
                            title: "License Commercial Details",
                            name: PROFILE_FIELDS.LICENSE_COMMERCIAL_NUMBER,
                            expiry: PROFILE_FIELDS.LICENSE_COMMERCIAL_EXPIRY_DATE,
                            fileObj: LICENSE_COMMERCIAL,
                            modalTypeKey: VIEW_MODAL_TYPE.LICENSE_COMMERCIAL_COPY
                        })}
                    </section>
                    <section className="flex column gap-05">
                        {createFileDetails({
                            title: "Establishment Details",
                            name: PROFILE_FIELDS.EID_NUMBER,
                            expiry: PROFILE_FIELDS.EID_EXPIRY_DATE,
                            fileObj: ESTABLISHMENT_ID,
                            modalTypeKey: VIEW_MODAL_TYPE.ESTABLISHMENT_ID_COPY
                        })}
                    </section>
                    <section className="flex column gap-05">
                        <Divider title="Contact" />
                        <Input
                            type={INPUT_TYPE.TEXT}
                            name="contact_name"
                            label="Name"
                            onChange={handleChange}
                            isLoading={isGettingProfile}
                            {...inputPropsValue(sanitizeWords(profile.contact_name))}
                        />
                        {isEditing ? (
                            <Input
                                type={INPUT_TYPE.MOBILE}
                                name="contact_number"
                                label="Mobile Number"
                                onChange={handleChange}
                                style={{ minWidth: "8rem" }}
                                value={profile.contact_number}
                                portalCodes
                                isCodeRightPos
                                {...inputPropsValue(profile.contact_number)}
                            />
                        ) : (
                            <Input
                                type={INPUT_TYPE.TEXT}
                                name="contact_number"
                                label="Mobile Number"
                                onChange={handleChange}
                                isLoading={isGettingProfile}
                                {...inputPropsValue(toReadablePhone(profile.contact_number))}
                            />
                        )}
                        <Input
                            type={INPUT_TYPE.TEXT}
                            nativeType="email"
                            name="contact_email"
                            label="Email"
                            onChange={handleChange}
                            isLoading={isGettingProfile}
                            {...inputPropsValue(profile.contact_email, {}, { noSanitize: true })}
                        />
                        <Input
                            type={INPUT_TYPE.TEXT}
                            name="contact_position"
                            label="Position"
                            onChange={handleChange}
                            minLength={2}
                            isLoading={isGettingProfile}
                            {...inputPropsValue(sanitizeWords(profile.contact_position))}
                        />
                    </section>
                    <Controls
                        beforeExtra={
                            !isEditing &&
                            !isChangePass && (
                                <Button
                                    options={{ type: "button" }}
                                    beforeExtra={<LockResetIcon style={{ color: "red" }} />}
                                    onClick={toggleChangePass}
                                    disabled={isLoading}
                                    small
                                    mini
                                >
                                    <span>Change Password</span>
                                </Button>
                            )
                        }
                        onCancel={handleCancel}
                        onEdit={toggleEdit}
                        isLoading={isLoading}
                        isEditing={isEditing}
                        disableSave={hasError || isLoading || !hasChanges}
                    />
                </div>
                {!!viewObject.type && (
                    <ViewModalSub
                        open={!!viewObject.type}
                        onChange={(bool) => handleViewChange({ type: bool ? viewObject.type : null })}
                        type={viewObject.type}
                        data={viewObject.data}
                    />
                )}
            </form>
        </Card>
    );
}

export default ProfileCompanyTab;

ProfileCompanyTab.propTypes = {
    loading: PropTypes.bool,
    setParentLoading: PropTypes.func,
    onLoading: PropTypes.func,
    styles: PropTypes.shape({
        parent: PropTypes.object,
        content: PropTypes.object
    })
};
