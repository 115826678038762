import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import isNull from "lodash/isNull";
import isEqual from "lodash/isEqual";

import {
    TOAST_TYPE,
    createEditingInfo,
    createToast,
    isEqualWithDates,
    sanitizeWords,
    toReadableSelectOptions
} from "../../../common/utilities/helper";
import { SHIFT_TYPE, SORT_ORDER, STANDARD_DATE_FORMAT_WITH_TIME } from "../../../common/utilities/const";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser, selectUserSetting } from "../../common/slice";
import { isReqHoursSatisfied, checkShiftDayDifferences, createVars } from "./helper";
import { BREAK_TYPE, DATE_FIELDS, FIELDS, FORM_FIELDS, HOL_PREFIX } from "./const";

import {
    useCreateWorkshiftMutation,
    useDeleteWorkshiftMutation,
    useGetWorkshiftByIdsMutation,
    useGetWorkshiftDetailsMutation,
    useLoadAllLazyWorkshiftMutation,
    useLoadAllWorkshiftMutation,
    useUpdateWorkshiftMutation
} from "../employeeWorkShift/api";

import Tooltip from "../../../common/components/extra/Tooltip";
import usePaginateFetch from "../../../common/hooks/usePaginateFetch";
import {
    selectCurrent,
    selectEmployeeWorkShiftData,
    selectTableConfig,
    setState,
    defaultConfig,
    selectLoading,
    setLoading,
    setCurrent
} from "./slice";
import { useFetchRecord, useUpsertRecord } from "../../common/hooks";

export const useGetWorkShiftDetails = (id, { runOnMount = true, onMount } = {}) => {
    const [current, { isLoading, updateCurrent, fetch, reset, clearCurrent, refetch }] = useFetchRecord(
        {
            id,
            rtk: {
                useGetMutation: useGetWorkshiftDetailsMutation,
                selectData: selectEmployeeWorkShiftData,
                selectCurrent,
                setCurrent,
                setState
            },
            dateFields: DATE_FIELDS,
            dateFormat: STANDARD_DATE_FORMAT_WITH_TIME
        },
        { runOnMount: !!runOnMount, onMount }
    );

    const setting = useAppSelector(selectUserSetting);

    const checkAllowEditTime = () => {
        if (!current) {
            return {};
        }
        const hasEmployees = !!current.Employees?.length;
        const isSplit = current.shift_type === SHIFT_TYPE.SPLIT;
        const calculateMinStartTime = current.start_time;
        const calculateMaxEndTime = isSplit ? current.end_time_2 : current.end_time;

        const editInfo = createEditingInfo({
            start: calculateMinStartTime,
            end: calculateMaxEndTime,
            ot: current.max_overtime,
            timezone: setting.timezone
        });

        return {
            isAllowed: !hasEmployees || editInfo.isEditAllowed,
            allowedTime: {
                after: editInfo.afterTime,
                before: editInfo.beforeTime
            }
        };
    };

    return [
        current,
        {
            fetch,
            isLoading,
            updateCurrent,
            reset,
            clearCurrent,
            refetch,
            allowEditInfo: checkAllowEditTime()
        }
    ];
};

export const useGetWorkShiftByIds = (ids = []) => {
    const [object, setObject] = useState({
        data: [],
        mounted: false
    });

    const [getWorkShifts, { isLoading }] = useGetWorkshiftByIdsMutation();

    const updateObject = (newObj = {}) => setObject({ ...object, ...newObj });

    const fetch = async () => {
        try {
            const response = await getWorkShifts({ body: { ids } });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data;
                updateObject({ data: resdata });
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            updateObject({ data: [] });
        }
    };

    useEffect(() => {
        updateObject({ mounted: true });
    }, []);

    useEffect(() => {
        if (object.mounted) {
            fetch();
        }
    }, [object.mounted]);

    return [object.data, isLoading, { isMounted: object.mounted }];
};

export const usePaginateWorkShift = ({ readOnly, isHoliday } = {}) => {
    const [load, isLoading, { onFilter, onSearch, data, onSort, onUpdate, tableConfig, resetTableConfig }] = usePaginateFetch(
        useLoadAllWorkshiftMutation,
        {
            redux: {
                dataSelector: selectEmployeeWorkShiftData,
                tableConfigSelector: selectTableConfig,
                currentSelector: selectCurrent,
                setState
            },
            defaultConfig,
            onMountConfig: {
                isHoliday: !!isHoliday,
                // make sure to reset sort when tab is changed
                sortBy: defaultConfig.sortBy,
                order: defaultConfig.order
            },
            runOnMount: !readOnly
        }
    );

    const fetch = async (config, isHolidayFlag) => {
        try {
            const response = await load({
                ...tableConfig,
                ...(config || {}),
                isHoliday: !!(isHolidayFlag || isHoliday)
            });
            if (response.error) {
                throw new Error("Failed to fetch data. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    return [data, { isLoading, fetch, update: onUpdate, onSort, onFilter, tableConfig, onSearch, resetTableConfig }];
};

export const useLazyWorkShift = ({ defaultValue, initializing, isReadableSelected, onMount = () => {}, isFilter, noHolidays, onlyHolidays } = {}) => {
    const LOAD_MORE_OFFSET = 5;
    const DEFAULT_SIZE = 15;

    const [fetching, setFetching] = useState(true);
    const [isMounted, setMounted] = useState(false);

    const [object, setObject] = useState({
        data: [],
        sort: { sortBy: FIELDS.TITLE.name, order: SORT_ORDER.ASC },
        totalCount: 0,
        cursor: "",
        search: ""
    });

    const hasMore = object.totalCount > object.data?.length || 0;

    const [load, { isLoading }] = useLoadAllLazyWorkshiftMutation();

    const updateObject = (newObject = {}) => setObject((prev) => ({ ...prev, ...newObject }));

    const createFixedValuesFromDefault = () =>
        Array.isArray(defaultValue)
            ? defaultValue
                  .filter(() => {
                      return isReadableSelected;
                  })
                  .map((item) => item.id)
            : [];

    const createRowItem = (row) => {
        const temp = cloneDeep(row || []);
        if (!temp.id) {
            return null;
        }
        const title = sanitizeWords(temp.title);
        temp.value = temp.id;
        if (isFilter) {
            temp.label = (
                <div className="flex gap-05 align-center overflow-hidden" style={{ minHeight: "1.5rem" }}>
                    <Tooltip element="span" className="text-ellipsis bold" message={title}>
                        {title}
                    </Tooltip>
                </div>
            );
        } else {
            temp.label = (
                <div className="flex gap-05 align-center overflow-hidden" style={{ minHeight: "1.5rem" }}>
                    <Tooltip element="span" className="text-ellipsis semi-bold" message={title}>
                        {title}
                    </Tooltip>
                </div>
            );
        }
        for (const key in temp) {
            if (Object.prototype.hasOwnProperty.call(temp, key)) {
                const value = temp[key];
                if (isNull(value)) {
                    temp[key] = "";
                }
            }
        }
        return temp;
    };

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        if (!sort) {
            sort = object.sort;
        }
        if (isReset) {
            config.cursor = "";
        }
        if (noHolidays) {
            config.noHolidays = noHolidays;
        }
        if (onlyHolidays) {
            config.onlyHolidays = onlyHolidays;
        }
        try {
            const response = await load({
                body: {
                    pageSize: DEFAULT_SIZE,
                    more: isReset ? DEFAULT_SIZE : LOAD_MORE_OFFSET,
                    ...object.sort,
                    ...sort,
                    ...(config || {})
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data || [];
                const newdata = [...(resdata.data || [])];
                const sameCursor = isEqual(object.cursor, resdata.cursor);
                const temp = {
                    data: isReset ? newdata : !sameCursor ? [...object.data, ...newdata] : newdata,
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount
                };
                sort && (temp.sort = sort);
                temp.data = [...(temp.data || [])].map((item) => createRowItem(item, true));
                updateObject(temp);
                return temp.data;
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            sort && updateObject({ sort, data: [], totalCount: 0 });
        }
    };

    const loadMore = () => hasMore && fetch({ cursor: object.cursor });
    const reset = () => fetch({}, true);
    const search = (value = "") => fetch({ search: value }, true);
    const sort = ({ sortBy, order }) => fetch({ sort: { sortBy, order } }, true);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        isMounted &&
            !initializing &&
            reset()
                .then(onMount)
                .catch(() => {})
                .finally(() => setFetching(false));
    }, [isMounted, initializing]);

    return [
        object,
        updateObject,
        {
            fixedValues: createFixedValuesFromDefault(),
            initializing: fetching,
            isLoading,
            hasMore,
            fetch,
            reset,
            loadMore,
            search,
            sort,
            createRowItem
        }
    ];
};

export const useDeleteWorkShift = () => {
    const dispatch = useAppDispatch();

    const [deleteWorkShift] = useDeleteWorkshiftMutation();

    const isLoading = useAppSelector(selectLoading);

    const remove = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await deleteWorkShift({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to delete work shift.");
            }
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [remove, isLoading];
};

export const useUpsertWorkShift = (updateId) => {
    const isCreate = !updateId;

    const [warning, setWarning] = useState({});
    const [oldInternalForms, setOldInternalForms] = useState({
        breakType: BREAK_TYPE.WITH_BREAK,
        breakTypeOption: toReadableSelectOptions(BREAK_TYPE)
    });
    const [internalForms, setInternalForms] = useState({
        breakType: BREAK_TYPE.WITH_BREAK,
        breakTypeOption: toReadableSelectOptions(BREAK_TYPE)
    });

    const isNoBreak = internalForms.breakType == BREAK_TYPE.NO_BREAK;
    const isWithBreak = internalForms.breakType == BREAK_TYPE.WITH_BREAK;
    const isFlexibleBreak = internalForms.breakType == BREAK_TYPE.FLEXIBLE_BREAK;

    const [current, { isLoading: isGettingDetails, allowEditInfo }] = useGetWorkShiftDetails(updateId, {
        onMount: (result) => {
            if (!isCreate) {
                const getInternalBreakType = (obj = {}) => {
                    const hasBreak = obj[FIELDS.BREAK_TIME.name] || obj[FIELDS.BREAK_TIME_2.name];
                    const hasMaxBreak = obj[FIELDS.MAX_BREAK_DURATION.name];
                    if (hasBreak && hasMaxBreak) {
                        return BREAK_TYPE.WITH_BREAK;
                    } else if (!hasBreak && hasMaxBreak) {
                        return BREAK_TYPE.FLEXIBLE_BREAK;
                    } else {
                        return BREAK_TYPE.NO_BREAK;
                    }
                };
                setInternalForms({
                    ...internalForms,
                    breakType: getInternalBreakType(result)
                });
                setOldInternalForms({
                    ...internalForms,
                    breakType: getInternalBreakType(result)
                });
            }
        }
    });

    const [form, updateForm, { upsert, isUpserting, isCreating, isUpdating, old, hasChanges, error, updateError, clearError, hasError }] =
        useUpsertRecord(
            {
                updateId,
                defaultForm: FORM_FIELDS,
                current,
                isGettingRecord: isGettingDetails,
                rtk: {
                    useCreateMutation: useCreateWorkshiftMutation,
                    useUpdateMutation: useUpdateWorkshiftMutation,
                    setCurrent
                },
                dateFields: DATE_FIELDS,
                dateFormat: STANDARD_DATE_FORMAT_WITH_TIME
            },
            {
                onBeforeUpsert: async (obj = {}) => {
                    const config = createVars(obj, timezone);
                    let clonedform = cloneDeep(obj);
                    clonedform.Employees && delete clonedform.Employees;
                    if (clonedform.is_holiday) {
                        if (!clonedform.title.startsWith(HOL_PREFIX)) {
                            clonedform.title = HOL_PREFIX + clonedform.title;
                        }
                    } else {
                        if (clonedform.title.startsWith(HOL_PREFIX)) {
                            clonedform.title = clonedform.title.replace(HOL_PREFIX, "");
                        }
                    }

                    if (isNoBreak || isFlexibleBreak) {
                        clonedform[FIELDS.BREAK_TIME.name] = null;
                        clonedform[FIELDS.BREAK_END_TIME.name] = null;
                        clonedform[FIELDS.BREAK_TIME_2.name] = null;
                        clonedform[FIELDS.BREAK_END_TIME_2.name] = null;
                    }
                    if (isNoBreak) {
                        clonedform[FIELDS.MAX_BREAK_DURATION.name] = null;
                    } else if (isWithBreak) {
                        clonedform[FIELDS.MAX_BREAK_DURATION.name] = config.totalBreakDiff;
                    }
                    return clonedform;
                },
                onAfterUpsert: async () => {
                    setWarning(null);
                }
            }
        );

    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    const timezone = setting.timezone;
    const isEditAllowed = isCreate || allowEditInfo.isAllowed;
    const config = {
        ...createVars(form, timezone),
        showError: !!error?.all,
        showEditCapabilityWarning: !isCreate && !isEditAllowed,
        isNoBreak,
        isWithBreak,
        isFlexibleBreak
    };

    const validateOnChange = (param = {}, name) => {
        const obj = { ...form, ...param };

        if (name == FIELDS.TITLE.name) {
            return obj;
        }

        const selectedOT = obj[FIELDS.MAX_OVERTIME.name];
        const hasShiftOne = obj[FIELDS.START_TIME.name] && obj[FIELDS.END_TIME.name];
        const hasShiftTwo = obj[FIELDS.START_TIME_2.name] && obj[FIELDS.END_TIME_2.name];
        const isSplit = obj[FIELDS.SHIFT_TYPE.name] == SHIFT_TYPE.SPLIT;
        const hasValidShift = (!isSplit && hasShiftOne) || (isSplit && hasShiftOne && hasShiftTwo);

        // validate req hrs
        const isReqHrsSatisfied = !hasValidShift || (hasValidShift && isReqHoursSatisfied(obj[FIELDS.REQ_SHIFT_TIME.name], obj, internalForms));

        if (!isReqHrsSatisfied) {
            updateError({
                [FIELDS.START_TIME.name]: `The total shift duration must meet the specified required shift hours of ${
                    form[FIELDS.REQ_SHIFT_TIME.name]
                }hr. (Break is not included)`,
                [FIELDS.START_TIME_2.name]: `The total shift must meet the specified minimum requirement of ${
                    form[FIELDS.REQ_SHIFT_TIME.name]
                }hr. (Break is not included)`,
                all: null
            });
        } else {
            updateError({ [FIELDS.START_TIME.name]: null, [FIELDS.START_TIME_2.name]: null, all: null });
        }

        // validate OT hrs
        if (hasValidShift) {
            const shiftDayDifferences = checkShiftDayDifferences(obj, timezone);
            const maxOTHoursOption = config.maxOTHoursOption.map((option) => ({
                ...option,
                isDisabled: isSplit ? option.value > shiftDayDifferences.remainingHrsUntilEndDay : false
            }));
            const remaininingAvailableOTHours = shiftDayDifferences.remainingHrsUntilEndDay;
            const isAllOtHoursOptionsDisabled = maxOTHoursOption.filter((hour) => hour.value != 0).every((hour) => hour.isDisabled);

            const OTOptions = [...maxOTHoursOption];
            OTOptions.sort((a, b) => b.value - a.value);
            const invalidOTSelecton = selectedOT && selectedOT > remaininingAvailableOTHours;

            if (invalidOTSelecton) {
                const newOT = OTOptions.find((option) => option.value <= remaininingAvailableOTHours);
                if (newOT.value != selectedOT) {
                    obj[FIELDS.MAX_OVERTIME.name] = newOT.value;
                    updateWarning({ [FIELDS.MAX_OVERTIME.name]: null });
                }
            } else {
                if (isAllOtHoursOptionsDisabled) {
                    obj[FIELDS.MAX_OVERTIME.name] = FIELDS.MAX_OVERTIME.constraint.min;
                    updateWarning({ [FIELDS.MAX_OVERTIME.name]: null });
                } else {
                    if (!isEqualWithDates(obj, old) && name != FIELDS.MAX_OVERTIME.name) {
                        obj[FIELDS.MAX_OVERTIME.name] = "";
                        updateWarning({ [FIELDS.MAX_OVERTIME.name]: "Value has been cleared due to changes" });
                    }
                }
            }
        }
        return obj;
    };

    const updateWarning = (obj = {}) => setWarning({ ...warning, ...obj });

    return [
        form,
        updateForm,
        {
            upsert,
            isLoading: isGettingDetails,
            isUpserting,
            isCreating,
            isUpdating,
            config,
            old,
            hasChanges,
            hasInternalChanges: !isEqualWithDates(internalForms, oldInternalForms),
            isEditAllowed,
            allowedTime: allowEditInfo.allowedTime,
            error,
            clearError,
            updateError,
            warning,
            setWarning,
            updateWarning,
            internalForms,
            setInternalForms,
            hasError,
            validateOnChange
        }
    ];
};
