import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { createClass, createConfirmAlert, createGroup, sanitizeWords, toTimeWithTimeZone } from "../../../common/utilities/helper";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import { useUpsertCompanyHolidays } from "./hooks";
import { BASE_CLASS, COMPANY_HOLIDAY_CATEGORY, COMPANY_HOLIDAY_LEVEL, COMPANY_HOLIDAY_TYPE, FIELD } from "./const";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import WorkShiftSelectLazy from "../employeeWorkShift/WorkShiftSelectLazy";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import SiteSelectLazy from "../companySites/SiteSelectLazy";
import WorkDetailSelectLazy from "../employeeWorkDetails/WorkDetailSelectLazy";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import { NOTES_MAX_LEN } from "../../../common/utilities/const";
import { isDateRangeSame } from "./helper";
import SelectConstant from "../../../common/components/extra/select/SelectConstant";

function UpdateModal({ open, onClose, onBack, onFinish, id }) {
    const isCreate = !id;

    const [form, updateForm, { upsert, hasChanges, isGettingHoliday, isUpserting }] = useUpsertCompanyHolidays(id);
    const [error, setError] = useState({ [FIELD.START_DATE]: null, all: null });

    const hasError = Object.values(error).filter(Boolean).length > 0;
    const disableSave = hasError || (!isCreate && !hasChanges) || isUpserting;

    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;
    const isChangeTiming = form.type == COMPANY_HOLIDAY_TYPE.CHANGE_TIMING;
    const isCompanyWide = form.level == COMPANY_HOLIDAY_LEVEL.COMPANY_WIDE;
    const startDate = form[FIELD.START_DATE];
    const endDate = form[FIELD.END_DATE];

    const isDateSame = isDateRangeSame(startDate, endDate, timezone);

    const handleSave = async () => {
        const result = await upsert();
        if (!result.error) {
            if (error.all) {
                setError({ ...error, all: null });
            }
            typeof onFinish === "function" && onFinish(result);
        }
        if (result.error) {
            setError({ ...error, all: result.error.message });
        }
        return result.error;
    };

    const handleFormChange = ({ name, value } = {}) => {
        const temp = { [name]: value };
        switch (name) {
            case FIELD.NOTES: {
                if (error.all) setError({ ...error, all: null });
                break;
            }
            case FIELD.START_DATE: {
                const start = (value && toTimeWithTimeZone(value, timezone)) || "";
                const end = (endDate && toTimeWithTimeZone(endDate, timezone)) || "";
                if (end && start && moment(start.format()).isAfter(end.format())) {
                    setError({
                        ...error,
                        [FIELD.START_DATE]: "Invalid Date: Start Date cannot exceed the End Date."
                    });
                } else {
                    if (form[FIELD.END_DATE]) {
                        temp[FIELD.END_DATE] = toTimeWithTimeZone(endDate, timezone).endOf("day").format();
                    } else {
                        temp[FIELD.END_DATE] = toTimeWithTimeZone(start, timezone).endOf("day").format();
                    }
                    if (error[FIELD.START_DATE]) {
                        setError({ ...error, [FIELD.START_DATE]: null });
                    }
                }
                break;
            }
            case FIELD.END_DATE: {
                const start = (startDate && toTimeWithTimeZone(startDate, timezone)) || "";
                const end = (value && toTimeWithTimeZone(value, timezone)) || "";

                if (startDate && !value) {
                    temp[FIELD.END_DATE] = toTimeWithTimeZone(startDate, timezone).endOf("day").format();
                }
                if (!value || (end && start && moment(end.format()).isAfter(start.format()))) {
                    if (error[FIELD.START_DATE]) {
                        setError({ ...error, [FIELD.START_DATE]: null });
                    }
                }
                break;
            }
            default:
                break;
        }
        if (error.all) {
            setError({ ...error, all: null });
        }
        updateForm(temp);
    };

    const renderApplyTo = () => {
        switch (form.level) {
            case COMPANY_HOLIDAY_LEVEL.BY_WORK_SHIFT:
                return (
                    <WorkShiftSelectLazy
                        label="Apply To"
                        value={form[FIELD.WORK_SHIFTS]}
                        onChange={(value) => handleFormChange({ name: FIELD.WORK_SHIFTS, value })}
                        required
                        forHoliday
                        isMulti
                        isClearable
                        isPortal
                    />
                );
            case COMPANY_HOLIDAY_LEVEL.BY_WORK_SITE:
                return (
                    <SiteSelectLazy
                        label="Apply To"
                        value={form[FIELD.WORK_SITES]}
                        onChange={(value) => handleFormChange({ name: FIELD.WORK_SITES, value })}
                        required
                        isMulti
                        isClearable
                        isPortal
                    />
                );
            case COMPANY_HOLIDAY_LEVEL.BY_WORK_TYPE:
                return (
                    <WorkDetailSelectLazy
                        label="Apply To"
                        value={form[FIELD.WORK_TYPES]}
                        onChange={(value) => handleFormChange({ name: FIELD.WORK_TYPES, value })}
                        allowInUse
                        required
                        isMulti
                        isClearable
                        isPortal
                    />
                );
            default:
                return;
        }
    };

    return (
        <BaseUpdateModal
            open={open}
            onClose={onClose}
            onBack={(!isCreate && onBack) || null}
            onSave={(e) =>
                createConfirmAlert({
                    title: !isCreate ? "Update Record" : "Create Record",
                    content: `Are you sure you want to ${isCreate ? "create" : "update"} this record? This cannot be undone.`,
                    onConfirm: async (close) => {
                        close();
                        const hasError = await handleSave(e);
                        if (!hasError) {
                            onBack();
                        }
                    }
                })
            }
            disableSave={disableSave}
            isLoading={isUpserting}
            isForm
        >
            {createGroup({
                base: createClass("__modal-content-update", BASE_CLASS),
                title: `${isCreate ? "Create" : "Update"} Holiday`,
                body: (
                    <div className="flex column gap-05">
                        <SectionCollapseError show={!!error.all}>{error.all}</SectionCollapseError>
                        <div className="flex gap-1 wrap" style={{ marginTop: "1rem" }}>
                            <div className="flex column gap-05" style={{ flex: 1 }}>
                                <Input
                                    name={FIELD.NAME}
                                    value={sanitizeWords(form[FIELD.NAME])}
                                    label="Name"
                                    type={INPUT_TYPE.TEXT}
                                    onChange={(e) => handleFormChange({ name: FIELD.NAME, value: e.target.value })}
                                    isLoading={isGettingHoliday}
                                    required
                                />
                                <SelectConstant
                                    label="Category"
                                    value={form[FIELD.CATEGORY]}
                                    base={COMPANY_HOLIDAY_CATEGORY}
                                    onChange={(target) => handleFormChange({ name: FIELD.CATEGORY, value: target.value })}
                                    isLoading={isGettingHoliday}
                                    required
                                    isOutlined
                                    disabledOutline
                                />
                                <SelectConstant
                                    label="Type"
                                    value={form[FIELD.TYPE]}
                                    base={COMPANY_HOLIDAY_TYPE}
                                    onChange={(target) => handleFormChange({ name: FIELD.TYPE, value: target.value })}
                                    isLoading={isGettingHoliday}
                                    required
                                    isOutlined
                                    disabledOutline
                                />
                                {isChangeTiming && (
                                    <WorkShiftSelectLazy
                                        label="Shift to Use"
                                        name={FIELD.WORK_SHIFT}
                                        onChange={(value) => handleFormChange({ name: FIELD.WORK_SHIFT, value })}
                                        value={form[FIELD.WORK_SHIFT]}
                                        isLoading={isGettingHoliday}
                                        required
                                        isClearable
                                        onlyHolidays
                                    />
                                )}
                                <SelectConstant
                                    label="Level"
                                    value={form[FIELD.LEVEL]}
                                    base={COMPANY_HOLIDAY_LEVEL}
                                    onChange={(target) => handleFormChange({ name: FIELD.LEVEL, value: target.value })}
                                    isLoading={isGettingHoliday}
                                    required
                                    isOutlined
                                    disabledOutline
                                />
                            </div>
                            <div className="flex column gap-05" style={{ flex: 1 }}>
                                <Input
                                    type={INPUT_TYPE.DATE}
                                    name={FIELD.START_DATE}
                                    label={<span style={{ whiteSpace: "nowrap" }}>Start Date</span>}
                                    onChange={(date) => handleFormChange({ name: FIELD.START_DATE, value: date })}
                                    selected={form[FIELD.START_DATE] && new Date(form[FIELD.START_DATE])}
                                    startDate={form[FIELD.START_DATE] && new Date(form[FIELD.START_DATE])}
                                    endDate={form[FIELD.END_DATE] && new Date(form[FIELD.END_DATE])}
                                    isLoading={isGettingHoliday}
                                    error={[error[FIELD.START_DATE]]}
                                    selectsStart
                                    required
                                    useSubTextStyle
                                />
                                <Input
                                    type={INPUT_TYPE.DATE}
                                    name={FIELD.END_DATE}
                                    label={<span style={{ whiteSpace: "nowrap" }}>End Date</span>}
                                    onChange={(date) => handleFormChange({ name: FIELD.END_DATE, value: date })}
                                    placeholder={form[FIELD.START_DATE] ? "Whole Day" : ""}
                                    selected={!isDateSame && form[FIELD.END_DATE] && new Date(form[FIELD.END_DATE])}
                                    startDate={form[FIELD.START_DATE] && new Date(form[FIELD.START_DATE])}
                                    endDate={form[FIELD.END_DATE] && new Date(form[FIELD.END_DATE])}
                                    minDate={form[FIELD.START_DATE] && new Date(form[FIELD.START_DATE])}
                                    required={!isDateSame}
                                    isLoading={isGettingHoliday}
                                    selectsEnd
                                />
                                <Input
                                    name={FIELD.IS_YEARLY}
                                    label="Repeat every year?"
                                    type={INPUT_TYPE.CHECKBOX}
                                    value={form[FIELD.IS_YEARLY]}
                                    onChange={(e) => handleFormChange({ name: FIELD.IS_YEARLY, value: e.target.checked })}
                                    tooltip={"Repeats the same Month and Date"}
                                    isLoading={isGettingHoliday}
                                />
                            </div>
                        </div>
                        {!isCompanyWide && <div style={{ marginTop: "1rem" }}>{renderApplyTo()}</div>}
                        <div className="flex column" style={{ marginTop: "1rem" }}>
                            <Input
                                name={FIELD.NOTES}
                                label="Notes/Description"
                                type={INPUT_TYPE.TEXTAREA}
                                value={form[FIELD.NOTES]}
                                parentStyle={{ height: "10rem", minHeight: "5rem" }}
                                onChange={(e) => handleFormChange({ name: FIELD.NOTES, value: e.target.value })}
                                maxLength={NOTES_MAX_LEN}
                                isLoading={isGettingHoliday}
                            />
                        </div>
                    </div>
                )
            })}
        </BaseUpdateModal>
    );
}

UpdateModal.propTypes = {
    photo: PropTypes.string,
    open: PropTypes.bool,
    id: PropTypes.any,
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func
};

export default UpdateModal;
